import { VIEW_FIELDS_FRAGMENT } from './fragments'

export const VIEWS_QUERY = gql`
  query ViewsQuery($objectType: String!, $workspaceId: String!) {
    views(objectType: $objectType, workspaceId: $workspaceId) {
      ...ViewFields
    }
  }
  ${VIEW_FIELDS_FRAGMENT}
`
