import { useMemo, useState, useEffect, useContext } from 'react'

import { GitHub } from '@mui/icons-material'
import { Box, Chip, Typography } from '@mui/material'
import {
  RiAddLine,
  RiAdminLine,
  RiFacebookFill,
  RiLinkedinFill,
  RiPencilLine,
  RiTwitterXFill,
} from '@remixicon/react'
import { IconBubbleText, IconCopy } from '@tabler/icons-react'
import toast from 'react-hot-toast'

import { navigate, routes } from '@redwoodjs/router'
import { useQuery } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import ActionCreateDialog from 'src/components/Actions/ActionCreateDialog/ActionCreateDialog'
import BaseChip from 'src/components/Chips/BaseChip'
import ContactAvatar from 'src/components/ContactAvatar/ContactAvatar'
import ContactActions from 'src/components/Contacts/ContactActions/ContactActions'
import OrganizationChip from 'src/components/Organizations/OrganizationChip/OrganizationChip'
import PersonAbout from 'src/components/People/PersonAbout/PersonAbout'
import PersonEnrichmentReview from 'src/components/People/PersonEnrichmentReview/PersonEnrichmentReview'
import PersonSlack from 'src/components/People/PersonSlack/PersonSlack'
import { GET_PERSON } from 'src/components/People/queries'
import Row from 'src/components/Row/Row'
import SlackLogo from 'src/components/Slack/SlackLogo/SlackLogo'
import ThreadCreateButton from 'src/components/Threads/ThreadCreateButton/ThreadCreateButton'
import {
  extractEmailDomain,
  NativeObjectFormatters,
} from 'src/lib/contactFormatting'
import { DayContext } from 'src/lib/dayContext'
import { isInternalUser, ungatedForNewMeetingsInSidebar } from 'src/lib/gates'
import { deepMerge, NativeObjectTypes } from 'src/lib/objects'

import SidebarContactAbout from '../SidebarContactAbout/SidebarContactAbout'
import SidebarEmailHistory from '../SidebarEmailHistory/SidebarEmailHistory'
import SidebarMeetingHistory from '../SidebarMeetingHistory/SidebarMeetingHistory'
import SidebarNotes from '../SidebarNotes/SidebarNotes'
import SidebarOrganization from '../SidebarOrganization/SidebarOrganization'
import SidebarPages from '../SidebarPages/SidebarPages'
import SidebarSectionExpand from '../SidebarSectionExpand/SidebarSectionExpand'
import { actionButtonStyle, socialIconStyle } from '../styles'

const GET_PAGES_FOR_PERSON = gql`
  query GetPagesForPerson($workspaceId: String!, $email: String!) {
    pagesWithPerson(workspaceId: $workspaceId, email: $email) {
      id
      title
      emoji
      madeExternalAt
      updatedAt
    }
  }
`

const GET_SLACK_USER_SIDEBAR = gql`
  query GetSlackUserSidebar($email: String!, $workspaceId: String) {
    getSlackUser(email: $email, workspaceId: $workspaceId) {
      id
      email
      slackUserId
      slackIntegration {
        id
        slackTeamId
        slackChannels {
          id
          slackChannelId
          name
          summary
        }
      }
    }
  }
`

const GET_MEETINGS_QUERY = gql`
  query GetContactSidebarMeetings($email: String!, $workspaceId: String!) {
    meetings: googleEventsForEmail(email: $email, workspaceId: $workspaceId) {
      id
      title
      start_time
      hangoutLink
      conferenceData
      location
      description
      attendees
      CalendarEvent {
        recordingSettings {
          canOverride
          enabled
          immediateRecordingStatus
          reason
        }
        meetingRecordings {
          id
          summary {
            output
          }
          video {
            private {
              thumbnail {
                url
              }
            }
          }
          clips {
            id
            participants {
              contact {
                objectId
              }
              email
            }
            title
          }
          statusHistory {
            status
            reason
            message
            createdAt
            level
          }
        }
      }
    }
  }
`

// const GET_ORGANIZATION_FOR_PERSON_SIDEBAR = gql`
//   query GetOrganizationForPersonSidebar(
//     $domain: String!
//     $workspaceId: String!
//   ) {
//     workspaceOrganization(domain: $domain, workspaceId: $workspaceId) {
//       id
//       name
//       photos {
//         square
//       }
//       colors {
//         colorVibrant
//       }
//       about {
//         aiDescription
//         description
//         employeesFrom
//         employeesTo
//       }
//       relationship {
//         highLevelSummary
//         oneSentenceSummary
//         proofOfPayment
//       }
//     }
//   }
// `

const SidebarLayoutContact = ({
  crmObject,
  setOpen,
  workspaceId,
  showAdmin = true,
}) => {
  const { currentUser } = useAuth()
  const { internalDomains, setSidebarObject } = useContext(DayContext)
  const [mergedCrmObject, setMergedCrmObject] = useState(crmObject)
  const [verified, setVerified] = useState(false)
  const [enrichmentReviewOpen, setEnrichmentReviewOpen] = useState(false)

  const { data: personData, refetch: refetchPerson } = useQuery(GET_PERSON, {
    variables: {
      email: crmObject?.objectId,
      workspaceId,
    },

    skip: !crmObject?.objectId || !workspaceId,
    pollInterval: verified ? 10000 : 5000,
    onCompleted: ({ getPerson }) => {
      setMergedCrmObject((prev) => ({
        ...prev,
        properties: deepMerge(prev.properties, getPerson),
      }))
    },
  })

  const { data: meetingsData, loading: meetingsLoading } = useQuery(
    GET_MEETINGS_QUERY,
    {
      variables: { email: crmObject?.objectId, workspaceId },
    }
  )

  const meetings = meetingsData?.meetings

  // const domain = extractEmailDomain(crmObject?.objectId)
  // const { data: organizationData } = useQuery(
  //   GET_ORGANIZATION_FOR_PERSON_SIDEBAR,
  //   {
  //     variables: {
  //       domain,
  //       workspaceId: selectedWorkspace,
  //     },
  //     skip: !crmObject?.objectId || !selectedWorkspace || !domain,
  //   }
  // )

  const { data: slackUser } = useQuery(GET_SLACK_USER_SIDEBAR, {
    variables: {
      email: crmObject?.objectId,
      workspaceId,
      skip: !crmObject?.objectId || !workspaceId,
    },
  })

  const person = personData?.getPerson || crmObject?.properties

  if (!verified && person?.verificationStatus?.verified === 'verified') {
    setVerified(true)
  }

  const { data: pagesData } = useQuery(GET_PAGES_FOR_PERSON, {
    variables: {
      workspaceId,
      email: crmObject?.objectId,
    },
  })

  const refreshPerson = () => {
    refetchPerson()
  }

  useEffect(() => {
    if (crmObject && !mergedCrmObject) {
      setMergedCrmObject(crmObject)
    }
  }, [crmObject, mergedCrmObject])

  const xUrl =
    person?.socialTwitter ||
    crmObject?.properties?.socialTwitter ||
    crmObject?.properties?.twitterUrl
  const linkedInUrl = person?.linkedInUrl || crmObject?.properties?.linkedInUrl
  const facebookUrl =
    person?.socialFacebook ||
    crmObject?.properties?.socialFacebook ||
    crmObject?.properties?.facebookUrl
  const githubUrl =
    person?.socialGithub ||
    crmObject?.properties?.socialGithub ||
    crmObject?.properties?.githubUrl
  const slack = slackUser?.getSlackUser?.slackUserId
    ? {
        channels: slackUser?.getSlackUser?.slackIntegration?.slackChannels,
        userId: slackUser?.getSlackUser?.slackUserId,
        teamId: slackUser?.getSlackUser?.slackIntegration?.slackTeamId,
      }
    : null

  const focus = crmObject?.properties?.sidebarFocus

  const isUnenriched = useMemo(() => {
    return (
      person &&
      !person.currentCompanyName &&
      !person.currentJobTitle &&
      !person.fullName
    )
  }, [person])

  const otherObjects = useMemo(() => {
    const output = []
    const domain = extractEmailDomain(crmObject?.objectId)
    if (domain) {
      output.push({
        objectType: NativeObjectTypes.Organization,
        objectId: domain,
      })
    }

    if (meetings?.meetingRecordings?.length > 0) {
      for (const meeting of meetings?.meetingRecordings || []) {
        if (meeting?.video?.private?.thumbnail?.url) {
          output.push({
            objectType: NativeObjectTypes.MeetingRecording,
            objectId: meeting.id,
          })
        }
      }
    }
    return output
  }, [crmObject, meetings])

  return (
    <Box
      key={`${crmObject?.objectId || 'personSidebar'}_sidebar`}
      sx={{
        height: '100%',
        overflowY: 'auto',
      }}
    >
      {showAdmin && (
        <Row
          sx={{
            justifyContent: 'space-between',
            alignItems: 'top',
            py: 1,
            px: 2,
          }}
        >
          <Row
            gap={2}
            sx={{ flexShrink: 1, pt: 2 }}
          >
            <ContactAvatar
              email={crmObject?.objectId}
              size={64}
              borderRadius={100}
              showVerification={true}
              showCompany={true}
            />
            <Box
              sx={{
                width: '100%',
                flexShrink: 1,
              }}
            >
              <Row>
                <Typography
                  variant="h1"
                  sx={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    flexShrink: 1,
                    fontSize: '1.5rem',
                  }}
                >
                  {person?.fullName ||
                    NativeObjectFormatters[NativeObjectTypes.Contact].label(
                      crmObject
                    )}
                </Typography>
                {(xUrl || linkedInUrl || facebookUrl || githubUrl || slack) && (
                  <Row
                    gap={'4px'}
                    sx={{ ml: 1 }}
                  >
                    {linkedInUrl && (
                      <Chip
                        variant="filled"
                        label=""
                        sx={{ ...socialIconStyle, background: '#0077B5' }}
                        icon={<RiLinkedinFill size={18} />}
                        clickable={true}
                        onClick={() => {
                          const url = linkedInUrl.startsWith('http')
                            ? linkedInUrl
                            : `https://${linkedInUrl}`
                          window.open(url, '_blank')
                        }}
                      />
                    )}
                    {xUrl && (
                      <Chip
                        variant="filled"
                        label=""
                        icon={
                          <RiTwitterXFill
                            style={{ color: 'white' }}
                            size={13}
                          />
                        }
                        clickable={true}
                        onClick={() => {
                          window.open(xUrl, '_blank')
                        }}
                        sx={{ ...socialIconStyle, background: '#000000' }}
                      />
                    )}
                    {facebookUrl && (
                      <Chip
                        variant="filled"
                        label=""
                        icon={
                          <RiFacebookFill
                            style={{ color: 'white' }}
                            size={15}
                          />
                        }
                        clickable={true}
                        onClick={() => {
                          window.open(facebookUrl, '_blank')
                        }}
                        sx={{ ...socialIconStyle, background: '#4267B2' }}
                      />
                    )}
                    {githubUrl && (
                      <Chip
                        variant="filled"
                        label=""
                        icon={<GitHub sx={{ color: 'white' }} />}
                        clickable={true}
                        onClick={() => {
                          window.open(githubUrl, '_blank')
                        }}
                        sx={{ ...socialIconStyle, background: '#333333' }}
                      />
                    )}
                    {slack && (
                      <Chip
                        variant="filled"
                        label=""
                        icon={
                          <Row sx={{ justifyContent: 'center' }}>
                            <SlackLogo size={18} />
                          </Row>
                        }
                        clickable={true}
                        onClick={() => {
                          const url = `slack://user?team=${slack.teamId}&id=${slack.userId}`
                          window.open(url, '_blank')
                        }}
                        sx={{ ...socialIconStyle, background: 'white' }}
                      />
                    )}
                  </Row>
                )}
              </Row>
              {enrichmentReviewOpen && (
                <PersonEnrichmentReview
                  email={crmObject?.objectId}
                  onClose={() => {
                    refetchPerson()
                    setEnrichmentReviewOpen(false)
                  }}
                />
              )}
              <Row>
                {isUnenriched ? (
                  <Chip
                    icon={<RiPencilLine />}
                    label="Edit & Enrich"
                    variant="outlined"
                    size="small"
                    sx={actionButtonStyle}
                    onClick={() => {
                      setEnrichmentReviewOpen(true)
                    }}
                  />
                ) : (
                  <Typography
                    sx={{
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      flexShrink: 1,
                      fontWeight: 500,
                      fontSize: '1.0rem',
                      letterSpacing: '-0.6px',
                    }}
                  >
                    {person?.currentJobTitle ||
                      NativeObjectFormatters[
                        NativeObjectTypes.Contact
                      ].subtitle(crmObject)}
                  </Typography>
                )}
              </Row>
            </Box>
          </Row>
        </Row>
      )}
      <Row
        gap={1}
        sx={{ pt: 1, pb: 2, pl: 2, flexWrap: 'wrap' }}
      >
        <Chip
          icon={<IconBubbleText size={16} />}
          label="Add context"
          variant="outlined"
          size="small"
          sx={actionButtonStyle}
          onClick={() => {
            setSidebarObject({
              mode: 'create',
              objectType: NativeObjectTypes.UserContext,
              objectId: 'CREATE',
              workspaceId,
              properties: {
                parentReferenceKey: `${NativeObjectTypes.Person} : ${crmObject?.objectId} : root`,
                onUpdate: refreshPerson,
              },
            })
          }}
        />
        <ActionCreateDialog
          variant="button"
          orgs={[extractEmailDomain(crmObject?.objectId)].filter(Boolean)}
          people={[mergedCrmObject?.objectId]}
          oppIds={[]}
          workspaceId={workspaceId}
          onUpdate={refreshPerson}
        />
        <Chip
          clickable={true}
          size="small"
          icon={<RiAddLine size={14} />}
          label="Opportunity"
          variant="outlined"
          sx={actionButtonStyle}
          onClick={() => {
            setSidebarObject({
              mode: 'create',
              objectType: NativeObjectTypes.Opportunity,
              objectId: 'CREATE',
              workspaceId,
              properties: {
                person: crmObject,
              },
            })
          }}
        />
        {internalDomains.includes(
          extractEmailDomain(crmObject?.objectId)
        ) ? null : (
          <OrganizationChip
            domain={extractEmailDomain(crmObject?.objectId)}
            size="small"
            sx={actionButtonStyle}
          />
        )}
        {slack?.channels?.length > 0 &&
          slack?.channels?.length < 3 &&
          slack?.channels?.map((channel) => (
            <Chip
              key={channel.slackChannelId}
              icon={<SlackLogo size={12} />}
              label={`#${channel.name}`}
              variant="outlined"
              size="small"
              sx={actionButtonStyle}
              onClick={() => {
                const url = `slack://channel?id=${channel.slackChannelId}&team=${slack.teamId}`
                window.open(url, '_blank')
              }}
            />
          ))}

        {isInternalUser(currentUser) && (
          <Chip
            label="Admin"
            icon={<RiAdminLine size={12} />}
            clickable={true}
            onClick={() => {
              navigate(routes.adminPerson({ email: person?.email }))
            }}
            variant="outlined"
            size="small"
            sx={actionButtonStyle}
          />
        )}
        <ThreadCreateButton
          sourceObject={crmObject}
          otherObjects={otherObjects}
          workspaceId={workspaceId}
          title={`Questions about ${person?.fullName || crmObject?.objectId}`}
          onThreadCreated={() => {
            setOpen(false)
          }}
        />
        {!isUnenriched && (
          <Chip
            label="Edit & Enrich"
            icon={<RiPencilLine size={12} />}
            clickable={true}
            onClick={() => {
              setEnrichmentReviewOpen(true)
            }}
            variant="outlined"
            size="small"
            sx={actionButtonStyle}
          />
        )}
      </Row>

      <Box sx={{ height: 'calc(100vh - 152px)', overflowY: 'auto' }}>
        <SidebarSectionExpand
          title="Actions"
          isOpen={focus === 'ACTIONS' || true}
        >
          <ContactActions email={crmObject?.objectId} />
        </SidebarSectionExpand>
        <SidebarSectionExpand
          title="About"
          isOpen={
            !focus && person?.fullName && !person?.relationship?.oneSentence
          }
        >
          {person?.fullName ? (
            <PersonAbout person={person} />
          ) : (
            <SidebarContactAbout contact={mergedCrmObject} />
          )}
        </SidebarSectionExpand>
        {slack && (
          <SidebarSectionExpand
            title="Slack"
            isOpen={focus === 'SLACK'}
          >
            <PersonSlack slack={slack} />
          </SidebarSectionExpand>
        )}
        <SidebarSectionExpand
          title="Emails"
          isOpen={focus === 'EMAILS'}
          objectChip={
            <BaseChip
              label={mergedCrmObject?.objectId}
              icon={<IconCopy />}
              onClick={(e) => {
                e.stopPropagation()
                // copy to clipboard and toast:
                navigator.clipboard.writeText(mergedCrmObject?.objectId)
                toast(`Email copied to clipboard`, { icon: '📨' })
              }}
              sx={{
                border: 'none',
              }}
            />
          }
        >
          <SidebarEmailHistory
            email={mergedCrmObject?.objectId}
            workspaceId={workspaceId}
          />
        </SidebarSectionExpand>

        {ungatedForNewMeetingsInSidebar(currentUser) && (
          <SidebarSectionExpand
            title="Meetings"
            count={meetings?.recentEvents?.length}
            isOpen={focus === 'MEETINGS'}
          >
            <SidebarMeetingHistory
              meetings={meetings}
              loading={meetingsLoading}
              workspaceId={workspaceId}
            />
          </SidebarSectionExpand>
        )}
        <SidebarSectionExpand
          title="Context"
          isOpen={focus === 'NOTES'}
          sx={{
            '& .sidebar-section-expand-container': {
              px: 0,
              '& .sidebar-section-expand-title-row': {
                px: 2,
              },
            },
          }}
        >
          <SidebarNotes email={mergedCrmObject?.objectId} />
        </SidebarSectionExpand>

        <SidebarSectionExpand
          title="Pages"
          isOpen={focus === 'PAGES'}
          count={pagesData?.pagesWithPerson?.length}
        >
          <SidebarPages
            pages={pagesData?.pagesWithPerson}
            setOpen={setOpen}
          />
        </SidebarSectionExpand>
        {extractEmailDomain(mergedCrmObject?.objectId) && (
          <SidebarSectionExpand
            title="Organization"
            isOpen={focus === 'ORGANIZATION'}
          >
            <SidebarOrganization
              domain={
                mergedCrmObject?.properties?.domain ||
                extractEmailDomain(mergedCrmObject?.objectId)
              }
            />
          </SidebarSectionExpand>
        )}
      </Box>
    </Box>
  )
}

export default SidebarLayoutContact
