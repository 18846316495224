const possibleRoles = [
  {
    key: 'DECISION_MAKER',
    label: 'Decision Maker',
    showInList: false,
    showChip: false,
    color: 'success',
  },
  {
    key: 'PRIMARY_CONTACT',
    label: 'Primary Contact',
    showInList: true,
    showChip: true,
    color: 'success',
  },
  {
    key: 'CHAMPION',
    label: 'Champion',
    showInList: true,
    showChip: true,
    color: 'success',
  },
  {
    key: 'SUPPORTER',
    label: 'Supporter',
    showInList: true,
    showChip: true,
    color: 'success',
  },
  {
    key: 'DETRACTOR',
    label: 'Skeptic',
    showInList: true,
    showChip: true,
    color: 'error',
  },
  {
    key: 'OWNER_EMAIL',
    label: 'Owner',
    showInList: false,
    showChip: false,
  },
  {
    key: 'HURDLE',
    label: 'Hurdle',
    showInList: true,
    showChip: true,
    color: 'warning',
  },
  {
    key: 'ECONOMIC_BUYER',
    label: 'Economic Buyer',
    showInList: true,
    showChip: true,
    color: 'info',
  },
  {
    key: 'OTHER',
    label: 'Other',
    showInList: true,
    showChip: false,
    color: 'secondary',
  },
  {
    key: 'IGNORE',
    label: 'Ignore',
    showInList: false,
    showChip: false,
  },
  {
    key: 'DIRECT_BENEFIT',
    label: 'Benefits',
    showInList: true,
    showChip: true,
    color: 'success',
  },
]

export const getKeyRoleEmailsWithFallback = ({
  organization,
  workspaces,
  peopleByEmail,
}) => {
  const workspaceMemberEmails = workspaces
    ?.map((workspace) =>
      (workspace.members || []).map((member) => member?.email)
    )
    .flat()
    .filter(Boolean)
  const rolesToShow = possibleRoles
    .map((pr) => {
      if (pr.showInList) {
        return pr.key
      }
    })
    .filter(Boolean)

  const roles = organization?.roles?.filter((role) => {
    const roleMatch = role.role.some((r) => {
      return rolesToShow.includes(r)
    })

    const isInternal = (workspaceMemberEmails || []).includes(role.email)
    const isDomain = role.email.endsWith(organization?.domain)

    return roleMatch && !isInternal && isDomain
  })

  const roleBasedEmails = (roles || [])
    .map((role) => role?.email)
    .filter(Boolean)

  const orgPeopleEmails = peopleByEmail
    ? Object.keys(peopleByEmail).filter((email) =>
        email?.endsWith(organization?.domain)
      )
    : []

  const orgBasedEmails = orgPeopleEmails.slice(0, 10)

  const allEmails = new Set<string>([...roleBasedEmails, ...orgBasedEmails])

  return Array.from(allEmails)
}

export default possibleRoles
