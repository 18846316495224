import { useContext, useEffect, useMemo, useState } from 'react'

import { Box, Button, CircularProgress, Typography } from '@mui/material'
import { IconPlayerPlayFilled } from '@tabler/icons-react'

import { isBrowser } from '@redwoodjs/prerender/browserUtils'
import { navigate, routes } from '@redwoodjs/router'
import { useQuery } from '@redwoodjs/web'

import ObjectChipRow from 'src/components/Chips/ObjectChipRow/ObjectChipRow'
import MeetingRecordingShareAsPage from 'src/components/MeetingRecordingShareAsPage/MeetingRecordingShareAsPage'
import Row from 'src/components/Row/Row'
import ThreadCreateButton from 'src/components/Threads/ThreadCreateButton/ThreadCreateButton'
import { getEventCRMObjects } from 'src/components/Today/eventRecordingStates'
import { DayContext } from 'src/lib/dayContext'
import { dayjs } from 'src/lib/dayjs'
import { logger } from 'src/lib/logger'

import SidebarSectionExpand from '../SidebarSectionExpand/SidebarSectionExpand'
import { actionButtonStyle } from '../styles'

const GET_MEETING_RECORDING_FOR_SIDEBAR = gql`
  query GetMeetingRecordingForSidebar($workspaceId: String!, $id: String!) {
    meetingRecording: workspaceMeetingRecording(
      workspaceId: $workspaceId
      id: $id
    ) {
      id
      startedAt
      statusHistory {
        level
        status
        reason
        message
        createdAt
      }
      title
      startedAt
      endedAt
      authorization {
        workspace {
          isShared
        }
        users {
          id
          accessLevel
        }
      }
      participants {
        email
        usernameFromRecording
        contact {
          objectId
          objectType
          properties
        }
        eventHistory {
          event
          createdAt
        }
      }
      transcript {
        status
        utterances {
          words {
            text
            startSeconds
            endSeconds
          }
          speaker
          language
        }
      }
      summary {
        status
        output
      }
      video {
        private {
          download {
            status
            urls {
              default
              quality
              url
            }
          }
          playbackId
          tokens {
            video
            thumbnail
            storyboard
          }
          thumbnail {
            status
            url
          }
        }
      }
      clips {
        id
        title
        description
        startSeconds
        endSeconds
        source
        video {
          private {
            playbackId

            download {
              status
              urls {
                default
                quality
                url
              }
            }
            thumbnail {
              status
              url
            }
            tokens {
              video
              thumbnail
              storyboard
            }
          }
        }
        participants {
          email
          usernameFromRecording
          eventHistory {
            event
            createdAt
          }
        }
        deletedAt
      }
      calendarEvents {
        GoogleEvent {
          id
          googleId
          start_time
          end_time
          description
          attendees
          title
        }
      }
    }
  }
`

const SidebarLayoutMeetingRecording = ({ crmObject, setOpen }) => {
  const {
    selectedWorkspace,
    internalDomains,
    workspaces,
    peopleByEmail,
    orgsByDomain,
  } = useContext(DayContext)
  const [playerModule, setPlayerModule] = useState<{
    default: typeof PlayerContainer
  }>(null)

  const { data } = useQuery(GET_MEETING_RECORDING_FOR_SIDEBAR, {
    variables: {
      workspaceId: selectedWorkspace,
      id: crmObject?.objectId,
    },
    skip: !crmObject?.objectId || !selectedWorkspace,
  })

  const workspaceMembers = workspaces
    .map((workspace) => workspace.members)
    .flat()
    .map((member) => member.email)

  useEffect(() => {
    if (isBrowser) {
      import('src/components/Video/PlayerContainer/PlayerContainer').then(
        (module) => {
          setPlayerModule(module)
        }
      )
    }
  }, [])

  const PlayerContainer = playerModule?.default

  const meeting = useMemo(() => {
    if (!crmObject?.properties && !data?.meetingRecording) {
      return null
    }
    if (!data?.meetingRecording) {
      return crmObject?.properties
    }
    return data?.meetingRecording
  }, [crmObject, data])

  const meetingObjects = useMemo(() => {
    const params = {
      event: data?.meetingRecording?.calendarEvents?.[0]?.GoogleEvent,
      internalDomains,
      workspaceMembers,
      peopleByEmail,
      orgsByDomain,
    }
    logger.dev({ ...params, data })
    if (!params.event) {
      return []
    }
    return getEventCRMObjects(params)
  }, [data, internalDomains, workspaceMembers, peopleByEmail, orgsByDomain])

  return meeting ? (
    <Box sx={{ height: 'calc(100vh - 52px)', overflowY: 'auto' }}>
      {PlayerContainer && (
        <PlayerContainer
          playbackId={meeting?.video?.private?.playbackId}
          tokens={meeting?.video?.private?.tokens}
        />
      )}
      <Row sx={{ justifyContent: 'space-between', pl: 2, pr: '11px', mt: 1 }}>
        <Typography
          variant="h2"
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {meeting.title}
        </Typography>
      </Row>
      <Row sx={{ justifyContent: 'space-between', px: 2, mt: 1, mb: 2 }}>
        <Typography
          sx={{
            color: 'text.secondary',
            opacity: 0.8,
            fontWeight: 600,
            letterSpacing: '-0.6px',
            cursor: 'pointer',
            '&:hover': {
              color: (theme) => theme.palette.secondary.main,
            },
          }}
          onClick={() => {
            navigate(
              routes.workspaceMeetingRecording({
                workspaceId: selectedWorkspace,
                recordingId: meeting?.id,
              })
            )
            setOpen(false)
          }}
        >
          {dayjs(meeting.startedAt).format('MMM D, YYYY h:mm A')}
        </Typography>
        <Row gap={1}>
          <MeetingRecordingShareAsPage
            meetingRecordingId={meeting?.id}
            event={meeting?.event}
            useIcon={true}
          />
          <ThreadCreateButton
            sourceObject={crmObject}
            otherObjects={[]}
            title="Thread"
            onThreadCreated={() => {
              setOpen(false)
            }}
            workspaceId={selectedWorkspace}
          />
          <Button
            sx={{
              ...actionButtonStyle,
              height: '24px',
              flexShrink: 0,
              width: '104px',
              fontSize: '11px',
            }}
            onClick={() => {
              navigate(
                routes.workspaceMeetingRecording({
                  workspaceId: selectedWorkspace,
                  recordingId: meeting?.id,
                })
              )
              setOpen(false)
            }}
            startIcon={
              <IconPlayerPlayFilled
                size={16}
                style={{
                  marginRight: '-2px',
                  paddingLeft: '4px',
                  flexShrink: 0,
                }}
              />
            }
          >
            View more
          </Button>
        </Row>
      </Row>
      {meetingObjects.length > 0 && (
        <Box sx={{ mt: 1, px: 2 }}>
          <ObjectChipRow
            objects={meetingObjects}
            workspaceId={selectedWorkspace}
            showSidebar={true}
          />
        </Box>
      )}

      <SidebarSectionExpand
        title="Summary"
        isOpen={true}
        plainText={meeting?.summary?.output?.Summary_Short}
      />
      {meeting?.summary?.output?.tabs &&
        meeting?.summary?.output?.tabs[0]?.content && (
          <Box
            sx={{
              h2: {
                fontSize: '16px',
                fontWeight: 600,
                letterSpacing: '-0.4px',
                mt: 2,
              },
            }}
          >
            <SidebarSectionExpand
              title="Meeting Notes"
              isOpen={true}
              plainText={meeting?.summary?.output?.tabs[0]?.content}
              markdown={true}
            />
          </Box>
        )}
    </Box>
  ) : (
    <Row
      sx={{ justifyContent: 'center', alignItems: 'center', height: '100%' }}
    >
      <CircularProgress />
    </Row>
  )
}

export default SidebarLayoutMeetingRecording
