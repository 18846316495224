import { Box, Typography } from '@mui/material'

import ObjectTile from 'src/components/ObjectTile/ObjectTile'

interface ObjectPreviewMenuProps {
  objectType: string
  objectId: string
  workspaceId: string
}

const containerSx = {
  width: '320px',
  background: (theme) => theme.palette.background.paper,
  p: 1,
  borderRadius: '4px',
  boxShadow: (theme) => theme.shadows[1],
  border: (theme) => `1px solid ${theme.palette.divider}`,
  '& .object-preview-view-more': {
    fontSize: '11px',
    color: (theme) => theme.palette.text.secondary,
    textAlign: 'center',
    width: '100%',
    padding: '4px 0',
  },
}

const ObjectPreviewMenu = ({
  objectType,
  objectId,
  workspaceId,
}: ObjectPreviewMenuProps) => {
  return (
    <Box sx={containerSx}>
      <ObjectTile
        objectType={objectType}
        objectId={objectId}
        workspaceId={workspaceId}
        size={36}
      />
      <Typography className="object-preview-view-more">
        (click to view more)
      </Typography>
    </Box>
  )
}

export default ObjectPreviewMenu
