import { useState, useRef, useContext } from 'react'

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material'
import {
  IconDotsVertical,
  IconFileSettings,
  IconMoodSmile,
  IconPencil,
  IconPhotoPlus,
  IconTrash,
} from '@tabler/icons-react'
import EmojiPicker from 'emoji-picker-react'
import { PickerOverlay } from 'filestack-react'
import { useConfirm } from 'material-ui-confirm'

import { navigate, routes, useLocation } from '@redwoodjs/router'
import { useMutation } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import { DayContext } from 'src/lib/dayContext'
import { logger } from 'src/lib/logger'
import { getUserAuthorizations } from 'src/lib/objects'

const DELETE_PAGE_FROM_ACTIONS = gql`
  mutation DeletePageFromActions($id: String!) {
    deletePage(id: $id) {
      id
    }
  }
`

const UPDATE_PAGE_FROM_ACTIONS = gql`
  mutation UpdatePageFromActions(
    $id: String!
    $madeExternalAt: DateTime
    $emoji: String
    $headerImage: String
    $workspaceId: String!
  ) {
    updatePage(
      id: $id
      input: {
        madeExternalAt: $madeExternalAt
        emoji: $emoji
        headerImage: $headerImage
        workspaceId: $workspaceId
      }
    ) {
      id
      madeExternalAt
      emoji
      headerImage
    }
  }
`

const menuSx = {
  pb: 0,
  '& .MuiMenuItem-root': {
    fontSize: '0.8rem',
  },
}

const iconButtonSx = {
  p: '4px',
  borderRadius: '3px',
  '& .tabler-icon': {
    height: '16px',
    width: '16px',
    opacity: 0.8,
  },
}

const PageActions = ({
  page,
  refetch = () => {},
  orientation = 'horizontal',
}: {
  page: any
  refetch?: () => void
  orientation?: 'horizontal' | 'vertical'
}) => {
  const { currentUser } = useAuth()
  const { workspaces } = useContext(DayContext)

  const workspace = (workspaces || []).find(({ id }) => id === page.workspaceId)

  const { userHasEditorAccess } = getUserAuthorizations({
    currentUser,
    authorization: page.authorization,
    workspace,
  })

  const confirm = useConfirm()
  const [menuOpen, setMenuOpen] = useState(false)
  const [editingEmoji, setEditingEmoji] = useState(false)
  const [editingPageHeader, setEditingPageHeader] = useState(false)
  const anchorEl = useRef(null)

  const iconSize = 18

  const location = useLocation()

  const onPage = location.pathname === routes.pageDetail({ id: page.id })

  const [deletePage] = useMutation(DELETE_PAGE_FROM_ACTIONS, {
    onCompleted: () => {
      refetch()
    },
  })

  const [updatePage] = useMutation(UPDATE_PAGE_FROM_ACTIONS, {
    onCompleted: () => {
      refetch()
    },
  })

  const handleMenuToggle = (e) => {
    e.stopPropagation()
    setMenuOpen((prevOpen) => !prevOpen)
  }

  const handleMenuClose = (e) => {
    e.stopPropagation()
    setMenuOpen(false)
  }

  const handleSetEmoji = (emoji) => {
    updatePage({
      variables: {
        id: page.id,
        emoji: emoji,
        title: 'new title',
        workspaceId: page.workspaceId,
      },
    }).then(() => {
      setEditingEmoji(false)
    })
  }

  const handleSetHeaderImage = (headerImage) => {
    updatePage({
      variables: {
        id: page.id,
        headerImage: headerImage,
        workspaceId: page.workspaceId,
      },
    }).then(() => {
      setEditingPageHeader(false)
    })
  }

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls="page-actions-menu"
        aria-haspopup="true"
        onClick={handleMenuToggle}
        size="small"
        ref={anchorEl}
        sx={iconButtonSx}
      >
        {orientation === 'vertical' ? (
          <IconDotsVertical size={iconSize} />
        ) : (
          <IconDotsVertical size={iconSize} />
        )}
      </IconButton>
      <Menu
        id="page-actions-menu"
        anchorEl={anchorEl.current}
        keepMounted
        open={menuOpen}
        onClose={handleMenuClose}
        onClick={handleMenuClose}
        sx={menuSx}
      >
        {!onPage && (
          <MenuItem
            onClick={() => navigate(routes.pageDetail({ id: page.id }))}
          >
            <Box
              component="span"
              sx={{ mr: 1 }}
            >
              <IconPencil size={iconSize} />
            </Box>
            View & Edit
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            setEditingEmoji(true)
          }}
          disabled={!userHasEditorAccess}
        >
          <Box
            component="span"
            sx={{ mr: 1 }}
          >
            {page.emoji ? <>{page.emoji}</> : <IconMoodSmile size={iconSize} />}
          </Box>
          Set Emoji
        </MenuItem>
        <MenuItem
          onClick={() => {
            setEditingPageHeader(true)
          }}
          disabled={!userHasEditorAccess}
        >
          <IconPhotoPlus
            size={iconSize}
            style={{ marginRight: '8px' }}
          />
          Edit header image
        </MenuItem>
        <MenuItem
          divider={true}
          onClick={() => navigate(routes.pageAudit({ id: page.id }))}
        >
          <IconFileSettings
            size={iconSize}
            style={{ marginRight: '8px' }}
          />
          Page Audit
        </MenuItem>
        <MenuItem
          onClick={async () => {
            confirm({
              title: 'Delete Page',
              description: 'Are you sure you want to delete this page?',
            }).then(() => {
              deletePage({ variables: { id: page.id } })
            })
          }}
          disabled={!userHasEditorAccess}
        >
          <IconTrash
            size={iconSize}
            style={{ marginRight: '8px' }}
          />
          Delete page
        </MenuItem>
      </Menu>
      <Dialog
        open={editingEmoji}
        onClose={() => setEditingEmoji(false)}
      >
        <DialogTitle>Set Emoji</DialogTitle>
        <DialogContent>
          <EmojiPicker onEmojiClick={(emoji) => handleSetEmoji(emoji.emoji)} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditingEmoji(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
      {editingPageHeader && (
        <PickerOverlay
          apikey={process.env.FILESTACK_API_KEY}
          onSuccess={(headerImageSuccess) => logger.dev({ headerImageSuccess })}
          onUploadDone={(headerImageUploadResult) => {
            if (headerImageUploadResult.filesUploaded.length === 1) {
              const headerImage = headerImageUploadResult.filesUploaded[0].url
              handleSetHeaderImage(headerImage)
            }
          }}
        />
      )}
    </>
  )
}

export default PageActions
