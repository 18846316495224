import { useRef } from 'react'

import { Chip } from '@mui/material'
import { IconX } from '@tabler/icons-react'
import type { RelationshipType } from 'types/graphql'

import { logger } from 'src/lib/logger'
import { parseRelationshipReference } from 'src/lib/objects'

const RelationshipChip = ({
  objectId,
  objectType,
  relationship,
  handleRemoveRelationship,
}: {
  objectId: string
  objectType: string
  relationship: RelationshipType
  handleRemoveRelationship: ({ objectType, objectId, relationship }) => void
}) => {
  const deleted = useRef(false)

  if (!relationship || !relationship.key) {
    logger.warn('Invalid relationship', { relationship, objectType, objectId })
    return null
  }

  const parsedRelationship = parseRelationshipReference(relationship.key)
  if (!parsedRelationship) {
    logger.warn('Invalid relationship', { relationship, objectType, objectId })
    return null
  }

  const { stage, direction, type } = parsedRelationship
  return (
    relationship.value &&
    !deleted.current && (
      <Chip
        label={
          relationship.value ? relationship.label : `Not ${relationship.label}`
        }
        variant="outlined"
        size="small"
        sx={{ border: 'none' }}
        deleteIcon={<IconX size={16} />}
        onClick={() => {
          logger.dev('Clicked relationship', { relationship })
        }}
        onDelete={(e) => {
          e.stopPropagation()
          deleted.current = true
          handleRemoveRelationship({
            objectType,
            objectId,
            relationship: {
              stage,
              direction,
              type,
            },
          })
        }}
      />
    )
  )
}

export default RelationshipChip
