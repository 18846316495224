import { useState } from 'react'

import { Box, Chip, Tooltip, Typography } from '@mui/material'
import { RiSchoolLine } from '@remixicon/react'
import type { Person } from 'types/graphql'

import Row from 'src/components/Row/Row'
import { dayjs } from 'src/lib/dayjs'
import { GoogleMap } from 'src/lib/googleMaps'
import { ensureArray } from 'src/lib/safeUi'

const PersonAbout = ({ person }: { person: Person }) => {
  const [skillsExpanded, setSkillsExpanded] = useState(false)
  const showLocation = false
  return (
    person && (
      <Box sx={{ pb: 1 }}>
        {showLocation && (
          <>
            <Typography variant="h6">Location</Typography>
            <Row>
              <Typography variant="body1">{person.location}</Typography>
              {person.latitude !== null &&
                person.longitude !== null &&
                person.latitude !== undefined &&
                person.longitude !== undefined && (
                  <GoogleMap
                    latitude={person.latitude}
                    longitude={person.longitude}
                  />
                )}
            </Row>
          </>
        )}
        {person.careerSummary && (
          <Row sx={{ alignItems: 'top', mb: 4 }}>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: '14px',
                letterSpacing: '-0.2px',
                width: '120px',
                flexShrink: 0,
              }}
            >
              Career
            </Typography>
            <Typography variant="body1">{person.careerSummary}</Typography>
          </Row>
        )}
        {ensureArray(person.education).length > 0 && (
          <Row sx={{ alignItems: 'top', mb: 4 }}>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: '14px',
                letterSpacing: '-0.2px',
                width: '120px',
                flexShrink: 0,
              }}
            >
              Education
            </Typography>
            <Box>
              <Row
                gap={1}
                sx={{ flexWrap: 'wrap' }}
              >
                {ensureArray(person.education).map((education, index) => (
                  <Tooltip
                    key={`education-${index}`}
                    title={
                      education.degree && education.schoolName
                        ? `${person.firstName} earned their ${
                            education.degree
                          } from ${education.schoolName} in ${dayjs(
                            education.endDate
                          ).format('YYYY')}`
                        : `${person.firstName} attended ${
                            education.schoolName
                          } between ${dayjs(education.startDate).format(
                            'YYYY'
                          )} and ${dayjs(education.endDate).format('YYYY')}`
                    }
                    placement="left"
                    arrow={true}
                  >
                    <Chip
                      variant="outlined"
                      size="small"
                      label={education.degree}
                      icon={
                        education.schoolPhotoUrl ? (
                          <Box
                            component="img"
                            src={education.schoolPhotoUrl}
                            sx={{ width: 18, height: 18, borderRadius: 100 }}
                          />
                        ) : (
                          <RiSchoolLine size={18} />
                        )
                      }
                      sx={{
                        fontSize: '11px',
                        fontWeight: 500,
                        letterSpacing: '-0.05px',
                        height: '24px',
                        '& .MuiChip-label': {
                          maxWidth:
                            ensureArray(person.education).length > 2
                              ? '196px'
                              : 'auto',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                        },
                      }}
                    />
                  </Tooltip>
                ))}
              </Row>
            </Box>
          </Row>
        )}
        {ensureArray(person.workExperience).length > 0 && (
          <Row sx={{ alignItems: 'top', mb: 4 }}>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: '14px',
                letterSpacing: '-0.2px',
                width: '120px',
                flexShrink: 0,
              }}
            >
              Experience
            </Typography>
            <Box>
              <Row
                gap={1}
                sx={{ flexWrap: 'wrap' }}
              >
                {[...ensureArray(person.workExperience)]
                  .sort(
                    (a, b) =>
                      new Date(b.endDate).getTime() -
                      new Date(a.endDate).getTime()
                  )
                  .map((workExperience, index) => (
                    <Chip
                      size="small"
                      label={`${workExperience.companyName} ${
                        workExperience.startDate
                          ? dayjs(workExperience.startDate).format('YYYY')
                          : ''
                      } - '${
                        workExperience.endDate
                          ? dayjs(workExperience.endDate).format('YY')
                          : 'Now'
                      }`}
                      key={`work-experience-${index}`}
                      variant="outlined"
                      sx={{
                        fontSize: '11px',
                        fontWeight: 600,
                        letterSpacing: '-0.2px',
                        height: '24px',
                        '& .MuiChip-label': {
                          maxWidth:
                            ensureArray(person.workExperience).length > 4
                              ? '148px'
                              : 'auto',
                          minWidth: '120px',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                        },
                      }}
                    />
                  ))}
              </Row>
            </Box>
          </Row>
        )}
        {ensureArray(person.skills).length > 0 && (
          <Row sx={{ alignItems: 'top', mb: 4 }}>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: '14px',
                letterSpacing: '-0.2px',
                width: '120px',
                flexShrink: 0,
              }}
            >
              Skills
            </Typography>
            <Box>
              <Row
                gap={1}
                sx={{ flexWrap: 'wrap' }}
              >
                {ensureArray(person.skills)
                  .slice(0, skillsExpanded ? 100 : 8)
                  .map((skill, index) => (
                    <Chip
                      size="small"
                      label={skill}
                      key={`skill-${index}`}
                      sx={{
                        backgroundColor: (theme) =>
                          theme.palette.background.default,
                        color: (theme) => theme.palette.text.secondary,
                        fontSize: '11px',
                        fontWeight: 500,
                        letterSpacing: '-0.05px',
                        height: '18px',
                      }}
                    />
                  ))}
                {person.skills.length > 8 && (
                  <Chip
                    size="small"
                    color="secondary"
                    variant="outlined"
                    onClick={() => setSkillsExpanded(!skillsExpanded)}
                    label={skillsExpanded ? 'Fewer' : 'More'}
                    sx={{
                      fontSize: '11px',
                      fontWeight: 500,
                      letterSpacing: '-0.05px',
                      height: '18px',
                    }}
                  />
                )}
              </Row>
            </Box>
          </Row>
        )}
      </Box>
    )
  )
}

export default PersonAbout
