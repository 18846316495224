import { useContext, useState } from 'react'

import { Avatar, Box, Button, Chip, Typography } from '@mui/material'
import type { SlackChannel } from 'types/graphql'

import Row from 'src/components/Row/Row'
import SlackLogo from 'src/components/Slack/SlackLogo/SlackLogo'
import { DayContext } from 'src/lib/dayContext'
import { dayjs } from 'src/lib/dayjs'

const SlackChannels = ({
  channels,
  includeTitle = true,
}: {
  channels: SlackChannel[]
  includeTitle?: boolean
}) => {
  const { workspacePeople } = useContext(DayContext)
  const [expandedChannel, setExpandedChannel] = useState<string | null>(null)

  return (
    <Box>
      {channels.map((channel: any) => (
        <Box
          key={`${channel.id}-${channel.name}`}
          sx={{ pt: includeTitle ? 2 : 0 }}
        >
          {includeTitle && (
            <Row sx={{ justifyContent: 'space-between' }}>
              <Chip
                variant="filled"
                key={channel.id}
                icon={
                  <Row sx={{ justifyContent: 'right', width: '20px' }}>
                    <SlackLogo size={16} />
                  </Row>
                }
                label={`#${channel.name}`}
                onClick={() => {
                  const url = `slack://channel?id=${channel.slackChannelId}&team=${slack.teamId}`
                  window.open(url, '_blank')
                }}
                sx={{
                  background: '#703259',
                  color: 'white',
                  fontWeight: '600',
                  fontSize: '14px',
                  letterSpacing: '-0.2px',
                }}
              />
              <Row gap={1}>
                <Typography sx={{ fontSize: '11px', color: 'text.secondary' }}>
                  {channel?.summary?.summarizedThrough
                    ? `Summarized ${dayjs(
                        channel?.summary?.summarizedThrough
                      ).fromNow()}`
                    : null}
                </Typography>
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  sx={{
                    fontSize: '12px',
                    fontWeight: '500',
                    minWidth: '20px',
                  }}
                  onClick={() =>
                    setExpandedChannel((prev) =>
                      prev === channel.id ? null : channel.id
                    )
                  }
                >
                  {expandedChannel === channel.id ? 'less' : 'more'}
                </Button>
              </Row>
            </Row>
          )}
          <Box sx={{ py: 2 }}>
            {expandedChannel === channel.id && (
              <Row sx={{ justifyContent: 'space-between' }}>
                <Typography sx={{ fontSize: '15px', fontWeight: 600 }}>
                  Summary
                </Typography>
                {!includeTitle && (
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    sx={{
                      fontSize: '12px',
                      fontWeight: '500',
                      minWidth: '20px',
                    }}
                    onClick={() => setExpandedChannel(null)}
                  >
                    less
                  </Button>
                )}
              </Row>
            )}
            <Typography variant="body1">
              {expandedChannel === channel.id
                ? channel?.summary?.output?.longSummary
                : channel?.summary?.output?.oneSentenceSummary}

              {!includeTitle && !expandedChannel && (
                <Button
                  variant="text"
                  size="small"
                  color="primary"
                  sx={{
                    fontSize: '12px',
                    fontWeight: '600',
                    minWidth: '20px',
                  }}
                  onClick={() => setExpandedChannel(channel.id)}
                >
                  more
                </Button>
              )}
            </Typography>

            {expandedChannel === channel.id &&
              channel?.summary?.output?.recentlyCompletedTasks?.length > 0 && (
                <>
                  <Typography sx={{ fontSize: '15px', fontWeight: 600, mt: 3 }}>
                    Recent Wins
                  </Typography>
                  {channel?.summary?.output?.recentlyCompletedTasks.map(
                    (task: any) => (
                      <Row
                        key={task.id}
                        sx={{
                          alignItems: 'top',
                          justifyContent: 'space-between',
                          mt: 2,
                        }}
                        gap={2}
                      >
                        {task.completorEmail ? (
                          <Row
                            sx={{ width: '160px', height: '24px' }}
                            gap={1}
                          >
                            <Avatar
                              sx={{ width: '24px', height: '24px' }}
                              src={
                                workspacePeople.find(
                                  (person: any) =>
                                    person.email === task.completorEmail
                                )?.photoUrl
                              }
                            />
                            <Typography
                              variant="body2"
                              sx={{
                                fontWeight: 500,
                                textOverflow: 'ellipsis',
                                width: '100%',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {workspacePeople.find(
                                (person: any) =>
                                  person.email === task.completorEmail
                              )?.fullName || task.completorEmail}
                            </Typography>
                          </Row>
                        ) : null}
                        <Typography
                          variant="body2"
                          sx={{
                            fontWeight: 500,
                            width: '100px',
                          }}
                        >
                          {task.completedAt
                            ? dayjs(task.completedAt).fromNow()
                            : null}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{ width: '340px' }}
                        >
                          {task.taskDescription}
                        </Typography>
                      </Row>
                    )
                  )}
                </>
              )}
          </Box>
        </Box>
      ))}
    </Box>
  )
}

export default SlackChannels
