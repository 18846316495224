import { useRef } from 'react'

import SidebarObjectConsole from 'src/components/Sidebar/SidebarObjectConsole/SidebarObjectConsole'

import useSidebar from './useSidebar'

const SidebarModeConsole = () => {
  const { object } = useSidebar()
  const lastConsoleFetch = useRef(0)

  return (
    <SidebarObjectConsole
      key={lastConsoleFetch.current}
      objectType={object?.objectType}
      objectId={object?.objectId}
      workspaceId={object?.workspaceId}
    />
  )
}

export default SidebarModeConsole
