import {
  createContext,
  useCallback,
  useMemo,
  useState,
  useEffect,
  useContext,
} from 'react'
import type { ReactNode } from 'react'

import { gql, useLazyQuery, type DocumentNode } from '@apollo/client'
import type { CRMObjectInput, Page, UpdatePageInput } from 'types/graphql'

import { useQuery, useMutation } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import { combineTitleAndContent } from 'src/components/Page/pages'
import { UPDATE_PAGE } from 'src/components/Pages/mutations'
import { GET_PAGE } from 'src/components/Pages/queries'
import { DayContext } from 'src/lib/dayContext'
import { logger } from 'src/lib/logger'
import { getUserAuthorizations } from 'src/lib/objects'

const GET_CONTEXT_FOR_PAGE = gql`
  query ContextForPage($pageId: String!, $workspaceId: String!) {
    contextForPage(pageId: $pageId, workspaceId: $workspaceId) {
      contextString
      tokenCount
    }
  }
`

const GET_TIPTAP_JWT = gql`
  query GetTiptapJwt($timestamp: Int!) {
    getTiptapJwt(timestamp: $timestamp)
  }
`

type PageContextType = {
  page: Page | null
  saving: boolean
  loading: boolean
  error: any
  setShowPrompt: (show: boolean | ((prev: boolean) => boolean)) => void
  showPrompt: boolean
  setShowVersionHistory: (show: boolean | ((prev: boolean) => boolean)) => void
  showVersionHistory: boolean
  contextObjects: any[]
  setContextObjects: (contextObjects: any[]) => void
  handleUpdatePage: (input: Partial<Page>) => Promise<void>
  refetch: () => any
  handleUpdatePageContent: (input: UpdatePageInput) => Promise<void>
  contextStatus:
    | 'loading'
    | 'loaded'
    | 'error_capacity'
    | 'error_unknown'
    | null
  contextString: string | null
  tokenCount: number | null
  handleGetPageContext: (pageId: string) => Promise<void>
  tokenLimit: number
  token: string | null
  ungatedForButtons: boolean
  userHasEditorAccess: boolean
  parentQuery: DocumentNode
}

export const PageContext = createContext<PageContextType | null>(null)

type PageProviderProps = {
  children: ReactNode
  pageId: string
  workspaceId: string
}

export function PageProvider({
  children,
  pageId,
  workspaceId,
}: PageProviderProps) {
  const { currentUser } = useAuth()
  const { workspaces } = useContext(DayContext)

  const ungatedForButtons = process.env.HOST.includes('localhost')

  const [showPrompt, setShowPrompt] = useState(ungatedForButtons)
  const [contextObjects, setContextObjects] = useState([])
  const [showVersionHistory, setShowVersionHistory] = useState(false)
  const [aiState, setAiState] = useState({
    isLoading: false,
    hasContent: false,
    errorMessage: null,
    response: null,
    contextString: null,
    contextStatus: null,
    tokenCount: 0,
  })

  // Calculate timestamp once per hour instead of every render
  const hourlyTimestamp = useMemo(
    () => Math.floor(Date.now() / 1000) % 1200,
    [] // Empty deps since we want this to be stable for the component's lifetime
  )

  const { data: tokenData } = useQuery(GET_TIPTAP_JWT, {
    variables: {
      timestamp: hourlyTimestamp,
    },
  })

  const token = useMemo(
    () => tokenData?.getTiptapJwt,
    [tokenData?.getTiptapJwt]
  )

  const { data, loading, error, refetch } = useQuery(GET_PAGE, {
    variables: { workspaceId, id: pageId },
    skip: !workspaceId || !pageId,
  })

  const page = data?.workspacePage ?? null

  const updateAiState = useCallback((newState) => {
    setAiState((prev) => ({
      ...prev,
      ...newState,
    }))
  }, [])

  const [updatePageMutation, { loading: saving }] = useMutation(UPDATE_PAGE)

  const handleUpdatePageContent = useCallback(
    async (input: UpdatePageInput) => {
      input.contentJson = combineTitleAndContent(input.title, input.contentJson)
      input.workspaceId = workspaceId
      logger.dev('handleUpdatePageContent', input)

      await updatePageMutation({
        variables: {
          id: page?.id,
          input: {
            ...input,
            workspaceId,
          },
        },
      })
    },
    [updatePageMutation, page?.id, workspaceId]
  )

  const [getPageContext] = useLazyQuery(GET_CONTEXT_FOR_PAGE)

  useEffect(() => {
    if (page?.crmObjects) {
      setContextObjects(page.crmObjects)
    }
  }, [page?.crmObjects])

  const handleGetPageContext = useCallback(
    async (pageId: string) => {
      updateAiState({ contextStatus: 'loading' })

      try {
        const { data } = await getPageContext({
          variables: { pageId, workspaceId },
        })

        const contextString = data?.contextForPage?.contextString
        const tokenCount = data?.contextForPage?.tokenCount
        logger.dev('handleGetPageContext', {
          data,
        })
        updateAiState({
          contextString,
          tokenCount,
          contextStatus: 'loaded',
        })
      } catch (error) {
        logger.error(`Failed to fetch context for page ${pageId}:`, error)
        updateAiState({
          contextString: null,
          tokenCount: null,
          contextStatus: 'error_unknown',
        })
      }
    },
    [getPageContext, workspaceId, updateAiState]
  )

  useEffect(() => {
    if (page?.id && contextObjects?.length > 0) {
      handleGetPageContext(page.id)
    }
  }, [page?.id, contextObjects?.length, handleGetPageContext])

  const handleUpdatePageObjects = useCallback(
    async (crmObjects: CRMObjectInput[]) => {
      setContextObjects(crmObjects)
      await updatePageMutation({
        variables: {
          id: page.id,
          input: {
            crmObjects: crmObjects.map((crmObject) => ({
              objectId: crmObject.objectId,
              objectType: crmObject.objectType,
            })),
          },
        },
      })
    },
    [page, updatePageMutation]
  )

  const updatePageSanitized = useCallback(
    async (input: Partial<Page>) => {
      if (!page) return

      const cleanInput = { ...input }
      delete cleanInput.ownerEmail
      delete cleanInput.createdAt
      delete cleanInput.updatedAt
      delete cleanInput.__typename
      delete cleanInput.contentHtml
      delete cleanInput.contentJson
      delete cleanInput.id
      delete cleanInput.people
      delete cleanInput.domains
      delete cleanInput.crmObjects

      await updatePageMutation({
        variables: {
          id: page.id,
          input: {
            ...cleanInput,
            workspaceId,
          },
        },
      })
    },
    [page, updatePageMutation, workspaceId]
  )

  const workspace = (workspaces || []).find(({ id }) => id === workspaceId)

  const { userHasEditorAccess } = useMemo(() => {
    if (!page) {
      return { userHasEditorAccess: false }
    }

    return getUserAuthorizations({
      currentUser,
      authorization: page.authorization,
      workspace,
    })
  }, [currentUser, page, workspace])

  const value = useMemo(
    () => ({
      page,
      saving,
      loading,
      error,
      setShowPrompt,
      showPrompt,
      handleUpdatePageContent,
      refetch,
      contextObjects,
      setContextObjects: handleUpdatePageObjects,
      showVersionHistory,
      setShowVersionHistory,
      handleUpdatePage: updatePageSanitized,
      contextStatus: aiState.contextStatus,
      contextString: aiState.contextString,
      tokenCount: aiState.tokenCount,
      handleGetPageContext,
      tokenLimit: 128000,
      token,
      ungatedForButtons,
      userHasEditorAccess,
      parentQuery: GET_PAGE,
    }),
    [
      page,
      saving,
      loading,
      error,
      updatePageSanitized,
      handleUpdatePageContent,
      refetch,
      showPrompt,
      contextObjects,
      handleUpdatePageObjects,
      showVersionHistory,
      aiState,
      handleGetPageContext,
      token,
      ungatedForButtons,
      userHasEditorAccess,
    ]
  )

  return <PageContext.Provider value={value}>{children}</PageContext.Provider>
}
