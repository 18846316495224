import { useEffect, useState } from 'react'

import { DownloadOutlined } from '@mui/icons-material'
import { Box, IconButton, Tooltip } from '@mui/material'
import { generateJSON } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import { marked } from 'marked'

import { isBrowser } from '@redwoodjs/prerender/browserUtils'

import AddToPage from '../../Page/AddToPage/AddToPage'
import Row from '../../Row/Row'
import SidebarAdminHeader from '../SidebarAdminHeader/SidebarAdminHeader'
import SidebarSectionExpand from '../SidebarSectionExpand/SidebarSectionExpand'

const SidebarLayoutClip = ({ crmObject, setOpen }) => {
  const [playerModule, setPlayerModule] = useState<{
    default: typeof PlayerContainer
  }>(null)

  useEffect(() => {
    if (isBrowser) {
      import('src/components/Video/PlayerContainer/PlayerContainer').then(
        (module) => {
          setPlayerModule(module)
        }
      )
    }
  }, [isBrowser])

  const PlayerContainer = playerModule?.default

  return (
    <>
      <SidebarAdminHeader
        crmObject={crmObject}
        setOpen={setOpen}
      />
      <Box>
        {PlayerContainer && (
          <PlayerContainer
            playbackId={crmObject?.properties?.video?.private?.playbackId}
            tokens={crmObject?.properties?.video?.private?.tokens}
          />
        )}
      </Box>
      <SidebarSectionExpand
        title="Description"
        isOpen={true}
        plainText={
          crmObject?.properties?.description || 'No description available'
        }
      />

      <SidebarSectionExpand
        title="Actions"
        isOpen={false}
      >
        <Row>
          <AddToPage
            blocks={[
              {
                type: 'title',
                attrs: {
                  level: 1,
                },
                content: [
                  {
                    text:
                      crmObject?.properties?.summary?.Suggested_Title ||
                      crmObject?.properties?.title ||
                      crmObject?.properties?.calendarEvents?.[0]?.GoogleEvent
                        ?.title ||
                      'Meeting Notes',
                    type: 'text',
                  },
                ],
              },
              {
                type: 'paragraph',
                content: [
                  {
                    type: 'text',
                    text:
                      crmObject?.properties?.summary?.output?.Summary_Short ||
                      '(Oops, we are missing the summary)',
                  },
                ],
              },
              {
                type: 'meetingrecording',
                attrs: {
                  id: crmObject?.objectId,
                },
              },
              generateJSON(
                marked.parse(
                  crmObject?.properties?.summary?.output?.tabs[0]?.content || ''
                ),
                [StarterKit]
              ),
            ]}
          />
          {crmObject?.properties?.video?.private?.download?.urls?.length >
            0 && (
            <Tooltip
              title="Download recording"
              placement="left"
              arrow={true}
            >
              <IconButton
                onClick={(e) => {
                  e.stopPropagation()
                  const downloadUrl =
                    crmObject?.properties?.video?.private?.download?.urls.find(
                      (urlObject) => urlObject.default
                    )?.url
                  if (downloadUrl) {
                    const link = document.createElement('a')
                    link.href = downloadUrl
                    link.download = `${crmObject?.properties?.title}.mp4` // Optional, if you want to suggest a filename
                    document.body.appendChild(link) // Append to html
                    link.click()
                    document.body.removeChild(link)
                  }
                }}
              >
                <DownloadOutlined sx={{ fontSize: '1.2rem' }} />
              </IconButton>
            </Tooltip>
          )}
        </Row>
      </SidebarSectionExpand>
    </>
  )
}

export default SidebarLayoutClip
