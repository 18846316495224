import {
  IconClock,
  IconLabel,
  IconLabelFilled,
  IconThumbDown,
} from '@tabler/icons-react'
import { IconCheck } from '@tabler/icons-react'

export const actionStatusMap = {
  COMPLETED: {
    label: 'Completed',
    color: 'success',
    icon: <IconCheck />,
  },
  READ: {
    label: 'In Progress',
    color: 'info',
    icon: <IconLabel />,
  },
  DISMISSED: {
    label: 'Dismissed',
    color: 'error',
    icon: <IconThumbDown />,
  },
  REDUNDANT: {
    label: 'Redundant',
    color: 'warning',
    icon: <IconCheck />,
  },
  SNOOZED: {
    label: 'Snoozed',
    color: 'info',
    icon: <IconClock />,
  },
  UNREAD: {
    label: 'Unread',
    color: 'info',
    icon: <IconLabelFilled />,
  },
}
