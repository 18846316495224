import { useContext, useMemo, useState } from 'react'

import { Menu, MenuItem } from '@mui/material'
import { IconCircleFilled } from '@tabler/icons-react'
import { StageTypes } from 'api/src/data/objects/stages'
import toast from 'react-hot-toast'

import { useMutation } from '@redwoodjs/web'

import { DayContext } from 'src/lib/dayContext'
import { logger } from 'src/lib/logger'
import { NativeObjectTypes } from 'src/lib/objects'

import MetadataChip from '../Chips/MetadataChip/MetadataChip'
import { getStageColor } from '../Pipeline/Pipeline'
import { buildReasoningKey } from '../Reasonings/reasonings'

const UPDATE_OPPORTUNITY_STAGE_FROM_CHIP = gql`
  mutation UpdateOpportunityStageFromChip(
    $opportunityId: String!
    $stageId: String!
    $workspaceId: String!
  ) {
    updateOpportunityStage(
      opportunityId: $opportunityId
      stageId: $stageId
      workspaceId: $workspaceId
    )
  }
`

const isClosedLostStage = (stage) => {
  return (
    stage?.type === StageTypes.CLOSED_LOST ||
    stage?.title?.toLowerCase()?.includes('lost')
  )
}

const StageChip = ({
  stage,
  allowChange = false,
  stages = [],
  opportunityId = null,
  onUpdate = () => {},
}) => {
  const { selectedWorkspace } = useContext(DayContext)
  const [anchorEl, setAnchorEl] = useState(null)
  const [optimisticStage, setOptimisticStage] = useState(null)

  const [updateOpportunityStageFromChip] = useMutation(
    UPDATE_OPPORTUNITY_STAGE_FROM_CHIP
  )

  const orderedStages = useMemo(() => {
    if (!stages) return []
    return stages.filter(Boolean).sort((a, b) => a.position - b.position)
  }, [stages])

  const handleUpdateStage = async (newStage) => {
    // Optimistically update the stage
    setOptimisticStage(newStage)
    handleClose()
    onUpdate()

    try {
      await updateOpportunityStageFromChip({
        variables: {
          opportunityId,
          stageId: newStage.id,
          workspaceId: selectedWorkspace,
        },
      })
      // Clear optimistic state on success
      setOptimisticStage(null)
    } catch (error) {
      // Revert to previous stage on error
      setOptimisticStage(null)
      toast.error('Failed to update stage')
      logger.error('Failed to update stage:', error)
      // Call onUpdate to revert any optimistic changes
      onUpdate()
    }
  }

  const handleClick = (event) => {
    if (allowChange) {
      event.stopPropagation()
      setAnchorEl(event.currentTarget)
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  // Use optimistic stage if available, otherwise use prop stage
  const displayStage = optimisticStage || stage
  const color = getStageColor(displayStage?.position)

  const reasoningKey = useMemo(
    () =>
      isClosedLostStage(displayStage)
        ? null
        : buildReasoningKey({
            workspaceId: selectedWorkspace,
            objectId: opportunityId,
            objectType: NativeObjectTypes.Opportunity,
            path: 'stageReasoning',
          }),
    [selectedWorkspace, opportunityId, displayStage]
  )

  return (
    displayStage && (
      <>
        <MetadataChip
          state={{
            label: displayStage?.title,
            color: color,
            value: displayStage?.id,
          }}
          onClick={handleClick}
          icon={<IconCircleFilled />}
          reasoningKey={reasoningKey}
        />
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {orderedStages.map((stageOption) => (
            <MenuItem
              key={stageOption.id}
              onClick={() => handleUpdateStage(stageOption)}
            >
              <StageChip stage={stageOption} />
            </MenuItem>
          ))}
        </Menu>
      </>
    )
  )
}

export default StageChip
