import { useContext } from 'react'

import { Chip } from '@mui/material'
import {
  IconNote,
  IconBrandSlack,
  IconBrandGmail,
  IconSparkles,
  IconVideo,
  IconTargetArrow,
} from '@tabler/icons-react'

import { navigate, routes } from '@redwoodjs/router'

import WorkspaceMemberChip from 'src/components/WorkspaceMemberChip/WorkspaceMemberChip'
import { DayContext } from 'src/lib/dayContext'
import { NativeObjectTypes, ActionSourceLabels } from 'src/lib/objects'

const sourceIcons = {
  AI_GENERATED: <IconSparkles />,
  MEETING_RECORDING: <IconVideo />,
  GMAIL_THREAD: <IconBrandGmail />,
  SLACK_CHANNEL: <IconBrandSlack />,
  NOTE: <IconNote />,
  OPPORTUNITY: <IconTargetArrow />,
}

const getSourceLink = (action, workspaceId, sourceType, oppsById) => {
  const sourceId = action?.source?.id
  const mainEmail = action.people?.[0]?.email

  const sidebarObject = {
    objectId: mainEmail,
    objectType: NativeObjectTypes.Person,
    properties: {
      email: mainEmail,
    },
  }

  const opportunitySidebarObject = {
    objectId: sourceId,
    objectType: NativeObjectTypes.Opportunity,
    properties: {
      objectId: sourceId,
      ...oppsById[sourceId],
    },
  }

  switch (sourceType) {
    case 'AI_GENERATED':
    case 'OPPORTUNITY':
      return opportunitySidebarObject

    case 'MEETING_RECORDING':
      return routes.workspaceMeetingRecording({
        recordingId: sourceId,
        workspaceId: workspaceId,
      })

    case 'GMAIL_THREAD':
      return sidebarObject

    case 'SLACK_CHANNEL':
      return sidebarObject

    case 'NOTE':
      return sidebarObject

    case 'ZAPIER':
    case 'API':
      return ''

    case 'MANUAL':
      return ''

    default:
      return ''
  }
}

const ActionSourceChip = ({ action, oppsById }) => {
  const { selectedWorkspace, setSidebarObject } = useContext(DayContext)

  const sourceType =
    action.source?.type === 'AI_GENERATED' && action.source.id
      ? 'OPPORTUNITY'
      : action.source?.type

  return (
    action?.id &&
    sourceType && (
      <>
        {sourceType !== 'MANUAL' ? (
          <Chip
            size="small"
            variant="outlined"
            icon={sourceIcons?.[sourceType]}
            label={ActionSourceLabels?.[sourceType] || sourceType}
            color={sourceType === 'email' ? 'primary' : 'default'}
            sx={{
              width: '100%',
              border: 'none',
              justifyContent: 'flex-start',
              p: 0,
              m: 0,
              '&:hover': {
                backgroundColor: 'transparent',
              },
              '& .MuiChip-icon': {
                m: 0,
                height: '12px',
                width: '12px',
                mt: '-1px',
              },
            }}
            onClick={(e) => {
              e.stopPropagation()
              const link = getSourceLink(
                action,
                selectedWorkspace,
                sourceType,
                oppsById
              )
              if (link) {
                if (typeof link === 'string') {
                  navigate(link)
                } else {
                  setSidebarObject(link)
                }
              }
            }}
          />
        ) : (
          <WorkspaceMemberChip
            userId={action.source.id}
            workspaceId={action.workspaceId}
          />
        )}
      </>
    )
  )
}

export default ActionSourceChip
