import { useEffect, useMemo, useRef, useState } from 'react'

import { AvatarGroup } from '@mui/material'
import type { CRMObject } from 'types/graphql'

import ObjectAvatar from 'src/components/Avatars/ObjectAvatar'
import ObjectChip from 'src/components/Chips/ObjectChip/ObjectChip'
import Row from 'src/components/Row/Row'
import { getBatchObjectMetadata, type ObjectMetadata } from 'src/lib/indexedDb'
import { logger } from 'src/lib/logger'
import {
  NativeObjectTypes,
  ObjectTypeMetadata,
  type NativeObjectType,
} from 'src/lib/objects'

import BaseChip from '../BaseChip'

const AVATAR_SIZE = 16
const GENERIC_LABEL = 'Record'
const AVATAR_GROUP_STYLES = {
  '& .MuiAvatar-root, .domainAvatar, .personAvatarBox': {
    height: `${AVATAR_SIZE}px`,
    width: `${AVATAR_SIZE}px`,
    ml: `-4px !important`,
    flexShrink: 0,
    border: '0px',
    fontSize: '8px',
    fontWeight: 400,
  },
  sx: {
    color: '#616161',
    background: 'red',
  },
  '& .overflow-avatar': {
    marginLeft: '-4px',
  },
}

interface MultiObjectChipProps {
  workspaceId: string
  crmObjects: ReadonlyArray<Partial<CRMObject>>
  max?: number
  fullWidth?: boolean
  label?: string
  onClick?: () => void
}

const extractObjectType = (objectData: Partial<CRMObject>) => {
  return objectData.objectType
}

const defaultMultiObjectParams = {
  max: 3,
  fullWidth: false,
  label: null,
  onClick: null,
}

const MultiObjectChip = ({
  workspaceId,
  crmObjects,
  max = defaultMultiObjectParams.max,
  fullWidth = defaultMultiObjectParams.fullWidth,
  label = defaultMultiObjectParams.label,
  onClick = defaultMultiObjectParams.onClick,
}: MultiObjectChipProps) => {
  const [objectMetadata, setObjectMetadata] = useState<
    Record<string, ObjectMetadata>
  >({})

  const validUniqueObjects = useMemo(() => {
    const objs = []
    const objIds = new Set<string>()
    for (const obj of crmObjects) {
      if (
        obj &&
        obj.objectId &&
        !objIds.has(obj.objectId) &&
        Object.values(NativeObjectTypes).includes(
          obj.objectType as NativeObjectType
        )
      ) {
        objs.push(obj)
        objIds.add(obj.objectId)
      } else {
        logger.warn('Invalid object', { object: obj })
      }
    }

    return objs
  }, [crmObjects])

  // Fetch metadata for all objects in batch
  const objectsWithAvatarUrl = useRef([])
  useEffect(() => {
    const fetchMetadata = async () => {
      if (!validUniqueObjects.length) return

      try {
        const requests = validUniqueObjects.map((obj) => ({
          workspaceId,
          objectType: obj.objectType as NativeObjectType,
          objectId: obj.objectId,
        }))

        const metadata = await getBatchObjectMetadata(requests)
        objectsWithAvatarUrl.current = Object.entries(metadata)
          .filter(([objectId, metadata]) => {
            if (metadata.avatarUrl) return objectId
          })
          .filter(Boolean)

        setObjectMetadata(metadata)
      } catch (error) {
        logger.error('Failed to fetch batch object metadata', error)
      }
    }

    fetchMetadata()
  }, [workspaceId, validUniqueObjects])

  const objectData = useMemo(() => {
    const objectsWithAvatar: Array<Partial<CRMObject>> = []
    const objectsWithoutAvatar: Array<Partial<CRMObject>> = []

    for (const obj of validUniqueObjects) {
      const objectId = obj.objectId
      const avatarUrl = objectMetadata[objectId]?.avatarUrl

      if (avatarUrl) {
        objectsWithAvatar.push(obj)
      } else {
        objectsWithoutAvatar.push(obj)
      }
    }

    const sortedObjects = [...objectsWithAvatar, ...objectsWithoutAvatar]

    return sortedObjects
  }, [validUniqueObjects, objectMetadata]) as Array<Partial<CRMObject>>

  const bestLabel = useMemo(() => {
    if (label) {
      return label
    }
    const objectTypes = new Set(
      objectData.map(extractObjectType).filter(Boolean)
    )

    if (objectTypes.size === 1) {
      const firstObjectType = objectData[0]?.objectType
      const metadata = ObjectTypeMetadata[firstObjectType ?? '']
      return objectData.length > 1
        ? `${objectData.length} ${metadata?.pluralLabel ?? GENERIC_LABEL}`
        : `${objectData.length} ${metadata?.label ?? GENERIC_LABEL}`
    }
    return `${objectData.length} ${objectData.length === 1 ? GENERIC_LABEL : GENERIC_LABEL + 's'}`
  }, [objectData, label])

  const avatarGroup = useMemo(() => {
    const renderedAvatars = Math.min(max, objectData.length)
    const baseMargin = (renderedAvatars - 1) * (16 - 4) + 6
    const avatarMargin = baseMargin // objectData.length > max ? baseMargin + 8 : baseMargin

    const finalAvatarMargin = onClick ? avatarMargin + 2 : avatarMargin

    const avatarGroupSx = {
      ml: `${finalAvatarMargin}px !important`,
      ...AVATAR_GROUP_STYLES,
    }

    // Only take max items, no +1 since we don't want overflow
    const displayObjects = objectData.slice(0, max) as Array<
      CRMObject & { objectId: string }
    >

    return (
      <AvatarGroup
        className="multi-object-avatar-group"
        sx={avatarGroupSx}
      >
        {displayObjects.map((objectData, index) => {
          const avatarUrl = objectMetadata[objectData.objectId]?.avatarUrl
          return (
            <ObjectAvatar
              workspaceId={workspaceId}
              crmObject={objectData}
              key={objectData.objectId ?? index}
              avatarUrl={avatarUrl}
              avatarProps={{
                sx: {
                  background: (theme) => theme.palette.action.selected,
                },
              }}
              size={AVATAR_SIZE}
            />
          )
        })}
      </AvatarGroup>
    )
  }, [objectData, workspaceId, max, onClick, objectMetadata])

  return (
    <Row>
      {validUniqueObjects.length > 1 ? (
        <BaseChip
          label={bestLabel}
          avatar={avatarGroup}
          icon={null}
          fullWidth={fullWidth}
          onClick={onClick}
        />
      ) : validUniqueObjects.length === 1 ? (
        <Row sx={{ '& .MuiAvatar-root': { ml: '2px' }, width: '100%' }}>
          <ObjectChip
            workspaceId={workspaceId}
            crmObject={validUniqueObjects[0]}
            onRemove={null}
            fullWidth={fullWidth}
          />
        </Row>
      ) : null}
    </Row>
  )
}

export default MultiObjectChip
