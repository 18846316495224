import { useMemo, useReducer } from 'react'

import {
  Box,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuList,
  Tooltip,
} from '@mui/material'
import { useGridApiContext } from '@mui/x-data-grid-premium'
import {
  IconChartFunnel,
  IconPlus,
  IconTriangleSquareCircleFilled,
} from '@tabler/icons-react'

import { logger } from 'src/lib/logger'

const menuWidth = '256px'

const ViewFilters = () => {
  const initialState = {
    settingType: null,
    fieldToFilter: null,
    operator: null,
    anchorEl: null,
  }

  const reducer = (state: any, action: any) => {
    switch (action.type) {
      case 'setFieldToFilter':
        return { ...state, fieldToFilter: action.payload }
      case 'setOperator':
        return { ...state, operator: action.payload }
      case 'setSettingType':
        return { ...state, settingType: action.payload }
      case 'setAnchorEl':
        return { ...state, anchorEl: action.payload }
      case 'reset':
        return initialState
      default:
        return state
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState)

  const apiGridRef = useGridApiContext()
  const columns = apiGridRef.current.getAllColumns()

  const handleReset = () => {
    dispatch({ type: 'reset' })
  }

  if (state.fieldToFilter) {
    logger.dev(
      'OPERATORS',
      apiGridRef.current.getColumn(state.fieldToFilter).filterOperators
    )
  }

  const containerSx = {
    '& > .MuiList-root.MuiMenu-list': {
      py: 0,
    },
    '& .setting-type-selector': {
      py: 0,
      minWidth: `${menuWidth}`,
      '& .MuiListItemIcon-root': {
        minWidth: '32px',
      },
      '& .MuiListItemText-root': {
        '& .MuiListItemText-secondary': {
          opacity: 0.8,
          fontSize: '12px',
          mt: '4px',
        },
        '& .MuiListItemText-primary': {
          fontWeight: 600,
          fontSize: '13px',
        },
      },
    },
    '& .filter-list': {
      minWidth: `${menuWidth}`,
      height: '300px',
      overflowY: 'auto',
      py: 0,
      '& .MuiListItemButton-root': {
        py: '2px',
        '& .MuiListItemText-root .MuiListItemText-primary': {
          fontWeight: 500,
          fontSize: '12px',
          letterSpacing: '-0.3px',
        },
      },
    },
    '& .filter-operators': {
      minWidth: `${menuWidth}`,
      py: 0,
      '& .MuiListItemButton-root': {
        py: '2px',
      },
    },
    '& .apply-filter-button': {
      py: '8px',
      borderTop: (theme) => `1px solid ${theme.palette.divider}`,
      boxShadow: (theme) => theme.shadows[3],
      '& .MuiListItemIcon-root': {
        minWidth: '24px',
      },
    },
  }

  const filterOperators = useMemo(() => {
    if (state.fieldToFilter) {
      return [
        ...apiGridRef.current.getColumn(state.fieldToFilter).filterOperators,
      ]
    }
    return []
  }, [state.fieldToFilter, apiGridRef])

  logger.dev('filterOperators', filterOperators)

  return (
    <Box>
      <Tooltip
        title="Filter what data is shown"
        arrow={true}
      >
        <IconButton
          onClick={(e) => {
            e.stopPropagation()
            dispatch({ type: 'setAnchorEl', payload: e.currentTarget })
          }}
        >
          <IconPlus size={12} />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={state.anchorEl}
        onClose={handleReset}
        open={!!state.anchorEl}
        slotProps={{
          paper: {
            sx: containerSx,
            className: 'view-filters-menu',
          },
        }}
      >
        {!state.settingType && state.anchorEl && (
          <MenuList className="setting-type-selector">
            <ListItemButton
              onClick={() =>
                dispatch({ type: 'setSettingType', payload: 'filter' })
              }
            >
              <ListItemIcon>
                <IconChartFunnel size={20} />
              </ListItemIcon>
              <ListItemText
                primary="Filter Records"
                secondary="Show only rows that match specific criteria (e.g., Status = 'Active', Created > 30 days ago)"
              />
            </ListItemButton>
            <ListItemButton
              onClick={() =>
                dispatch({ type: 'setSettingType', payload: 'grouping' })
              }
            >
              <ListItemIcon>
                <IconTriangleSquareCircleFilled size={20} />
              </ListItemIcon>
              <ListItemText
                primary="Group Records"
                secondary="Organize rows into collapsible sections based on shared values (e.g., group by Status, Department, or Date)"
              />
            </ListItemButton>
          </MenuList>
        )}
        {state.settingType === 'filter' && (
          <>
            {!state.fieldToFilter ? (
              <MenuList className="filter-list">
                {columns.map((col, index) => (
                  <ListItemButton
                    key={`${index}-column-${col?.field}`}
                    onClick={() =>
                      dispatch({
                        type: 'setFieldToFilter',
                        payload: col?.field,
                      })
                    }
                  >
                    <ListItemText primary={col.headerName} />
                  </ListItemButton>
                ))}
              </MenuList>
            ) : (
              <MenuList className="filter-operators">
                {apiGridRef.current
                  .getColumn(state.fieldToFilter)
                  .filterOperators.map((operator) => (
                    <ListItemButton
                      key={operator.value}
                      onClick={() =>
                        dispatch({ type: 'setOperator', payload: operator })
                      }
                    >
                      <ListItemText
                        primary={operator.getValueAsString(operator.value)}
                      />
                    </ListItemButton>
                  ))}
              </MenuList>
            )}

            <ListItemButton
              disabled={!state.operator || !state.fieldToFilter}
              className="apply-filter-button"
            >
              <ListItemIcon>
                <IconChartFunnel size={16} />
              </ListItemIcon>
              <ListItemText primary={'Apply filter'} />
            </ListItemButton>
          </>
        )}
      </Menu>
    </Box>
  )
}

export default ViewFilters
