import { useContext, useState } from 'react'

import { useLazyQuery } from '@apollo/client'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { RiFileAddLine } from '@remixicon/react'
import toast from 'react-hot-toast'

import { navigate, routes, useLocation } from '@redwoodjs/router'
import { useMutation, useQuery } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import { DayContext } from 'src/lib/dayContext'
import { logger } from 'src/lib/logger'

const GET_ALL_PAGES_TO_ADD_BLOCK = gql`
  query GetAllPagesToAddBlock($workspaceId: String!) {
    pages(workspaceId: $workspaceId) {
      id
      title
      contentJson
    }
  }
`

const GET_PAGE_FOR_ADD_BLOCK = gql`
  query GetPageToAddBlock($workspaceId: String!, $id: String!) {
    workspacePage(workspaceId: $workspaceId, id: $id) {
      id
      title
      contentJson
    }
  }
`

const UPDATE_PAGE_TO_ADD_BLOCK = gql`
  mutation UpdatePageToAddBlock($id: String!, $input: UpdatePageInput!) {
    updatePage(input: $input, id: $id) {
      id
      title
      contentJson
    }
  }
`

const CREATE_PAGE_MUTATION_TO_ADD_BLOCK = gql`
  mutation CreatePageToAddBlockMutation($input: CreatePageInput!) {
    createPage(input: $input) {
      id
      createdAt
      updatedAt
      ownerEmail
      title
      publishedForUserAt
      madeExternalAt
      contentJson
      contentHtml
    }
  }
`

const makeRedirectUrl = (pageId) => {
  return routes.pageDetail({ id: pageId })
}

const existsInContent = (block, content) => {
  return content.some((b) => {
    if (b.type === 'doc' && Array.isArray(b.content)) {
      return existsInContent(block, b.content)
    }
    return (
      b.type === block.type && b.attrs?.id && b.attrs?.id === block.attrs?.id
    )
  })
}

const addBlocksToContent = (blocksToAdd, json) => {
  const targetJson = JSON.parse(JSON.stringify(json))

  blocksToAdd.forEach((block) => {
    logger.dev({ block, targetJson })
    if (existsInContent(block, targetJson.content)) {
      toast.error('This item already exists on this page')
    } else {
      if (block.type === 'doc' && Array.isArray(block.content)) {
        block.content.forEach((contentBlock) => {
          targetJson.content.push(contentBlock)
        })
      } else {
        targetJson.content.push(block)
      }
    }
  })

  return targetJson
}

const AddToPage = ({ blocks, suggestedTitle = 'New Page', size = 20 }) => {
  const { selectedWorkspace } = useContext(DayContext)
  const { currentUser: user } = useAuth()

  let pageId = 'NEW'
  const location = useLocation()
  if (location.pathname.startsWith('/page/'))
    pageId = location.pathname.split('/').pop()

  const [useCurrentPage, setUseCurrentPage] = useState(
    !!pageId && pageId !== 'NEW'
  )
  const [expanded, setExpanded] = useState(false)
  const [selectedPageId, setSelectedPageId] = useState(pageId)
  const [newPageTitle, setNewPageTitle] = useState(suggestedTitle)
  const [redirectUrl, setRedirectUrl] = useState(null)

  const { data, refetch } = useQuery(GET_ALL_PAGES_TO_ADD_BLOCK, {
    variables: {
      workspaceId: selectedWorkspace,
    },
    skip: !selectedWorkspace,
  })

  const [getPageToAddBlock] = useLazyQuery(GET_PAGE_FOR_ADD_BLOCK, {
    variables: {
      workspaceId: selectedWorkspace,
      id: selectedPageId,
    },
  })

  const [updatePageToAddBlock] = useMutation(UPDATE_PAGE_TO_ADD_BLOCK, {
    onCompleted: (d) => {
      const url = makeRedirectUrl(d.updatePage.id)
      if (d.updatePage.id === pageId) {
        navigate(url + `?t=${new Date().getTime()}`)
        setExpanded(false)
      } else {
        setRedirectUrl(url)
      }
    },
  })

  const [createPageToAddBlock] = useMutation(
    CREATE_PAGE_MUTATION_TO_ADD_BLOCK,
    {
      onCompleted: (d) => {
        const url = makeRedirectUrl(d.createPage.id)
        setNewPageTitle('New page')
        refetch()
        setRedirectUrl(url)
      },
    }
  )

  const handleAddToPage = async () => {
    const blocksNoTitle = blocks.filter((block) => block.type !== 'title')

    const pageData = await getPageToAddBlock()
    logger.dev({ pageData })
    const existingContentJson = pageData?.data?.workspacePage?.contentJson

    if (existingContentJson) {
      logger.dev({ existingContentJson })
      const contentJson = addBlocksToContent(blocksNoTitle, existingContentJson)
      updatePageToAddBlock({
        variables: {
          id: selectedPageId,
          input: {
            contentJson,
            workspaceId: selectedWorkspace,
          },
        },
      })
    }
  }

  const handleNewPage = () => {
    // if there is a title block in the variable blocks, don't add the empty one:
    const titleBlock = blocks.find((block) => block.type === 'title')

    const content = []
    if (!titleBlock) {
      content.push({
        type: 'title',
        attrs: {
          level: 1,
        },
        content: [
          {
            text: newPageTitle,
            type: 'text',
          },
        ],
      })
    }

    const contentJson = addBlocksToContent(blocks, {
      type: 'doc',
      content,
    })

    createPageToAddBlock({
      variables: {
        input: {
          workspaceId: selectedWorkspace,
          title: newPageTitle,
          ownerEmail: user.email,
          contentHtml: '',
          publishedForUserAt: new Date().toISOString(),
          contentJson,
        },
      },
    })
  }

  const handleSubmit = () => {
    if (selectedPageId) {
      if (selectedPageId === 'NEW') {
        handleNewPage()
      } else {
        handleAddToPage()
      }
    }
  }

  return (
    <Box>
      <Tooltip
        title="Add to page"
        arrow={true}
      >
        <IconButton
          onClick={() => setExpanded(true)}
          sx={{
            borderRadius: '2px',
            p: '4px',
          }}
        >
          <RiFileAddLine size={size} />
        </IconButton>
      </Tooltip>
      <Dialog
        open={expanded}
        onClose={() => {
          setRedirectUrl(null)
          setExpanded(false)
        }}
      >
        <DialogContent sx={{ width: '420px' }}>
          {!redirectUrl ? (
            <>
              {!useCurrentPage && selectedPageId === 'NEW' && (
                <TextField
                  fullWidth={true}
                  label="New page title"
                  value={newPageTitle}
                  onChange={(e) => setNewPageTitle(e.target.value)}
                  sx={{ mb: 1, mt: 1 }}
                />
              )}
              {!useCurrentPage && (
                <FormControl
                  fullWidth={true}
                  sx={{ mb: 3, mt: 1 }}
                >
                  <Select
                    variant="outlined"
                    id="page-selector"
                    value={selectedPageId}
                    onChange={(e) => setSelectedPageId(e.target.value)}
                    sx={{ fontSize: '0.9rem' }}
                    fullWidth={true}
                  >
                    <MenuItem
                      value={'NEW'}
                      key={'NEW'}
                      sx={{
                        fontWeight: 600,
                        fontSize: '0.9rem',
                      }}
                    >
                      + Create new page
                    </MenuItem>
                    {data?.pages?.map((page) => (
                      <MenuItem
                        key={page.id}
                        value={page.id}
                        sx={{ fontSize: '0.9rem' }}
                      >
                        {page.title || '(no title)'}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              <Button
                fullWidth={true}
                variant="contained"
                color="primary"
                onClick={handleSubmit}
              >
                {`Add to ${useCurrentPage ? 'current ' : ''} page`}
              </Button>
              {useCurrentPage && (
                <>
                  <Button
                    sx={{ mt: 2 }}
                    fullWidth={true}
                    color="primary"
                    onClick={() => setUseCurrentPage(false)}
                  >
                    Add to another page
                  </Button>
                </>
              )}
            </>
          ) : (
            <Box>
              <Typography sx={{ mb: 2, fontWeight: 500, fontSize: '1.0rem' }}>
                Successfully added to page.
              </Typography>
              <Button
                variant="outlined"
                fullWidth={true}
                color="primary"
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  navigate(redirectUrl)
                  setRedirectUrl(null)
                  setExpanded(false)
                }}
              >
                Go to page
              </Button>
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </Box>
  )
}

export default AddToPage
