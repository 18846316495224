import { useMemo, useState } from 'react'

import { Chip, Menu, MenuItem, Tooltip, useTheme } from '@mui/material'
import { IconCircleFilled, IconX } from '@tabler/icons-react'

import { useQuery } from '@redwoodjs/web'

import { parseReasoningKey } from 'src/components/Reasonings/reasonings'

import Row from '../../Row/Row'

const GET_REASONING_FOR_CHIP = gql`
  query GetReasoningForChip(
    $workspaceId: String!
    $objectId: String!
    $objectType: String!
    $path: String!
  ) {
    fieldReasoning(
      workspaceId: $workspaceId
      objectType: $objectType
      objectId: $objectId
      path: $path
    ) {
      headline
      description
    }
  }
`

interface MetadataChipOption {
  label: string
  value: string
  color?: string
}

interface MetadataChipProps {
  state: MetadataChipOption
  icon?: React.ReactElement | null
  fullWidth?: boolean
  options?: Array<MetadataChipOption>
  onChange?: (value: string) => void
  onRemove?: (value: string) => void
  onClick?: (event) => void
  disabled?: boolean
  reasoningKey?: string
}

const MetadataChip = ({
  state,
  icon = null,
  fullWidth = false,
  options = [],
  onChange,
  onRemove = null,
  onClick,
  disabled = false,
  reasoningKey,
}: MetadataChipProps) => {
  const theme = useTheme()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const { workspaceId, objectId, objectType, path } = useMemo(
    () =>
      reasoningKey
        ? parseReasoningKey(reasoningKey)
        : { workspaceId: null, objectId: null, objectType: null, path: null },
    [reasoningKey]
  )

  const { data } = useQuery(GET_REASONING_FOR_CHIP, {
    skip: !workspaceId || !objectId || !objectType || !path,
    variables: { workspaceId, objectId, objectType, path },
  })

  const reasoning = data?.fieldReasoning

  const handleOpenMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    if (options.length > 0) {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSelect = (value: string) => {
    handleClose()
    onChange?.(value)
  }

  const handleRemove = (value: string) => {
    onRemove?.(value)
  }

  const finalColor = useMemo(() => {
    if (state.color?.startsWith('#')) {
      return state.color
    } else if (theme.palette[state.color as keyof typeof theme.palette]) {
      return theme.palette[state.color as keyof typeof theme.palette]?.main
    } else {
      return theme.palette.primary.main
    }
  }, [state.color, theme])

  const handleClick = (event) => {
    if (options.length > 0) {
      handleOpenMenu(event)
    } else {
      onClick?.(event)
    }
  }

  const isClickable = useMemo(() => {
    return (options.length > 0 || onClick) && !disabled
  }, [options, onClick, disabled])

  const containerSx = {
    height: '24px',
    ...(fullWidth ? { width: '100%' } : {}),
    ml: '-2px',
    border: 'none',
    justifyContent: 'flex-start',
    cursor: isClickable ? 'pointer' : 'default',
    '& .MuiChip-icon': {
      width: 10,
      height: 10,
      flexShrink: 0,
      mr: 0,
      color: (theme) => finalColor || theme.palette.secondary.main,
    },
    '& .MuiChip-deleteIcon': {
      color: (theme) => theme.palette.primary.main,
      opacity: 0.7,
      flexShrink: 0,
    },
    '&:hover': isClickable
      ? {
          ml: '-4px',
          '& .MuiChip-icon': {
            ml: '4px',
          },
          '& .MuiChip-deleteIcon': {
            opacity: 1,
            mr: '2px',
            '&:hover': {
              color: (theme) => theme.palette.primary.main,
            },
          },
        }
      : {},
  }

  return (
    <>
      <Tooltip title={reasoning?.headline || ''}>
        <Chip
          label={state.label}
          variant="outlined"
          size="small"
          disabled={disabled}
          icon={icon || <IconCircleFilled />}
          onClick={handleClick}
          clickable={isClickable}
          sx={containerSx}
          onDelete={onRemove ? () => handleRemove(state.value) : undefined}
          deleteIcon={
            onRemove ? (
              <Row sx={{ justifyContent: 'flex-end', flex: 1 }}>
                <IconX size={12} />
              </Row>
            ) : null
          }
        />
      </Tooltip>

      {options?.length > 0 && (
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {options.map((option) => (
            <MenuItem
              key={option.value}
              onClick={() => handleSelect(option.value)}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
              }}
            >
              <IconCircleFilled
                size={8}
                style={{
                  color: option.color || state.color || 'inherit',
                }}
              />
              {option.label}
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  )
}

export default MetadataChip
