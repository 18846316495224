import { useCallback, useMemo, useState } from 'react'

import {
  Box,
  Button,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  List,
  TextField,
  Typography,
  IconButton,
  Divider,
} from '@mui/material'
import { IconBubbleText, IconPlus } from '@tabler/icons-react'
import { toast } from 'react-hot-toast'
import type { CreateWorkspaceUserContextInput } from 'types/graphql'

import { useMutation, useQuery } from '@redwoodjs/web'

import ObjectChip from 'src/components/Chips/ObjectChip/ObjectChip'
import { CREATE_CONTEXT } from 'src/components/Context/mutations'
import ObjectFeedback from 'src/components/ObjectFeedback/ObjectFeedback'
import PropertyDisplay from 'src/components/Properties/PropertyDisplay'
import {
  STANDARD_PROPERTY_DEFINITIONS,
  OBJECT_PROPERTY_DEFINITIONS_BY_TYPE,
  CONTEXT_FOR_PROPERTY,
} from 'src/components/Properties/queries'
import Row from 'src/components/Row/Row'
import WorkspaceMemberChip from 'src/components/WorkspaceMemberChip/WorkspaceMemberChip'
import { dayjs } from 'src/lib/dayjs'
import { logger } from 'src/lib/logger'
import {
  buildUserContextReferenceKey,
  ObjectTypeMetadata,
} from 'src/lib/objects'

import SidebarButton from '../SidebarButton/SidebarButton'

const SidebarPropertyDetail = ({
  crmObject,
  setOpen: _setOpen,
}: {
  crmObject: any
  setOpen: (open: boolean) => void
}) => {
  const [context, setContext] = useState('')
  const [enterContextOpen, setEnterContextOpen] = useState(false)
  const { objectType, workspaceId, objectId } = crmObject

  const { data: standardPropertyDefinitions } = useQuery(
    STANDARD_PROPERTY_DEFINITIONS,
    {
      variables: { workspaceId },
      skip: !workspaceId,
      onCompleted: (data) => {
        logger.dev({
          standardPropertyDefinitions: data.getStandardPropertyDefinitions,
        })
      },
    }
  )

  const { data: objectPropertyDefinitions } = useQuery(
    OBJECT_PROPERTY_DEFINITIONS_BY_TYPE,
    {
      variables: { workspaceId, objectTypeId: objectType },
      skip: !objectType,
    }
  )

  const [createContext] = useMutation(CREATE_CONTEXT)

  const propertyDefinitions = useMemo(() => {
    return [
      ...((standardPropertyDefinitions || {})?.getStandardPropertyDefinitions ||
        []),
      ...((objectPropertyDefinitions || {})
        ?.objectPropertyDefinitionsByObjectType || []),
    ]
  }, [standardPropertyDefinitions, objectPropertyDefinitions])

  const propertyDefinition = useMemo(() => {
    return propertyDefinitions.find(
      (propertyDefinition) =>
        propertyDefinition.id === crmObject.properties.propertyDefinition.id
    )
  }, [propertyDefinitions, crmObject.properties.propertyDefinition.id])

  logger.dev({
    propertyDefinition,
    passed: crmObject.properties.propertyDefinition.id,
    propertyDefinitions,
  })

  const handleAddContext = useCallback(() => {
    const parentReferenceKey = buildUserContextReferenceKey(
      objectType,
      objectId,
      propertyDefinition.id
    )

    const input: CreateWorkspaceUserContextInput = {
      workspaceId,
      parentReferenceKey,
      plainTextValue: context,
      referencedObjectIds: [],
    }

    toast.promise(
      createContext({
        variables: {
          input,
        },
        refetchQueries: [
          {
            query: CONTEXT_FOR_PROPERTY,
            variables: {
              workspaceId,
              objectId,
              objectType,
              propertyDefinitionId: propertyDefinition.id,
            },
          },
        ],
      }),
      {
        loading: 'Adding context...',
        success: () => {
          setContext('')
          return 'Context added'
        },
        error: 'Failed to add context',
      }
    )
  }, [
    context,
    objectId,
    objectType,
    propertyDefinition,
    workspaceId,
    createContext,
  ])

  const { data: contextItemsData } = useQuery(CONTEXT_FOR_PROPERTY, {
    variables: {
      workspaceId,
      objectId,
      objectType,
      propertyDefinitionId: propertyDefinition?.id,
    },
    skip: !propertyDefinition?.id || !objectType,
  })

  const containerSx = {
    backgroundColor: (theme) => theme.palette.background.default,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',

    '& .property-value': {
      px: 2,
      py: 4,
      backgroundColor: (theme) => theme.palette.background.paper,
      borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
      boxShadow: (theme) => theme.shadows[3],
    },
    '& .property-detail-sidebar-container': {
      mx: '12px',
      mt: 3,
    },
    '& .property-definition-header': {
      justifyContent: 'space-between',
      mb: 2,
    },
    '& .standard-property-definition': {
      px: '12px',
      '& .MuiTypography-root': {
        opacity: 0.8,
      },
      '& .MuiTypography-h3': {
        fontSize: '15px',
      },
      '& .standard-property-definition-header': {
        mb: '6px',
      },
    },

    '& .user-context-items': {
      px: '12px',
      '& .MuiTypography-root': {
        opacity: 0.8,
      },
      '& .MuiTypography-h3': {
        fontSize: '15px',
      },
      '& .user-context-items-header': {
        mb: '6px',
        justifyContent: 'space-between',
      },
    },
  }

  return (
    propertyDefinition && (
      <Box sx={containerSx}>
        <Box className="property-value">
          <Row
            gap={2}
            className="property-definition-header"
          >
            <Row gap={1}>
              <Typography variant="h3">{propertyDefinition.name}</Typography>
              <ObjectChip
                crmObject={{
                  objectId: crmObject.objectId,
                  objectType: crmObject.objectType,
                  workspaceId: crmObject.workspaceId,
                }}
                workspaceId={workspaceId}
              />
            </Row>
            <Row gap={1}>
              <ObjectFeedback
                objectType={objectType}
                objectId={objectId}
                workspaceId={workspaceId}
                path={`${propertyDefinition.id}`}
                version={1}
                variant="inline"
              />
              <SidebarButton
                label="Add context"
                icon={<IconBubbleText size={16} />}
                onClick={() => setEnterContextOpen(true)}
              />
            </Row>
          </Row>
          <Box>
            <PropertyDisplay
              propertyDefinition={propertyDefinition}
              value={crmObject.properties.propertyDefinition.currentValue}
              objectId={objectId}
              objectType={objectType}
              workspaceId={workspaceId}
            />
          </Box>
        </Box>
        <Divider />

        {enterContextOpen && (
          <Box className="property-detail-sidebar-container">
            <TextField
              multiline={true}
              placeholder="Adding context will help Day.ai populate this property for you in the future, and also trigger an immediate re-population."
              rows={4}
              fullWidth={true}
              value={context}
              onChange={(e) => setContext(e.target.value)}
            />
            <Row
              sx={{ justifyContent: 'flex-end', mt: 1 }}
              gap={1}
            >
              <Button
                variant="outlined"
                onClick={() => setEnterContextOpen(false)}
              >
                Cancel
              </Button>
              <Button
                variant="outlined"
                onClick={handleAddContext}
              >
                Add context
              </Button>
            </Row>
          </Box>
        )}

        {contextItemsData?.getContextForProperty?.length > 0 && (
          <Box className="user-context-items property-detail-sidebar-container">
            <Row
              gap={1}
              className="user-context-items-header"
            >
              <Row gap={1}>
                <IconBubbleText size={16} />
                <Typography variant="h3">Additional context</Typography>
              </Row>
              <IconButton
                onClick={() => {
                  setEnterContextOpen(true)
                }}
              >
                <IconPlus size={16} />
              </IconButton>
            </Row>
            <List sx={{ p: 0 }}>
              {contextItemsData?.getContextForProperty.map((note) => (
                <ListItemButton
                  key={note.id}
                  sx={{ p: 0 }}
                  onClick={() => {}}
                >
                  <ListItemText
                    primary={note.plainTextValue}
                    secondary={dayjs(note.createdAt).format('MM/DD/YYYY')}
                    primaryTypographyProps={{
                      noWrap: true,
                      variant: 'subtitle2',
                    }}
                    secondaryTypographyProps={{
                      noWrap: true,
                      variant: 'caption',
                    }}
                  />

                  <ListItemIcon
                    sx={{
                      minWidth: '156px',
                      width: '156px',
                      justifyContent: 'flex-end',
                      ml: 2,
                      p: 0,
                    }}
                  >
                    <WorkspaceMemberChip
                      userId={note.userId}
                      workspaceId={workspaceId}
                    />
                  </ListItemIcon>
                </ListItemButton>
              ))}
            </List>
          </Box>
        )}
        <Box className="standard-property-definition property-detail-sidebar-container">
          <Row
            gap={1}
            className="standard-property-definition-header"
          >
            {React.createElement(ObjectTypeMetadata[objectType].icon, {
              size: 16,
              style: {
                display: 'inline-block',
              },
            })}
            <Typography variant="h3">{propertyDefinition.name}</Typography>
          </Row>
          <Typography variant="body1">
            {propertyDefinition.description}
          </Typography>
        </Box>
      </Box>
    )
  )
}

export default SidebarPropertyDetail
