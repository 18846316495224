import { useCallback, useState } from 'react'

import { Box, Typography } from '@mui/material'
import { Node, mergeAttributes } from '@tiptap/core'
import { ReactNodeViewRenderer, NodeViewWrapper } from '@tiptap/react'

import { useQuery } from '@redwoodjs/web'

import MeetingRecordingDialog from 'src/components/MeetingRecordingDialog/MeetingRecordingDialog'
import Row from 'src/components/Row/Row'
import { clampStyle } from 'src/lib/formatters'

import { nodeViewContainerSx } from '../styles'

const GET_RECORDING_CLIP_FOR_TIPTAP = gql`
  query GetRecordingClipByIdForTiptap($id: String!) {
    meetingRecordingClip(id: $id) {
      id
      title
      description
      meetingRecording {
        id
        startedAt
        statusHistory {
          level
          status
          reason
          message
          createdAt
        }
        title
        startedAt
        endedAt
        video {
          private {
            thumbnail {
              status
              url
            }
            download {
              status
              urls {
                default
                quality
                url
              }
            }
            playbackId
            tokens {
              video
              thumbnail
              storyboard
            }
          }
        }
      }
      participants {
        email
        contact {
          objectType
          objectId
          properties
        }
      }
      video {
        private {
          playbackId
          thumbnail {
            status
            url
          }
          tokens {
            video
            thumbnail
            storyboard
          }
          download {
            status
            urls {
              default
              quality
              url
            }
          }
        }
      }
    }
  }
`

const TiptapNodeClipRenderer = (props) => {
  const [open, setOpen] = useState(false)

  const meetingRecordingClipId = props.node.attrs.id
  const [clipData, setClipData] = useState(null)

  const clipId = useCallback(
    () => meetingRecordingClipId,
    [meetingRecordingClipId]
  )

  useQuery(GET_RECORDING_CLIP_FOR_TIPTAP, {
    variables: { id: clipId() },
    skip: !!clipData,
    onCompleted: ({ meetingRecordingClip }) => {
      setClipData(meetingRecordingClip)
    },
  })

  return (
    <NodeViewWrapper
      as="div"
      className="day-ai-clip"
      draggable={props.editor.isEditable}
      {...{ 'data-drag-handle': '' }}
    >
      <Row
        sx={{
          ...nodeViewContainerSx,
          width: '172px',
          height: '112px',
          alignItems: 'flex-start',
          ml: 4,
          mt: 2,
          p: 0,
          cursor: 'pointer',
        }}
        className={`day-ai-node ${
          props.editor.isEditable ? 'editable' : 'public'
        } ${props.selected ? 'selected' : ''}`}
        onClick={() => setOpen(true)}
      >
        <Box
          onClick={() => setOpen(true)}
          sx={{
            height: '112px',
            width: '172px',
            cursor: 'pointer',
            border: 'none !important',
            background: (theme) => theme.palette.background.default,
            '& .clipMetadata': {
              overflow: 'hidden',
              position: 'relative',
              top: '-112px',
              height: '112px',
              width: '172px',
              zIndex: 2,
              px: 1,
              py: '4px',
              background: (theme) => `${theme.palette.background.paper}EE`,
              opacity: 0,
              transition: 'all 0.4s ease-in-out',

              '& .clipTitle': {
                fontSize: '0.8rem',
                fontWeight: 600,
                width: '100%',
                mb: '4px',
                ...clampStyle(2),
              },

              '& .clipDescription': {
                fontSize: '0.6rem',
                lineHeight: '14px',
                width: '100%',
                ...clampStyle(4),
              },
            },
            '&:hover .clipMetadata': {
              opacity: 1,
            },
          }}
        >
          <>
            <Box
              component="img"
              src={
                clipData?.video?.private?.thumbnail?.url ||
                props?.node?.attrs?.thumbnailUrl
              }
              sx={{
                height: '112px',
                width: '172px',
                objectFit: 'cover',
                zIndex: 1,
                border: 'none !important',
                flexShrink: 0,
              }}
            />
            <Box className="clipMetadata">
              <Typography className="clipTitle">{clipData?.title}</Typography>
              <Typography className="clipDescription">
                {clipData?.description || clipData?.id || 'Clip not found'}
              </Typography>
            </Box>
          </>
        </Box>
      </Row>
      {clipData?.meetingRecording?.id && meetingRecordingClipId && (
        <MeetingRecordingDialog
          open={open}
          id={clipData?.meetingRecording?.id}
          clipId={meetingRecordingClipId}
          clipOnly={true}
          onClose={() => setOpen(false)}
        />
      )}
    </NodeViewWrapper>
  )
}

const TiptapNodeClip = Node.create({
  name: 'clip',
  group: 'block',
  atom: true,
  draggable: true,

  addAttributes() {
    return {
      id: {
        default: null,
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'day-ai-clip',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    const clipAttributes = mergeAttributes(HTMLAttributes, {
      'data-type': 'clip',
    })
    return ['day-ai-clip', clipAttributes]
  },

  addNodeView() {
    return ReactNodeViewRenderer(TiptapNodeClipRenderer)
  },
})

export default TiptapNodeClip
