import React from 'react'

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material'

import { navigate } from '@redwoodjs/router'

import { logger } from 'src/lib/logger'

interface TiptapSignupCtaProps {
  open: boolean
  setClosed: () => void
  text: string
  redirectTo: string // URL to redirect to when the button is clicked
}

const TiptapSignupCta: React.FC<TiptapSignupCtaProps> = ({
  open,
  setClosed,
  text,
  redirectTo,
}) => {
  const handleButtonClick = () => {
    navigate(redirectTo)
  }

  const handleClose = (event) => {
    logger.dev('useState firing')

    event.stopPropagation()
    event.preventDefault()
    setClosed()
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="dialog-title"
    >
      <DialogTitle id="dialog-title">
        Get started with Day.ai for free
      </DialogTitle>
      <DialogContent sx={{ width: '400px' }}>
        <DialogContentText>{text} (and much more)</DialogContentText>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button
          onClick={handleButtonClick}
          color="primary"
          size="small"
          variant="outlined"
          fullWidth={true}
        >
          Log in or Sign up
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default TiptapSignupCta
