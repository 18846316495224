import { useReducer, useEffect } from 'react'

import { Box, Typography } from '@mui/material'

import { useQuery } from '@redwoodjs/web'

import PropertyDisplay from 'src/components/Properties/PropertyDisplay'
import PropertyEdit from 'src/components/Properties/PropertyEdit'
import { PROPERTY_DEFINITIONS } from 'src/components/Properties/queries'
import propertiesReducer from 'src/components/Properties/reducer'
import { PROPERTY_ACTIONS } from 'src/components/Properties/types'
import Row from 'src/components/Row/Row'

// Action types

const fallbackCustomProperties = {}

const SidebarCustomProperties = ({
  workspaceId,
  customProperties = fallbackCustomProperties,
  objectType,
  objectId,
}) => {
  const [state, dispatch] = useReducer(propertiesReducer, {
    properties: {},
    pendingUpdates: {},
  })

  const { data: propertyDefinitions } = useQuery(PROPERTY_DEFINITIONS, {
    variables: {
      workspaceId,
    },
    skip: !workspaceId,
  })

  // Set initial state when customProperties changes
  useEffect(() => {
    dispatch({
      type: PROPERTY_ACTIONS.SET_INITIAL_STATE,
      payload: customProperties,
    })
  }, [customProperties])

  const containerSx = {
    mt: 4,
    width: '672px',
    flexWrap: 'wrap',
    gap: 3,
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
    flexDirection: 'row',
    '& .property-container': {
      py: 1,
      px: '12px',
      borderRadius: '4px',
      border: (theme) => `1px solid ${theme.palette.divider}`,
      '& .property-name-row': {
        gap: '4px',
        justifyContent: 'space-between',
        mb: 1,
        '& .property-name': {
          fontSize: '14px',
          color: 'text.primary',
          fontWeight: 600,
          letterSpacing: '-0.3px',
          flexShrink: 0,
        },
      },
    },
    '& .options-row': {
      justifyContent: 'flex-end',
      width: '300px',
      flexWrap: 'wrap',
      overflow: 'hidden',

      '& .property-display-label': {
        fontSize: '12px',
        fontWeight: 400,
        letterSpacing: '-0.33px',
        opacity: 0.8,
        wordWrap: 'break-word',
        overflow: 'visible',
        textOverflow: 'unset',
        flexGrow: 1,
        whiteSpace: 'normal',
        width: '300px',
      },
    },
  }

  return (
    <Box sx={containerSx}>
      {propertyDefinitions?.objectPropertyDefinitions
        .filter(
          (propertyDefinition) => propertyDefinition.objectTypeId === objectType
        )
        .map(
          (propertyDefinition, index) =>
            propertyDefinition && (
              <Box
                key={`${index}-${propertyDefinition.id}`}
                className="property-container"
              >
                <Row className="property-name-row">
                  <Typography className="property-name">
                    {propertyDefinition.name}
                  </Typography>
                  <PropertyEdit
                    propertyDefinition={propertyDefinition}
                    objectId={objectId}
                    objectType={objectType}
                    value={state?.properties?.[propertyDefinition.id] || ''}
                    workspaceId={workspaceId}
                    onUpdate={() => {}}
                    onClose={() => {}}
                    dispatch={dispatch}
                    targetElementId={`${propertyDefinition.id}-options-row`}
                    justify="flex-end"
                    showEditButton={true}
                  />
                </Row>
                <Row
                  className="options-row"
                  id={`${propertyDefinition.id}-options-row`}
                >
                  <PropertyDisplay
                    propertyDefinition={propertyDefinition}
                    objectId={objectId}
                    objectType={objectType}
                    value={state?.properties?.[propertyDefinition.id] || ''}
                    workspaceId={workspaceId}
                    justify="flex-start"
                  />
                </Row>
              </Box>
            )
        )}
    </Box>
  )
}

export default SidebarCustomProperties
