import { Chip, Link, Tooltip, Typography, Box } from '@mui/material'
import { IconCheck, IconTriangleFilled, IconX } from '@tabler/icons-react'
import type { DayObject, ObjectPropertyDefinition } from 'types/graphql'

import { isValidEmail } from 'src/lib/contactFormatting'
import { dayjs } from 'src/lib/dayjs'
import { logger } from 'src/lib/logger'
import {
  NativeObjectTypes,
  PropertyTypes,
  type NativeObjectType,
} from 'src/lib/objects'

import MetadataChip from '../Chips/MetadataChip/MetadataChip'
import ObjectChip from '../Chips/ObjectChip/ObjectChip'
import Row from '../Row/Row'

import { formatPhoneNumberForDisplay } from './formattingAndValidation'
import propertyDisplayOverrides from './propertyDisplayOverrides'

const picklistOptionsRowSx = {
  height: '100%',
  flexWrap: 'wrap',
  gap: 0.5,
}

const PropertyTooltip = ({ reasoning, children }) => {
  return (
    <Tooltip
      title={reasoning?.reasoning || reasoning}
      arrow={true}
    >
      <Box
        sx={{
          display: 'flex',
          flex: 1,
        }}
      >
        {children}
      </Box>
    </Tooltip>
  )
}
const PicklistOptionsRow = ({ propertyValue, options, objectId }) => {
  if (!Array.isArray(propertyValue?.value) || !options) {
    return null
  }

  const reasoningMap = {}
  const safeSelectedOptionIds = Array.from(
    new Set<string>(
      propertyValue.value.map((option) => {
        if (option.value !== undefined) {
          reasoningMap[option.id] = option.reasoning
        }
        return option.value ? option.id : null
      })
    )
  ).filter(Boolean)

  return (
    <Row
      sx={picklistOptionsRowSx}
      className="picklist-options-row"
    >
      {Array.isArray(safeSelectedOptionIds) &&
        safeSelectedOptionIds.map((optionId) => {
          const cleanOptions = options.filter((opt) => opt.id)
          if (!optionId || !cleanOptions || !Array.isArray(cleanOptions))
            return null

          const option = cleanOptions.find((opt) => opt.id === optionId)
          return (
            option && (
              <PropertyTooltip
                reasoning={reasoningMap[optionId]}
                key={`option-${optionId}-${objectId}`}
              >
                <Chip
                  key={optionId}
                  label={option?.name || optionId}
                  size="small"
                  sx={{
                    height: '20px',
                    fontSize: '11px',
                    '& .MuiChip-label': {
                      px: 1,
                    },
                  }}
                />
              </PropertyTooltip>
            )
          )
        })}
    </Row>
  )
}

type PropertyDisplayProps = {
  propertyDefinition: ObjectPropertyDefinition
  objectId?: string
  objectType: NativeObjectType
  workspaceId: string
  value: any
  justify?: 'flex-start' | 'flex-end'
  object?: DayObject
}

const PropertyDisplay = ({
  propertyDefinition,
  objectId,
  objectType,
  workspaceId,
  value,
  justify = 'flex-end',
  object = null,
}: PropertyDisplayProps) => {
  let component = null
  let label = null
  let useLink = false

  const override = propertyDisplayOverrides[objectType]?.[propertyDefinition.id]
  if (override?.propertyRenderer) {
    return override.propertyRenderer({
      propertyDefinition,
      objectId,
      objectType,
      workspaceId,
      value,
      object,
    })
  }

  const propertyType = propertyDefinition.propertyTypeId

  if (value === undefined || value === null) {
    label = null
  } else if (propertyType === PropertyTypes.Boolean) {
    component = value.value ? (
      <PropertyTooltip reasoning={value.reasoning}>
        <MetadataChip
          state={{
            label: 'Yes',
            value: value.value,
            color: 'success',
          }}
          icon={<IconCheck size={16} />}
        />
      </PropertyTooltip>
    ) : (
      <PropertyTooltip reasoning={value.reasoning}>
        <MetadataChip
          state={{
            label: 'No',
            value: value.value,
            color: 'primary',
          }}
          icon={
            <IconX
              size={16}
              style={{ opacity: 0.6 }}
            />
          }
        />
      </PropertyTooltip>
    )
  } else if (propertyType === PropertyTypes.TextArea) {
    label = value.value
  } else if (propertyType === PropertyTypes.DateTime) {
    label = dayjs(value.value).format('M/DD/YYYY')
  } else if (propertyType === PropertyTypes.Currency) {
    label = value.value ? `$${Math.round(value.value).toLocaleString()}` : ''
  } else if (propertyType === PropertyTypes.Email) {
    if (isValidEmail(value.value)) {
      component = (
        <Row>
          <PropertyTooltip reasoning={value.reasoning}>
            <ObjectChip
              crmObject={{
                objectId: value.value,
                objectType: NativeObjectTypes.Person,
                properties: { email: value.value },
              }}
              workspaceId={workspaceId}
            />
          </PropertyTooltip>
        </Row>
      )
    } else if (value?.length > 0) {
      component = (
        <MetadataChip
          state={{
            label: `Invalid Email (${value.value})`,
            value: value.value,
            color: 'error',
          }}
          icon={<IconTriangleFilled />}
        />
      )
    }
  } else if (propertyType === PropertyTypes.Phone) {
    label =
      typeof value.value === 'string'
        ? formatPhoneNumberForDisplay(value.value)
        : ''
  } else if (propertyType === PropertyTypes.DeleteMarker) {
    //Obviously should never happen
    label = null
  } else if (propertyType === PropertyTypes.Calculated) {
    label = value.value
  } else if (propertyType === PropertyTypes.Address) {
    label = value.value
  } else if (propertyType === PropertyTypes.Url) {
    label = value.value
      ? !value.value.startsWith('http')
        ? `http://${value.value}`
        : value.value
      : ''
    useLink = true
  } else if (propertyType === PropertyTypes.Combobox) {
    label = null
  } else if (propertyType === PropertyTypes.MultiPicklist) {
    label = null
  } else if (propertyType === PropertyTypes.Integer) {
    logger.dev('Integer', { value })
    if (typeof value.value === 'number') {
      label = Math.round(value.value).toLocaleString()
    } else if (typeof value.value === 'string') {
      try {
        label = Math.round(Number(value.value)).toLocaleString()
      } catch (e) {
        label = value.value
      }
    }
  }

  if (
    value?.value &&
    propertyDisplayOverrides[objectType]?.[propertyDefinition.id]
      ?.stringFormatter
  ) {
    label = propertyDisplayOverrides[objectType]?.[
      propertyDefinition.id
    ]?.stringFormatter(value.value)
  }

  const hasOptions = [
    PropertyTypes.Combobox,
    PropertyTypes.MultiPicklist,
    PropertyTypes.Picklist,
  ].includes(propertyType as any)

  const containerSx = {
    width: '100%',
    justifyContent: justify,
    userSelect: 'none',
    '& .MuiTypography-root': {
      fontSize: '12px',
      color: 'text.secondary',
      fontWeight: 400,
      letterSpacing: '-0.22px',
      userSelect: 'none',
    },
    '& .property-display-label': {
      flexShrink: 0,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      fontSize: '11px',

      '& a.MuiTypography-root.MuiLink-root': {
        textDecoration: 'none',
      },
    },
  }

  return (
    <Row sx={containerSx}>
      {component ? (
        component
      ) : useLink ? (
        <PropertyTooltip reasoning={value?.reasoning}>
          <Link
            href={label}
            target="_blank"
            rel="noopener noreferrer"
          >
            {label}
          </Link>
        </PropertyTooltip>
      ) : hasOptions && propertyDefinition.options ? (
        <PicklistOptionsRow
          propertyValue={value}
          options={propertyDefinition.options}
          objectId={objectId}
        />
      ) : label && typeof label === 'string' ? (
        <PropertyTooltip reasoning={value?.reasoning}>
          <Typography className="property-display-label">{label}</Typography>
        </PropertyTooltip>
      ) : null}
    </Row>
  )
}

export default PropertyDisplay
