import { useEffect, useState } from 'react'

import { Box, Typography } from '@mui/material'

import { useQuery } from '@redwoodjs/web'

import Row from '../Row/Row'
import SidebarActions from '../Sidebar/SidebarActions/SidebarActions'

export const GET_ACTIONS_FOR_MEETING_RECORDING = gql`
  query getActionsForMeetingRecording(
    $workspaceId: String!
    $meetingRecordingId: String!
  ) {
    actionsForMeetingRecording(
      workspaceId: $workspaceId
      meetingRecordingId: $meetingRecordingId
    ) {
      id
      workspaceId
      createdAt
      updatedAt
      title
      description
      reasoning
      type
      priority
      people
      organizations
      opportunityIds
      pinnedFor
      channel {
        id
        label
        type
        accountId
      }
      status {
        id
        label
        updatedAt
      }
      assignedAt
      owner {
        id
        email
      }
      draft {
        title
        body
        prompts {
          shortPrompt
          channelType
        }
      }
      source {
        label
        type
        id
      }
      pipelineType
      timeframe {
        dueDate
        reminderDate
        updatedAt
      }
    }
  }
`

const MEETING_RECENTLY_ENDED_TIMEFRAME_MS = 60 * 60 * 1000 // 1 hour in ms

const MeetingRecordingActionItems = ({
  meetingRecordingId,
  readyAt = null,
  workspaceId,
}: {
  meetingRecordingId: string
  readyAt?: Date | null
  workspaceId: string
}) => {
  const {
    data: actionsData,
    loading,
    refetch,
  } = useQuery(GET_ACTIONS_FOR_MEETING_RECORDING, {
    variables: { workspaceId, meetingRecordingId },
    skip: !meetingRecordingId || !workspaceId,
  })

  const [currentTime, setCurrentTime] = useState(() => Date.now())

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(Date.now())
    }, 600000) // update every 10 minutes

    return () => clearInterval(interval)
  }, [])

  const actions = actionsData?.actionsForMeetingRecording

  const isInRecentlyEndedWindow =
    readyAt &&
    currentTime > readyAt.getTime() &&
    currentTime < readyAt.getTime() + MEETING_RECENTLY_ENDED_TIMEFRAME_MS

  return (
    <>
      <Row
        sx={{
          gap: 2,
          mt: 4,
        }}
      >
        <Typography variant="h4">Actions</Typography>
      </Row>
      {loading ? (
        <Box>
          <Typography
            variant="body2"
            color="text.disabled"
            sx={{ pt: 2 }}
          >
            Loading...
          </Typography>
        </Box>
      ) : (
        <>
          {actionsData && (
            <>
              {actions.length > 0 ? (
                <Box>
                  <SidebarActions
                    actions={actions}
                    onUpdate={refetch}
                  />
                </Box>
              ) : isInRecentlyEndedWindow ? (
                <Box>
                  <Typography
                    variant="body2"
                    color="text.disabled"
                    sx={{ pt: 2 }}
                  >
                    No actions identified for this meeting yet. Any actions will
                    appear here as we process your recordings.
                  </Typography>
                </Box>
              ) : (
                <Box>
                  <Typography
                    variant="body2"
                    color="text.disabled"
                    sx={{ pt: 2 }}
                  >
                    No actions identified for this meeting.
                  </Typography>
                </Box>
              )}
            </>
          )}
        </>
      )}
    </>
  )
}

export default MeetingRecordingActionItems
