import { PAGE_FIELDS_FRAGMENT } from './fragments'

export const CREATE_PAGE = gql`
  mutation CreatePage($input: CreatePageInput!) {
    createPage(input: $input) {
      ...PageFields
    }
  }
  ${PAGE_FIELDS_FRAGMENT}
`

export const UPDATE_PAGE = gql`
  mutation UpdatePage($id: String!, $input: UpdatePageInput!) {
    updatePage(id: $id, input: $input) {
      ...PageFields
    }
  }
  ${PAGE_FIELDS_FRAGMENT}
`

export const CREATE_EMAIL_DRAFT = gql`
  mutation CreateEmailDraft($draft: GmailDraftInput!, $workspaceId: String!) {
    createGmailDraft(draft: $draft, workspaceId: $workspaceId)
  }
`

export const CONVERT_PAGE_TO_TEMPLATE = gql`
  mutation ConvertPageToTemplate(
    $id: String!
    $outputType: TemplateOutputType!
    $workspaceId: String!
    $undo: Boolean
  ) {
    convertPageToTemplate(
      id: $id
      outputType: $outputType
      workspaceId: $workspaceId
      undo: $undo
    ) {
      ...PageFields
    }
  }

  ${PAGE_FIELDS_FRAGMENT}
`

export const CONVERT_PAGE_TO_KNOWLEDGE = gql`
  mutation ConvertPageToKnowledge(
    $id: String!
    $workspaceId: String!
    $undo: Boolean
  ) {
    convertPageToKnowledge(id: $id, workspaceId: $workspaceId, undo: $undo) {
      ...PageFields
    }
  }
  ${PAGE_FIELDS_FRAGMENT}
`

export const ADD_TEMPLATE_TO_WORKSPACE_USER_CONTEXT = gql`
  mutation AddTemplateToWorkspaceUserContext(
    $contextId: String!
    $pageId: String!
    $workspaceId: String!
    $undo: Boolean
  ) {
    addTemplateToWorkspaceUserContext(
      contextId: $contextId
      pageId: $pageId
      workspaceId: $workspaceId
      undo: $undo
    )
  }
`
