import { Menu, MenuItem } from '@mui/material'
import type { TemplateOutputType } from 'types/graphql'

import { TemplateTypeMetadata } from 'src/lib/objects'

const TemplateTypeChooserMenu = ({
  anchorEl,
  onSelect,
  onClose,
}: {
  anchorEl: HTMLElement | null
  onSelect: (templateType: TemplateOutputType) => void
  onClose: () => void
}) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={onClose}
    >
      {Object.keys(TemplateTypeMetadata).map((type) => (
        <MenuItem
          key={type}
          onClick={() => onSelect(type as TemplateOutputType)}
        >
          {TemplateTypeMetadata[type].label}
        </MenuItem>
      ))}
    </Menu>
  )
}

export default TemplateTypeChooserMenu
