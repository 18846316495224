export const VIEW_FIELDS_FRAGMENT = gql`
  fragment ViewFields on View {
    id
    workspaceId
    creatorId
    title
    description
    createdAt
    updatedAt
    gridState
    objectType
    workspaceId
    pins {
      id
      userId
      objectType
      position
    }
    position
    shareWithWorkspace
    description
  }
`
