import { Chip } from '@mui/material'

import { actionStatusMap } from '../styles'

const ActionStatusChip = ({ status }) => {
  if (!status) return null
  const statusObject = actionStatusMap[status.id]
  return (
    <Chip
      size="small"
      icon={statusObject.icon}
      label={statusObject.label}
      variant="outlined"
      sx={{
        border: 'none',
        justifyContent: 'flex-start',
        width: '100%',
        fontSize: '11px',
        fontWeight: 600,
        letterSpacing: '-0.2px',
        '& .MuiChip-icon': {
          color: (theme) => theme.palette[statusObject.color].main,
          height: '12px',
          width: '12px',
          m: 0,
        },
      }}
    />
  )
}

export default ActionStatusChip
