import { useCallback, useContext } from 'react'

import {
  Box,
  Button,
  Chip,
  IconButton,
  lighten,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material'
import { useGridApiContext, type GridSortItem } from '@mui/x-data-grid-premium'
import {
  IconAdjustmentsHorizontal,
  IconArrowBigDown,
  IconArrowBigUp,
  IconDownload,
  IconX,
} from '@tabler/icons-react'

import { logger } from 'src/lib/logger'
import { modernButtonContainer, modernTabStyles } from 'src/lib/style'

import Row from '../Row/Row'

import ColumnSelectorChip from './ColumnSelectorChip'
import { viewManagerContainerSx, tabsContainerSx } from './styles'
import ViewCreate from './ViewCreate'
import ViewFilters from './ViewFilters'
import ViewsContext from './ViewsContext'
import type { ViewsContextValue } from './ViewsContext'
import ViewTab from './ViewTab'

interface ViewManagerProps {
  showDisplaySettings: boolean
  setShowDisplaySettings: (_) => void
}

const colors = {
  columns: '#8A6FD1',
  filters: '#37B393',
  groupings: '#DE7C45',
  sorts: '#4B97D2',
}

const ViewManager: React.FC<ViewManagerProps> = ({
  showDisplaySettings,
  setShowDisplaySettings,
}) => {
  const viewsContext = useContext(ViewsContext) as ViewsContextValue
  const apiGridRef = useGridApiContext()

  const {
    views,
    pinnedViews,
    currentView,
    loading,
    saving: _saving,
    activateView,
  } = viewsContext

  const apiRef = useGridApiContext()

  const handleViewChange = useCallback(
    (viewId: string | null) => {
      if (!viewId) return

      const view = views.find((v) => v.id === viewId)
      if (view) {
        activateView(view)
      }
    },
    [views, activateView]
  )

  const handleToggleSort = (field, remove = false) => {
    const currentSortModel = apiGridRef.current.getSortModel()
    const newSortModel = currentSortModel
      .map((sort) => {
        if (sort.field === field) {
          const newSort = remove ? null : sort.sort === 'asc' ? 'desc' : 'asc'
          return { ...sort, sort: newSort }
        }
        return sort
      })
      .filter((sort) => sort.sort !== null) as GridSortItem[]
    logger.dev('newSortModel', newSortModel)
    apiGridRef.current.setSortModel(newSortModel)
  }

  const displaySettingsSx = {
    height: '36px',
    justifyContent: 'space-between',

    '& .chip-row': {
      gap: '8px',

      '& .MuiChip-root': {
        height: '24px',

        '& .MuiChip-icon': {
          mr: '0px',
          ml: '6px',
          color: 'inherit',
          height: '11px',
          width: '11px',
        },

        '& .MuiChip-deleteIcon': {
          color: 'inherit',
          height: '11px',
          width: '11px',
        },

        '& .MuiChip-label': {
          fontSize: '11px',
          fontWeight: 600,
          lineHeight: '16px',
          letterSpacing: '-0.2px',
        },

        '&.columns': {
          backgroundColor: lighten(colors.columns, 0.9),
          borderColor: lighten(colors.columns, 0.7),
          color: colors.columns,
        },

        '&.filters': {
          backgroundColor: lighten(colors.filters, 0.9),
          borderColor: lighten(colors.filters, 0.7),
          color: colors.filters,
        },

        '&.groupings': {
          backgroundColor: lighten(colors.groupings, 0.9),
          borderColor: lighten(colors.groupings, 0.7),
          color: colors.groupings,
        },

        '&.sorts': {
          backgroundColor: lighten(colors.sorts, 0.9),
          borderColor: lighten(colors.sorts, 0.7),
          color: colors.sorts,
        },
      },
    },
  }

  return (
    <Box>
      <Row sx={viewManagerContainerSx}>
        <Row className="view-manager-tabs-container">
          <Row sx={{ ...tabsContainerSx, ...modernTabStyles }}>
            {pinnedViews.length > 0 ? (
              <Tabs
                value={0}
                visibleScrollbar={true}
              >
                {pinnedViews.map((view) => (
                  <ViewTab
                    key={view.id}
                    view={view}
                    currentView={currentView}
                    setCurrentView={handleViewChange}
                  />
                ))}
              </Tabs>
            ) : (
              <Tabs value={loading ? 'loading' : 'all'}>
                <Tab
                  value={loading ? 'loading' : 'all'}
                  disabled={loading}
                  label={
                    <Row>
                      <Typography>{loading ? 'Loading...' : 'All'}</Typography>
                    </Row>
                  }
                />
              </Tabs>
            )}
            <Row>
              <ViewCreate />
            </Row>
          </Row>

          <Row gap={1}>
            <Tooltip
              title="Export"
              arrow={true}
            >
              <IconButton className="export-button">
                <IconDownload
                  size={12}
                  stroke={2.5}
                />
              </IconButton>
            </Tooltip>

            {!showDisplaySettings && (
              <Tooltip
                title={
                  showDisplaySettings
                    ? 'Hide display settings'
                    : 'Show display settings'
                }
                arrow={true}
              >
                <Box sx={{ ...modernButtonContainer }}>
                  <Button
                    onClick={() => setShowDisplaySettings((prev) => !prev)}
                    startIcon={
                      <IconAdjustmentsHorizontal
                        size={12}
                        stroke={2.5}
                      />
                    }
                    size="small"
                    variant="outlined"
                    className="right flat"
                  >
                    {'Display'}
                  </Button>
                </Box>
              </Tooltip>
            )}
          </Row>
        </Row>
      </Row>
      {showDisplaySettings && (
        <Row sx={displaySettingsSx}>
          <Row className="chip-row">
            <ColumnSelectorChip />
            {(apiRef?.current.getSortModel() || []).map((sort) => (
              <Chip
                key={sort.field}
                size="small"
                variant="outlined"
                className="sorts"
                onDelete={() => handleToggleSort(sort.field, true)}
                deleteIcon={
                  <IconX
                    size={12}
                    stroke={2.5}
                  />
                }
                onClick={() => handleToggleSort(sort.field)}
                label={
                  apiRef?.current
                    .getAllColumns()
                    .find((c) => c.field === sort.field)?.headerName ||
                  sort.field
                }
                icon={
                  sort.sort === 'asc' ? (
                    <IconArrowBigUp
                      size={6}
                      stroke={2.5}
                    />
                  ) : (
                    <IconArrowBigDown
                      size={6}
                      stroke={2.5}
                    />
                  )
                }
              />
            ))}

            <ViewFilters />
          </Row>

          <Tooltip
            title="Reset Filters"
            arrow={true}
          >
            <IconButton
              onClick={() => {
                setShowDisplaySettings(false)
              }}
              sx={{
                p: '8px',
                borderRadius: '3px',
              }}
            >
              <IconX size={16} />
            </IconButton>
          </Tooltip>
        </Row>
      )}
    </Box>
  )
}

export default ViewManager
