import type { SidebarState } from './SidebarContext'

export type NavigationState = {
  history: any[]
  currentIndex: number
}

export enum NavigationActions {
  ADD_OBJECT = 'ADD_OBJECT',
  NAVIGATE_BACK = 'NAVIGATE_BACK',
  NAVIGATE_FORWARD = 'NAVIGATE_FORWARD',
  RESET = 'RESET',
  OBJECT_UPDATED = 'OBJECT_UPDATED',
}

export interface NavigationUserAction {
  run: () => void
  enabled: boolean
}

export type NavigationAction =
  | { type: NavigationActions.ADD_OBJECT; payload: SidebarState }
  | { type: NavigationActions.NAVIGATE_BACK }
  | { type: NavigationActions.NAVIGATE_FORWARD }
  | { type: NavigationActions.RESET }
  | { type: NavigationActions.OBJECT_UPDATED; payload: SidebarState }

const buildNavigationKey = (state: SidebarState) => {
  return `${state.mode}-${state.object.objectId}-${state.object.objectType}`
}

function navigationReducer(
  state: NavigationState,
  action: NavigationAction
): NavigationState {
  let newHistory: any[]

  switch (action.type) {
    case 'ADD_OBJECT':
      // Skip if we're already at this object
      if (
        buildNavigationKey(state.history[state.currentIndex]) ===
        buildNavigationKey(action.payload)
      ) {
        return state
      }
      // Truncate history at current index and add new object
      newHistory = [
        ...state.history.slice(0, state.currentIndex + 1),
        action.payload,
      ]
      return {
        history: newHistory,
        currentIndex: state.currentIndex + 1,
      }
    case 'NAVIGATE_BACK':
      if (state.currentIndex > 0) {
        return { ...state, currentIndex: state.currentIndex - 1 }
      }
      return state
    case 'NAVIGATE_FORWARD':
      if (state.currentIndex < state.history.length - 1) {
        return { ...state, currentIndex: state.currentIndex + 1 }
      }
      return state
    case 'OBJECT_UPDATED': {
      const newHistory = [
        ...state.history.slice(0, state.currentIndex),
        action.payload,
        ...state.history.slice(state.currentIndex + 1, state.history.length),
      ]
      return { ...state, history: newHistory }
    }
    case 'RESET':
      return { history: [], currentIndex: -1 }
    default:
      return state
  }
}

export default navigationReducer
