import { Box, Typography } from '@mui/material'

import { Redirect } from '@redwoodjs/router'

import { useAuth } from 'src/auth'
import BodyContainer from 'src/components/BodyContainer/BodyContainer'
import MeetingRecordingActionItemsStories from 'src/components/MeetingRecordingActionItems/MeetingRecordingActionItems.stories'
import { isInternalUser } from 'src/lib/gates'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column' as const,
    gap: 4,
    padding: 4,
    overflow: 'auto',
    height: '100%',
  },
  section: {
    backgroundColor: 'white',
    padding: 3,
    borderRadius: 1,
    boxShadow: 1,
  },
  sectionTitle: {
    typography: 'h6',
    marginBottom: 2,
    backgroundColor: 'white',
    paddingBottom: 1,
  },
}

const styleGuides = [
  {
    title: 'Meeting Recording Followups',
    component: () => (
      <div>
        <MeetingRecordingActionItemsStories />
      </div>
    ),
  },
]

const StyleGuidesPage = () => {
  const { currentUser } = useAuth()

  if (!currentUser) {
    return <Redirect to="/login" />
  }

  if (!isInternalUser(currentUser)) {
    return <Redirect to="/" />
  }

  return (
    <BodyContainer>
      <Box sx={styles.container}>
        {styleGuides.map(({ title, component: Component }, index) => (
          <Box
            key={index}
            sx={styles.section}
          >
            <Typography sx={styles.sectionTitle}>{title}</Typography>
            <Component />
          </Box>
        ))}
      </Box>
    </BodyContainer>
  )
}

export default StyleGuidesPage
