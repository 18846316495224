import { Box, Typography } from '@mui/material'

import { sidebarBodyTextStyle, sidebarHeadingTextStyle } from '../styles'

const SidebarContactAbout = ({ contact }) => {
  return (
    <Box sx={{}}>
      {contact?.properties?.careerHistory && (
        <>
          <Typography sx={{ ...sidebarHeadingTextStyle, mt: 1 }}>
            Career History
          </Typography>
          <Typography sx={{ mb: 2, ...sidebarBodyTextStyle }}>
            {contact?.properties?.careerHistory}
          </Typography>
        </>
      )}
      {contact?.properties?.interestsHobbies && (
        <>
          <Typography sx={sidebarHeadingTextStyle}>
            Interests & Hobbies
          </Typography>

          <Typography sx={{ fontWeight: 300, fontSize: '0.8rem', mb: 3 }}>
            {contact?.properties?.interestsHobbies?.map((interest, index) => (
              <Box
                key={`interest_${index}`}
                sx={{
                  display: 'inline-block',
                  background: (theme) => theme.palette.text.primary,
                  padding: '2px 5px',
                  mr: '4px',
                  mb: '4px',
                  borderRadius: '3px',
                  fontSize: '0.6rem',
                  fontWeight: 500,
                  color: 'white',
                }}
              >
                {interest}
              </Box>
            ))}
          </Typography>
        </>
      )}

      {contact?.properties?.description && (
        <>
          <Typography sx={sidebarHeadingTextStyle}>Bio</Typography>
          <Typography sx={{ mb: 3, ...sidebarBodyTextStyle }}>
            {contact?.properties?.description}
          </Typography>
        </>
      )}
    </Box>
  )
}

export default SidebarContactAbout
