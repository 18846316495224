import { NativeObjectTypes } from 'src/lib/objects'

export const PROPERTY_ACTIONS = {
  SET_INITIAL_STATE: 'SET_INITIAL_STATE',
  UPDATE_PROPERTY: 'UPDATE_PROPERTY',
  UPDATE_PROPERTY_SUCCESS: 'UPDATE_PROPERTY_SUCCESS',
  UPDATE_PROPERTY_ERROR: 'UPDATE_PROPERTY_ERROR',
}

export type PicklistOption = {
  id: string
  name: string
  description?: string
  selected?: boolean
}

export const customPropertyObjectTypes = {
  [NativeObjectTypes.Organization]: {
    enabled: true,
    hasWeb: true,
  },
  [NativeObjectTypes.Opportunity]: {
    enabled: true,
    hasWeb: false,
  },
  [NativeObjectTypes.Person]: {
    enabled: true,
    hasWeb: true,
  },
}
