import { useState } from 'react'

import { Chip, Popover } from '@mui/material'
import { DateCalendar } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { IconCalendarBolt } from '@tabler/icons-react'
import type { Dayjs } from 'dayjs'
import type { Action } from 'types/graphql'

import { dayjs } from 'src/lib/dayjs'

const ActionTimeframeChip = ({
  action,
  handleSetDate,
}: {
  action: Action
  handleSetDate: (dueDate: Dayjs) => void
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [dueDate, setDueDate] = useState<Dayjs | null>(
    action.timeframe?.dueDate ? dayjs.utc(action.timeframe?.dueDate) : null
  )

  return (
    <>
      <Chip
        size="small"
        variant="outlined"
        icon={<IconCalendarBolt />}
        label={dueDate ? dueDate.utc().format('MMM D, YYYY') : 'Choose a date'}
        sx={{
          border: 'none',
          justifyContent: 'flex-start',
          width: '100%',
          p: 0,
          m: 0,
          '&:hover': {
            backgroundColor: 'transparent !important',
          },
          '& .MuiChip-icon': {
            m: 0,
            height: '12px',
            width: '12px',
            flexShrink: 0,
            mt: '-1px',
          },
        }}
        onClick={(event) => setAnchorEl(event.currentTarget)}
      />
      <Popover
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
      >
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          dateLibInstance={dayjs}
        >
          <DateCalendar
            value={dueDate}
            timezone="UTC"
            disablePast
            onChange={(e) => {
              setAnchorEl(null)
              setDueDate(dayjs(e))
              handleSetDate(dayjs(e))
            }}
          />
        </LocalizationProvider>
      </Popover>
    </>
  )
}

export default ActionTimeframeChip
