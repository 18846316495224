import { useMemo } from 'react'

import { Box, Typography } from '@mui/material'
import type { CRMObject, GmailThread, GmailThreadSummary } from 'types/graphql'

import ObjectChipRow from 'src/components/Chips/ObjectChipRow/ObjectChipRow'
import InteractionsTimeline from 'src/components/InteractionsTimeline/InteractionsTimeline'
import Row from 'src/components/Row/Row'
import { NativeObjectTypes } from 'src/lib/objects'

const decodeHtml = (html: string): string => {
  const txt = document.createElement('textarea')
  txt.innerHTML = html
  return txt.value
}

const SidebarLayoutGmailThread = ({ crmObject }: { crmObject: CRMObject }) => {
  const workspaceId = (crmObject?.properties as any)?.workspaceId
  const gmailThread: GmailThread = crmObject?.properties as GmailThread
  const summary = (gmailThread?.summary as GmailThreadSummary)?.summary

  const timeline = useMemo(
    () =>
      gmailThread?.messages && gmailThread.messages.length > 0
        ? gmailThread.messages.map((message) => ({
            type: 'email',
            actor: (message.recipients || [])?.find(
              (person) => person.type === 'FROM'
            )?.address,
            timestamp: message.internalDate,
            label: decodeHtml(message.snippet),
            people: message.recipients?.map((r) => r.address),
          }))
        : [],
    [gmailThread]
  )

  return gmailThread ? (
    <Box sx={{ pb: 3, height: '100%' }}>
      <Row
        sx={{
          justifyContent: 'space-between',
          background: '#c2e7ff',
          px: '12px',
          height: '32px',
          mb: '12px',
        }}
      >
        <Row gap={1}>
          <Box
            component="img"
            src={'/gmail-icon.svg'}
            sx={{
              height: '18px',
              width: '18px',
            }}
          />
          <Typography
            sx={{
              fontSize: '14px',
              letterSpacing: '-0.3px',
              fontWeight: 500,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              maxWidth: '620px',
            }}
          >
            {gmailThread.subject}
          </Typography>
        </Row>
      </Row>
      <Box sx={{ px: '12px', height: 'calc(100% - 32px)', overflow: 'hidden' }}>
        <Box sx={{ pb: 2, pt: 0 }}>
          <ObjectChipRow
            objects={gmailThread.recipients?.map((r) => ({
              objectId: r.email,
              objectType: NativeObjectTypes.Person,
              properties: r,
            }))}
            workspaceId={workspaceId}
            showSidebar={true}
            onRemove={null}
          />
        </Box>
        <Box
          sx={{
            cursor: 'pointer',
            height: 'calc(100%)',
            overflowY: 'auto',
          }}
          onClick={() => {
            window.open(gmailThread.externalUrl, '_blank')
          }}
        >
          {summary && (
            <>
              <Typography
                variant="h3"
                sx={{ textAlign: 'center', lineHeight: '160%', mt: '12px' }}
              >
                Summary
              </Typography>

              <Row sx={{ justifyContent: 'center' }}>
                <Box
                  sx={{
                    width: '1px',
                    height: '20px',
                    my: '4px',
                    backgroundColor: '#000000',
                    opacity: 0.4,
                  }}
                />
              </Row>
              {summary && (
                <Typography
                  sx={{
                    fontSize: '14px',
                    opacity: 0.7,
                    lineHeight: '140%',
                    letterSpacing: '-0.42px',
                    fontWeight: 400,
                    border: (theme) => `1px solid ${theme.palette.divider}`,
                    borderRadius: 2,
                    p: 2,
                    my: 1,
                  }}
                >
                  {typeof summary === 'string' ? summary : 'No summary found'}
                </Typography>
              )}

              <Row sx={{ justifyContent: 'center' }}>
                <Box
                  sx={{
                    width: '1px',
                    height: '20px',
                    my: '4px',
                    backgroundColor: '#000000',
                    opacity: 0.4,
                  }}
                />
              </Row>
            </>
          )}
          <Box sx={{ mt: 1 }}>
            <InteractionsTimeline
              timeline={timeline}
              showHeader={false}
              loading={false}
              title="Timeline"
              refetch={null}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  ) : null
}

export default SidebarLayoutGmailThread
