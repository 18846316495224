import { useContext, useMemo, useState } from 'react'

import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  MenuItem,
  Popover,
  Select,
  Typography,
} from '@mui/material'
import {
  RiCheckFill,
  RiCommunityFill,
  RiLink,
  RiLockFill,
} from '@remixicon/react'
import { IconEye } from '@tabler/icons-react'
import type { DocumentNode } from 'graphql'
import { useConfirm } from 'material-ui-confirm'
import toast from 'react-hot-toast'
import type { ValueOf } from 'type-fest'
import type {
  CalendarEvent,
  ObjectAuthorization,
  RemoveUserAuthorizationForObject,
  UpdatePageAuthorization,
  UpdateWorkspaceAuthorizationForObject,
  UpsertUserAuthorizationForObject,
  WorkspaceMember,
} from 'types/graphql'

import { routes } from '@redwoodjs/router'
import { useMutation } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import { DayContext } from 'src/lib/dayContext'
import { logger } from 'src/lib/logger'
import {
  getUserAuthorizations,
  NativeObjectTypes,
  ObjectAccessLevels,
  type NativeObjectType,
} from 'src/lib/objects'

import MeetingRecordingShareAsPage from './MeetingRecordingShareAsPage/MeetingRecordingShareAsPage'
import Row from './Row/Row'
import WorkspaceMemberSelect from './WorkspaceMemberSelect/WorkspaceMemberSelect'
import WorkspaceMemberTile from './WorkspaceMemberTile'

const ACCESS_LEVELS = {
  [ObjectAccessLevels.Owner]: {
    displayName: 'Owner',
    chooseable: false,
    order: 1,
  },
  [ObjectAccessLevels.FullAccess]: {
    displayName: 'Full access',
    description: 'Can edit and share with others',
    chooseable: true,
    order: 2,
  },
  [ObjectAccessLevels.Editor]: {
    displayName: 'Editor',
    description: 'Can edit, but not share with others',
    chooseable: true,
    order: 3,
  },
  [ObjectAccessLevels.Collaborator]: {
    displayName: 'Collaborator',
    description: `Can view and collaborate, but not edit or share`,
    chooseable: true,
    order: 4,
  },
  [ObjectAccessLevels.Viewer]: {
    displayName: 'Viewer',
    description: 'Can only view',
    chooseable: true,
    order: 5,
  },
}

const UPSERT_USER_AUTHORIZATION = gql`
  mutation UpsertUserAuthorizationForObject(
    $input: UpsertUserAuthorizationForObjectInput!
  ) {
    upsertUserAuthorizationForObject(input: $input)
  }
`

const REMOVE_USER_AUTHORIZATION = gql`
  mutation RemoveUserAuthorizationForObject(
    $input: RemoveUserAuthorizationForObjectInput!
  ) {
    removeUserAuthorizationForObject(input: $input)
  }
`

const UPDATE_WORKSPACE_AUTHORIZATION = gql`
  mutation UpdateWorkspaceAuthorizationForObject(
    $input: UpdateWorkspaceAuthorizationForObjectInput!
  ) {
    updateWorkspaceAuthorizationForObject(input: $input)
  }
`

const UPDATE_PAGE_AUTHORIZATION = gql`
  mutation UpdatePageAuthorization($input: UpdatePageAccessLevelInput!) {
    updatePageAuthorization(input: $input)
  }
`

const ObjectAccessLevelSelect = ({
  currentAccessLevel,
  onChange,
  onRemove,
  disabled,
  includeRemove = false,
}: {
  currentAccessLevel: ValueOf<Omit<typeof ObjectAccessLevels, 'owner'>>
  onChange: (
    newAccessLevel: ValueOf<Omit<typeof ObjectAccessLevels, 'owner'>>
  ) => void | Promise<void>
  onRemove?: () => void | Promise<void>
  disabled?: boolean
  includeRemove?: boolean
}) => {
  return (
    <Select
      variant="outlined"
      size="small"
      value={currentAccessLevel}
      renderValue={(value) => ACCESS_LEVELS[value].displayName}
      disabled={disabled}
      onChange={(event) => {
        if (event.target.value === 'remove') {
          onRemove && onRemove()
        } else {
          onChange(
            event.target.value as ValueOf<
              Omit<typeof ObjectAccessLevels, 'owner'>
            >
          )
        }
      }}
      sx={{
        // Keep all the dropdowns equal visual width regardless
        // of their setting — 'collaborator' is the longest
        minWidth: '120px',
        '& .MuiSelect-outlined': { py: '6px' },
        '& .MuiSelect-select': { fontSize: '0.7rem' },
        color: (theme) => theme.palette.text.secondary,
      }}
    >
      {Object.keys(ACCESS_LEVELS)
        .filter((accessLevel) => ACCESS_LEVELS[accessLevel].chooseable)
        .sort((a, b) => ACCESS_LEVELS[a].order - ACCESS_LEVELS[b].order)
        .map((accessLevel) => (
          <MenuItem
            sx={{
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
            key={accessLevel}
            value={accessLevel}
          >
            <Typography
              sx={{
                fontSize: '0.8rem',
                fontWeight: 500,
              }}
            >
              {ACCESS_LEVELS[accessLevel].displayName}
            </Typography>
            <Typography
              sx={{
                fontSize: '0.7rem',
                color: (theme) => theme.palette.text.secondary,
                minWidth: '280px',
                textWrap: 'pretty',
              }}
            >
              {ACCESS_LEVELS[accessLevel].description}
            </Typography>
          </MenuItem>
        ))
        .concat(
          includeRemove
            ? [
                <Box
                  key="divider"
                  sx={{
                    my: 1,
                    borderTop: (theme) => `1px solid ${theme.palette.divider}`,
                  }}
                />,
                <MenuItem
                  sx={{
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    height: '44px',
                    mt: 1,
                    pt: 1,
                  }}
                  key="remove"
                  value="remove"
                >
                  <Typography
                    sx={{
                      fontSize: '0.8rem',
                      fontWeight: 500,
                    }}
                  >
                    Remove
                  </Typography>
                </MenuItem>,
              ]
            : []
        )}
    </Select>
  )
}

const ObjectSharingPopover = ({
  open,
  onClose,
  workspaceId,
  objectId,
  objectType,
  authorization,
  anchorElement,
  parentQuery,
  event,
  isPublic,
}: {
  open: boolean
  onClose: () => any
  workspaceId: string
  objectId: string
  objectType: NativeObjectType
  authorization: ObjectAuthorization
  anchorElement: HTMLButtonElement | null
  parentQuery: DocumentNode
  event?: CalendarEvent
  isPublic?: boolean
}) => {
  const confirm = useConfirm()
  const { currentUser } = useAuth()
  const { workspaces } = useContext(DayContext)
  const [linkCopied, setLinkCopied] = useState(false)
  const [savingAuthorizationChange, setSavingAuthorizationChange] =
    useState(false)
  const [selectedMembersToAdd, setSelectedMembersToAdd] = useState<
    WorkspaceMember[]
  >([])
  const [accessLevelForAddedMembers, setAccessLevelForAddedMembers] = useState<
    ValueOf<Omit<typeof ObjectAccessLevels, 'owner'>>
  >(ObjectAccessLevels.FullAccess)

  const [upsertUserAuthorization] =
    useMutation<UpsertUserAuthorizationForObject>(UPSERT_USER_AUTHORIZATION, {
      refetchQueries: [
        {
          query: parentQuery,
          variables: { workspaceId, id: objectId },
        },
      ],
      awaitRefetchQueries: true,
    })
  const [removeUserAuthorization] =
    useMutation<RemoveUserAuthorizationForObject>(REMOVE_USER_AUTHORIZATION, {
      refetchQueries: [
        {
          query: parentQuery,
          variables: { workspaceId, id: objectId },
        },
      ],
      awaitRefetchQueries: true,
    })
  const [updateWorkspaceAuthorization] =
    useMutation<UpdateWorkspaceAuthorizationForObject>(
      UPDATE_WORKSPACE_AUTHORIZATION,
      {
        refetchQueries: [
          {
            query: parentQuery,
            variables: { workspaceId, id: objectId },
          },
        ],
        awaitRefetchQueries: true,
      }
    )
  const [updatePageAuthorization] = useMutation<UpdatePageAuthorization>(
    UPDATE_PAGE_AUTHORIZATION,
    {
      refetchQueries: [
        {
          query: parentQuery,
          variables: { workspaceId, id: objectId },
        },
      ],
      awaitRefetchQueries: true,
    }
  )

  const workspace = (workspaces || []).find(({ id }) => id === workspaceId)

  const { userHasOwnerOrFullAccess, userHasIndirectOwnerOrFullAccess } =
    getUserAuthorizations({
      currentUser,
      authorization,
      workspace,
    })

  const usersWithOwnerOrFullAccess = authorization?.users?.filter(
    (user) =>
      user.accessLevel === ObjectAccessLevels.Owner ||
      user.accessLevel === ObjectAccessLevels.FullAccess
  )

  const onlyOneRemainingOwnerOrFullAccess =
    usersWithOwnerOrFullAccess?.length === 1

  const sharingSettingsValue = useMemo(() => {
    if (isPublic) {
      return 'PUBLIC'
    } else {
      return authorization?.workspace?.isShared ? 'WORKSPACE' : 'INVITE_ONLY'
    }
  }, [isPublic, authorization])

  const linkToObject = useMemo(() => {
    switch (objectType) {
      case NativeObjectTypes.MeetingRecording:
        return routes.workspaceMeetingRecording({
          workspaceId,
          recordingId: objectId,
        })

      case NativeObjectTypes.Page:
        return sharingSettingsValue === 'PUBLIC'
          ? routes.pagePublic({ id: objectId })
          : routes.pageDetail({ id: objectId })
    }
  }, [objectType, objectId, workspaceId, sharingSettingsValue])

  return (
    <Popover
      TransitionProps={{
        onExited: () => {
          setSelectedMembersToAdd([])
          setAccessLevelForAddedMembers(ObjectAccessLevels.FullAccess)
        },
      }}
      open={open}
      anchorEl={anchorElement}
      onClose={() => {
        onClose()
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      sx={{
        maxHeight: '500px',
      }}
    >
      <DialogContent sx={{ width: '500px', pb: 2.3 }}>
        {userHasOwnerOrFullAccess && authorization?.users && (
          <Box
            sx={{
              mb: selectedMembersToAdd.length > 0 ? 0 : 2.5,
              position: 'sticky',
              top: 0,
            }}
          >
            <WorkspaceMemberSelect
              label="Add people by name or email"
              size="small"
              disabled={savingAuthorizationChange || !userHasOwnerOrFullAccess}
              exclude={(authorization?.users || []).map(({ id }) => id)}
              value={selectedMembersToAdd}
              onSelect={(members) => {
                setSelectedMembersToAdd(members)
                if (members.length === 0) {
                  // Reset back to default when switching back to non-invite mode
                  setAccessLevelForAddedMembers(ObjectAccessLevels.FullAccess)
                }
              }}
            />
          </Box>
        )}
        {!userHasOwnerOrFullAccess && (
          <Typography
            sx={{
              pl: 0.2,
              mb: 1.8,
              fontSize: '0.7rem',
              fontWeight: 400,
              textWrap: 'pretty',
              color: (theme) => theme.palette.text.disabled,
            }}
          >
            {`You don't have permission to adjust workspace sharing sharings`}
          </Typography>
        )}
        {selectedMembersToAdd.length === 0 &&
          (authorization?.users || [])
            .slice()
            .sort((a, b) =>
              // Visually cleaner to have all the owners at the top, since
              // their acess level has a different visual treatment
              a.accessLevel === ObjectAccessLevels.Owner
                ? -1
                : b.accessLevel === ObjectAccessLevels.Owner
                  ? 1
                  : 0
            )
            .map((authorizedUser) => {
              return (
                <Box
                  key={authorizedUser.id}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    '&:last-child': { mb: 0 },
                    mb: 1.8,
                  }}
                >
                  <Box sx={{ flex: 1, mr: 2 }}>
                    <WorkspaceMemberTile
                      workspaceId={workspaceId}
                      userId={authorizedUser.id}
                    />
                  </Box>
                  <Box sx={{ ml: 'auto' }}>
                    {authorizedUser.accessLevel === ObjectAccessLevels.Owner ? (
                      <Typography
                        sx={{
                          fontSize: '0.8rem',
                          fontWeight: 400,
                          mr: 0.5,
                          color: (theme) => theme.palette.text.disabled,
                        }}
                      >
                        {ACCESS_LEVELS[authorizedUser.accessLevel].displayName}
                      </Typography>
                    ) : (
                      <ObjectAccessLevelSelect
                        currentAccessLevel={authorizedUser.accessLevel}
                        onChange={async (newAccessLevel) => {
                          setSavingAuthorizationChange(true)
                          try {
                            if (
                              authorizedUser.id === currentUser.id &&
                              authorizedUser.accessLevel ===
                                ObjectAccessLevels.FullAccess &&
                              newAccessLevel !==
                                ObjectAccessLevels.FullAccess &&
                              !userHasIndirectOwnerOrFullAccess
                            ) {
                              // If the user currently only has full access via a direct
                              // authorization, and they're trying to downgrade themselves,
                              // let's make sure they reall want to.
                              await confirm({
                                title: 'Downgrade your own access?',
                                description: `You'll lose the ability to change sharing settings.`,
                                confirmationText: 'Downgrade my access',
                                cancellationText: 'Back',
                                confirmationButtonProps: {
                                  variant: 'contained',
                                  color: 'error',
                                },
                                allowClose: false,
                                dialogProps: {
                                  maxWidth: 'sm',
                                  sx: {
                                    '& h2': {
                                      color: 'error.main',
                                      fontWeight: 600,
                                    },
                                    '& p': {
                                      color: 'text.primary',
                                      fontWeight: 400,
                                      fontSize: '0.8rem',
                                      my: 2,
                                    },
                                  },
                                },
                              })
                            }

                            await toast.promise(
                              upsertUserAuthorization({
                                variables: {
                                  input: {
                                    workspaceId,
                                    objectType,
                                    objectId,
                                    accessLevel: newAccessLevel,
                                    targetUserIds: [authorizedUser.id],
                                  },
                                },
                              }),
                              {
                                loading: 'Saving...',
                                success: 'Saved',
                                error: 'Error saving',
                              }
                            )
                          } catch (_) {
                            /* empty */
                          }
                          setSavingAuthorizationChange(false)
                        }}
                        onRemove={async () => {
                          setSavingAuthorizationChange(true)
                          try {
                            if (
                              authorizedUser.id === currentUser.id &&
                              !userHasIndirectOwnerOrFullAccess
                            ) {
                              // If the user currently only has full access via a direct
                              // authorization, and they're trying to downgrade themselves,
                              // let's make sure they reall want to.
                              await confirm({
                                title: 'Remove your own access?',
                                confirmationText: 'Remove my access',
                                cancellationText: 'Back',
                                confirmationButtonProps: {
                                  variant: 'contained',
                                  color: 'error',
                                },
                                allowClose: false,
                                dialogProps: {
                                  maxWidth: 'sm',
                                  sx: {
                                    '& h2': {
                                      color: 'error.main',
                                      fontWeight: 600,
                                    },
                                    '& p': {
                                      color: 'text.primary',
                                      fontWeight: 400,
                                      fontSize: '0.8rem',
                                      my: 2,
                                    },
                                  },
                                },
                              })
                            }

                            await toast.promise(
                              removeUserAuthorization({
                                variables: {
                                  input: {
                                    workspaceId,
                                    objectType,
                                    objectId,
                                    targetUserId: authorizedUser.id,
                                  },
                                },
                              }),
                              {
                                loading: 'Removing...',
                                success: 'Removed',
                                error: 'Error removing',
                              }
                            )
                          } catch (_) {
                            /* empty */
                          }
                          setSavingAuthorizationChange(false)
                        }}
                        disabled={
                          savingAuthorizationChange ||
                          !userHasOwnerOrFullAccess ||
                          (onlyOneRemainingOwnerOrFullAccess &&
                            authorizedUser.accessLevel ===
                              ObjectAccessLevels.FullAccess)
                        }
                        includeRemove={true}
                      />
                    )}
                  </Box>
                </Box>
              )
            })}
      </DialogContent>

      <DialogActions
        sx={{
          px: 3,
          pt: 1.8,
          pb: 0,
          mb: 2,
          width: '500px',
          borderTop: (theme) => `1px solid ${theme.palette.divider}`,
          position: 'sticky',
          bottom: 0,
          backgroundColor: (theme) => theme.palette.background.paper,
        }}
      >
        {selectedMembersToAdd.length === 0 && (
          <>
            <Select
              variant="outlined"
              size="small"
              value={sharingSettingsValue}
              disabled={savingAuthorizationChange || !userHasOwnerOrFullAccess}
              sx={{
                mr: 'auto',
                '& .MuiSelect-outlined': { py: '8px' },
                '& .MuiSelect-select': { fontSize: '0.8rem' },
              }}
              renderValue={(value) => (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    minWidth: '150px',
                  }}
                >
                  {value === 'INVITE_ONLY' && (
                    <RiLockFill
                      style={{
                        width: '16px',
                        height: '16px',
                        marginTop: '-1px',
                      }}
                    />
                  )}
                  {value === 'WORKSPACE' && (
                    <RiCommunityFill
                      style={{
                        width: '16px',
                        height: '16px',
                      }}
                    />
                  )}
                  {value === 'PUBLIC' && (
                    <IconEye
                      style={{
                        width: '16px',
                        height: '16px',
                      }}
                    />
                  )}
                  <Box
                    sx={{
                      ml: 1,
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}
                  >
                    {value === 'INVITE_ONLY' && 'Invite only'}
                    {value === 'WORKSPACE' && `Shared with ${workspace?.name}`}
                    {value === 'PUBLIC' && 'Shared publicly'}
                  </Box>
                </Box>
              )}
              onChange={async (event) => {
                if (objectType !== NativeObjectTypes.Page) {
                  const newSharingSetting = event.target.value as
                    | 'INVITE_ONLY'
                    | 'WORKSPACE'

                  setSavingAuthorizationChange(true)

                  try {
                    await toast.promise(
                      updateWorkspaceAuthorization({
                        variables: {
                          input: {
                            workspaceId,
                            objectType,
                            objectId,
                            accessLevel: newSharingSetting,
                          },
                        },
                      }),

                      {
                        loading: 'Saving...',
                        success: 'Saved',
                        error: 'Error saving',
                      }
                    )
                  } catch (_) {
                    /* empty */
                  }
                  setSavingAuthorizationChange(false)
                } else {
                  const newSharingSetting = event.target.value as
                    | 'INVITE_ONLY'
                    | 'WORKSPACE'
                    | 'PUBLIC'

                  setSavingAuthorizationChange(true)

                  try {
                    await toast.promise(
                      updatePageAuthorization({
                        variables: {
                          input: {
                            pageId: objectId,
                            workspaceId,
                            accessLevel: newSharingSetting,
                          },
                        },
                      }),

                      {
                        loading: 'Saving...',
                        success: 'Saved',
                        error: 'Error saving',
                      }
                    )
                  } catch (_) {
                    /* empty */
                  }
                  setSavingAuthorizationChange(false)
                }
              }}
            >
              {(['INVITE_ONLY', 'WORKSPACE'] as const).map((sharingSetting) => (
                <MenuItem
                  sx={{
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                  key={sharingSetting}
                  value={sharingSetting}
                >
                  {sharingSetting === 'INVITE_ONLY' ? (
                    <RiLockFill style={{ width: '18px', height: '18px' }} />
                  ) : (
                    <RiCommunityFill
                      style={{ width: '18px', height: '18px' }}
                    />
                  )}

                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      ml: 1.5,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '0.8rem',
                        fontWeight: 500,
                      }}
                    >
                      {sharingSetting === 'INVITE_ONLY'
                        ? 'Invite only'
                        : 'Workspace'}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '0.7rem',
                        color: (theme) => theme.palette.text.secondary,
                        maxWidth: '260px',
                        textWrap: 'pretty',
                      }}
                    >
                      {sharingSetting === 'INVITE_ONLY'
                        ? `Only you and people invited can access`
                        : `${workspace?.name} can access`}
                    </Typography>
                  </Box>
                </MenuItem>
              ))}
              {objectType === NativeObjectTypes.Page && (
                <MenuItem
                  sx={{
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                  key={'PUBLIC'}
                  value={'PUBLIC'}
                >
                  <IconEye style={{ width: '18px', height: '18px' }} />

                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      ml: 1.5,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '0.8rem',
                        fontWeight: 500,
                      }}
                    >
                      Public
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '0.7rem',
                        color: (theme) => theme.palette.text.secondary,
                        maxWidth: '260px',
                        textWrap: 'pretty',
                      }}
                    >
                      Anyone with the link can access
                    </Typography>
                  </Box>
                </MenuItem>
              )}
            </Select>
            <Button
              onClick={async () => {
                const url = `${process.env.HOST}${linkToObject}`

                const clipboardItemInput = new ClipboardItem({
                  'text/plain': new Blob([url], { type: 'text/plain' }),
                })

                await navigator.clipboard
                  .write([clipboardItemInput])
                  .catch((err) =>
                    logger.error('Error copying to clipboard', err)
                  )

                setLinkCopied(true)
                setTimeout(() => setLinkCopied(false), 3000)
              }}
              color="primary"
              size="small"
              startIcon={
                linkCopied ? <RiCheckFill size={16} /> : <RiLink size={16} />
              }
              sx={{
                '& .MuiButton-startIcon': {
                  marginRight: '4px',
                },
                color: (theme) => theme.palette.text.secondary,
              }}
            >
              {linkCopied ? 'Copied' : 'Copy link'}
            </Button>
          </>
        )}
        {selectedMembersToAdd.length > 0 && (
          <>
            <ObjectAccessLevelSelect
              currentAccessLevel={accessLevelForAddedMembers}
              onChange={(newAccessLevel) => {
                setAccessLevelForAddedMembers(newAccessLevel)
              }}
              disabled={savingAuthorizationChange}
            />
            <Button
              sx={{ ml: 1 }}
              size="small"
              color="primary"
              variant="contained"
              disableElevation={true}
              disabled={savingAuthorizationChange || !userHasOwnerOrFullAccess}
              onClick={async () => {
                setSavingAuthorizationChange(true)

                try {
                  await toast.promise(
                    upsertUserAuthorization({
                      variables: {
                        input: {
                          workspaceId,
                          objectType,
                          objectId,
                          accessLevel: accessLevelForAddedMembers,
                          targetUserIds: selectedMembersToAdd.map(
                            ({ id }) => id
                          ),
                        },
                      },
                    }),
                    {
                      loading: 'Adding...',
                      success: 'Added',
                      error: 'Error adding',
                    }
                  )
                } catch (_) {
                  /* empty */
                }
                setSavingAuthorizationChange(false)
                setSelectedMembersToAdd([])
                setAccessLevelForAddedMembers(ObjectAccessLevels.FullAccess)
              }}
            >
              Add
            </Button>
          </>
        )}
      </DialogActions>
      {userHasOwnerOrFullAccess &&
        objectType === NativeObjectTypes.MeetingRecording && (
          <>
            <Divider sx={{ mb: 2 }} />
            <Row sx={{ pl: 3, pr: 2, pb: 2, justifyContent: 'space-between' }}>
              <Typography
                sx={{
                  fontSize: '0.9rem',
                  fontWeight: 600,
                  color: (theme) => theme.palette.text.secondary,
                }}
              >
                External sharing
              </Typography>

              <MeetingRecordingShareAsPage
                event={event}
                meetingRecordingId={objectId}
                workspaceMode={true}
                useIcon={false}
              />
            </Row>
          </>
        )}
    </Popover>
  )
}

export default ObjectSharingPopover
