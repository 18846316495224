import type { CRMObject } from 'types/graphql'

import { NativeObjectTypes } from 'src/lib/objects'

export type EditorMode = 'create' | 'edit'

export interface BaseEditorState {
  mode: EditorMode
  objectType: string
  title: string
  workspaceId: string
}

// Opportunity state
export interface OpportunityState {
  title: string
  oppType: string
  selectedOwner: any | null
  ownerWasDismissed: boolean
  selectedPipeline: any | null
  selectedStage: any | null
  selectedOrganization: any | null
  selectedPerson: any | null
  expectedCloseDate: Date
  expectedRevenue: number | null
  hasRevenue: boolean
  domain: string | null
}

// Property Definition state
export interface PropertyDefinitionState {
  name: string
  description: string
  objectType: string
  propertyType: string
  aiManaged: boolean
  useWeb: boolean
  options: Array<{
    id: string
    name: string
    description?: string
  }>
  selectedDefinition: any | null
  newOptionLabel: string
  newOptionDefinition: string
  editingOptionKey: string | null
  editedLabel: string
  editedDefinition: string
}

// User Context state
export interface UserContextState {
  crmObject: CRMObject
}
// Combined editor state
export interface EditorState extends BaseEditorState {
  objectState: OpportunityState | PropertyDefinitionState | UserContextState
}

// Editor-specific actions
export type EditorAction =
  | { type: 'SET_MODE'; payload: EditorMode }
  | { type: 'SET_TITLE'; payload: string }
  | { type: 'SET_OBJECT_TYPE'; payload: string }
  | {
      type: 'SET_OBJECT_STATE'
      payload: Partial<OpportunityState> | Partial<PropertyDefinitionState>
    }
  | { type: 'RESET' }
  | {
      type: 'INITIALIZE'
      payload: Partial<PropertyDefinitionState> | Partial<OpportunityState>
    }

// Opportunity-specific action types
export type OpportunityAction =
  | { type: 'SET_TITLE'; payload: string }
  | { type: 'SET_TYPE'; payload: string }
  | { type: 'SET_OPP_TYPE'; payload: string }
  | { type: 'SET_OWNER'; payload: any }
  | { type: 'DISMISS_OWNER' }
  | { type: 'SET_PIPELINE'; payload: any }
  | { type: 'SET_STAGE'; payload: any }
  | { type: 'SET_ORGANIZATION'; payload: any }
  | { type: 'SET_PERSON'; payload: any }
  | { type: 'SET_EXPECTED_CLOSE_DATE'; payload: Date }
  | { type: 'SET_EXPECTED_REVENUE'; payload: number | null }
  | { type: 'SET_HAS_REVENUE'; payload: boolean }
  | { type: 'SET_DOMAIN'; payload: string | null }
  | { type: 'RESET' }
  | { type: 'INITIALIZE'; payload: Partial<OpportunityState> }

// Property definition action types
export type PropertyDefinitionAction =
  | { type: 'SET_NAME'; payload: string }
  | { type: 'SET_DESCRIPTION'; payload: string }
  | { type: 'SET_PROPERTY_TYPE'; payload: string }
  | { type: 'SET_AI_ENABLED'; payload: boolean }
  | { type: 'SET_USE_WEB'; payload: boolean }
  | {
      type: 'SET_OPTIONS'
      payload: Array<{ id: string; name: string; description?: string }>
    }
  | { type: 'ADD_OPTION'; payload: { name: string; description?: string } }
  | { type: 'REMOVE_OPTION'; payload: string }
  | {
      type: 'EDIT_OPTION'
      payload: { id: string; name: string; description?: string }
    }

// Initial states
export const initialOpportunityState: OpportunityState = {
  title: '',
  oppType: '',
  selectedOwner: null,
  ownerWasDismissed: false,
  selectedPipeline: null,
  selectedStage: null,
  selectedOrganization: null,
  selectedPerson: null,
  expectedCloseDate: new Date(),
  expectedRevenue: null,
  hasRevenue: false,
  domain: null,
}

export const initialPropertyDefinitionState: PropertyDefinitionState = {
  name: '',
  description: '',
  objectType: null,
  propertyType: null,
  aiManaged: true,
  useWeb: false,
  options: [],
  selectedDefinition: null,
  newOptionLabel: '',
  newOptionDefinition: '',
  editingOptionKey: null,
  editedLabel: '',
  editedDefinition: '',
}

export const initialEditorState: EditorState = {
  mode: 'create',
  objectType: NativeObjectTypes.Opportunity,
  title: '',
  workspaceId: '',
  objectState: initialOpportunityState,
}

export interface SidebarObjectEditorProps {
  objectType: string
  mode?: EditorMode
  initialData?: any
  workspaceId: string
  onComplete?: () => void
  onUpdate?: (any) => void
}
