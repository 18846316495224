import { useContext, useState } from 'react'

import { Alert, Box, Button } from '@mui/material'
import { RiGroupLine } from '@remixicon/react'
import toast from 'react-hot-toast'
import type { RespondToWorkspaceInvite } from 'types/graphql'
import type { Workspace } from 'types/graphql'

import { navigate, routes } from '@redwoodjs/router'
import { useMutation } from '@redwoodjs/web'

import { DayContext } from 'src/lib/dayContext'

const RESPOND_TO_INVITE = gql`
  mutation RespondToWorkspaceInvite($input: RespondToWorkspaceInviteInput!) {
    respondToWorkspaceInvite(input: $input)
  }
`

const WorkspaceInviteBanner = ({
  workspaceInvites,
}: {
  workspaceInvites: Workspace[]
}) => {
  const { refetchWorkspaces, setSelectedWorkspace } = useContext(DayContext)

  const [respondToInvite] =
    useMutation<RespondToWorkspaceInvite>(RESPOND_TO_INVITE)

  const [savingResponse, setSavingResponse] = useState(false)

  return workspaceInvites.length > 0 ? (
    <>
      {workspaceInvites.map((workspace) => (
        <Alert
          key={workspace.id}
          severity="info"
          icon={<RiGroupLine />}
          action={
            <Box>
              <Button
                variant="outlined"
                size="small"
                color="info"
                sx={{ mr: 1 }}
                disabled={savingResponse}
                onClick={async () => {
                  setSavingResponse(true)

                  try {
                    await toast.promise(
                      respondToInvite({
                        variables: {
                          input: {
                            workspaceId: workspace.id,
                            response: 'DECLINED',
                          },
                        },
                      }).then(() => refetchWorkspaces()),
                      {
                        loading: `Declining...`,
                        success: `Declined`,
                        error: `Error declining invitation`,
                      }
                    )
                  } catch (error) {}

                  setSavingResponse(false)
                }}
              >
                Decline
              </Button>
              <Button
                variant="contained"
                size="small"
                color="info"
                sx={{ mr: 1 }}
                disabled={savingResponse}
                onClick={async () => {
                  setSavingResponse(true)

                  try {
                    await toast.promise(
                      respondToInvite({
                        variables: {
                          input: {
                            workspaceId: workspace.id,
                            response: 'ACCEPTED',
                          },
                        },
                      })
                        .then(() => refetchWorkspaces())
                        .then(() => {
                          setSelectedWorkspace(workspace.id)
                          navigate(routes.today())
                        }),
                      {
                        loading: `Joining...`,
                        success: `Joined ${workspace.name}`,
                        error: `Error joining ${workspace.name}`,
                      }
                    )
                  } catch (error) {}

                  setSavingResponse(false)
                }}
              >
                Join
              </Button>
            </Box>
          }
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              mt: 0.3,
            }}
          >
            <Box
              component="span"
              sx={{ fontWeight: 600, mr: 2 }}
            >
              {workspace.name}
            </Box>
            <Box component="span">{`You're invited to join your team`}</Box>
          </Box>
        </Alert>
      ))}
    </>
  ) : null
}

export default WorkspaceInviteBanner
