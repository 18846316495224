import { useState } from 'react'

import { Box, Chip, Menu, MenuItem } from '@mui/material'
import type { Page } from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import ObjectChip from 'src/components/Chips/ObjectChip/ObjectChip'
import { ungatedForTemplates } from 'src/lib/gates'
import { NativeObjectTypes, TemplateTypes } from 'src/lib/objects'

import { GET_WORKSPACE_TEMPLATES } from '../queries'

const TemplatePicker = ({
  workspaceId,
  setTemplate,
  hiddenTemplateIds = [],
}: {
  workspaceId: string
  setTemplate: (template: Partial<Page> | null) => void
  hiddenTemplateIds?: string[]
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const { currentUser } = useAuth()

  const { data: templates } = useQuery(GET_WORKSPACE_TEMPLATES, {
    variables: { workspaceId },
    skip: !ungatedForTemplates(currentUser),
  })

  const filteredTemplates = templates?.workspaceTemplates?.filter(
    (template) => !hiddenTemplateIds.includes(template.id)
  )

  return (
    <>
      <Box sx={{ height: 'auto' }}>
        <Chip
          clickable={true}
          variant="outlined"
          size="small"
          sx={{
            border: (theme) => `1px solid ${theme.palette.divider}`,
            p: 0,
          }}
          onClick={(e) => setAnchorEl(e.currentTarget)}
          label={
            <ObjectChip
              workspaceId={workspaceId}
              crmObject={{
                objectType: NativeObjectTypes.Page,
                objectId: 'CHOOSE_TEMPLATE',
                properties: {
                  templateType: TemplateTypes.BASE,
                  label: 'Choose template',
                },
              }}
            />
          }
        />
      </Box>
      <Menu
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {filteredTemplates?.slice(0, 3)?.map((menuTemplate) => (
          <MenuItem
            onClick={() => setTemplate(menuTemplate)}
            key={menuTemplate.id}
          >
            <ObjectChip
              workspaceId={workspaceId}
              crmObject={{
                objectType: NativeObjectTypes.Page,
                objectId: menuTemplate.id,
                properties: menuTemplate,
              }}
            />
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default TemplatePicker
