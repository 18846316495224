import { logger as loggerDev } from 'src/lib/logger'
import { NativeObjectTypes, ObjectTypeMetadata } from 'src/lib/objects'

import type { SyncStateObjectType } from './ObjectsContext'

export interface ObjectSyncReducerState {
  [NativeObjectTypes.Organization]: SyncStateObjectType
}

export type ObjectSyncReducerAction = {
  type: string
  payload: any
}

const loggingEnabled = false

const logger = loggingEnabled
  ? loggerDev
  : {
      dev: () => {},
      info: () => {},
      warn: () => {},
      error: () => {},
    }

export const objectSyncReducer = (
  state: ObjectSyncReducerState,
  action: any
) => {
  logger.dev(action.type, { state, action })
  switch (action.type) {
    case 'INITIALIZE_STATE':
      return Object.fromEntries(
        Object.values(NativeObjectTypes)
          .filter((objectType) => ObjectTypeMetadata[objectType]?.syncEnabled)
          .map((objectType) => [
            objectType,
            {
              currentCount: 0,
              updatedAt: null,
              objectsNeedingSync: 0,
              syncedThrough: null,
            },
          ])
      )
    case 'UPDATE_SYNC_STATUS':
      logger.dev('newState', { ...(state || {}), ...action.payload })
      return { ...(state || {}), ...action.payload }
    case 'UPDATE_SYNC_STATUS_BY_TYPE': {
      if (!action.payload || Object.keys(action.payload).length === 0) {
        logger.warn('Empty payload in UPDATE_SYNC_STATUS_BY_TYPE', { action })
        return state
      }

      const objectType = Object.keys(action.payload)[0]

      if (!action.payload[objectType]) {
        logger.warn(
          `Missing data for objectType ${objectType} in UPDATE_SYNC_STATUS_BY_TYPE`,
          { action }
        )
        return state
      }

      const updatedState = {
        ...state,
        [objectType]: {
          ...state[objectType],
          ...action.payload[objectType],
        },
      }
      return updatedState
    }
    case 'SET_OBJECT_TYPE_SYNCED_THROUGH': {
      const objectType = action.payload.objectType
      return {
        ...state,
        [objectType]: {
          ...state[objectType],
          syncedThrough: action.payload.syncedThrough,
        },
      }
    }
    case 'RESET_STATE':
      return null // This will trigger re-initialization
  }
}
