import { useState } from 'react'

import {
  Box,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import {
  IconBrowser,
  IconChartColumn,
  IconFileImport,
  IconHandFinger,
  IconPlus,
  IconRefresh,
  IconSparkles,
  IconTestPipe,
  IconX,
} from '@tabler/icons-react'
import toast from 'react-hot-toast'

import { useMutation, useQuery } from '@redwoodjs/web'

import MetadataChip from 'src/components/Chips/MetadataChip/MetadataChip'
import ObjectChip from 'src/components/Chips/ObjectChip/ObjectChip'
import { BACKFILL_PROPERTY_DEFINITION } from 'src/components/Properties/mutations'
import { PROPERTY_DEFINITION_HISTORY } from 'src/components/Properties/queries'
import Row from 'src/components/Row/Row'
import SearchModal from 'src/components/Search/SearchModal/SearchModal'
import WorkspaceMemberChip from 'src/components/WorkspaceMemberChip/WorkspaceMemberChip'
import { dayjs } from 'src/lib/dayjs'
import { PropertySources } from 'src/lib/objects'

import SidebarButton from '../SidebarButton/SidebarButton'

const PropertyTest = ({ propertyDefinition, workspaceId }) => {
  const [testCreateOpen, setTestCreateOpen] = useState(false)
  const [testObjectIds, setTestObjectIds] = useState<string[]>([])
  const [testObjectSearchOpen, setTestObjectSearchOpen] = useState(false)

  const { data, loading, refetch } = useQuery(PROPERTY_DEFINITION_HISTORY, {
    variables: {
      workspaceId,
      propertyDefinitionId: propertyDefinition.id,
    },
    skip: !propertyDefinition.id || !workspaceId,
    pollInterval: 3000,
  })
  const [runTest] = useMutation(BACKFILL_PROPERTY_DEFINITION)

  const handleRunTest = async () => {
    toast.promise(
      runTest({
        variables: {
          workspaceId,
          objectType: propertyDefinition.objectTypeId,
          propertyDefinitionId: propertyDefinition.id,
          testObjectIds,
        },
      }),
      {
        loading: 'Running test...',
        success: () => {
          refetch()
          setTestCreateOpen(false)
          return 'Test is running'
        },
        error: 'Error running test',
      }
    )
  }

  const handleRunBackfill = async () => {
    toast.promise(
      runTest({
        variables: {
          workspaceId,
          objectType: propertyDefinition.objectTypeId,
          propertyDefinitionId: propertyDefinition.id,
        },
      }),
      {
        loading: 'Setting up backfill...',
        success: () => {
          refetch()
          return 'Backfill is running'
        },
        error: 'Failed to run backfill',
      }
    )
  }

  const updates = data?.propertyDefinitionHistory?.updates
  const backfills = data?.propertyDefinitionHistory?.backfills

  const containerSx = {
    '& .property-header': {
      justifyContent: 'space-between',
      gap: 2,
      mt: 5,
      mb: 2,
      '&.top': {
        mt: 2,
        p: 2,
        border: (theme) => `1px solid ${theme.palette.divider}`,
      },
    },
    '& .property-header-buttons': {
      gap: 1,
    },
    '& .property-test-description': {
      color: 'text.secondary',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    '& h2, h3': {
      flexShrink: 0,
      p: 0,
      m: 0,
    },
    '& .property-update-row': {
      gap: 2,
    },
    '& .property-update-object-chip': {
      flexShrink: 0,
    },
    '& .property-update-value': {
      fontWeight: 600,
      fontSize: '11px',
      letterSpacing: '-0.2px',
      maxWidth: '160px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    '& .property-update-reasoning': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      fontSize: '11px',
      maxWidth: '280px',
      flexShrink: 1,
    },

    '& .MuiTableCell-root': {
      pl: 0,
      pr: '12px',
      py: '6px',
      fontSize: '11px',
      letterSpacing: '-0.2px',
      '&:last-child': {
        pr: 0,
      },
      '&.ellipsis': {
        maxWidth: '160px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
  }

  return loading && !data ? (
    <CircularProgress size={24} />
  ) : (
    <Box sx={containerSx}>
      <Row className="property-header top">
        <Typography variant="h2">{propertyDefinition.name}</Typography>
        <Typography className="property-test-description">
          {propertyDefinition.description}
        </Typography>
      </Row>
      {testCreateOpen ? (
        <>
          <Row className="property-header">
            <Typography variant="h3">Create Test</Typography>
            <Row className="property-header-buttons">
              <SidebarButton
                label="Add to test"
                icon={<IconPlus />}
                onClick={() => {
                  setTestObjectSearchOpen(true)
                }}
              />
              {testObjectIds.length > 0 && (
                <SidebarButton
                  label="Run Test"
                  icon={<IconTestPipe />}
                  onClick={handleRunTest}
                />
              )}
            </Row>
          </Row>
          <Table>
            <TableBody>
              {testObjectIds.map((objectId) => (
                <TableRow key={objectId}>
                  <TableCell sx={{ width: '24px' }}>
                    <IconButton
                      onClick={() => {
                        setTestObjectIds((prev) =>
                          prev.filter((id) => id !== objectId)
                        )
                      }}
                    >
                      <IconX size={16} />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <ObjectChip
                      crmObject={{
                        objectId,
                        objectType: propertyDefinition.objectTypeId,
                        workspaceId,
                      }}
                      workspaceId={workspaceId}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <SearchModal
            open={testObjectSearchOpen}
            onClose={() => {
              setTestObjectSearchOpen(false)
            }}
            onSelect={(searchResult) => {
              setTestObjectIds((prev) => [...prev, searchResult.objectId])
            }}
            allowCreation={true}
            forcedObjectTypes={[propertyDefinition.objectTypeId]}
            workspaceId={workspaceId}
          />
        </>
      ) : (
        <>
          <Row className="property-header">
            <Typography variant="h3">Backfills & Tests</Typography>
            <Row className="property-header-buttons">
              <SidebarButton
                label="Run Test"
                icon={<IconTestPipe />}
                onClick={() => {
                  setTestCreateOpen(true)
                }}
              />
              <SidebarButton
                label="Run Backfill"
                icon={<IconChartColumn />}
                onClick={handleRunBackfill}
                disabled={
                  !propertyDefinition.useWeb && !propertyDefinition.aiManaged
                }
              />
            </Row>
          </Row>
          {backfills && backfills.length > 0 ? (
            <Table>
              <TableBody>
                {backfills.map((backfill) => (
                  <TableRow key={backfill.id}>
                    <TableCell>
                      <WorkspaceMemberChip
                        workspaceId={workspaceId}
                        userId={backfill.userId}
                      />
                    </TableCell>
                    <TableCell sx={{ fontWeight: 600 }}>
                      {dayjs(backfill.createdAt).fromNow()}
                    </TableCell>
                    <TableCell sx={{ width: '112px' }}>
                      <MetadataChip
                        state={{
                          label: backfill.status,
                          value: backfill.status,
                          color:
                            backfill.status === 'COMPLETED'
                              ? 'success'
                              : backfill.status === 'FAILED'
                                ? 'error'
                                : backfill.status === 'PENDING'
                                  ? 'primary'
                                  : 'default',
                        }}
                        disabled={true}
                      />
                    </TableCell>
                    <TableCell>{backfill.error}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <Typography>
              No backfills run for {propertyDefinition.name}
            </Typography>
          )}

          <Row className="property-header">
            <Typography variant="h3">Update History</Typography>
            <Row className="property-header-buttons">
              <IconButton
                onClick={() => {
                  refetch()
                }}
              >
                <IconRefresh size={16} />
              </IconButton>
            </Row>
          </Row>
          {updates && updates.length > 0 ? (
            <Table>
              <TableBody>
                {updates.map((update, index) => (
                  <Tooltip
                    title={update.reasoning?.reasoning || update.reasoning}
                    key={`update-${index}-${propertyDefinition.id}`}
                    arrow={true}
                    placement="left"
                  >
                    <TableRow className="property-update-row">
                      <TableCell sx={{ width: '80px' }}>
                        <Typography variant="caption">
                          {dayjs(update.createdAt).format('M/D/YY')}
                        </Typography>
                      </TableCell>

                      <TableCell sx={{ width: '24px' }}>
                        {update.propertySource ===
                          PropertySources.AIGenerated && (
                          <IconSparkles size={14} />
                        )}
                        {update.propertySource ===
                          PropertySources.ManualEntry && (
                          <IconHandFinger size={14} />
                        )}
                        {update.propertySource === PropertySources.Import && (
                          <IconFileImport size={14} />
                        )}
                        {update.propertySource ===
                          PropertySources.ParallelAi && (
                          <IconBrowser size={14} />
                        )}
                      </TableCell>
                      <TableCell className="property-update-object-chip">
                        <ObjectChip
                          crmObject={{
                            objectId: update.objectId,
                            objectType: propertyDefinition.objectTypeId,
                            workspaceId,
                          }}
                          workspaceId={workspaceId}
                        />
                      </TableCell>

                      <TableCell>
                        {update.option && (
                          <MetadataChip
                            state={{
                              label: update.option.name,
                              value: update.option.id,
                              color: 'secondary',
                            }}
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        <Typography className="property-update-value">
                          {update.value}
                        </Typography>
                      </TableCell>
                      <TableCell className="ellipsis">
                        <Typography className="property-update-reasoning">
                          {update.reasoning?.reasoning || update.reasoning}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </Tooltip>
                ))}
              </TableBody>
            </Table>
          ) : (
            <Typography>
              No updates found for {propertyDefinition.name}
            </Typography>
          )}
        </>
      )}
    </Box>
  )
}

export default PropertyTest
