import { extractEmailDomain } from 'src/lib/contactFormatting'
import { excludedSenderNames } from 'src/lib/excludedSenderNames'
import { NativeObjectTypes } from 'src/lib/objects'

const eventRecordingStates = {
  MEETING_IN_PROGRESS_RECORDING_SUCCESSFULLY: {
    label: 'Meeting in progress, recording successfully',
    setToRecord: true,
    overridable: false,
  },
  MEETING_IN_PROGRESS_PENDING_RECORDING: {
    label:
      'Meeting in progress, recording has not started - make sure the Day.ai Assistant (bot attendee) is allowed to enter from the waiting room, and that the host of the meeting has allowed the bot to record.',
    setToRecord: true,
    overridable: false,
  },
  MEETING_IN_PROGRESS_RECORDING_FAILED: {
    label: 'Meeting in progress, recording failed',
    setToRecord: false,
    overridable: false,
  },
  MEETING_UPCOMING_NO_LINK_FOUND: {
    label: 'Meeting upcoming - no meeting link found',
    setToRecord: false,
    overridable: false,
  },
  MEETING_UPCOMING_ALL_RECORDING_OFF: {
    label:
      'Meeting upcoming - set to not record because all recording is off per calendar rule and no overrides found',
    setToRecord: false,
    overridable: true,
  },
  MEETING_UPCOMING_NOT_RECORD_INTERNAL: {
    label:
      'Meeting upcoming - set to not record because meeting is internal, calendar rule set to not record internal',
    setToRecord: false,
    overridable: true,
  },
  MEETING_UPCOMING_NOT_RECORD_EXTERNAL: {
    label:
      'Meeting upcoming - set to not record because meeting is external, calendar rule set to not record external',
    setToRecord: false,
    overridable: true,
  },
  MEETING_UPCOMING_OVERRIDE_NOT_RECORD: {
    label:
      'Meeting upcoming - set to not record because override says to not record',
    setToRecord: false,
    overridable: false,
  },
  MEETING_UPCOMING_ALL_RECORDING_ON: {
    label:
      'Meeting upcoming - set to record because all recording is on, no overrides found',
    setToRecord: true,
    overridable: true,
  },
  MEETING_UPCOMING_OVERRIDE_TO_RECORD: {
    label: 'Meeting upcoming - set to record because override says to record',
    setToRecord: true,
    overridable: false,
  },
  MEETING_UPCOMING_CANCELLED: {
    label: 'Meeting has been cancelled',
    setToRecord: false,
    overridable: false,
  },
}

const EVENT_START_BUFFER = 5 * 1000 // 5 seconds

export const isStartingOrStarted = (event) => {
  const startTime = new Date(event.start_time).valueOf()
  const now = new Date().valueOf()
  if (startTime < now) {
    return 'STARTED' as const
  } else if (startTime < now + EVENT_START_BUFFER) {
    return 'STARTING' as const
  } else {
    return null
  }
}

export const isPastEndTime = (event) => {
  return new Date(event.end_time).valueOf() < new Date().valueOf()
}

export const getCalendarRecordingRules = ({ event, workAccounts }) => {
  const workAccount = workAccounts.find(
    (wa) => wa.email === event.calendarEmail
  )
  return workAccount?.calendarAutoRecordSettings || []
}

export const canRecordEvent = (event) => {
  return !(
    // !!isStartingOrStarted(event) ||
    (
      event.CalendarEvent[0]?.recordingSettings.canOverride === false ||
      !(event?.CalendarEvent?.length > 0) ||
      !event?.CalendarEvent[0]?.recordingSettings ||
      !event?.meetingJoinLink
    )
  )
}

export const getEventRecordingState = ({ event, calendarRules }) => {
  const allRules = {
    calendarRule: calendarRules?.mode, // possible values: "ALL"," EXTERNAL_ONLY", "INTERNAL_ONLY", "NONE"
    isMeetingLinkPresent: !!event?.meetingJoinLink, // possible value: true, false
    eventOverrideRules: event?.CalendarEvent[0]?.recordingSettings?.enabled, // possible values: true, false, undefined - undefined means there is no override set for this meeting to either record or not record
  }

  if (event.status === 'cancelled') {
    return eventRecordingStates.MEETING_UPCOMING_CANCELLED
  }

  if (!allRules.isMeetingLinkPresent) {
    return eventRecordingStates.MEETING_UPCOMING_NO_LINK_FOUND
  }

  if (allRules.calendarRule === 'NONE') {
    return eventRecordingStates.MEETING_UPCOMING_ALL_RECORDING_OFF
  }
}

export function getEventCRMObjects({
  event,
  internalDomains,
  workspaceMembers,
  peopleByEmail,
  orgsByDomain,
}) {
  if (!event) return []
  const people = (event?.attendees || [])
    .map((attendee: any) => {
      if (
        excludedSenderNames.some((excludeString) =>
          attendee?.email?.includes(excludeString)
        ) ||
        internalDomains.includes(extractEmailDomain(attendee?.email)) ||
        workspaceMembers.includes(attendee?.email)
      ) {
        return null
      }
      if (attendee?.email) {
        return peopleByEmail[attendee?.email] || { email: attendee?.email }
      }
      return null
    })
    .filter(Boolean)
  const organizations = {}
  for (const attendee of (event?.attendees || []) as any[]) {
    if (attendee?.email) {
      const domain = extractEmailDomain(attendee?.email)
      if (domain && !internalDomains.includes(domain)) {
        organizations[domain] = orgsByDomain[domain] || { domain }
      }
    }
  }
  const peopleObjects = people.map((person) => ({
    objectId: person.email,
    objectType: NativeObjectTypes.Person,
    properties: person,
  }))
  const organizationsObjects = Object.entries(organizations).map(
    ([domain, org]) => ({
      objectId: domain,
      objectType: NativeObjectTypes.Organization,
      properties: org,
    })
  )
  return [...peopleObjects, ...organizationsObjects]
}
