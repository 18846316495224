import { Box } from '@mui/material'

import ObjectTile from 'src/components/ObjectTile/ObjectTile'

const containerSx = {
  '& .event-object-tile': {
    py: 1, // Add vertical padding
  },
}

interface SidebarObjectListProps {
  objects: Array<{
    objectId: string
    objectType: string
    properties: any
  }>
  workspaceId: string
  onObjectClick: (object: any) => void
}

const SidebarObjectList = ({
  objects,
  workspaceId,
  onObjectClick,
}: SidebarObjectListProps) => {
  if (!objects?.length) return null

  return (
    <Box sx={containerSx}>
      {objects.map((object, index) => (
        <Box
          className="event-object-tile"
          key={`object-tile-${index}-${object.objectId}`}
        >
          <ObjectTile
            objectId={object.objectId}
            objectType={object.objectType}
            workspaceId={workspaceId}
            properties={object.properties}
            size={48}
            onClick={() => {
              onObjectClick(object)
            }}
          />
        </Box>
      ))}
    </Box>
  )
}

export default SidebarObjectList
