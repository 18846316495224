import { useContext } from 'react'

import type { ViewsContextValue } from './ViewsContext'
import ViewsContext from './ViewsContext'

/**
 * Custom hook to access the ViewsContext
 * @returns {ViewsContextValue} The views context value
 * @throws {Error} If used outside a ViewsProvider
 */
const useViews = (): ViewsContextValue => {
  const context = useContext(ViewsContext)

  if (!context) {
    throw new Error('useViews must be used within a ViewsProvider')
  }

  return context
}

export default useViews
