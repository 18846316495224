import type { ObjectPropertyDefinition, Workspace } from 'types/graphql'

export const canManageObjectType = (
  propertyDefinition: ObjectPropertyDefinition,
  workspace: Workspace
) => {
  if (!workspace || !Array.isArray(workspace.roles)) return false

  const allowedAccessLevelsToManage = ['fullAccess']
  const accessTypeRequired = 'OBJECT_TYPE'
  const objectType = propertyDefinition.objectTypeId
  const wildcardAccessString = '*'

  return workspace.roles.some((role) =>
    role.permissions.some(
      (permission) =>
        permission.type === accessTypeRequired &&
        allowedAccessLevelsToManage.includes(permission.permission) &&
        [objectType, wildcardAccessString].includes(permission.scope)
    )
  )
}
