import {
  IconBubbleText,
  IconBuildingSkyscraper,
  IconColumns2,
  IconFiles,
  IconPlayerPlay,
  IconSquareRoundedCheck,
  IconUser,
} from '@tabler/icons-react'
import dayjs from 'dayjs'

import {
  NativeObjectFormatters,
  extractEmailDomain,
} from 'src/lib/contactFormatting'
import { ellipsize } from 'src/lib/formatters'
import { logger } from 'src/lib/logger'
import { NativeObjectTypes } from 'src/lib/objects'
import {
  getRelationshipSummaryTypeLabel,
  getRelationshipSummaryTypeMetadataFromKey,
} from 'src/lib/relationshipSummary'
export const RELEVANT_RELATIONSHIP_TYPES = [
  'PROSPECTIVE_CUSTOMER',
  'CUSTOMER_EXISTING',
  'PROSPECTIVE_INVESTOR',
  'INVESTOR_EXISTING',
  'prospective customer',
  'current customer',
  'CURRENT_CUSTOMER',
  'prospective investor',
  'current investor',
  'investor',
  'CURRENT_INVESTOR',
]

export const OrganizationIndustryTypes = {
  PROFESSIONAL_SERVICES: { label: 'Professional Services', color: '#9D4EDD' }, // Bright purple
  TECHNOLOGY: { label: 'Technology', color: '#00B4D8' }, // Bright cyan
  GOODS: { label: 'Goods', color: '#FF6B6B' }, // Bright coral
  MANUFACTURING: { label: 'Manufacturing', color: '#FFD93D' }, // Bright yellow
  RETAIL: { label: 'Retail', color: '#4ADE80' }, // Bright green
  WHOLESALE: { label: 'Wholesale', color: '#FF49DB' }, // Bright pink
  FINANCIAL_SERVICES: { label: 'Financial Services', color: '#06D6A0' }, // Bright turquoise
  HEALTHCARE: { label: 'Healthcare', color: '#4CC9F0' }, // Bright blue
  EDUCATION: { label: 'Education', color: '#FF9F1C' }, // Bright orange
  MEDIA_ENTERTAINMENT: { label: 'Media & Entertainment', color: '#F72585' }, // Hot pink
  HOSPITALITY_TOURISM: { label: 'Hospitality & Tourism', color: '#7209B7' }, // Deep purple
  REAL_ESTATE: { label: 'Real Estate', color: '#4361EE' }, // Electric blue
  TRANSPORTATION_LOGISTICS: {
    label: 'Transportation & Logistics',
    color: '#3A86FF', // Bright royal blue
  },
  ENERGY_UTILITIES: { label: 'Energy & Utilities', color: '#38B000' }, // Bright lime
  AGRICULTURE: { label: 'Agriculture', color: '#80ED99' }, // Bright mint
}

export const actionContent = {}

export const DataNavItems = (routes) =>
  routes
    ? [
        {
          icon: <IconPlayerPlay />,
          label: 'Recordings',
          to: routes.recordings(),
        },
        {
          icon: <IconBuildingSkyscraper />,
          label: 'Organizations',
          to: routes.organizations(),
        },
        {
          icon: <IconUser />,
          label: 'People',
          to: routes.people(),
        },
        {
          icon: <IconSquareRoundedCheck />,
          label: 'Actions',
          to: routes.actions(),
        },
        {
          icon: <IconBubbleText />,
          label: 'Instructions',
          to: routes.instructions(),
        },
        {
          icon: <IconFiles />,
          label: 'Pages',
          to: routes.pages(),
        },
        {
          icon: <IconColumns2 />,
          label: 'Pipelines',
          to: routes.pipelines(),
        },
      ]
    : []

export const getOrgPeople = ({ domain, people }) => {
  return people
    .map((person) => {
      const email =
        person?.objectType === NativeObjectTypes.Contact
          ? person.objectId
          : person?.email || person?.properties?.email
      const emailDomain = extractEmailDomain(email)
      return emailDomain === domain ? person : null
    })
    .filter(Boolean)
}

export const prioritizeKeys = (keys) => {
  const uniqueKeys = new Set<string>(keys)
  if (
    uniqueKeys.has('PROSPECTIVE_CUSTOMER') &&
    uniqueKeys.has('CUSTOMER_EXISTING')
  ) {
    uniqueKeys.delete('PROSPECTIVE_CUSTOMER')
  }
  if (
    uniqueKeys.has('PROSPECTIVE_INVESTOR') &&
    uniqueKeys.has('INVESTOR_EXISTING')
  ) {
    uniqueKeys.delete('PROSPECTIVE_INVESTOR')
  }
  return Array.from(uniqueKeys)
    .filter(Boolean)
    .sort((a, b) => {
      const metadataA = getRelationshipSummaryTypeMetadataFromKey(a)
      const metadataB = getRelationshipSummaryTypeMetadataFromKey(b)
      return (metadataB.valueInSearch || 0) - (metadataA.valueInSearch || 0)
    })
}

export const getOrgLabels = ({ organization, people }) => {
  if (!organization) return { pipelineLabels: [], personLabels: [] }
  const personLabelTypes = [
    ...new Set(
      people.map((person) =>
        getRelationshipSummaryTypeLabel(
          person,
          RELEVANT_RELATIONSHIP_TYPES,
          'keys'
        )
      )
    ),
  ]

  const cleanLabels = prioritizeKeys(personLabelTypes)
  const existingOpportunities = organization.opportunities
  const labels = {
    pipelineLabels: { existingOpportunities },
    personLabels: cleanLabels.map((typeKey) =>
      getRelationshipSummaryTypeMetadataFromKey(typeKey)
    ),
  }

  return labels
}

const blocksFromTimelineItems = (timelineItems) => {
  const timelineBlocks = []
  timelineBlocks.push({
    type: 'heading',
    attrs: {
      level: 2,
    },
    content: [
      {
        type: 'text',
        text: `Meeting & Email History`,
      },
    ],
  })

  /*
  {
    "timestamp": "2024-06-11T17:46:50.996Z",
    "type": "email",
    "label": "Kevin Walsh from Melodi followed up with Dharmesh at agent.ai after previously offering to let their team try Melodi's feedback component and requested any feedback they may have.",
    "expandedText": "Kevin Walsh, co-founder of Melodi, initially responded to an email from Dharmesh at agent.ai informing him that he was approved from the agent.ai waitlist. Kevin provided information about Melodi's react SDK and feedback component, which allows customers to provide thumbs up/down feedback on AI responses that is tracked for data curation and model customization. He offered to let the agent.ai team try out the feedback component and created a Melodi account for team@agent.ai with login credentials. Kevin included links to demo videos, documentation, and the Melodi app. He mentioned that self-serve accounts were coming soon and offered to create additional accounts if needed. Kevin invited Dharmesh and the agent.ai team to provide any questions or feedback. In a follow-up email, Kevin reached out again to Dharmesh, mentioning that he wanted to try one more time and would love any feedback from the agent.ai team.",
    "id": "3fe3856a-f483-46ac-864f-52462fabcac1"
  },
  {
      "timestamp": "2024-04-12T17:00:03.558Z",
      "label": "AI Product Development and Implementation Challenges at HubSpot",
      "expandedText": "Kevin Walsh and Sarah Remsen introduced their start-up and their solution, Melody, to David Groechel, who is working on AI projects at HubSpot. David shared the challenges he's facing with fine-tuning and annotation, and the development of an AI tool for marketing. The team is also facing challenges in evaluating their models and dealing with language barriers. They plan to address these issues in the second half of the year.",
      "type": "meetingRecording",
      "id": "404c30da-ef82-47ec-843f-1c3c6e6d3ef0"
  }
  */

  for (const item of timelineItems
    .filter((item) => !!item?.label && item.label.length > 0 && !!item.id)
    .sort(
      (a, b) =>
        new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
    )) {
    const newBlock = []
    newBlock.push({
      type: 'heading',
      attrs: {
        level: 3,
      },
      content: [{ type: 'text', text: item.title }],
    })
    if (item.type === 'meetingRecording' && item?.label) {
      timelineBlocks.push({
        type: 'paragraph',
        content: [
          {
            type: 'text',
            text: `🗓️ Meeting on ${dayjs(item.timestamp).format(
              'MM/DD/YYYY'
            )} (recorded)`,
            marks: [
              {
                type: 'bold',
              },
            ],
          },
        ],
      })

      timelineBlocks.push({
        type: 'meetingrecording',
        attrs: {
          id: item.id,
        },
      })
    } else if (item.type === 'email') {
      timelineBlocks.push({
        type: 'paragraph',
        content: [
          {
            type: 'text',
            text: `📩 Email thread on ${dayjs(item.timestamp).format(
              'MM/DD/YYYY'
            )}`,
            marks: [
              {
                type: 'bold',
              },
            ],
          },
        ],
      })
      timelineBlocks.push({
        type: 'paragraph',
        content: [
          {
            type: 'text',
            text: `${item.expandedText}`,
          },
        ],
      })
    }
  }
  return timelineBlocks
}

const blocksFromPeople = (orgPeople) => {
  const blocks = []
  blocks.push({
    type: 'heading',
    attrs: {
      level: 2,
    },
    content: [
      {
        type: 'text',
        text: `People`,
      },
    ],
  })
  blocks.push({
    type: 'day-ai-table-text',
    content: [
      {
        type: 'tableRow',
        content: [
          {
            type: 'tableHeader',
            attrs: { colspan: 1, rowspan: 1, colwidth: null },
            content: [
              {
                type: 'paragraph',
                content: [{ type: 'text', text: 'Person' }],
              },
            ],
          },
          {
            type: 'tableHeader',
            attrs: { colspan: 1, rowspan: 1, colwidth: null },
            content: [
              {
                type: 'paragraph',
                content: [{ type: 'text', text: 'Details' }],
              },
            ],
          },
        ],
      },
      ...orgPeople.map((person) => ({
        type: 'tableRow',
        content: [
          {
            type: 'tableCell',
            attrs: { colspan: 1, rowspan: 1, colwidth: null },
            content: [
              {
                type: 'paragraph',
                content: [
                  {
                    type: 'objectChip',
                    attrs: {
                      objectId: person.objectId,
                      objectType: NativeObjectTypes.Contact,
                      displayName:
                        NativeObjectFormatters[NativeObjectTypes.Contact].label(
                          person
                        ) || '',
                      photoUrl:
                        NativeObjectFormatters[
                          NativeObjectTypes.Contact
                        ].photoUrl(person) || '',
                    },
                  },
                ],
              },
            ],
          },
          {
            type: 'tableCell',
            attrs: { colspan: 1, rowspan: 1, colwidth: null },
            content: [
              {
                type: 'paragraph',
                content: [
                  {
                    type: 'text',
                    text:
                      NativeObjectFormatters[
                        NativeObjectTypes.Contact
                      ].description(person) || '(no information available)',
                  },
                ],
              },
            ],
          },
        ],
      })),
    ],
  })
  return blocks
}

const blocksFromQuotes = (quotes, orgPeople) => {
  const header = {
    type: 'heading',
    attrs: {
      level: 2,
    },
    content: [
      {
        type: 'text',
        text: 'Quotes',
      },
    ],
  }

  const table = {
    type: 'day-ai-table-text',
    attrs: {
      columnWidths: [200, null],
    },
    content: quotes.map((quote) => ({
      type: 'tableRow',
      content: [
        {
          type: 'tableCell',
          attrs: { colspan: 1, rowspan: 1, colwidth: null },
          content: [
            {
              type: 'paragraph',
              content: [
                {
                  type: 'objectChip',
                  attrs: {
                    objectId: quote.email,
                    objectType: NativeObjectTypes.Contact,
                    displayName:
                      NativeObjectFormatters[NativeObjectTypes.Contact].label(
                        orgPeople.find((person) => person.email === quote.email)
                      ) || quote.email,
                    photoUrl:
                      NativeObjectFormatters[
                        NativeObjectTypes.Contact
                      ].photoUrl(
                        orgPeople.find((person) => person.email === quote.email)
                      ) || `${process.env.HOST}/people/profile_blank.png`,
                  },
                },
              ],
            },
          ],
        },
        {
          type: 'tableCell',
          attrs: { colspan: 1, rowspan: 1, colwidth: null },
          content: [
            {
              type: 'paragraph',
              content: [
                {
                  type: 'text',
                  text: quote.text,
                },
              ],
            },
          ],
        },
      ],
    })),
  }

  return [header, table]
}

const blocksFromActions = (actions, orgPeople) => {
  const header = {
    type: 'heading',
    attrs: {
      level: 2,
    },
    content: [
      {
        type: 'text',
        text: `Next Steps`,
      },
    ],
  }
  const newBlockBulletList = {
    type: 'taskList',
    content: actions.map((action) => ({
      type: 'taskItem',
      content: [
        {
          type: 'paragraph',
          content: [
            {
              type: 'objectChip',
              attrs: {
                objectId: action.person,
                objectType: NativeObjectTypes.Contact,
                displayName:
                  NativeObjectFormatters[NativeObjectTypes.Contact].label(
                    orgPeople.find((person) => person.email === action.person)
                  ) || action.person,
                photoUrl:
                  NativeObjectFormatters[NativeObjectTypes.Contact].photoUrl(
                    orgPeople.find((person) => person.email === action.person)
                  ) || `${process.env.HOST}/people/profile_blank.png`,
              },
            },
            {
              type: 'text',
              text: ` - ${action.description}`,
            },
          ],
        },
      ],
    })),
  }
  return [header, newBlockBulletList]
}

const blocksFromChallenges = (challenges) => {
  const header = {
    type: 'heading',
    attrs: {
      level: 2,
    },
    content: [
      {
        type: 'text',
        text: `Challenges and Solutions`,
      },
    ],
  }

  const table = {
    type: 'day-ai-table-text',
    content: [
      {
        type: 'tableRow',
        content: [
          {
            type: 'tableHeader',
            attrs: { colspan: 1, rowspan: 1, colwidth: null },
            content: [
              {
                type: 'paragraph',
                content: [{ type: 'text', text: 'Challenge' }],
              },
            ],
          },
          {
            type: 'tableHeader',
            attrs: { colspan: 1, rowspan: 1, colwidth: null },
            content: [
              {
                type: 'paragraph',
                content: [{ type: 'text', text: 'Solution' }],
              },
            ],
          },
        ],
      },
      ...challenges.map((challenge) => ({
        type: 'tableRow',
        content: [
          {
            type: 'tableCell',
            attrs: { colspan: 1, rowspan: 1, colwidth: null },
            content: [
              {
                type: 'paragraph',
                content: [{ type: 'text', text: challenge.challenge }],
              },
            ],
          },
          {
            type: 'tableCell',
            attrs: { colspan: 1, rowspan: 1, colwidth: null },
            content: [
              {
                type: 'paragraph',
                content: [{ type: 'text', text: challenge.solution }],
              },
            ],
          },
        ],
      })),
    ],
  }

  return [header, table]
}

const blocksFromSummaryItems = (summaryItems) => {
  const summaryBlocks = [
    {
      type: 'heading',
      attrs: {
        level: 2,
      },
      content: [
        {
          type: 'text',
          text: `Current Status`,
        },
      ],
    },
  ]
  const bulletList = {
    type: 'bulletList',
    content: summaryItems.map((item) => ({
      type: 'listItem',
      content: [
        {
          type: 'paragraph',
          content: [{ type: 'text', text: item }],
        },
      ],
    })),
  }
  summaryBlocks.push(bulletList)

  return summaryBlocks
}

export const buildTimelineItems = (history) => {
  logger.dev({ history })
  return history
    ? [
        ...(history?.domainGmailThreadSummaries?.map((emailSummary) => {
          const people = emailSummary?.thread?.messageRecipients
            ?.map((recipient) => recipient.address)
            .filter(Boolean)
          return {
            timestamp:
              emailSummary?.latestMessage?.internalDate ||
              emailSummary?.updatedAt,
            type: 'email',
            label: emailSummary?.summary?.oneSentenceSummary,
            expandedText: emailSummary?.summary?.summary,
            title: emailSummary?.latestMessage?.encryptedSubject,
            people,
            actor: emailSummary?.thread?.messageRecipients.find(
              (person) => person.type === 'FROM'
            )?.address,
            id: emailSummary?.threadId,
          }
        }) || []),
        ...(history?.meetingRecordings
          .filter((recording) => recording?.summary?.json?.Summary_Short)
          ?.map((recording) => {
            logger.dev({ recording })
            const people = recording?.participants
              ?.map((participant) => participant.email)
              .filter(Boolean)
            return {
              timestamp: recording?.storedAt,
              label: ellipsize(recording?.summary?.json?.Summary_Short, 200),
              expandedText: recording?.summary?.json?.Summary_Short,
              type: 'meetingRecording',
              id: recording?.id,
              title: recording?.summary?.json?.Suggested_Title,
              people,
            }
          }) || []),
        ...(history?.notes?.map((note) => {
          return {
            timestamp: note?.createdAt,
            label: ellipsize(note?.content, 200),
            expandedText: note?.content?.length > 200 ? note?.content : null,
            title: `Note added by ${note?.person?.firstName} ${note?.person?.lastName}`,
            type: 'note',
            id: note?.id,
            people: null,
            actor: note?.person?.email,
          }
        }) || []),
        ...(history?.events?.map((event) => {
          logger.dev({ event })
          const people = event?.attendees?.map((attendee) => attendee.email)
          return {
            timestamp: event?.start_time,
            label: `Meeting on ${dayjs(event?.start_time).format(
              'MM/DD/YYYY'
            )} at ${dayjs(event?.start_time).format('h:mm A')}`,
            expandedText: 'This meeting was not recorded by Day.ai',
            type: 'event',
            id: event?.id,
            title: event?.title,
            people,
          }
        }) || []),
      ].sort(
        (a, b) =>
          new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
      )
    : []
}

export const buildOrgPageBlocks = ({
  orgPeople = [],
  org,
  timelineItems = [],
  summaryItems = [],
  challenges = [],
  actions = [],
  quotes = [],
  prompt = '',
  order = [
    'about',
    'nextSteps',
    'summary',
    'challenges',
    'people',
    'quotes',
    'timeline',
  ],
}) => {
  logger.dev({
    prompt,
    order,
  })

  const blocks: any[] = []

  for (const section of order) {
    if (section === 'prompt' && prompt != null) {
      if (typeof prompt === 'string' && prompt?.length > 0) {
        blocks.push({
          type: 'paragraph',
          content: [{ type: 'text', text: prompt }],
        })
      } else if (typeof prompt === 'object' && prompt?.length > 0) {
        const promptItems: any[] = prompt
        blocks.push(...promptItems)
      }
    }
    if (section === 'about') {
      blocks.push({
        type: 'organization',
        attrs: {
          domain: org.domain,
        },
      })
    }
    if (section === 'nextSteps' && actions?.length > 0) {
      const actionsBlock = blocksFromActions(actions, orgPeople)
      blocks.push(...actionsBlock)
    }
    if (section === 'summary' && summaryItems?.length > 0) {
      const summaryBlocks = blocksFromSummaryItems(summaryItems)
      blocks.push(...summaryBlocks)
    }
    if (section === 'challenges' && challenges?.length > 0) {
      const challengeBlocks = blocksFromChallenges(challenges)
      blocks.push(...challengeBlocks)
    }
    if (section === 'people' && orgPeople?.length > 0) {
      const peopleBlock = blocksFromPeople(orgPeople)
      blocks.push(...peopleBlock)
    }
    if (section === 'quotes' && quotes?.length > 0) {
      const quotesBlock = blocksFromQuotes(quotes, orgPeople)
      blocks.push(...quotesBlock)
    }
    if (section === 'timeline' && timelineItems?.length > 0) {
      const timelineBlocks = blocksFromTimelineItems(timelineItems)
      blocks.push(...timelineBlocks)
    }
    if (section === 'spacer') {
      const spacerBlocks = [
        {
          type: 'paragraph',
        },
        {
          type: 'horizontalRule',
        },
        {
          type: 'paragraph',
        },
      ]
      blocks.push(...spacerBlocks)
    }
  }

  return blocks
}

export const getNextStepMetadata = (organization) => {
  const stage =
    organization?.overview?.['status/recommendedStage']?.stage || 'AWARENESS'
  const nextStep = actionContent?.[stage]?.templates?.[0]
  return {
    ...nextStep,
    stage,
    pipelineType:
      organization?.overview?.['status/recommendedStage']?.pipelineType,
  }
}

export const orgBlockMetadata = {
  prompt: {
    label: 'Coaching & Suggestions',
  },
  about: {
    label: 'About',
  },
  nextSteps: {
    label: 'Next Steps',
  },
  summary: {
    label: 'Current Status',
  },
  challenges: {
    label: 'Challenges & Solutions',
  },
  people: {
    label: 'People',
  },
  quotes: {
    label: 'Quotes',
  },
  timeline: {
    label: 'Timeline',
  },
  spacer: {
    label: null,
  },
}
