import { useMemo } from 'react'

import type { Pipeline } from 'types/graphql'

export const oppsById = (pipelines: Pipeline[]) => {
  const oppsMap = {}
  for (const pipeline of pipelines) {
    for (const stage of pipeline.stages) {
      for (const opportunity of stage.opportunities) {
        oppsMap[opportunity.id] = opportunity
      }
    }
  }
  return oppsMap
}

export const useOppsById = (pipelines: Pipeline[]) => {
  return useMemo(() => oppsById(pipelines), [pipelines])
}
