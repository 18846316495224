import { gql } from '@apollo/client'

export const ACTION_FIELDS_FRAGMENT = gql`
  fragment ActionFields on Action {
    id
    workspaceId
    title
    reasoning
    description
    assignedAt
    people
    organizations
    type
    pinnedFor
    draft {
      title
      body
      prompts {
        shortPrompt
        channelType
      }
    }
    status {
      id
      label
      updatedAt
    }
    source {
      id
      type
      label
      info {
        id
        runId
        foundSource
        gmail
        meetingRecording
        slack
        reasoning
      }
    }
    channel {
      type
      id
      label
      accountId
    }
    priority
    redundantTo
    opportunityIds
    updatedAt
    createdAt
    owner {
      id
      email
    }
    timeframe {
      dueDate
      reminderDate
      updatedAt
    }
  }
`
