export const actionChipStyle = {
  px: '8px',
  height: '24px',
  fontSize: '0.7rem',
  fontWeight: 500,
  backgroundColor: (theme) => theme.palette.background.default,
  letterSpacing: '-0.6px',
  borderRadius: '3px',
}

export const sidebarBodyTextStyle = {
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '140%',
  letterSpacing: '-0.22px',
  color: (theme) => theme.palette.text.secondary,
}

export const sidebarHeadingTextStyle = {
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '130%',
  letterSpacing: '-0.7px',
  color: (theme) => theme.palette.text.primary,
}

export const socialIconStyle = {
  px: '0px',
  width: '20px',
  height: '20px',
  '& .MuiChip-label': {
    width: '0px',
    minWidth: '0px',
    m: 0,
    p: 0,
    pr: '10px',
  },
  '& .MuiChip-icon': {
    color: 'white',
  },
  '&:hover': {
    background: (theme) => theme.palette.success.main,
  },
}

export const actionButtonStyle = {
  borderRadius: '3px',
  px: '6px',
  height: '24px',
  background: (theme) => theme.palette.background.default,
  border: '0.5px solid rgba(53, 64, 82, 0.5) !important',
  fontSize: '12px',
  fontWeight: 500,
  '&:hover': {
    background: (theme) => theme.palette.action.selected,
    border: '0.5px solid rgba(53, 64, 82, 0.5) !important',
  },
  letterSpacing: '-0.6px',
  flexShrink: 0,
  boxShadow: (theme) => theme.shadows[1],
  '&.disable-elevation': {
    boxShadow: 'none',
  },
}
