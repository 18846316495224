import { useContext, useMemo, useState, useRef } from 'react'

import {
  Box,
  Chip,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { IconCopy, IconPlus, IconX } from '@tabler/icons-react'
import type { Dayjs } from 'dayjs'
import { toast } from 'react-hot-toast'
import type { Action, Page } from 'types/graphql'

import { routes } from '@redwoodjs/router'
import { useMutation } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import { actionQueries } from 'src/lib/actions'
import { DayContext } from 'src/lib/dayContext'
import { dayjs } from 'src/lib/dayjs'
import { isInternalUser, ungatedForSlackNotifications } from 'src/lib/gates'
import {
  buildUserContextReferenceKey,
  NativeObjectTypes,
} from 'src/lib/objects'
import { useOppsById } from 'src/lib/opportunities'

import ActionSourceChip from '../Actions/ActionSourceChip/ActionSourceChip'
import ActionSourceInfo from '../Actions/ActionSourceInfo/ActionSourceInfo'
import ActionStatusChip from '../Actions/ActionStatusChip/ActionStatusChip'
import ActionTimeframeChip from '../Actions/ActionTimeframeChip/ActionTimeframeChip'
import ObjectChipRow from '../Chips/ObjectChipRow/ObjectChipRow'
import Row from '../Row/Row'
import SidebarButton from '../Sidebar/SidebarButton/SidebarButton'
import SidebarObjectList from '../Sidebar/SidebarObjectList/SidebarObjectList'

import InboxActionContext from './InboxActionContext'

interface ActionPerson {
  email: string
  [key: string]: unknown
}

interface ActionOrganization {
  domain: string
  [key: string]: unknown
}

const isActionPerson = (json: unknown): json is ActionPerson => {
  return (
    typeof json === 'object' &&
    json !== null &&
    'email' in json &&
    typeof (json as ActionPerson).email === 'string'
  )
}

const isActionOrganization = (json: unknown): json is ActionOrganization => {
  return (
    typeof json === 'object' &&
    json !== null &&
    'domain' in json &&
    typeof (json as ActionOrganization).domain === 'string'
  )
}

const SET_TIMEFRAME_END = gql`
  mutation SetTimeframeEnd(
    $id: String!
    $workspaceId: String!
    $timeframeEnd: DateTime!
  ) {
    setTimeframeEnd(
      id: $id
      workspaceId: $workspaceId
      timeframeEnd: $timeframeEnd
    )
  }
`

const defaultPages = []

const InboxSidebar = ({
  selectedAction,
  pages = defaultPages,
  width,
  grid = false,
  addContext,
  refetch,
}: {
  selectedAction: Action
  pages?: Page[]
  width: number
  grid: boolean
  addContext: (input: any) => Promise<void>
  refetch: () => void
}) => {
  const { currentUser } = useAuth()
  const { pipelines, selectedWorkspace, setSidebarObject } =
    useContext(DayContext)
  const oppsById = useOppsById(pipelines)
  const [showNewContext, setShowNewContext] = useState(false)
  const [newContext, setNewContext] = useState('')
  const contextUpdatedAt = useRef(0)

  const [timeframeEndMutation] = useMutation(SET_TIMEFRAME_END, {
    refetchQueries: actionQueries,
  })

  const handleAddContext = async () => {
    const referencedObjectIds = []
    for (const personJson of (selectedAction?.people ?? []).filter(Boolean)) {
      if (isActionPerson(personJson)) {
        referencedObjectIds.push(
          buildUserContextReferenceKey(
            NativeObjectTypes.Person,
            personJson.email
          )
        )
      }
    }
    for (const orgJson of (selectedAction?.organizations ?? []).filter(
      Boolean
    )) {
      if (isActionOrganization(orgJson)) {
        referencedObjectIds.push(
          buildUserContextReferenceKey(
            NativeObjectTypes.Organization,
            orgJson.domain
          )
        )
      }
    }

    const input = {
      parentReferenceKey: buildUserContextReferenceKey(
        NativeObjectTypes.Action,
        selectedAction.id
      ),
      plainTextValue: newContext,
      referencedObjectIds,
      workspaceId: selectedAction.workspaceId,
    }

    await addContext({ input })
    setNewContext('')
    contextUpdatedAt.current += 1
    refetch()
  }

  const containerStyle = useMemo(
    () => ({
      width: `${width}px`,
      borderLeft: (theme) =>
        grid ? 'none' : `1px solid ${theme.palette.divider}`,
      flexDirection: grid ? 'row' : 'column',
      alignItems: 'flex-start',
      height: '100%',
      maxHeight: grid ? null : '100%',
      gap: grid ? '12px' : 0,
      overflowY: grid ? 'hidden' : 'auto',
      '&::-webkit-scrollbar': {
        width: '0px',
      },
      p: 3,
      '& h3': {
        mb: 1,
      },
      '& .action-sidebar-item-container': {
        mb: 4,
        '& p': {
          fontSize: '12px',
          lineHeight: '130%',
          letterSpacing: '-0.3px',
          opacity: 0.8,
        },
      },
      '& .action-sidebar-item-grid-container': {
        width: grid ? '50%' : '100%',
      },
      '& .action-sidebar-item-row': {
        mb: 3,
        justifyContent: 'space-between',
        '&.with-content': {
          mb: 1,
        },
        '& h3': {
          width: '128px',
          mb: 0,
          mr: 3,
        },
      },
    }),
    [width, grid]
  )

  const handleSetTimeframeEnd = (dueDate: Dayjs) => {
    timeframeEndMutation({
      variables: {
        id: selectedAction.id,
        workspaceId: selectedAction.workspaceId,
        timeframeEnd: dueDate.toISOString(),
      },
    })
  }

  return (
    selectedAction && (
      <Row sx={containerStyle}>
        <Box className="action-sidebar-item-grid-container">
          {ungatedForSlackNotifications(currentUser) && (
            <Row className="action-sidebar-item-row">
              <Typography variant="h3">Reminder</Typography>
              <ActionTimeframeChip
                action={selectedAction}
                handleSetDate={handleSetTimeframeEnd}
              />
            </Row>
          )}
          <Row className="action-sidebar-item-row">
            <Typography variant="h3">Status</Typography>
            <ActionStatusChip status={selectedAction.status} />
          </Row>
          <Row className="action-sidebar-item-row">
            <Typography variant="h3">Source</Typography>
            <ActionSourceChip
              action={selectedAction}
              oppsById={oppsById}
            />
          </Row>
          {selectedAction.createdAt && (
            <Row className="action-sidebar-item-row">
              <Typography variant="h3">Created</Typography>
              <Row sx={{ width: '100%' }}>
                <Typography
                  sx={{ fontSize: '11px', letterSpacing: '-0.3px', ml: '2px' }}
                >
                  {dayjs(selectedAction.createdAt).fromNow()}
                </Typography>
              </Row>
            </Row>
          )}
          <Row className="action-sidebar-item-row">
            <Typography variant="h3">Link</Typography>

            {isInternalUser(currentUser) ? (
              <Chip
                icon={<IconCopy size={14} />}
                label="View in Admin"
                size="small"
                variant="outlined"
                sx={{
                  width: '100%',
                  border: 'none',
                  justifyContent: 'flex-start',
                  px: '0px',
                  '& .MuiChip-icon': {
                    mr: '0px',
                    ml: 0,
                  },
                }}
                onClick={() => {
                  const url = routes.adminAction({
                    actionId: selectedAction.id,
                    workspaceId: selectedAction.workspaceId,
                  })
                  window.open(url, '_blank')
                }}
              />
            ) : (
              <Chip
                icon={<IconCopy size={14} />}
                label="Copy link"
                size="small"
                variant="outlined"
                sx={{
                  width: '100%',
                  border: 'none',
                  justifyContent: 'flex-start',
                  px: '0px',
                  '& .MuiChip-icon': {
                    mr: '0px',
                    ml: 0,
                  },
                }}
                onClick={() => {
                  const url = routes.actions({
                    id: selectedAction.id,
                    workspaceId: selectedAction.workspaceId,
                  })
                  navigator.clipboard
                    .writeText(`${process.env.HOST}${url}`)
                    .then(() => {
                      toast.success('Link copied to clipboard')
                    })
                    .catch(() => {
                      toast.error('Failed to copy link')
                    })
                }}
              />
            )}
          </Row>

          {(selectedAction.opportunityIds?.length ?? 0) > 0 && (
            <>
              <Typography variant="h3">Opportunities</Typography>
              <Box className="action-sidebar-item-container">
                <ObjectChipRow
                  objects={selectedAction.opportunityIds?.map(
                    (opportunityId) => ({
                      objectType: NativeObjectTypes.Opportunity,
                      objectId: opportunityId,
                    })
                  )}
                  workspaceId={selectedAction.workspaceId}
                  showSidebar={true}
                />
              </Box>
            </>
          )}

          <Row className="action-sidebar-item-row with-content">
            <Typography variant="h3">Context</Typography>
            {!showNewContext ? (
              <Tooltip title="Add context">
                <IconButton onClick={() => setShowNewContext(true)}>
                  <IconPlus size={16} />
                </IconButton>
              </Tooltip>
            ) : (
              <IconButton onClick={() => setShowNewContext(false)}>
                <IconX size={16} />
              </IconButton>
            )}
          </Row>
          <Box className="action-sidebar-item-container">
            {showNewContext && (
              <>
                <TextField
                  value={newContext}
                  onChange={(e) => setNewContext(e.target.value)}
                  placeholder="Add context"
                  multiline={true}
                  rows={4}
                  fullWidth={true}
                  variant="outlined"
                  sx={{
                    backgroundColor: (theme) => theme.palette.background.paper,
                  }}
                />
                <Row
                  sx={{ justifyContent: 'flex-end', mt: '-28px', mr: '4px' }}
                >
                  <SidebarButton
                    onClick={handleAddContext}
                    icon={<IconPlus />}
                    label="Add context"
                  />
                </Row>
              </>
            )}
            <InboxActionContext
              key={`${selectedAction.id}-${contextUpdatedAt.current}`}
              action={selectedAction}
            />
          </Box>
        </Box>
        <Box className="action-sidebar-item-grid-container">
          {selectedAction.description && (
            <>
              <Row className="action-sidebar-item-row with-content">
                <Typography variant="h3">Description</Typography>
              </Row>

              <Box className="action-sidebar-item-container">
                <Typography>{selectedAction.description}</Typography>
              </Box>
            </>
          )}
          {pages.length > 0 && (
            <>
              <Row className="action-sidebar-item-row with-content">
                <Typography variant="h3">Drafts</Typography>
              </Row>

              <Box className="action-sidebar-item-container">
                <SidebarObjectList
                  objects={
                    pages.filter(Boolean).map((page) => ({
                      objectType: NativeObjectTypes.Page,
                      objectId: page.id,
                      workspaceId: selectedWorkspace,
                      properties: page,
                    })) || []
                  }
                  workspaceId={selectedWorkspace}
                  onObjectClick={setSidebarObject}
                />
              </Box>
            </>
          )}
          <Box className="action-sidebar-item-container">
            <ActionSourceInfo
              action={selectedAction}
              showReasoning={false}
            />
          </Box>
        </Box>
      </Row>
    )
  )
}

export default InboxSidebar
