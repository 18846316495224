import { useCallback, useContext, useMemo, useState } from 'react'

import { gql, useQuery, useMutation } from '@apollo/client'
import { Box, Typography, TextField } from '@mui/material'
import {
  IconCalendarEvent,
  IconFile,
  IconRefresh,
  IconSparkles,
  IconVideo,
} from '@tabler/icons-react'
import toast from 'react-hot-toast'
import type { CRMObject, GoogleEvent } from 'types/graphql'

import MetadataChip from 'src/components/Chips/MetadataChip/MetadataChip'
import ObjectChipRow from 'src/components/Chips/ObjectChipRow/ObjectChipRow'
import { CREATE_PAGE } from 'src/components/Pages/mutations'
import Row from 'src/components/Row/Row'
import {
  getEventCRMObjects,
  isPastEndTime,
} from 'src/components/Today/eventRecordingStates'
import TodayMeetingRecordingAction from 'src/components/Today/TodayMeetingRecordingAction/TodayMeetingRecordingAction'
import { formatMeetingJoinLink } from 'src/lib/calendarEventFormatting'
import { DayContext } from 'src/lib/dayContext'
import { dayjs } from 'src/lib/dayjs'
import { logger } from 'src/lib/logger'
import { NativeObjectTypes } from 'src/lib/objects'

import SidebarButton from '../SidebarButton/SidebarButton'
import SidebarLoader from '../SidebarLoader/SidebarLoader'
import SidebarObjectList from '../SidebarObjectList/SidebarObjectList'

const GET_PAGES_FOR_EVENT = gql`
  query GetPagesForEvent(
    $workspaceId: String!
    $objectType: String!
    $objectId: String!
  ) {
    pagesForParentObject(
      workspaceId: $workspaceId
      objectType: $objectType
      objectId: $objectId
    ) {
      id
      title
      objectId: id
      objectType: __typename
      properties: contentJson
    }
  }
`

const GET_GOOGLE_EVENT_SIDEBAR = gql`
  query GetGoogleEventSidebar($id: String!) {
    googleEvent(id: $id) {
      id
      googleId
      title
      start_time
      calendarEmail
      end_time
      description
      calendarEmail
      attendees
      conferenceData
      organizer
      summary
      recurringEventId
      meetingJoinLink
      CalendarEvent {
        id
        recordingSettings {
          enabled
          canOverride
          reason
          immediateRecordingStatus
        }
        workAccountId
        WorkAccount {
          email
          workspaces {
            id
            name
            domains {
              domain
            }
            members {
              id
              email
              isDefaultOwner
              status
              roleId
            }
          }
        }
        meetingRecordings {
          id
          statusHistory {
            status
            createdAt
            reason
            message
            level
          }
          summary {
            status
            output
          }
        }
        followUpEmail {
          threadId
        }
      }
    }
  }
`

const getMeetingPrepPrompt = ({
  event,
  meetingGoal,
  userCoreContact,
}: {
  event: GoogleEvent
  meetingGoal: string
  userCoreContact: any
}) => {
  return `${userCoreContact.firstName} ${userCoreContact.lastName} (${userCoreContact.email}) has a meeting "${event.title}" on ${dayjs(event.start_time).format('MMM D, YYYY h:mm A')}. Use the context provided to help prepare them to have a successful meeting, using the relevant information and arranging it so they know who is who in the meeting, what the background/history is (if any), and what questions they might ask to move the conversation forward.\n\n Most importantly,${userCoreContact.firstName} stated their goal for the meeting is: "${meetingGoal}". Keep your response focused on this goal and use to context principally for this purpose.`
}

const SidebarLayoutEvent = ({
  crmObject,
  workspaceId,
}: {
  crmObject: Partial<CRMObject>
  workspaceId: string
}) => {
  const {
    workspaces,
    orgsByDomain,
    peopleByEmail,
    internalDomains,
    setSidebarObject,
  } = useContext(DayContext)

  const { userCoreContact } = useContext(DayContext)

  const [meetingGoal, setMeetingGoal] = useState('')
  const [pageCreationUnderway, setPageCreationUnderway] = useState(false)

  const { data: googleEventData } = useQuery(GET_GOOGLE_EVENT_SIDEBAR, {
    variables: {
      id: crmObject.objectId,
    },
    skip:
      !crmObject?.objectId ||
      crmObject?.objectType !== NativeObjectTypes.Event ||
      !crmObject?.properties,
    pollInterval: 5000,
  })

  const event = useMemo(
    () => (crmObject.properties as GoogleEvent) || googleEventData?.googleEvent,
    [crmObject, googleEventData]
  )

  const { data: pagesData, loading: pagesLoading } = useQuery(
    GET_PAGES_FOR_EVENT,
    {
      variables: {
        workspaceId,
        objectType: NativeObjectTypes.Event,
        objectId: crmObject.objectId,
      },
      skip: !crmObject.objectId || !workspaceId,
    }
  )

  const workspaceMembers = workspaces
    .map((workspace) => workspace.members)
    .flat()
    .map((member) => member.email)

  const meetingObjects = useMemo(() => {
    return getEventCRMObjects({
      event,
      orgsByDomain,
      peopleByEmail,
      internalDomains,
      workspaceMembers,
    })
  }, [event, orgsByDomain, peopleByEmail, internalDomains, workspaceMembers])

  const refetch = async () => {
    // TODO: Implement refetch
  }

  const containerSx = useMemo(() => {
    return {
      height: '100%',
      width: '100%',
      h3: {
        mt: 3,
      },
      '.header': {
        p: '12px',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
        overflow: 'hidden',
        '.title': {
          width: '100%',
          overflow: 'hidden',
          '& h2': {
            width: '100%',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          },
        },
        '.actions': {
          mt: 1,
          gap: '4px',
          alignItems: 'flex-start',
        },
      },
    }
  }, [])

  const [createPage, { loading: createPageLoading }] = useMutation(
    CREATE_PAGE,
    {
      refetchQueries: [
        {
          query: GET_PAGES_FOR_EVENT,
          variables: {
            workspaceId,
            objectType: NativeObjectTypes.Event,
            objectId: crmObject.objectId,
          },
        },
      ],
    }
  )

  const handleCreatePage = async () => {
    try {
      setPageCreationUnderway(true)
      const result = await createPage({
        variables: {
          input: {
            workspaceId,
            title: `Meeting Prep: ${event.title}`,
            aiInitialPrompt: getMeetingPrepPrompt({
              event,
              meetingGoal,
              userCoreContact,
            }),
            contentJson: {
              type: 'doc',
              content: [],
            },
            contentHtml: '',
            objectType: NativeObjectTypes.Event,
            objectId: crmObject.objectId,
            crmObjects: [
              {
                objectType: NativeObjectTypes.Event,
                objectId: crmObject.objectId,
              },
              ...(meetingObjects || []).map((object) => ({
                objectType: object.objectType,
                objectId: object.objectId,
              })),
            ],
          },
        },
      })
      if (result.data?.createPage) {
        const newSidebarPageObject = {
          objectId: result.data.createPage.id,
          objectType: NativeObjectTypes.Page,
          workspaceId,
        }

        setSidebarObject(newSidebarPageObject)
      }
    } catch (error) {
      logger.error('Failed to create page:', error)
      setPageCreationUnderway(false)
      toast.error('Failed to create page')
    }
  }

  const handleJoinMeeting = useCallback((event) => {
    if (!event?.meetingJoinLink || !event?.calendarEmail) return
    return () => {
      window.open(
        formatMeetingJoinLink({
          meetingJoinLink: event.meetingJoinLink,
          calendarEmail: event.calendarEmail,
        }),
        '_blank'
      )
    }
  }, [])

  return event ? (
    <Box sx={containerSx}>
      <Box className="header">
        <Row
          gap={1}
          className="title"
        >
          <IconCalendarEvent
            size={24}
            style={{ flexShrink: 0 }}
          />
          <Typography variant="h2">{event.title}</Typography>
        </Row>

        <Row className="actions">
          <ObjectChipRow
            fullWidth={true}
            workspaceId={workspaceId}
            objects={meetingObjects}
            showSidebar={true}
            action={
              <Row gap={0.5}>
                {event?.id && (
                  <TodayMeetingRecordingAction
                    key={event?.id}
                    event={event}
                    refetch={refetch}
                    sx={{ flexShrink: 0, m: 0 }}
                    workspacesWithOtherMembers={[]}
                  />
                )}
                {!isPastEndTime(event) && (
                  <SidebarButton
                    label="Join Meeting"
                    icon={<IconVideo />}
                    onClick={() => handleJoinMeeting(event)}
                  />
                )}
              </Row>
            }
            maxToShow={2}
          />
        </Row>
      </Box>

      <Box
        sx={{
          px: '12px',
          height: 'calc(100vh - 160px)',
          overflowY: 'auto',
        }}
      >
        {createPageLoading || pageCreationUnderway ? (
          <SidebarLoader />
        ) : (
          <>
            {false && (
              <Row className="justify-between">
                <Typography variant="h3">Prep & Review</Typography>
                {pagesLoading ? (
                  <MetadataChip
                    state={{
                      label: 'Loading...',
                      value: 'pages',
                    }}
                    icon={<IconRefresh />}
                  />
                ) : pagesData?.pagesForParentObject?.length === 0 ? (
                  <MetadataChip
                    state={{
                      label: 'No pages found',
                      value: 'pages',
                    }}
                    icon={<IconFile />}
                  />
                ) : null}
              </Row>
            )}

            <TextField
              fullWidth={true}
              variant="standard"
              multiline={true}
              rows={3}
              placeholder="Tell Day.ai what you hope to achieve in this meeting ..."
              value={meetingGoal}
              onChange={(e) => setMeetingGoal(e.target.value)}
              sx={{
                mt: 1,
                mb: 1,
                border: (theme) => `1px solid ${theme.palette.divider}`,
                borderRadius: '4px',
              }}
              InputProps={{
                disableUnderline: true,
                sx: {
                  p: '4px',
                  pt: '12px',
                  pl: '12px',
                },
                endAdornment: (
                  <Row sx={{ height: '60px', alignItems: 'flex-end' }}>
                    <SidebarButton
                      label="Create Page"
                      icon={<IconSparkles />}
                      onClick={handleCreatePage}
                    />
                  </Row>
                ),
              }}
            />
            <SidebarObjectList
              objects={
                (pagesData?.pagesForParentObject || [])
                  .filter(Boolean)
                  .map((page) => ({
                    objectType: NativeObjectTypes.Page,
                    objectId: page.id,
                    properties: page,
                  })) || []
              }
              workspaceId={workspaceId}
              onObjectClick={setSidebarObject}
            />

            {false && (
              <>
                <Typography
                  variant="h3"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Attendees & Organizations
                </Typography>
                <SidebarObjectList
                  objects={meetingObjects}
                  workspaceId={workspaceId}
                  onObjectClick={setSidebarObject}
                />
              </>
            )}
          </>
        )}
      </Box>
    </Box>
  ) : (
    <SidebarLoader />
  )
}

export default SidebarLayoutEvent
