import { Button, Tooltip } from '@mui/material'
import DeleteBinIcon from 'remixicon-react/DeleteBinLineIcon'
import DeleteColumnIcon from 'remixicon-react/DeleteColumnIcon'
import DeleteRowIcon from 'remixicon-react/DeleteRowIcon'
import InsertColumnLeftIcon from 'remixicon-react/InsertColumnLeftIcon'
import InsertColumnRightIcon from 'remixicon-react/InsertColumnRightIcon'
import InsertRowBottomIcon from 'remixicon-react/InsertRowBottomIcon'
import InsertRowTopIcon from 'remixicon-react/InsertRowTopIcon'
import MagicIcon from 'remixicon-react/MagicLineIcon'
import MergeCellsVerticalIcon from 'remixicon-react/MergeCellsVerticalIcon'
import SplitCellsVerticalIcon from 'remixicon-react/SplitCellsVerticalIcon'

import { tiptapButtonSx } from '../styles'

const TiptapTableActions = ({ editor }) => {
  return (
    <>
      <Tooltip
        title="Add Column Before"
        placement="top"
        arrow
      >
        <>
          <Button
            size="small"
            sx={{
              ...tiptapButtonSx,
            }}
            onClick={() => editor.chain().focus().addColumnBefore().run()}
            disabled={!editor.can().addColumnBefore()}
          >
            <InsertColumnLeftIcon />
          </Button>
        </>
      </Tooltip>
      <Tooltip
        title="Add Column After"
        placement="top"
        arrow
      >
        <>
          <Button
            size="small"
            sx={{
              ...tiptapButtonSx,
            }}
            onClick={() => editor.chain().focus().addColumnAfter().run()}
            disabled={!editor.can().addColumnAfter()}
          >
            <InsertColumnRightIcon />
          </Button>
        </>
      </Tooltip>
      <Tooltip
        title="Delete Column"
        placement="top"
        arrow
      >
        <>
          <Button
            size="small"
            sx={{
              ...tiptapButtonSx,
            }}
            onClick={() => editor.chain().focus().deleteColumn().run()}
            disabled={!editor.can().deleteColumn()}
          >
            <DeleteColumnIcon />
          </Button>
        </>
      </Tooltip>
      <Tooltip
        title="Add Row Before"
        placement="top"
        arrow
      >
        <>
          <Button
            size="small"
            sx={{
              ...tiptapButtonSx,
            }}
            onClick={() => editor.chain().focus().addRowBefore().run()}
            disabled={!editor.can().addRowBefore()}
          >
            <InsertRowTopIcon />
          </Button>
        </>
      </Tooltip>
      <Tooltip
        title="Add Row After"
        placement="top"
        arrow
      >
        <>
          <Button
            size="small"
            sx={{
              ...tiptapButtonSx,
            }}
            onClick={() => editor.chain().focus().addRowAfter().run()}
            disabled={!editor.can().addRowAfter()}
          >
            <InsertRowBottomIcon />
          </Button>
        </>
      </Tooltip>
      <Tooltip
        title="Delete Row"
        placement="top"
        arrow
      >
        <>
          <Button
            size="small"
            sx={{
              ...tiptapButtonSx,
            }}
            onClick={() => editor.chain().focus().deleteRow().run()}
            disabled={!editor.can().deleteRow()}
          >
            <DeleteRowIcon />
          </Button>
        </>
      </Tooltip>
      <Tooltip
        title="Delete Table"
        placement="top"
        arrow
      >
        <>
          <Button
            size="small"
            sx={{
              ...tiptapButtonSx,
            }}
            onClick={() => editor.chain().focus().deleteTable().run()}
            disabled={!editor.can().deleteTable()}
          >
            <DeleteBinIcon />
          </Button>
        </>
      </Tooltip>
      <Tooltip
        title="Merge Cells"
        placement="top"
        arrow
      >
        <>
          <Button
            size="small"
            sx={{
              ...tiptapButtonSx,
            }}
            onClick={() => editor.chain().focus().mergeCells().run()}
            disabled={!editor.can().mergeCells()}
          >
            <MergeCellsVerticalIcon />
          </Button>
        </>
      </Tooltip>
      <Tooltip
        title="Split Cell"
        placement="top"
        arrow
      >
        <>
          <Button
            size="small"
            sx={{
              ...tiptapButtonSx,
            }}
            onClick={() => editor.chain().focus().splitCell().run()}
            disabled={!editor.can().splitCell()}
          >
            <SplitCellsVerticalIcon />
          </Button>
        </>
      </Tooltip>

      <Tooltip
        title="Fix Table"
        placement="top"
        arrow
      >
        <>
          <Button
            size="small"
            sx={{
              ...tiptapButtonSx,
            }}
            onClick={() => editor.chain().focus().fixTables().run()}
            disabled={!editor.can().fixTables()}
          >
            <MagicIcon />
          </Button>
        </>
      </Tooltip>
    </>
  )
}

export default TiptapTableActions
