import { Box, Chip } from '@mui/material'
import type { CRMObject, SlackChannel } from 'types/graphql'

import ObjectChipRow from 'src/components/Chips/ObjectChipRow/ObjectChipRow'
import Row from 'src/components/Row/Row'
import SlackChannels from 'src/components/Slack/SlackChannels/SlackChannels'
import SlackLogo from 'src/components/Slack/SlackLogo/SlackLogo'
import { NativeObjectTypes } from 'src/lib/objects'

const SidebarLayoutSlackChannel = ({ crmObject }: { crmObject: CRMObject }) => {
  const workspaceId = (crmObject?.properties as any)?.workspaceId
  const slackChannel: SlackChannel = crmObject?.properties as SlackChannel

  return slackChannel ? (
    <Box sx={{ py: 3, height: '100%' }}>
      <Box sx={{ px: '12px', height: 'calc(100%)', overflow: 'hidden' }}>
        <Box
          sx={{
            cursor: 'pointer',
            height: 'calc(100%)',
            overflowY: 'auto',
          }}
        >
          <Chip
            variant="filled"
            key={slackChannel?.id}
            icon={
              <Row sx={{ justifyContent: 'right', width: '20px' }}>
                <SlackLogo size={14} />
              </Row>
            }
            label={`#${slackChannel?.name}`}
            onClick={() => {
              const url = `slack://channel?id=${slackChannel?.slackChannelId}&team=${slackChannel?.slackIntegration?.slackTeamId}`
              window.open(url, '_blank')
            }}
            sx={{
              background: '#703259',
              color: 'white',
              fontWeight: '600',
              fontSize: '12px',
              letterSpacing: '-0.2px',
            }}
          />
          <Box sx={{ pb: 2, pt: 2 }}>
            <ObjectChipRow
              objects={slackChannel?.people?.map((p) => ({
                objectId: p,
                objectType: NativeObjectTypes.Person,
                properties: p,
              }))}
              workspaceId={workspaceId}
              showSidebar={true}
              onRemove={null}
            />
          </Box>
          <SlackChannels
            channels={[slackChannel]}
            includeTitle={false}
          />
        </Box>
      </Box>
    </Box>
  ) : null
}

export default SidebarLayoutSlackChannel
