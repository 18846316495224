import {
  createSchema,
  definePermissions,
  table,
  relationships,
  string,
  number,
  json,
  boolean,
  ANYONE_CAN,
  type PermissionRule,
} from '@rocicorp/zero'

// import {globalWorkspaceId} from 'src/data/objects/mappers'
export const globalWorkspaceId =
  process.env.NODE_ENV === 'production'
    ? 'cb5f969e-9045-47f8-b4fc-02afdc2bced1'
    : 'e6a2b090-01a9-4659-b504-7ace0f621c74'

// import { internalUserEmails } from 'src/lib/gates'
const internalUserEmails = [
  'markitecht@gmail.com',
  'erik@ipsumcreative.com',
  'gwendolynr@gmail.com',
  'erin@day.ai',
  'michael.g.pici@gmail.com',
  'christopher@theproviders.com',
  'daphne@day.ai',
  'daphnegrayson@gmail.com',
  'stephen@day.ai',
  'gwen@day.ai',
  'will@day.ai',
  'todd@day.ai',
]

// import { NativeObjectTypes } from 'src/lib/objects'
const NativeObjectTypes = {
  Person: 'native_contact',
  Contact: 'native_contact',
  Organization: 'native_organization',
  Opportunity: 'native_opportunity',
  Pipeline: 'native_pipeline',
  Stage: 'native_stage',
  MeetingRecording: 'native_meetingrecording',
  MeetingRecordingClip: 'native_meetingrecordingclip',
  Page: 'native_page',
  Action: 'native_action',
  Workspace: 'native_workspace',
  View: 'native_view',
  Event: 'native_calendarevent',
  GmailThread: 'native_gmailthread',
  UserContext: 'native_usercontext',
  PropertyDefinition: 'property_definition',
  SlackChannel: 'native_slackchannel',
  WebPage: 'native_webpage',
} as const

const user = table('user')
  .from('User')
  .columns({
    id: string(),
    createdAt: number(),
  })
  .primaryKey('id')

const userIdentity = table('userIdentity')
  .from('UserIdentity')
  .columns({
    type: string(),
    identity: string(),
    userId: string(),
    createdAt: number(),
    domain: string().optional(),
    isFreemail: boolean().optional(),
  })
  .primaryKey('type', 'identity')

const workspace = table('workspace')
  .from('Workspace')
  .columns({
    id: string(),
    name: string(),
    createdAt: number(),
    deletedAt: number().optional(),
    deleteStatus: string().optional(),
  })
  .primaryKey('id')

const role = table('role')
  .from('Role')
  .columns({
    workspaceId: string(),
    roleId: string(),
    name: string(),
    description: string(),
    createdAt: number(),
    updatedAt: number(),
    deletedAt: number().optional(),
  })
  .primaryKey('workspaceId', 'roleId')

const group = table('group')
  .from('Group')
  .columns({
    workspaceId: string(),
    groupId: string(),
    name: string(),
    description: string(),
    createdAt: number(),
    updatedAt: number(),
    deletedAt: number().optional(),
  })
  .primaryKey('workspaceId', 'groupId')

const authorizationRelationship = table('authorizationRelationship')
  .from('AuthorizationRelationship')
  .columns({
    entityType: string(),
    entityId: string(),
    entityIdSlot1: string(),
    entityIdSlot2: string(),
    entityIdSlot3: string(),
    entityIdSlot4: string(),

    relationship: string(),

    targetType: string(),
    targetId: string(),
    targetIdSlot1: string(),
    targetIdSlot2: string(),
    targetIdSlot3: string(),
    targetIdSlot4: string(),

    createdAt: number(),
  })
  .primaryKey(
    'entityType',
    'entityId',
    'relationship',
    'targetType',
    'targetId'
  )

const workspaceDomainClaim = table('workspaceDomainClaim')
  .from('WorkspaceDomainClaim')
  .columns({
    workspaceId: string(),
    domain: string(),

    createdByUserId: string().optional(),
    createdAt: number(),
    updatedAt: number(),
  })
  .primaryKey('workspaceId', 'domain')

const workspaceDomainAutoInviteRule = table('workspaceDomainAutoInviteRule')
  .from('WorkspaceDomainAutoInviteRule')
  .columns({
    workspaceId: string(),
    domain: string(),
    roleId: string(),

    createdByUserId: string().optional(),
    createdAt: number(),
    updatedAt: number(),
  })
  .primaryKey('workspaceId', 'domain')

const workspaceMemberInvite = table('workspaceMemberInvite')
  .from('WorkspaceMemberInvite')
  .columns({
    workspaceId: string(),
    roleId: string(),

    email: string(),
    status: string(),

    createdByUserId: string().optional(),
    createdAt: number(),
    updatedAt: number(),
  })
  .primaryKey('workspaceId', 'email')

const objectType = table('objectType')
  .from('ObjectType')
  .columns({
    id: string(),
    name: string(),
  })
  .primaryKey('id')

const objectPropertyType = table('objectPropertyType')
  .from('ObjectPropertyType')
  .columns({
    id: string(),
    name: string(),
    description: string(),
  })
  .primaryKey('id')

const objectPropertySource = table('objectPropertySource')
  .from('ObjectPropertySource')
  .columns({
    id: string(),
    name: string(),
    description: string(),
    priority: number(),
  })
  .primaryKey('id')

const objectProperty = table('objectProperty')
  .from('ObjectProperty')
  .columns({
    propertyHash: string(),
    propertyVersionHash: string(),

    workspaceId: string(),
    objectTypeId: string(),

    objectId: string(),
    userId: string(),
    name: string(),
    propertySourceId: string(),

    propertyTypeId: string(),
    value: string(),
    version: number(),

    createdAt: number(),
    updatedAt: number(),
    metadata: json().optional(),
    meta_sourceUserId: string().optional(),
    meta_sourceDescription: string().optional(),
  })
  .primaryKey(
    'workspaceId',
    'objectTypeId',
    'objectId',
    'userId',
    'name',
    'propertySourceId'
  )

const objectPropertyHistory = table('objectPropertyHistory')
  .from('ObjectPropertyHistory')
  .columns({
    propertyVersionHash: string(),

    workspaceId: string(),
    objectTypeId: string(),

    objectId: string(),
    userId: string(),
    name: string(),
    propertySourceId: string(),
    version: number(),

    propertyTypeId: string(),
    value: string(),

    createdAt: number(),
    metadata: json().optional(),
    meta_sourceUserId: string().optional(),
    meta_sourceDescription: string().optional(),
  })
  .primaryKey(
    'workspaceId',
    'objectTypeId',
    'objectId',
    'userId',
    'name',
    'propertySourceId',
    'version'
  )

const objectLineageRelationship = table('objectLineageRelationship')
  .from('ObjectLineageRelationship')
  .columns({
    internalId: string(),

    workspaceId: string(),

    relationshipHash: string(),

    sourceObjectPropertyHash: string(),
    sourceRelationshipPropertyHash: string(),

    relationship: string(),

    targetObjectPropertyHash: string(),
    targetRelationshipPropertyHash: string(),

    featureId: string(),
    featureVersion: string(),
    description: string(),

    properties: json(),

    createdAt: number(),
    updatedAt: number(),
  })
  .primaryKey('internalId')

const objectLineageRelationshipRelationships = relationships(
  objectLineageRelationship,
  ({ one }) => ({
    sourcePropertyVersion: one({
      sourceField: ['sourceObjectPropertyHash'],
      destSchema: objectPropertyHistory,
      destField: ['propertyVersionHash'],
    }),
    targetPropertyVersion: one({
      sourceField: ['targetObjectPropertyHash'],
      destSchema: objectPropertyHistory,
      destField: ['propertyVersionHash'],
    }),
  })
)

const objectPropertyDefinition = table('objectPropertyDefinition')
  .from('ObjectPropertyDefinition')
  .columns({
    id: string(),

    workspaceId: string(),
    objectTypeId: string(),
    propertyTypeId: string(),

    name: string(),
    description: string(),
    aiManaged: boolean(),
    useWeb: boolean(),

    parallelTaskId: string().optional(),
    parallelTaskSuccess: boolean(),
    parallelLastRunAt: number().optional(),

    createdAt: number(),
    updatedAt: number(),
  })
  .primaryKey('id')

const objectPropertyDefinitionOption = table('objectPropertyDefinitionOption')
  .from('ObjectPropertyDefinitionOption')
  .columns({
    id: string(),

    propertyDefinitionId: string(),
    name: string(),
    description: string().optional(),

    createdAt: number(),
    updatedAt: number(),
  })
  .primaryKey('id')

const workspaceRelationships = relationships(workspace, ({ many }) => ({
  authorizationRelationships: many({
    sourceField: ['id'],
    destSchema: authorizationRelationship,
    destField: ['entityId'],
  }),
  memberInvites: many({
    sourceField: ['id'],
    destSchema: workspaceMemberInvite,
    destField: ['workspaceId'],
  }),
  domainAutoInviteRules: many({
    sourceField: ['id'],
    destSchema: workspaceDomainAutoInviteRule,
    destField: ['workspaceId'],
  }),
}))

const workspaceInviteRelationships = relationships(
  workspaceMemberInvite,
  ({ one }) => ({
    userIdentity: one({
      sourceField: ['email'],
      destSchema: userIdentity,
      destField: ['identity'],
    }),
    workspace: one({
      sourceField: ['workspaceId'],
      destSchema: workspace,
      destField: ['id'],
    }),
  })
)

const roleRelationships = relationships(role, ({ one }) => ({
  workspace: one({
    sourceField: ['workspaceId'],
    destSchema: workspace,
    destField: ['id'],
  }),
}))

const objectPropertyRelationships = relationships(
  objectProperty,
  ({ one, many }) => ({
    workspace: one({
      sourceField: ['workspaceId'],
      destSchema: workspace,
      destField: ['id'],
    }),
    workspaceObjectAuthorizationRelationships: many({
      sourceField: ['workspaceId', 'objectTypeId', 'objectId', 'workspaceId'],
      destSchema: authorizationRelationship,
      destField: [
        'entityIdSlot1',
        'entityIdSlot2',
        'entityIdSlot3',
        'targetIdSlot1',
      ],
    }),
    // This relationship should not be trusted on its own, but should be used in conjunction with
    // checks on entityType and targetType.
    workspaceRoleAuthorizationRelationships: many({
      sourceField: ['workspaceId'],
      destSchema: authorizationRelationship,
      destField: ['entityIdSlot1'],
    }),
  })
)

const objectPropertyHistoryRelationships = relationships(
  objectPropertyHistory,
  ({ one, many }) => ({
    workspace: one({
      sourceField: ['workspaceId'],
      destSchema: workspace,
      destField: ['id'],
    }),
    workspaceObjectAuthorizationRelationships: many({
      sourceField: ['workspaceId', 'objectTypeId', 'objectId', 'workspaceId'],
      destSchema: authorizationRelationship,
      destField: [
        'entityIdSlot1',
        'entityIdSlot2',
        'entityIdSlot3',
        'targetIdSlot1',
      ],
    }),
    workspaceRoleAuthorizationRelationships: many({
      sourceField: ['workspaceId'],
      destSchema: authorizationRelationship,
      destField: ['entityIdSlot1'],
    }),
  })
)

const authorizationRelationshipRelationships = relationships(
  authorizationRelationship,
  ({ one, many }) => ({
    // Assuming this is currently an AR with a role as the entity,
    // this relationship will find other ARs with the same role as the entity,
    // and potentially a permission as the target.
    roleToPermission: many({
      sourceField: [
        'entityIdSlot1',
        'entityIdSlot2',
        'entityIdSlot3',
        'entityIdSlot4',
        'entityIdSlot1',
      ],
      destSchema: authorizationRelationship,
      destField: [
        'entityIdSlot1',
        'entityIdSlot2',
        'entityIdSlot3',
        'entityIdSlot4',
        'targetIdSlot1',
      ],
    }),
    // Assumes that entityIdSlot1 is the workspaceId. Be careful!
    workspace: one({
      sourceField: ['entityIdSlot1'],
      destSchema: workspace,
      destField: ['id'],
    }),
    // Assumes that the entity is a workspace object. Be careful!
    objectProperty: one({
      sourceField: ['entityIdSlot1', 'entityIdSlot2', 'entityIdSlot3'],
      destSchema: objectProperty,
      destField: ['workspaceId', 'objectTypeId', 'objectId'],
    }),
  })
)

const groupRelationships = relationships(group, ({ one }) => ({
  workspace: one({
    sourceField: ['workspaceId'],
    destSchema: workspace,
    destField: ['id'],
  }),
}))

const objectPropertyDefinitionRelationships = relationships(
  objectPropertyDefinition,
  ({ one, many }) => ({
    workspace: one({
      sourceField: ['workspaceId'],
      destSchema: workspace,
      destField: ['id'],
    }),
    objectPropertyDefinitionOptions: many({
      sourceField: ['id'],
      destSchema: objectPropertyDefinitionOption,
      destField: ['propertyDefinitionId'],
    }),
  })
)

const objectPropertyDefinitionOptionRelationships = relationships(
  objectPropertyDefinitionOption,
  ({ one }) => ({
    objectPropertyDefinition: one({
      sourceField: ['propertyDefinitionId'],
      destSchema: objectPropertyDefinition,
      destField: ['id'],
    }),
  })
)

const workspaceDomainAutoInviteRuleRelationships = relationships(
  workspaceDomainAutoInviteRule,
  ({ many, one }) => ({
    userIdentities: many({
      sourceField: ['domain'],
      destSchema: userIdentity,
      destField: ['domain'],
    }),
    workspace: one({
      sourceField: ['workspaceId'],
      destSchema: workspace,
      destField: ['id'],
    }),
  })
)

export const schema = createSchema({
  tables: [
    user,
    userIdentity,
    workspace,
    role,
    group,
    authorizationRelationship,
    workspaceDomainClaim,
    workspaceDomainAutoInviteRule,
    workspaceMemberInvite,
    objectType,
    objectPropertyType,
    objectPropertySource,
    objectProperty,
    objectPropertyHistory,
    objectLineageRelationship,
    objectPropertyDefinition,
    objectPropertyDefinitionOption,
  ],
  relationships: [
    workspaceRelationships,
    workspaceInviteRelationships,
    roleRelationships,
    groupRelationships,
    objectPropertyDefinitionRelationships,
    objectPropertyDefinitionOptionRelationships,
    workspaceDomainAutoInviteRuleRelationships,
    objectPropertyRelationships,
    objectPropertyHistoryRelationships,
    objectLineageRelationshipRelationships,
    authorizationRelationshipRelationships,
  ],
})

export type Schema = typeof schema

type AuthData = {
  sub: string
  email: string
}

const allowReadIf = <T extends keyof Schema['tables']>(
  rule: PermissionRule<AuthData, Schema, T> | typeof ANYONE_CAN
) => ({
  row: {
    select: Array.isArray(rule) ? rule : [rule],
  },
})

const isSignedIn: PermissionRule<AuthData, Schema, keyof Schema['tables']> = (
  authData,
  { cmpLit }
) => cmpLit(authData.sub, 'IS NOT', null)

const INTERNAL_ADMIN_VIEWING_ENABLED = false

const isInternalAdmin: PermissionRule<
  AuthData,
  Schema,
  keyof Schema['tables']
> = (authData, { cmpLit }) =>
  cmpLit(
    authData.email,
    'IN',
    INTERNAL_ADMIN_VIEWING_ENABLED ? internalUserEmails : []
  )

const isActiveWorkspaceMember: PermissionRule<AuthData, Schema, 'workspace'> = (
  authData,
  eb
) =>
  eb.exists('authorizationRelationships', (authRelationship) =>
    authRelationship.where(({ and, cmp }) =>
      and(
        cmp('entityType', '=', 'WORKSPACE'),
        cmp('targetType', '=', 'USER'),
        cmp('relationship', '=', 'active'),
        cmp('targetIdSlot1', '=', authData.sub)
      )
    )
  )

const objectPropertyAllowedTypes = [
  NativeObjectTypes.Person,
  NativeObjectTypes.Organization,
  NativeObjectTypes.Pipeline,
  NativeObjectTypes.Stage,
  NativeObjectTypes.Opportunity,
  NativeObjectTypes.Action,
  NativeObjectTypes.WebPage,
]

const isAllowedObjectPropertyOrHistory: PermissionRule<
  AuthData,
  Schema,
  'objectProperty' | 'objectPropertyHistory'
> = (authData, eb) => {
  const { and, or, cmp, exists } = eb

  const isGlobalWorkspaceProperty = cmp('workspaceId', '=', globalWorkspaceId)

  const activeWorkspaceMember = exists('workspace', (workspace) =>
    workspace.where((eb) => isActiveWorkspaceMember(authData, eb))
  )

  // implicitly shared properties
  const implicitlySharedProperties = and(
    cmp('objectTypeId', 'IN', objectPropertyAllowedTypes),
    or(
      cmp('userId', '=', ''),
      cmp('objectTypeId', '=', NativeObjectTypes.Action)
    )
  )

  // The user's personal properties
  const personalProperties = cmp('userId', '=', authData.sub)

  // Properties that are shared with the user
  const propertiesSharedWithUser = and(
    cmp('userId', '=', ''),
    // Must have an authorization rule that grants the user access to view the object
    exists('workspaceObjectAuthorizationRelationships', (authRelationship) =>
      authRelationship.where(({ and, cmp }) =>
        and(
          cmp('entityType', '=', 'WORKSPACE_OBJECT'),
          cmp('relationship', 'IN', [
            'viewer',
            'collaborator',
            'editor',
            'fullAccess',
            'owner',
          ]),
          cmp('targetType', '=', 'WORKSPACE_USER'),
          cmp('targetIdSlot2', '=', authData.sub)
        )
      )
    )
  )

  // Properties that are shared with the workspace, so long as the user has a role that
  // grants them full access
  const propertiesSharedWithWorkspace = and(
    cmp('userId', '=', ''),
    exists('workspaceObjectAuthorizationRelationships', (authRelationship) =>
      authRelationship.where(({ and, cmp }) =>
        and(
          cmp('entityType', '=', 'WORKSPACE_OBJECT'),
          cmp('relationship', '=', 'sharedWith'),
          cmp('targetType', '=', 'WORKSPACE')
        )
      )
    ),
    exists('workspaceRoleAuthorizationRelationships', (authRelathionship) =>
      authRelathionship.where(({ and, cmp, exists }) =>
        and(
          cmp('entityType', '=', 'WORKSPACE_ROLE'),
          cmp('relationship', '=', 'assignee'),
          cmp('targetType', '=', 'USER'),
          cmp('targetIdSlot1', '=', authData.sub),
          exists('roleToPermission', (authRelationship) =>
            authRelationship.where(({ and, cmp }) =>
              and(
                cmp('entityType', '=', 'WORKSPACE_ROLE'),
                cmp('relationship', '=', 'member'),
                cmp('targetType', '=', 'WORKSPACE_PERMISSION'),
                cmp('targetIdSlot2', '=', 'OBJECT_TYPE'),
                cmp('targetIdSlot3', '=', 'fullAccess'),
                cmp('targetIdSlot4', '=', '*')
              )
            )
          )
        )
      )
    )
  )

  return or(
    isGlobalWorkspaceProperty,
    and(
      activeWorkspaceMember,
      or(
        implicitlySharedProperties,
        personalProperties,
        propertiesSharedWithUser,
        propertiesSharedWithWorkspace
      )
    )
  )
}

const isAllowedWorkspaceMembershipAuthRelationship: PermissionRule<
  AuthData,
  Schema,
  'authorizationRelationship'
> = (authData, eb) => {
  const { and, or, cmp, exists } = eb

  // The user can see all active or deactivated relationships of the workspace they are a member of
  const activeOrDeactivatedMembers = and(
    cmp('entityType', '=', 'WORKSPACE'),
    cmp('relationship', 'IN', ['active', 'deactivated']),
    cmp('targetType', '=', 'USER'),
    // Following the workspace relationship only works here because we know that
    // entityIdSlot1 is the workspaceId.
    exists('workspace', (workspace) =>
      workspace.where((eb) => isActiveWorkspaceMember(authData, eb))
    )
  )

  // The user can see the defaultFor relationship if they are the target
  const defaultFor = and(
    cmp('entityType', '=', 'WORKSPACE'),
    cmp('relationship', '=', 'defaultFor'),
    cmp('targetType', '=', 'USER'),
    cmp('targetIdSlot1', '=', authData.sub)
  )

  // The user can see the roles for the workspace they are a member of
  const roles = and(
    cmp('entityType', '=', 'WORKSPACE'),
    cmp('relationship', '=', 'member'),
    cmp('targetType', '=', 'WORKSPACE_ROLE'),
    // Following the workspace relationship only works here because we know that
    // entityIdSlot1 is the workspaceId.
    exists('workspace', (workspace) =>
      workspace.where((eb) => isActiveWorkspaceMember(authData, eb))
    )
  )

  // And the role assignments for the workspace they are a member of
  const roleAssignments = and(
    cmp('entityType', '=', 'WORKSPACE_ROLE'),
    cmp('relationship', '=', 'assignee'),
    cmp('targetType', '=', 'USER'),
    // Following the workspace relationship only works here because we know that
    // entityIdSlot1 is the workspaceId.
    exists('workspace', (workspace) =>
      workspace.where((eb) => isActiveWorkspaceMember(authData, eb))
    )
  )

  return or(activeOrDeactivatedMembers, defaultFor, roles, roleAssignments)
}

const isAllowedObjectSharingAuthRelationship: PermissionRule<
  AuthData,
  Schema,
  'authorizationRelationship'
> = (authData, eb) => {
  const { and, or, cmp, exists } = eb
  // The user can see that an object has been shared with a workspace if they are
  // a member of the workspace
  const sharedWithWorkspace = and(
    cmp('entityType', '=', 'WORKSPACE_OBJECT'),
    cmp('relationship', '=', 'sharedWith'),
    cmp('targetType', '=', 'WORKSPACE'),
    // Following the workspace relationship only works here because we know that
    // entityIdSlot1 is the workspaceId.
    exists('workspace', (workspace) =>
      workspace.where((eb) => isActiveWorkspaceMember(authData, eb))
    )
  )

  // The user can see all individual shares of an object if they have access to the object
  const sharedWithIndividuals = and(
    cmp('entityType', '=', 'WORKSPACE_OBJECT'),
    cmp('relationship', 'IN', [
      'viewer',
      'collaborator',
      'editor',
      'fullAccess',
      'owner',
    ]),
    cmp('targetType', '=', 'WORKSPACE_USER'),
    // Following the objectProperty relationship only works here because we know that the entity
    // of this AR is a workspace object.
    exists('objectProperty', (objectProperty) =>
      objectProperty.where((eb) =>
        isAllowedObjectPropertyOrHistory(authData, eb)
      )
    )
  )

  return or(sharedWithWorkspace, sharedWithIndividuals)
}

const isAllowedAuthorizationRelationship: PermissionRule<
  AuthData,
  Schema,
  'authorizationRelationship'
> = (authData, eb) => {
  // We're currently only allowing workspace membership and object sharing authorization
  // relationships to be visible. As we need to support more types of relationships in the
  // frontend, we'll add them here.
  const { or } = eb
  return or(
    isAllowedWorkspaceMembershipAuthRelationship(authData, eb),
    isAllowedObjectSharingAuthRelationship(authData, eb)
  )
}

export const permissions = definePermissions<AuthData, Schema>(schema, () => ({
  objectLineageRelationship: allowReadIf((authData, eb) => {
    const { and, or, exists } = eb

    const canSeeSourceAndTarget = and(
      exists('sourcePropertyVersion', (propertyVersion) =>
        propertyVersion.where((eb) =>
          isAllowedObjectPropertyOrHistory(authData, eb)
        )
      ),
      exists('targetPropertyVersion', (propertyVersion) =>
        propertyVersion.where((eb) =>
          isAllowedObjectPropertyOrHistory(authData, eb)
        )
      )
    )

    return and(
      isSignedIn(authData, eb),
      or(isInternalAdmin(authData, eb), canSeeSourceAndTarget)
    )
  }),
  objectProperty: allowReadIf((authData, eb) => {
    const { and, or } = eb

    return and(
      isSignedIn(authData, eb),
      or(
        isInternalAdmin(authData, eb),
        isAllowedObjectPropertyOrHistory(authData, eb)
      )
    )
  }),

  objectPropertyHistory: allowReadIf((authData, eb) => {
    const { and, or } = eb

    return and(
      isSignedIn(authData, eb),
      or(
        isInternalAdmin(authData, eb),
        isAllowedObjectPropertyOrHistory(authData, eb)
      )
    )
  }),

  authorizationRelationship: allowReadIf((authData, eb) => {
    const { and, or } = eb

    return and(
      isSignedIn(authData, eb),
      or(
        isInternalAdmin(authData, eb),
        isAllowedAuthorizationRelationship(authData, eb)
      )
    )
  }),

  user: allowReadIf((authData, eb) => {
    const { and, or, cmp } = eb

    return and(
      isSignedIn(authData, eb),
      or(isInternalAdmin(authData, eb), cmp('id', '=', authData.sub))
    )
  }),

  userIdentity: allowReadIf((authData, eb) => {
    const { and, or, cmp } = eb

    return and(
      isSignedIn(authData, eb),
      or(isInternalAdmin(authData, eb), cmp('userId', '=', authData.sub))
    )
  }),

  workspace: allowReadIf((authData, eb) => {
    const { and, or, exists, not } = eb

    /*
      [done] signed in AND
      [done] internal employee/ admin user
      OR
      [done] has an active relationship
      OR
      [done] has email invites
      OR
      has no deactivated relationships AND has an auto invite

      todos:
      - add a `domain` column to the UserIdentity table

    */

    const isExplicitlyInvited = exists('memberInvites', (invite) =>
      invite.whereExists('userIdentity', (identity) =>
        identity.where(({ and, cmp }) =>
          and(cmp('type', '=', 'EMAIL'), cmp('userId', '=', authData.sub))
        )
      )
    )

    const isImplicityInvitedViaEmailDomain = and(
      not(
        exists('authorizationRelationships', (authRelationship) =>
          authRelationship.where(({ and, cmp }) =>
            and(
              cmp('entityType', '=', 'WORKSPACE'),
              cmp('targetType', '=', 'USER'),
              cmp('relationship', '=', 'deactivated'),
              cmp('targetIdSlot1', '=', authData.sub)
            )
          )
        )
      ),
      exists('domainAutoInviteRules', (autoInviteRule) =>
        autoInviteRule.whereExists('userIdentities', (identity) =>
          identity.where(({ and, cmp }) =>
            and(
              cmp('type', '=', 'EMAIL'),
              cmp('isFreemail', '=', false),
              cmp('userId', '=', authData.sub)
            )
          )
        )
      )
    )

    return and(
      isSignedIn(authData, eb),
      or(
        isInternalAdmin(authData, eb),
        isActiveWorkspaceMember(authData, eb),
        isExplicitlyInvited,
        isImplicityInvitedViaEmailDomain
      )
    )
  }),

  // role: must be signed in AND either an admin or active member of the workspace
  role: allowReadIf((authData, eb) => {
    const { and, or, exists } = eb

    return and(
      isSignedIn(authData, eb),
      or(
        isInternalAdmin(authData, eb),
        exists('workspace', (workspace) =>
          workspace.where((eb) => isActiveWorkspaceMember(authData, eb))
        )
      )
    )
  }),

  // group: must be signed in AND either an admin or active member of the workspace
  group: allowReadIf((authData, eb) => {
    const { and, or, exists } = eb

    return and(
      isSignedIn(authData, eb),
      or(
        isInternalAdmin(authData, eb),
        exists('workspace', (workspace) =>
          workspace.where((eb) => isActiveWorkspaceMember(authData, eb))
        )
      )
    )
  }),

  workspaceDomainAutoInviteRule: allowReadIf((authData, eb) => {
    const { and, or, exists } = eb

    return and(
      isSignedIn(authData, eb),
      or(
        isInternalAdmin(authData, eb),
        exists('workspace', (workspace) =>
          workspace.where((eb) => isActiveWorkspaceMember(authData, eb))
        )
      )
    )
  }),

  /* workspaceMemberInvite:
     signed in AND (
         internal admin
         OR logged in user's email matches the invitation
         OR logged in user is a member of the workspace
      )
      TODO: Change to look at UserIdentity table for any user with a matching userId
   */
  workspaceMemberInvite: allowReadIf((authData, eb) => {
    const { and, or, exists } = eb

    return and(
      isSignedIn(authData, eb),
      or(
        isInternalAdmin(authData, eb),
        exists('userIdentity', (identity) =>
          identity.where(({ and, cmp }) =>
            and(cmp('type', '=', 'EMAIL'), cmp('userId', '=', authData.sub))
          )
        ),
        exists('workspace', (workspace) =>
          workspace.where((eb) => isActiveWorkspaceMember(authData, eb))
        )
      )
    )
  }),

  // objectPropertyDefinition: signed in AND admin or has access to the workspace
  objectPropertyDefinition: allowReadIf((authData, eb) => {
    const { and, or, exists } = eb

    return and(
      isSignedIn(authData, eb),
      or(
        isInternalAdmin(authData, eb),
        exists('workspace', (workspace) =>
          workspace.where((eb) => isActiveWorkspaceMember(authData, eb))
        )
      )
    )
  }),

  // isActiveWorkspaceMember(eb)
  // objectPropertyDefinitionOption: signed in AND admin or has access to the workspace
  objectPropertyDefinitionOption: allowReadIf((authData, eb) => {
    const { and, or, exists } = eb
    return and(
      isSignedIn(authData, eb),
      or(
        isInternalAdmin(authData, eb),
        exists('objectPropertyDefinition', (definition) =>
          definition.whereExists('workspace', (workspace) =>
            workspace.where((eb) => isActiveWorkspaceMember(authData, eb))
          )
        )
      )
    )
  }),

  objectType: allowReadIf(ANYONE_CAN),

  objectPropertyType: allowReadIf(ANYONE_CAN),

  objectPropertySource: allowReadIf(ANYONE_CAN),
}))
