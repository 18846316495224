import { useContext, useMemo, useState } from 'react'

import { useQuery } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import { DayContext } from 'src/lib/dayContext'
import { ungatedForDayObjectsPart2 } from 'src/lib/gates'
import { logger } from 'src/lib/logger'

import MeetingRecordingsContext from './MeetingRecordingContext'

const MEETING_RECORDINGS_FOR_CONTEXT = gql`
  query MeetingRecordingsForContext(
    $workspaceId: String!
    $limit: Int!
    $page: Int!
  ) {
    workspaceMeetingRecordings(
      workspaceId: $workspaceId
      limit: $limit
      page: $page
    ) {
      pageInfo {
        limit
        page
        totalPages
      }
      recordings {
        id
        title
        summary {
          output
        }
        startedAt
        endedAt
        statusHistory {
          level
          status
          createdAt
          reason
          message
        }
        participants {
          email
        }
        clips {
          id
          startSeconds
          endSeconds
        }
        calendarEvents {
          id
          GoogleEvent {
            title
          }
        }
      }
    }
  }
`

const MeetingRecordingsProvider = ({ children }) => {
  const { currentUser } = useAuth()
  const { selectedWorkspace } = useContext(DayContext)
  const [meetingRecordings, setMeetingRecordings] = useState<any[]>([])
  const [meetingRecordingsPageInfo, setMeetingRecordingsPageInfo] =
    useState<any>(null)
  const [meetingRecordingsPage, setMeetingRecordingsPage] = useState<number>(1)

  const PRECACHE_MEETING_RECORDINGS = true

  useQuery(MEETING_RECORDINGS_FOR_CONTEXT, {
    variables: {
      limit: 20,
      page: meetingRecordingsPage,
      workspaceId: selectedWorkspace,
    },
    onCompleted: (data) => {
      logger.dev(
        `Loaded ${data.workspaceMeetingRecordings.recordings.length} meeting recordings`,
        {
          data,
        }
      )
      setMeetingRecordings((prev) => [
        ...prev,
        ...data.workspaceMeetingRecordings.recordings,
      ])
      setMeetingRecordingsPageInfo(data.workspaceMeetingRecordings.pageInfo)
      setMeetingRecordingsPage(
        data.workspaceMeetingRecordings.pageInfo.page + 1
      )
    },
    skip:
      !selectedWorkspace ||
      !PRECACHE_MEETING_RECORDINGS ||
      meetingRecordingsPageInfo?.totalPages < meetingRecordingsPage ||
      ungatedForDayObjectsPart2(currentUser),
  })

  const value = useMemo(() => {
    return {
      meetingRecordings,
    }
  }, [meetingRecordings])

  return (
    <MeetingRecordingsContext.Provider value={value}>
      {children}
    </MeetingRecordingsContext.Provider>
  )
}

export default MeetingRecordingsProvider
