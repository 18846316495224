export const GET_PERSON_BASIC = gql`
  query GetPersonBasic($email: String!) {
    getPersonPublic(email: $email) {
      id
      firstName
      lastName
      photoUrl
    }
  }
`

export const GET_PERSON = gql`
  query GetPerson($email: String!, $workspaceId: String) {
    getPerson(email: $email, workspaceId: $workspaceId) {
      id
      email
      firstName
      lastName
      fullName
      photoUrl
      canonicalEmail
      currentCompanyName
      currentJobTitle
      currentJobStartDate
      linkedInUrl
      socialTwitter
      socialFacebook
      pastEmails
      photoUrl
      skills
      languages
      interests
      careerSummary
      location
      city
      state
      country
      postalCode
      latitude
      longitude
      workExperience {
        companyName
        jobTitle
        startDate
        endDate
        description
      }
      education {
        schoolName
        schoolPhotoUrl
        degree
        fieldOfStudy
        startDate
        endDate
      }
      certifications {
        name
        issuer
        date
        url
      }
      verificationStatus {
        verified
      }
      relationship {
        oneSentence
        short
        long
      }
      actions {
        id
        title
        updatedAt
        owner {
          email
          id
        }
        channel {
          id
          label
          type
          accountId
        }
        source {
          id
          label
          type
        }
        priority
        status {
          id
          label
        }
      }
    }
  }
`
