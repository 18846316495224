import {
  isValidElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import {
  Box,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  Typography,
} from '@mui/material'
import type { GridEventLookup } from '@mui/x-data-grid-premium'
import {
  GridToolbarContainer,
  GridToolbarQuickFilter,
  useGridApiContext,
} from '@mui/x-data-grid-premium'
import { IconChevronDown, IconSearch } from '@tabler/icons-react'

import { navigate } from '@redwoodjs/router'

import { useAuth } from 'src/auth'
import { ungatedForViews } from 'src/lib/gates'
import { logger } from 'src/lib/logger'
import { NativeObjectTypes, ObjectTypeMetadata } from 'src/lib/objects'

import Row from '../Row/Row'
import ViewManager from '../Views/ViewManager'
import ViewsProvider from '../Views/ViewsProvider'

const QuickFilterProps = {
  startAdornment: (
    <IconSearch
      stroke={3.5}
      size={20}
    />
  ),
  placeholder: ``,
  disableUnderline: true,
  sx: {
    m: 0,
    py: 0,
    height: '30px',
    border: (theme) => `1px solid ${theme.palette.divider}`,
    borderRadius: '4px',
    transition: 'all 0.2s ease-in-out',
    //width: '232px',
    flexShrink: 1,
    background: (theme) => theme.palette.background.paper,
    '& .MuiInput-input': {
      padding: 1,
      fontSize: '12px',
      letterSpacing: '-0.3px',
      color: (theme) => theme.palette.text.primary,
      fontWeight: 500,
      opacity: 1,
      m: 0,
      transition: 'all 0.2s ease-in-out',

      '&::placeholder': {
        textOverflow: 'ellipsis !important',
        color: (theme) => theme.palette.text.primary,
        opacity: 0.7,
        transition: 'all 0.2s ease-in-out',
      },
      '&:focus': {
        opacity: 1,
      },
    },
    '& .tabler-icon': {
      ml: '8px',
      color: (theme) => theme.palette.primary.main,
      opacity: 0.6,
      transition: 'all 0.2s ease-in-out',
    },
    '&.Mui-focused': {
      transition: 'all 0.2s ease-in-out',
      boxShadow: (theme) => theme.shadows[2],
      '& .MuiInput-input': {
        '&::placeholder': {
          opacity: 0.4,
        },
      },
      '& .tabler-icon': {
        opacity: '1 !important',
      },
    },
  },
}

const DatagridToolbarViews = (props) => {
  const { currentUser } = useAuth()
  const [showDisplaySettings, setShowDisplaySettings] = useState(true)
  const lastUserInteraction = useRef(0)
  const [filterPanelOpen, setFilterPanelOpen] = useState(false)

  const hasViews = useMemo(() => {
    if (!currentUser) return true
    return ungatedForViews(currentUser) &&
      props.objectType &&
      Object.values(NativeObjectTypes).includes(props.objectType) &&
      props.workspaceId
      ? true
      : false
  }, [props.objectType, props.workspaceId, currentUser])

  const apiRef = useGridApiContext()

  const [navMenuEl, setNavMenuEl] = useState(null)

  const initialized = useRef(false)

  const rowCount = useMemo(() => {
    return apiRef?.current?.getRowsCount()
  }, [apiRef])

  const navAnchorEl = useRef(null)

  const navWidth = useMemo(() => {
    if (navMenuEl) {
      return navMenuEl.offsetLeft
    } else {
      return 128
    }
  }, [navMenuEl])

  const lastGridChangeAt = useRef(0)

  const handleMouseDown = useCallback(() => {
    lastUserInteraction.current = Date.now()
    logger.dev('🖱️ User interaction detected:', {
      timestamp: lastUserInteraction.current,
    })
  }, [lastUserInteraction])

  useEffect(() => {
    const instrumentContainers = () => {
      const containers = document.querySelectorAll(
        '.MuiDataGrid-columnHeaders, .MuiDataGrid-columnsManagement, .MuiDataGrid-panelWrapper, .filter-tab, .grouping-tab'
      )
      if (containers) {
        for (const container of containers) {
          container.addEventListener('mousedown', handleMouseDown)
        }
      } else {
        logger.dev('⚠️ No containers found to instrument')
      }
      return containers
    }

    setTimeout(() => {
      const containers = instrumentContainers()
      return () => {
        for (const container of containers || []) {
          container.removeEventListener('mousedown', handleMouseDown)
        }
      }
    }, 500)
  }, [filterPanelOpen, handleMouseDown])

  const saveStateToViews = useCallback(() => {
    if (hasViews && lastUserInteraction.current > 0) {
      logger.dev('Saving state to views')
      lastGridChangeAt.current = Date.now()
    }
  }, [hasViews, lastUserInteraction])

  const handleViewsSaveCallback = useCallback(() => {
    lastUserInteraction.current = 0
  }, [lastUserInteraction])

  const handleGroupingModelChange = useCallback(() => {
    saveStateToViews()
  }, [saveStateToViews])

  const handleFilterModelChange = useCallback(() => {
    saveStateToViews()
  }, [saveStateToViews])

  const handleUserStateChange = useCallback(() => {
    saveStateToViews()
  }, [saveStateToViews])

  const handleFilterPanelOpen = useCallback(() => {
    setFilterPanelOpen(true)
  }, [])

  const handleFilterPanelClose = useCallback(() => {
    setFilterPanelOpen(false)
  }, [])

  const intializeStateFromViews = useCallback(() => {
    initialized.current = true
  }, [])

  useEffect(() => {
    const subs: VoidFunction[] = []

    subs.push(
      apiRef.current.subscribeEvent(
        'filterModelChange',
        handleFilterModelChange
      )
    )

    subs.push(
      apiRef.current.subscribeEvent(
        'rowGroupingModelChange',
        handleGroupingModelChange
      )
    )

    subs.push(
      apiRef.current.subscribeEvent(
        'preferencePanelOpen',
        handleFilterPanelOpen
      )
    )

    subs.push(
      apiRef.current.subscribeEvent(
        'preferencePanelClose',
        handleFilterPanelClose
      )
    )

    /*
      Supported Events:

      aggregationModelChange
      Fired when the aggregation model changes.

      columnOrderChange
      Fired when the user ends reordering a column.

      columnVisibilityModelChange
      Fired when the column visibility model changes.

      columnWidthChange
      Fired when the width of a column is changed.

      densityChange
      Fired when the density changes.

      filterModelChange
      Fired when the filter model changes.

      headerSelectionCheckboxChange
      Fired when the value of the selection checkbox of the header is changed.

      rowGroupingModelChange
      Fired when the row grouping model changes.

      sortModelChange
      Fired when the sort model changes.
    */

    const eventsToSubscribe: (keyof GridEventLookup)[] = [
      'columnOrderChange',
      'columnWidthChange',
      'columnVisibilityModelChange',
      'densityChange',
      'sortModelChange',
      'headerSelectionCheckboxChange',
      'aggregationModelChange',
    ]

    eventsToSubscribe.forEach((event) => {
      subs.push(apiRef.current.subscribeEvent(event, handleUserStateChange))
    })

    return () => {
      subs.forEach((unsub) => {
        unsub()
      })
    }
  }, [
    apiRef,
    handleFilterModelChange,
    handleGroupingModelChange,
    handleUserStateChange,
    handleFilterPanelOpen,
    handleFilterPanelClose,
  ])

  const navElement = props.navItems.find((item) => {
    return isValidElement(item)
  })
  const navItems = navElement ? [] : props.navItems

  return (
    <GridToolbarContainer
      className="data-grid-toolbar"
      sx={{
        height: showDisplaySettings ? '116px !important' : '88px !important',
        p: 0,
        m: 0,
        mb: '0px',
        border: 'none',
        borderWidth: '0px',
        display: 'block',
        boxSizing: 'border-box',
        flexShrink: 0,
      }}
    >
      <Row
        sx={{
          width: '100%',
          justifyContent: 'space-between',
          height: '48px !important',
          boxSizing: 'border-box',
          '& .MuiButton-root': {
            m: 0,
            px: 0,
          },
        }}
      >
        <Box>
          <Row
            gap={1}
            sx={{ alignItems: 'flex-start' }}
          >
            {props.objectType && (
              <>
                {React.createElement(
                  ObjectTypeMetadata[props.objectType].icon,
                  { size: 18 }
                )}
              </>
            )}
            <Box>
              <Typography
                sx={{
                  lineHeight: '115%',
                  fontWeight: 600,
                  fontSize: '16px',
                  letterSpacing: '-0.3px',
                }}
              >
                {props.title ||
                  ObjectTypeMetadata?.[props.objectType]?.pluralLabel ||
                  ''}
              </Typography>
              {props.subtitle && (
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: 'normal',
                    letterSpacing: '-0.7px',
                    opacity: 0.6,
                  }}
                >
                  {props.subtitle}
                </Typography>
              )}
            </Box>

            {navItems.length > 0 && (
              <>
                <IconButton
                  ref={navAnchorEl}
                  onClick={() => {
                    setNavMenuEl(navAnchorEl?.current)
                  }}
                  sx={{
                    background: (theme) =>
                      navMenuEl
                        ? theme.palette.background.paper
                        : 'transparent',
                    transition: '0.3s all ease-in-out',
                    borderRadius: '4px',
                    p: '4px',
                    mt: '-2px',
                    boxSizing: 'border-box',
                    borderBottomRightRadius: navMenuEl ? '0px' : '4px',
                    borderBottomLeftRadius: navMenuEl ? '0px' : '4px',
                    boxShadow: navMenuEl
                      ? `
            0 1px 2px rgba(136, 161, 183, 0.07),
            0 2px 4px rgba(136, 161, 183, 0.07),
            0 4px 8px rgba(136, 161, 183, 0.07),
            0 8px 16px rgba(136, 161, 183, 0.07),
            0 16px 32px rgba(136, 161, 183, 0.07),
            0 32px 64px rgba(136, 161, 183, 0.07)
          `
                      : 'none',
                  }}
                >
                  <IconChevronDown
                    stroke={2}
                    style={{
                      transform: navMenuEl ? 'rotate(180deg)' : null,
                      transition: '0.3s all ease-in-out',
                    }}
                    size={16}
                  />
                </IconButton>
                <Menu
                  open={!!navMenuEl}
                  anchorEl={navMenuEl}
                  onClose={() => setNavMenuEl(null)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 2,
                    horizontal: navWidth + 28.02,
                  }}
                  sx={{
                    border: 'none',
                    boxSizing: 'border-box',
                    '& .MuiPaper-root.MuiPopover-paper.MuiMenu-paper': {
                      minWidth: '128px',
                      boxSizing: 'border-box',
                      borderTopRightRadius: '0px !important',
                      overflow: 'visible',
                      '::before': {
                        height: '4px',
                        width: '24px',
                        background: (theme) => theme.palette.background.paper,
                        content: '""',
                        position: 'absolute',
                        top: '-2px',
                        right: '0px',
                        zIndex: 1000000,
                      },
                    },
                  }}
                >
                  {navItems.map((item, index) => (
                    <ListItem
                      key={`dayTable-nav-item-${index}`}
                      sx={{
                        cursor: 'pointer',
                        p: 0,
                        width: `${navWidth + 28}px`,
                      }}
                      onClick={(e) => {
                        e.stopPropagation()
                        if (item.to) {
                          navigate(item.to)
                        } else if (props.onNavEvent && item.callback) {
                          props.onNavEvent(item.callback)
                        }

                        setNavMenuEl(null)
                        return
                      }}
                    >
                      <ListItemButton sx={{ px: '10px' }}>
                        {item.icon && (
                          <ListItemIcon sx={{ minWidth: '14px', mr: '6px' }}>
                            {React.cloneElement(item.icon, { size: 14 })}
                          </ListItemIcon>
                        )}
                        <ListItemText
                          primary={item.label}
                          primaryTypographyProps={{
                            sx: {
                              fontSize: '12px',
                              fontWeight: 500,
                              color: 'text.primary',
                              opacity: 0.8,
                              letterSpacing: '-0.3',
                            },
                          }}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </Menu>
              </>
            )}
          </Row>

          <Typography
            sx={{
              fontSize: '14px',
              color: 'text.primary',
              fontWeight: 500,
              letterSpacing: '-0.3px',
              opacity: 0.7,
              lineHeight: '215%',
              display: 'none',
            }}
          >{`${rowCount} ${props.rowObjectName}`}</Typography>
        </Box>

        <Row
          gap={1}
          sx={{ flexGrow: 1, flexShrink: 0, justifyContent: 'flex-end' }}
        >
          {props.actionButtons.length > 0 && (
            <Row
              gap={1}
              sx={{
                '& .MuiButton-root': {
                  height: '30px !important',
                  borderRadius: '3px !important',
                  flexShrink: 0,
                  fontSize: '11px !important',
                  px: '12px !important',
                  background: (theme) => theme.palette.background.paper,
                  border: (theme) => `1px solid ${theme.palette.divider}`,
                },
              }}
            >
              {props.actionButtons.map((button) => button)}
            </Row>
          )}

          {navElement}
          {props.showQuickFilter && (
            <GridToolbarQuickFilter InputProps={QuickFilterProps} />
          )}
        </Row>
      </Row>
      <ViewsProvider
        objectType={props.objectType}
        workspaceId={props.workspaceId}
        lastGridChangeAt={lastGridChangeAt.current}
        setInitialized={intializeStateFromViews}
        lastUserInteraction={lastUserInteraction.current}
        onSave={handleViewsSaveCallback}
      >
        <ViewManager
          setPanelAnchorEl={props.setPanelAnchorEl}
          workspaceId={props.workspaceId}
          showDisplaySettings={showDisplaySettings}
          setShowDisplaySettings={setShowDisplaySettings}
        />
      </ViewsProvider>
    </GridToolbarContainer>
  )
}

// const isPresentInOperator = {
//   value: 'isPresentIn',
//   getApplyFilterFn: (filterItem) => {
//     if (!filterItem.field || !filterItem.value || !filterItem.operator) {
//       return false
//     }

//     return (params): boolean => {
//       return (
//         params.value &&
//         filterItem.value &&
//         filterItem.value.includes(params.value)
//       )
//     }
//   },
//   InputComponent: null,
// }

export default DatagridToolbarViews
