import { MenuItem, Select, Typography } from '@mui/material'
import type { WorkspaceRole } from 'types/graphql'

export const WorkspaceRoleDropdown = ({
  allRoles,
  currentRole,
  onSelectRole,
  disabled = false,
}: {
  allRoles: WorkspaceRole[]
  currentRole: WorkspaceRole
  onSelectRole: (roleId: string) => void
  disabled?: boolean
}) => {
  return (
    <Select
      variant="outlined"
      size="small"
      value={currentRole.id}
      renderValue={(value) => allRoles.find((r) => r.id === value)?.name}
      disabled={disabled}
      onChange={(event) => {
        onSelectRole(event.target.value)
      }}
      sx={{
        minWidth: '100px',
        '& .MuiSelect-outlined': { py: '6px' },
        '& .MuiSelect-select': { fontSize: '0.7rem' },
      }}
    >
      {allRoles.map((role) => (
        <MenuItem
          sx={{
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
          key={role.id}
          value={role.id}
        >
          <Typography
            sx={{
              fontSize: '0.8rem',
              fontWeight: 500,
            }}
          >
            {role.name}
          </Typography>
          <Typography
            sx={{
              fontSize: '0.7rem',
              color: (theme) => theme.palette.text.secondary,
              maxWidth: '260px',
              textWrap: 'pretty',
            }}
          >
            {role.description}
          </Typography>
        </MenuItem>
      ))}
    </Select>
  )
}

export default WorkspaceRoleDropdown
