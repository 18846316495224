export const DAY_OBJECT_FRAGMENT = gql`
  fragment DayObjectFragment on DayObject {
    workspaceId
    objectId
    objectType
    updatedAt
    properties {
      standard
      custom
      relationships
    }
  }
`
