import { Box, Chip } from '@mui/material'
import { IconCircleFilled, IconPointFilled } from '@tabler/icons-react'
import type { Action } from 'types/graphql'

import { isActionCompleted } from 'src/components/Inbox/inbox'
import { actionPriorityMetadata } from 'src/components/Inbox/InboxContext'

import ActionStatusChip from '../ActionStatusChip/ActionStatusChip'

const ActionPriorityChip = ({
  action,
  variant = 'filled',
}: {
  action: Action
  variant?: 'filled' | 'outlined' | 'icon'
}) => {
  const priority = action.priority
  // const snoozedUntil =
  //   action.timeframe?.reminderDate &&
  //   action.status?.id === 'SNOOZED' &&
  //   dayjs(action.timeframe?.reminderDate).unix() > dayjs().unix()
  //     ? dayjs(action.timeframe?.reminderDate).format('MMM D, YYYY')
  //     : null

  const completed = isActionCompleted(action)

  return (
    priority &&
    (variant === 'icon' ? (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          color: (theme) =>
            theme.palette[actionPriorityMetadata[priority].color].main,
        }}
      >
        <IconPointFilled size={16} />
      </Box>
    ) : !completed ? (
      <>
        <Chip
          label={actionPriorityMetadata[priority].label}
          size="small"
          variant="outlined"
          icon={<IconCircleFilled />}
          sx={{
            width: 'fit-content',
            height: '22px',
            border: 'none',
            justifyContent: 'left',
            '& .MuiChip-icon': {
              width: '8px',
              height: '8px',
              color: (theme) =>
                theme.palette[actionPriorityMetadata[priority].color].main,
            },
          }}
        />
      </>
    ) : (
      <ActionStatusChip status={action.status} />
    ))
  )
}

export default ActionPriorityChip
