import { memo, useCallback, useEffect, useMemo, useRef } from 'react'

import { Box, Typography } from '@mui/material'
import { IconSparkles } from '@tabler/icons-react'
import { EditorContent, useEditor } from '@tiptap/react'
import Ai from '@tiptap-pro/extension-ai-advanced'

import Row from 'src/components/Row/Row'
import { logger } from 'src/lib/logger'

import { extensionsEmbeddedAi } from '../extensions'

const systemPrompt = `<system>
  You are an the world's best personal assistant, brilliantly proficient in effective, direct business communication, focused always on the details that matter in interpersonal relationships.
  You are an expert in using CRM data to connect the dots for your user, and diligently follow their instructions.
  Use only h3 and smaller headings. NEVER return an empty html element with only whitespace or no content.
  Be sure to use the custom <span> tags provided in the context when referencing specific objects, like people and organizations and other objects.
  Do not write or include page titles for your responses.
  Return the raw inner HTML with just your response, no other text, introductory or otherwise, only string that contains one or more HTML tags.
  Do not include line breaks in your response.
  </system>`

const log = false

const logAiEditor = (...args) => {
  if (log) {
    logger.dev('AiEditor', ...args)
  }
}

const thinkingSx = {
  width: '100%',
  pl: '8px',
  pt: '24px',
  height: '42px',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  gap: '4px',
  opacity: 0.7,
  '& .MuiTypography-root': {
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '16px',
    letterSpacing: '-0.4px',
  },
  '& .tabler-icon': {
    animation: 'growShrink 2s infinite',
  },
  '@keyframes growShrink': {
    '0%': {
      transform: 'scale(0.3)',
    },
    '50%': {
      transform: 'scale(1)',
    },
    '100%': {
      transform: 'scale(0.6)',
    },
  },
}

const forceProd = false

const streamUrl =
  process.env.HOST.includes('localhost') && !forceProd
    ? 'https://aa6acxvvavnjstyzypjhvjsxvi0uzcaz.lambda-url.us-east-1.on.aws/'
    : `https://f5gegaoxtd4kuyhc35q62wdyoq0ciwqh.lambda-url.us-east-1.on.aws/`

const AiEditorComponent = ({
  token,
  prompt,
  onSuccess,
  contextString,
}: {
  token: string
  prompt: string
  onSuccess: (result: any) => void
  contextString: string
}) => {
  const memoizedOnSuccess = useCallback(onSuccess, [onSuccess])
  const memoizedPrompt = useMemo(() => prompt, [prompt])
  const memoizedContextString = useMemo(() => contextString, [contextString])
  const memoizedToken = useMemo(() => token, [token])

  const aiInitialized = useRef(false)
  const successCalled = useRef(false)
  const contentPopulated = useRef(false)

  const editor = useEditor({
    extensions: [
      ...extensionsEmbeddedAi,
      Ai.configure({
        appId: process.env.TIPTAP_APP_ID,
        token: memoizedToken,
        autocompletion: true,

        onChunk: (_) => {
          logger.dev('onChunk called', { chunk: _ })
          if (contentPopulated.current) {
            return
          }
          contentPopulated.current = true
        },

        onSuccess: (result) => {
          if (successCalled.current) {
            logger.dev('Success already called, skipping')
            return
          }
          successCalled.current = true
          logger.dev('AiEditor onSuccess called', { result })
          memoizedOnSuccess(result)
        },
        aiStreamResolver: async ({ action, text, textOptions }) => {
          logAiEditor('aiStreamResolver called', { action, text, textOptions })
          const fetchOptions = {
            method: 'POST',
            headers: {
              accept: 'application/json',
              'content-type': 'application/json',
            },
            body: JSON.stringify({
              ...textOptions,
              text,
            }),
          }

          const response = await fetch(streamUrl, fetchOptions)

          return response?.body
        },
      }),
    ],
    autofocus: false,
    editable: false,
  })

  useEffect(() => {
    const initializeAi = async () => {
      if (!editor || !memoizedPrompt) {
        logger.dev('Editor or prompt not found, skipping AI initialization')
        return
      }

      successCalled.current = false

      try {
        logAiEditor('Starting AI population', {
          token: memoizedToken,
          prompt: memoizedPrompt,
          contextString: memoizedContextString,
        })

        editor
          .chain()
          .aiTextPrompt({
            text: `${systemPrompt}\n\n<user-prompt>${memoizedPrompt}</user-prompt>`,
            stream: true,
            insert: true,
            format: 'rich-text',
            context: [
              {
                type: 'text',
                text: `${memoizedContextString}\n\n<user-prompt>${memoizedPrompt}</user-prompt>`,
              },
            ],
          })
          .run()
      } catch (error) {
        logger.error('AI initialization failed:', error)
      }
    }

    if (
      !aiInitialized.current &&
      editor &&
      memoizedPrompt &&
      memoizedToken &&
      memoizedContextString
    ) {
      aiInitialized.current = true
      initializeAi()
    }
  }, [editor, memoizedPrompt, memoizedContextString, memoizedToken])

  useEffect(() => {
    aiInitialized.current = false
    successCalled.current = false
  }, [memoizedPrompt, memoizedToken])

  const containerSx = {
    width: '100%',
  }

  logAiEditor('AiEditorComponent', {
    token: memoizedToken,
    prompt: memoizedPrompt,
    contextString: memoizedContextString,
  })

  return (
    <Box sx={containerSx}>
      {!contentPopulated.current && (
        <Row sx={thinkingSx}>
          <IconSparkles size={16} />
          <Typography>Day.ai is thinking...</Typography>
        </Row>
      )}
      <div className="editor-tiptap-inner">
        <EditorContent editor={editor} />
      </div>
    </Box>
  )
}

const AiEditor = memo(AiEditorComponent, (previous, next) => {
  return (
    previous.token === next.token &&
    previous.prompt === next.prompt &&
    previous.contextString === next.contextString
  )
})

AiEditor.displayName = 'AiEditor'

export default AiEditor
