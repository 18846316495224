import { useCallback, useMemo } from 'react'

import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Tooltip,
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { IconX } from '@tabler/icons-react'
import dayjs from 'dayjs'

import ContactTile from 'src/components/ContactTile/ContactTile'
import PipelineChooser from 'src/components/Pipeline/PipelineChooser/PipelineChooser'
import Row from 'src/components/Row/Row'
import WorkspaceMemberSelect from 'src/components/WorkspaceMemberSelect/WorkspaceMemberSelect'

import type { OpportunityState, OpportunityAction } from './opportunityReducer'

// Styles
const STYLES = {
  hiddenLabel: {
    display: 'none',
  },
  ownerTextField: {
    height: '52px',
    width: '100%',
    '& .MuiInputBase-input': {
      display: 'none !important',
    },
  },
  ownerButton: {
    borderRadius: '2px',
    p: '2px',
  },
  ownerBox: {
    width: '644px',
    flexShrink: 0,
  },
  pipelineBox: {
    mt: 4,
  },
  ownerBox2: {
    mt: 3,
  },
  textField: {
    mt: 3,
  },
  dateRow: {
    mt: 3,
  },
  datePicker: {
    width: '50%',
  },
  revenueField: {
    mx: 1,
  },
  revenueButton: {
    ml: 1,
    width: '50%',
  },
  section: {
    my: 3,
  },
  errorText: {
    color: 'error.contrastText',
    fontSize: '11px',
    backgroundColor: 'error.light',
    px: 1,
    py: '2px',
    borderRadius: '4px',
    borderTopLeftRadius: '0px',
    borderTopRightRadius: '0px',
    mt: '-2px',
    fontWeight: 500,
  },
} as const

interface OpportunityCreateFormProps {
  state: OpportunityState
  dispatch: React.Dispatch<OpportunityAction>
  pipelines: any[]
  loading: boolean
  onSubmit: () => Promise<void>
  onDismissOwner: () => void
}

const OpportunityCreateForm = ({
  state,
  dispatch,
  pipelines,
  loading,
  onSubmit,
  onDismissOwner,
}: OpportunityCreateFormProps) => {
  const handleOwnerSelect = useCallback(
    (selected: any[]) => {
      if (selected.length > 0) {
        dispatch({ type: 'SET_OWNER', payload: selected[0] })
      }
    },
    [dispatch]
  )

  // Memoize validation state
  const formValidation = useMemo(() => {
    const errors: { [key: string]: string } = {}

    if (!state.title?.trim()) {
      errors.title = 'Title is required'
    }

    if (!state.selectedPipeline?.id) {
      errors.pipeline = 'Pipeline is required'
    }

    if (!state.selectedStage?.id) {
      errors.stage = 'Stage is required'
    }

    if (!state.selectedOwner && !state.ownerWasDismissed) {
      errors.owner = 'Owner is required'
    }

    return {
      errors,
      isValid: Object.keys(errors).length === 0,
    }
  }, [
    state.title,
    state.selectedPipeline?.id,
    state.selectedStage?.id,
    state.selectedOwner,
    state.ownerWasDismissed,
  ])

  // Calculate suggested close date based on stage
  const suggestedCloseDate = dayjs().add(60, 'days')

  return (
    <Box sx={STYLES.pipelineBox}>
      <PipelineChooser
        selectedPipeline={state.selectedPipeline}
        onChangePipeline={(pipeline) =>
          dispatch({ type: 'SET_PIPELINE', payload: pipeline })
        }
        selectedStage={state.selectedStage}
        onChangeStage={(stage) =>
          dispatch({ type: 'SET_STAGE', payload: stage })
        }
        pipelineTitles={pipelines}
        pipelines={pipelines}
        loading={
          loading || !state.selectedPipeline?.id || !state.selectedStage?.id
        }
      />
      {formValidation.errors.pipeline && (
        <Box sx={STYLES.errorText}>{formValidation.errors.pipeline}</Box>
      )}
      {formValidation.errors.stage && (
        <Box sx={STYLES.errorText}>{formValidation.errors.stage}</Box>
      )}

      <Box sx={STYLES.ownerBox2}>
        {state.selectedOwner ? (
          <FormControl fullWidth={true}>
            <InputLabel
              sx={STYLES.hiddenLabel}
              id="owner"
            >
              {'Owner'}
            </InputLabel>
            <TextField
              id="owner"
              label="Owner"
              fullWidth={true}
              error={!!formValidation.errors.owner}
              InputProps={{
                sx: STYLES.ownerTextField,
                endAdornment: (
                  <Tooltip title="Remove owner">
                    <IconButton
                      sx={STYLES.ownerButton}
                      onClick={onDismissOwner}
                    >
                      <IconX size={16} />
                    </IconButton>
                  </Tooltip>
                ),
                startAdornment: (
                  <Box sx={STYLES.ownerBox}>
                    <ContactTile
                      showSidebar={false}
                      email={state.selectedOwner.email}
                    />
                  </Box>
                ),
              }}
            />
            {formValidation.errors.owner && (
              <Box sx={STYLES.errorText}>{formValidation.errors.owner}</Box>
            )}
          </FormControl>
        ) : (
          <>
            <WorkspaceMemberSelect
              onSelect={handleOwnerSelect}
              label={'Owner'}
              value={[]}
              exclude={[]}
            />
            {formValidation.errors.owner && (
              <Box sx={STYLES.errorText}>{formValidation.errors.owner}</Box>
            )}
          </>
        )}
      </Box>

      <TextField
        sx={STYLES.textField}
        label="Opportunity Name"
        fullWidth={true}
        value={state.title}
        onChange={(e) =>
          dispatch({ type: 'SET_TITLE', payload: e.target.value })
        }
        error={!!formValidation.errors.title}
        helperText={formValidation.errors.title}
      />

      <Autocomplete
        options={state.selectedPipeline?.opportunityTypes || []}
        value={state.oppType}
        onChange={(_, newValue) =>
          dispatch({
            type: 'SET_OPP_TYPE',
            payload:
              newValue ||
              state.selectedPipeline?.opportunityTypes?.[0] ||
              'New Business',
          })
        }
        freeSolo={true}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={STYLES.textField}
            label="Type"
            fullWidth={true}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              dispatch({
                type: 'SET_OPP_TYPE',
                payload:
                  e.target.value ||
                  state.selectedPipeline?.opportunityTypes?.[0] ||
                  'New Business',
              })
            }
          />
        )}
      />

      <Box sx={STYLES.section}>
        <Row gap={1}>
          <Box sx={{ flex: 1 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Expected Close Date"
                value={dayjs(state.expectedCloseDate || suggestedCloseDate)}
                onChange={(date) =>
                  dispatch({
                    type: 'SET_EXPECTED_CLOSE_DATE',
                    payload: date?.toDate() || new Date(),
                  })
                }
                slotProps={{
                  textField: {
                    fullWidth: true,
                    error: !!formValidation.errors.expectedCloseDate,
                    helperText: formValidation.errors.expectedCloseDate,
                  },
                }}
              />
            </LocalizationProvider>
          </Box>
          {state.hasRevenue && (
            <Box sx={{ flex: 1 }}>
              <TextField
                label="Expected Revenue"
                type="number"
                value={state.expectedRevenue}
                onChange={(e) =>
                  dispatch({
                    type: 'SET_EXPECTED_REVENUE',
                    payload: parseInt(e.target.value, 10) || 0,
                  })
                }
                fullWidth
                error={!!formValidation.errors.expectedRevenue}
                helperText={formValidation.errors.expectedRevenue}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </Box>
          )}
        </Row>
      </Box>
      <Button
        variant="outlined"
        onClick={onSubmit}
        fullWidth={true}
        size="large"
        disabled={!formValidation.isValid || loading}
      >
        {loading ? 'Creating...' : 'Create'}
      </Button>
    </Box>
  )
}

export default OpportunityCreateForm
