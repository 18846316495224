import { Box, Grid, Hidden } from '@mui/material'

import BackToTop from 'src/pages/HomepageSpringLaunch2024Page/components/common/BackToTop'
import Footer from 'src/pages/HomepageSpringLaunch2024Page/components/common/Footer'
import Header from 'src/pages/HomepageSpringLaunch2024Page/components/common/Header'

type PublicSiteLayoutProps = {
  children?: React.ReactNode
}

const PublicSiteLayout = ({ children }: PublicSiteLayoutProps) => {
  return (
    <Box
      sx={{ background: '#FFFFFF', width: '100%', height: '100%', m: 0, p: 0 }}
    >
      <BackToTop />
      <Header />
      <Grid container>
        <Hidden mdDown>
          <Grid
            item
            xs={2}
          />
        </Hidden>
        <Grid
          item
          xs={12}
          sm={12}
          md={8}
          lg={8}
          xl={8}
        >
          {children}
        </Grid>
        <Hidden mdDown>
          <Grid
            item
            xs={2}
          />
        </Hidden>
      </Grid>
      <Footer />
    </Box>
  )
}

export default PublicSiteLayout
