import ContextDebug from 'src/components/ContextDebug/ContextDebug'

import useThread from '../V2/Provider/useThread'

const ThreadContextDebug = ({
  open,
  setOpen,
}: {
  open: boolean
  setOpen: (open: boolean) => void
}) => {
  const { state, fetchContext } = useThread()

  return (
    <ContextDebug
      label="Thread Context"
      contextString={state.contextString}
      tokenCount={state.tokenCount}
      onRefetch={() => fetchContext()}
      tokenLimit={state.tokenLimit}
      open={open}
      setOpen={setOpen}
    />
  )
}

export default ThreadContextDebug
