import { useContext } from 'react'

import { Box, Typography } from '@mui/material'

import { useQuery } from '@redwoodjs/web'

import { DayContext } from 'src/lib/dayContext'

import SidebarActions from '../Sidebar/SidebarActions/SidebarActions'

const GET_ACTIONS_FOR_ORGANIZATION_SIDEBAR = gql`
  query GetActionsForOrganizationSidebar(
    $organizationId: String!
    $workspaceId: String!
  ) {
    actionsForOrganization(
      organizationId: $organizationId
      workspaceId: $workspaceId
    ) {
      id
      workspaceId
      createdAt
      updatedAt
      title
      description
      reasoning
      type
      priority
      people
      organizations
      opportunityIds

      channel {
        id
        label
        type
        accountId
      }
      status {
        id
        label
        updatedAt
      }
      assignedAt
      owner {
        id
        email
      }
      draft {
        title
        body
      }
      source {
        label
        type
        id
      }
      pipelineType
      timeframe {
        dueDate
        reminderDate
        updatedAt
      }
      opportunity
    }
  }
`

const OrganizationActions = ({ orgId }: { orgId: string }) => {
  const { selectedWorkspace } = useContext(DayContext)
  const { data, refetch } = useQuery(GET_ACTIONS_FOR_ORGANIZATION_SIDEBAR, {
    variables: { organizationId: orgId, workspaceId: selectedWorkspace },
    skip: !selectedWorkspace || !orgId,
  })
  const actions = data?.actionsForOrganization || []
  return data && actions.length > 0 ? (
    <Box sx={{ mt: 0 }}>
      <Typography variant="h2">Actions</Typography>
      <SidebarActions
        actions={actions}
        onUpdate={refetch}
      />
    </Box>
  ) : null
}

export default OrganizationActions
