import { useCallback, useState } from 'react'

import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@mui/material'
import {
  IconArrowLeft,
  IconH1,
  IconH2,
  IconH3,
  IconHeading,
  IconLink,
  IconListCheck,
  IconList,
  IconListNumbers,
  IconSeparator,
  IconTable,
  IconUsers,
  IconDots,
  IconCylinder,
} from '@tabler/icons-react'
import { FloatingMenu } from '@tiptap/react'

import Row from 'src/components/Row/Row'
import TiptapBlockBySearch from 'src/components/Tiptap/TiptapBlockBySearch/TiptapBlockBySearch'
import TiptapLinkEditor from 'src/components/Tiptap/TiptapLinkEditor/TiptapLinkEditor'
import TiptapYouTubeAdder from 'src/components/Tiptap/TiptapYouTubeAdder/TiptapYouTubeAdder'

import { tiptapButtonSx, tiptapFloaterSx } from '../styles'

const iconSize = 16

const tooltipDirection = 'top'

const addToCrmTypographyProps = {
  fontSize: '12px',
  fontWeight: 500,
  letterSpacing: '-0.3px',
}

const TiptapFloatingMenu = ({ editor }) => {
  const [focus, setFocus] = useState<
    'link' | 'table' | 'header' | 'block' | 'list' | null
  >(null)

  const handleChooseLink = () => {
    setFocus('link')
  }

  const shouldShow = useCallback(({ state, view }) => {
    const { selection } = state
    const { $anchor } = selection
    const parent = $anchor.parent
    const isEmptyTextBlock =
      (parent.type.name === 'paragraph' || parent.type.name === 'listItem') &&
      parent.content.size === 0
    const hasFocus = view.hasFocus()
    const shouldShow = hasFocus && isEmptyTextBlock

    return shouldShow
  }, [])

  const BackButton = () => (
    <IconButton
      sx={{
        p: 1,
        borderRadius: '0px',
      }}
      onClick={() => setFocus(null)}
    >
      <IconArrowLeft size={iconSize} />
    </IconButton>
  )

  const tippyOptions = {
    duration: 100,
    placement: 'bottom-end' as any,
    zIndex: 10,
  }

  return (
    editor && (
      <FloatingMenu
        className="floating-menu"
        tippyOptions={tippyOptions}
        editor={editor}
        shouldShow={shouldShow}
      >
        {!focus && (
          <List
            disablePadding={true}
            sx={tiptapFloaterSx}
          >
            <ListItem
              divider={true}
              disablePadding={true}
            >
              <Tooltip
                title="Add a heading"
                placement={tooltipDirection}
                arrow={true}
              >
                <Button
                  sx={tiptapButtonSx}
                  size="small"
                  onClick={() => {
                    setFocus('header')
                  }}
                  className={editor.isActive('heading') ? 'is-active' : ''}
                >
                  <IconHeading size={iconSize} />
                </Button>
              </Tooltip>

              <Tooltip
                title="Add a list"
                placement={tooltipDirection}
                arrow={true}
              >
                <Button
                  sx={tiptapButtonSx}
                  size="small"
                  onClick={() => setFocus('list')}
                >
                  <IconList size={iconSize} />
                </Button>
              </Tooltip>

              <Tooltip
                title="Add a table"
                placement={tooltipDirection}
                arrow={true}
              >
                <Button
                  sx={tiptapButtonSx}
                  size="small"
                  onClick={() => {
                    setFocus('table')
                  }}
                >
                  <IconTable size={iconSize} />
                </Button>
              </Tooltip>
              <Tooltip
                title="Insert a horizontal rule"
                placement={tooltipDirection}
                arrow={true}
              >
                <Button
                  sx={tiptapButtonSx}
                  size="small"
                  onClick={() =>
                    editor.chain().focus().setHorizontalRule().run()
                  }
                >
                  <IconSeparator size={iconSize} />
                </Button>
              </Tooltip>

              <Tooltip
                title="Add a link"
                placement={tooltipDirection}
                arrow={true}
              >
                <Button
                  sx={tiptapButtonSx}
                  size="small"
                  onClick={handleChooseLink}
                >
                  <IconLink size={iconSize} />
                </Button>
              </Tooltip>
              <TiptapYouTubeAdder
                buttonSx={tiptapButtonSx}
                iconStyle={{ height: iconSize, width: iconSize }}
                editor={editor}
                tooltipDirection={tooltipDirection}
              />
            </ListItem>
            <ListItem disablePadding={true}>
              <ListItemButton
                className="add-to-crm"
                disableGutters={true}
                onClick={() => setFocus('block')}
              >
                <ListItemIcon>
                  <IconCylinder size={iconSize} />
                </ListItemIcon>
                <ListItemText
                  primary="Add from CRM"
                  primaryTypographyProps={addToCrmTypographyProps}
                />
              </ListItemButton>
            </ListItem>
          </List>
        )}
        {editor.isEditable && focus && (
          <Box sx={tiptapFloaterSx}>
            {focus === 'header' && (
              <Row>
                <BackButton />
                <Tooltip
                  title="Write an H1 heading"
                  placement={tooltipDirection}
                  arrow={true}
                >
                  <Button
                    sx={tiptapButtonSx}
                    size="small"
                    onClick={() => {
                      editor.chain().focus().toggleHeading({ level: 1 }).run()
                    }}
                    className={
                      editor.isActive('heading', { level: 1 })
                        ? 'is-active'
                        : ''
                    }
                  >
                    <IconH1 size={iconSize} />
                  </Button>
                </Tooltip>
                <Tooltip
                  title="Write an H2 heading"
                  placement={tooltipDirection}
                  arrow={true}
                >
                  <Button
                    sx={tiptapButtonSx}
                    size="small"
                    onClick={() =>
                      editor.chain().focus().toggleHeading({ level: 2 }).run()
                    }
                    className={
                      editor.isActive('heading', { level: 2 })
                        ? 'is-active'
                        : ''
                    }
                  >
                    <IconH2 size={iconSize} />
                  </Button>
                </Tooltip>

                <Tooltip
                  title="Write an H3 heading"
                  placement={tooltipDirection}
                  arrow={true}
                >
                  <Button
                    sx={tiptapButtonSx}
                    size="small"
                    onClick={() =>
                      editor.chain().focus().toggleHeading({ level: 3 }).run()
                    }
                    className={
                      editor.isActive('heading', { level: 3 })
                        ? 'is-active'
                        : ''
                    }
                  >
                    <IconH3 size={iconSize} />
                  </Button>
                </Tooltip>
              </Row>
            )}
            {focus === 'list' && (
              <Row>
                <BackButton />
                <Tooltip
                  title="Add a bullet list"
                  placement={tooltipDirection}
                  arrow={true}
                >
                  <Button
                    sx={tiptapButtonSx}
                    size="small"
                    onClick={() =>
                      editor.chain().focus().toggleBulletList().run()
                    }
                    className={editor.isActive('bulletList') ? 'is-active' : ''}
                  >
                    <IconDots size={iconSize} />
                  </Button>
                </Tooltip>
                <Tooltip
                  title="Add a numbered list"
                  placement={tooltipDirection}
                  arrow={true}
                >
                  <Button
                    sx={tiptapButtonSx}
                    size="small"
                    onClick={() =>
                      editor.chain().focus().toggleOrderedList().run()
                    }
                    className={
                      editor.isActive('orderedList') ? 'is-active' : ''
                    }
                  >
                    <IconListNumbers size={iconSize} />
                  </Button>
                </Tooltip>
                <Tooltip
                  title="Add a task list"
                  placement={tooltipDirection}
                  arrow={true}
                >
                  <Button
                    sx={tiptapButtonSx}
                    size="small"
                    onClick={() =>
                      editor.chain().focus().toggleTaskList().run()
                    }
                    className={editor.isActive('taskList') ? 'is-active' : ''}
                  >
                    <IconListCheck size={iconSize} />
                  </Button>
                </Tooltip>
              </Row>
            )}
            {focus === 'link' && (
              <TiptapLinkEditor
                editor={editor}
                setFocus={setFocus}
              />
            )}
            {focus === 'block' && (
              <TiptapBlockBySearch
                open={focus === 'block'}
                onClose={() => setFocus(null)}
                editor={editor}
              />
            )}
            {focus === 'table' && (
              <Row>
                <BackButton />
                <Tooltip
                  title="Add a table"
                  placement={tooltipDirection}
                  arrow={true}
                >
                  <Button
                    sx={tiptapButtonSx}
                    size="small"
                    onClick={() => {
                      editor.commands.insertContent([
                        {
                          type: 'day-ai-table-text',
                          attrs: {
                            class: 'day-ai-table-text',
                          },
                          content: [
                            {
                              type: 'tableRow',
                              content: [
                                {
                                  type: 'tableHeader',
                                  attrs: {
                                    colspan: 1,
                                    rowspan: 1,
                                    colwidth: null,
                                  },
                                  content: [
                                    {
                                      type: 'paragraph',
                                    },
                                  ],
                                },
                                {
                                  type: 'tableHeader',
                                  attrs: {
                                    colspan: 1,
                                    rowspan: 1,
                                    colwidth: null,
                                  },
                                  content: [
                                    {
                                      type: 'paragraph',
                                    },
                                  ],
                                },
                                {
                                  type: 'tableHeader',
                                  attrs: {
                                    colspan: 1,
                                    rowspan: 1,
                                    colwidth: null,
                                  },
                                  content: [
                                    {
                                      type: 'paragraph',
                                    },
                                  ],
                                },
                              ],
                            },
                            {
                              type: 'tableRow',
                              content: [
                                {
                                  type: 'tableCell',
                                  attrs: {
                                    colspan: 1,
                                    rowspan: 1,
                                    colwidth: null,
                                  },
                                  content: [
                                    {
                                      type: 'paragraph',
                                    },
                                  ],
                                },
                                {
                                  type: 'tableCell',
                                  attrs: {
                                    colspan: 1,
                                    rowspan: 1,
                                    colwidth: null,
                                  },
                                  content: [
                                    {
                                      type: 'paragraph',
                                    },
                                  ],
                                },
                                {
                                  type: 'tableCell',
                                  attrs: {
                                    colspan: 1,
                                    rowspan: 1,
                                    colwidth: null,
                                  },
                                  content: [
                                    {
                                      type: 'paragraph',
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: 'paragraph',
                        },
                      ])
                      editor.commands.focus()
                    }}
                  >
                    <IconTable size={iconSize} />
                  </Button>
                </Tooltip>
                <Tooltip
                  title="Add a Person table"
                  placement={tooltipDirection}
                  arrow={true}
                >
                  <Button
                    sx={tiptapButtonSx}
                    size="small"
                    onClick={() => {
                      editor.commands.insertContent([
                        {
                          type: 'persontable',
                          attrs: {
                            stateJsonString: JSON.stringify({ init: true }),
                          },
                        },
                        {
                          type: 'paragraph',
                        },
                      ])
                      editor.commands.focus()
                    }}
                  >
                    <IconUsers size={iconSize} />
                  </Button>
                </Tooltip>
              </Row>
            )}
          </Box>
        )}
      </FloatingMenu>
    )
  )
}

export default TiptapFloatingMenu
