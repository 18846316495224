import { useContext } from 'react'

import { Box, Typography } from '@mui/material'

import { useQuery } from '@redwoodjs/web'

import { DayContext } from 'src/lib/dayContext'
import { ACTION_FIELDS_FRAGMENT } from 'src/lib/fragments'
import { logger } from 'src/lib/logger'

import SidebarActions from '../../Sidebar/SidebarActions/SidebarActions'

const GET_ACTIONS_FOR_ORGANIZATION_SIDEBAR = gql`
  ${ACTION_FIELDS_FRAGMENT}
  query GetActionsForOpportunitySidebar(
    $opportunityId: String!
    $workspaceId: String!
  ) {
    actionsForOpportunity(
      opportunityId: $opportunityId
      workspaceId: $workspaceId
    ) {
      ...ActionFields
      description
      opportunity
    }
  }
`

const OpportunityActions = ({ opportunityId }: { opportunityId: string }) => {
  const { selectedWorkspace } = useContext(DayContext)
  const { data, refetch } = useQuery(GET_ACTIONS_FOR_ORGANIZATION_SIDEBAR, {
    variables: {
      opportunityId,
      workspaceId: selectedWorkspace,
    },
    skip: !selectedWorkspace || !opportunityId,
  })

  const finalActions = data?.actionsForOpportunity

  logger.dev({ finalActions })

  return Array.isArray(finalActions) && finalActions?.length > 0 ? (
    <Box sx={{ mt: 0 }}>
      <Typography variant="h2">Actions</Typography>
      <SidebarActions
        actions={finalActions}
        onUpdate={refetch}
        opportunityId={opportunityId}
      />
    </Box>
  ) : null
}

export default OpportunityActions
