export const PAGE_FIELDS_FRAGMENT = gql`
  fragment PageFields on Page {
    id
    title
    contentJson
    contentHtml
    ownerEmail
    createdAt
    updatedAt
    shortLinkHash
    madeExternalAt
    workspaceId
    emoji
    headerImage
    publishedForUserAt
    templateType
    isKnowledge
    parentObject {
      objectId
      objectType
    }
    crmObjects {
      objectId
      objectType
      properties
      workspaceId
    }
    aiInitialPrompt
    aiPopulationCompletedAt
    templateType
    isKnowledge
    sourceTemplate {
      id
      templateType
    }
  }
`
