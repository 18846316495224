import { DAY_OBJECT_FRAGMENT } from './fragments'

export const GET_WORKSPACE_SCHEMA = gql`
  query GetWorkspaceSchema(
    $workspaceId: String!
    $objectType: String!
    $purpose: String!
  ) {
    workspaceSchemaForPrompt(
      workspaceId: $workspaceId
      objectType: $objectType
      purpose: $purpose
    ) {
      systemPrompt
      userPrompt
    }
  }
`

export const GET_WORKSPACE_OBJECTS_IDS = gql`
  query GetWorkspaceObjectsIds(
    $workspaceId: String!
    $objectType: String!
    $offset: Int
    $limit: Int
  ) {
    workspaceObjectsIds(
      workspaceId: $workspaceId
      objectType: $objectType
      offset: $offset
      limit: $limit
    ) {
      objectId
      objectType
      updatedAt
    }
  }
`

export const GET_WORKSPACE_OBJECTS_BY_IDS = gql`
  query GetWorkspaceObjectsByIds(
    $workspaceId: String!
    $objectType: String!
    $objectIds: [String!]!
  ) {
    workspaceObjectsByIds(
      workspaceId: $workspaceId
      objectType: $objectType
      objectIds: $objectIds
    ) {
      ...DayObjectFragment
    }
  }
  ${DAY_OBJECT_FRAGMENT}
`

export const GET_WORKSPACE_OBJECTS_UPDATED_SINCE = gql`
  query GetWorkspaceObjectsUpdatedSince(
    $workspaceId: String!
    $objectType: String!
    $updatedSince: DateTime!
  ) {
    workspaceObjectsUpdatedSince(
      workspaceId: $workspaceId
      objectType: $objectType
      updatedSince: $updatedSince
    ) {
      ...DayObjectFragment
    }
  }
  ${DAY_OBJECT_FRAGMENT}
`

export const GET_OBJECT_PROPERTIES_REQUESTED_PROMPT = gql`
  query GetObjectPropertiesRequestedPrompt(
    $workspaceId: String!
    $objectType: String!
    $contextString: String!
    $objectId: String
    $targetProperties: [String!]
  ) {
    objectPropertiesRequestedPrompt(
      workspaceId: $workspaceId
      objectType: $objectType
      contextString: $contextString
      objectId: $objectId
      targetProperties: $targetProperties
    )
  }
`

export const GET_CHIP_METADATA = gql`
  query ChipMetadata(
    $objectId: String!
    $objectType: String!
    $workspaceId: String!
  ) {
    chipMetadata(
      objectId: $objectId
      objectType: $objectType
      workspaceId: $workspaceId
    ) {
      label
      icon
      domain
      avatarUrl
      description
    }
  }
`

export const GET_CONTACT_BY_EMAIL_FOR_CHIP = gql`
  query GetContactByEmailForChip($contactEmail: String!, $ownerEmail: String!) {
    getContactByEmail(contactEmail: $contactEmail, ownerEmail: $ownerEmail) {
      objectId
      objectType
      properties
    }
  }
`
