import { gql } from '@apollo/client'

export const CREATE_THREAD = gql`
  mutation CreateThread(
    $workspaceId: String!
    $contextObjects: [CRMObjectInput!]!
    $dismissedObjects: [CRMObjectInput!]!
    $sourceObject: CRMObjectInput
    $title: String
    $userPrompt: String
  ) {
    createThread(
      workspaceId: $workspaceId
      contextObjects: $contextObjects
      dismissedObjects: $dismissedObjects
      sourceObject: $sourceObject
      title: $title
      userPrompt: $userPrompt
    ) {
      id
      workspaceId
      title
      avatarId
      avatarType
      contextObjects {
        objectId
        objectType
      }
      messages {
        id
        content
        type
        userId
        createdAt
        pages {
          id
          title
        }
        links {
          href
          text
        }
      }
    }
  }
`

export const ADD_MESSAGE_TO_THREAD = gql`
  mutation AddMessageToThread(
    $threadId: String!
    $workspaceId: String!
    $content: JSON!
    $userId: String
    $type: String!
  ) {
    addMessageToThread(
      threadId: $threadId
      workspaceId: $workspaceId
      content: $content
      userId: $userId
      type: $type
    ) {
      id
    }
  }
`

export const UPDATE_CONTEXT_OBJECTS = gql`
  mutation UpdateContextObjects(
    $threadId: String!
    $workspaceId: String!
    $contextObjects: [CRMObjectInput!]!
    $dismissedObjects: [CRMObjectInput!]
  ) {
    updateContextObjects(
      threadId: $threadId
      workspaceId: $workspaceId
      contextObjects: $contextObjects
      dismissedObjects: $dismissedObjects
    ) {
      id
    }
  }
`

export const GENERATE_ARTIFACT_AND_ADD_TO_THREAD = gql`
  mutation GenerateArtifactAndAddToThread(
    $threadMessageId: String!
    $workspaceId: String!
  ) {
    generateArtifactAndAddToThread(
      threadMessageId: $threadMessageId
      workspaceId: $workspaceId
    ) {
      id
      content
      type
      createdAt
      pages {
        id
        title
        contentHtml
        contentJson
        createdAt
        updatedAt
        ownerEmail
        crmObjects {
          objectId
          objectType
        }
        actionIds
        templateType
      }
      links {
        href
        text
      }
      userId
    }
  }
`

export const TITLE_THREAD = gql`
  mutation TitleThread(
    $threadId: String!
    $workspaceId: String!
    $title: String
  ) {
    titleThread(threadId: $threadId, workspaceId: $workspaceId, title: $title) {
      id
      title
    }
  }
`

export const CREATE_THREAD_FROM_BUTTON = gql`
  mutation CreateThreadFromButton(
    $workspaceId: String!
    $sourceObject: CRMObjectInput!
    $otherObjects: [CRMObjectInput!]!
    $title: String
  ) {
    createThread(
      workspaceId: $workspaceId
      sourceObject: $sourceObject
      contextObjects: $otherObjects
      title: $title
    ) {
      id
    }
  }
`
