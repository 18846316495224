import { parsePhoneNumber } from 'awesome-phonenumber'

import { logger } from 'src/lib/logger'
import { PropertyTypes } from 'src/lib/objects'

export function hasOptions(propertyType: string) {
  return (
    propertyType === PropertyTypes.Picklist ||
    propertyType === PropertyTypes.Combobox ||
    propertyType === PropertyTypes.MultiPicklist
  )
}

export interface PhoneNumberInfo {
  formatted: string
  e164: string | null
  valid: boolean
  regionCode: string | null
}

/**
 * Parses and formats a phone number, with fallback handling for partial/invalid numbers
 */
export const parseAndFormatPhoneNumber = (input: string): PhoneNumberInfo => {
  try {
    const phoneNumber = parsePhoneNumber(input)

    if (phoneNumber.valid) {
      return {
        formatted: phoneNumber.number.international,
        e164: phoneNumber.number.e164,
        valid: true,
        regionCode: phoneNumber.regionCode,
      }
    }

    return {
      formatted: input,
      e164: null,
      valid: false,
      regionCode: null,
    }
  } catch (error) {
    logger.warn('Phone number parse error:', { input, error })
    return {
      formatted: input,
      e164: null,
      valid: false,
      regionCode: null,
    }
  }
}

/**
 * Formats a phone number for display, with fallback to original input
 */
export const formatPhoneNumberForDisplay = (input: string): string => {
  const { formatted } = parseAndFormatPhoneNumber(input)
  return formatted
}

/**
 * Gets the E.164 format for storage, returns null if invalid
 */
export const getE164PhoneNumber = (input: string): string | null => {
  const { e164 } = parseAndFormatPhoneNumber(input)
  return e164
}

/**
 * Validates if a phone number is valid
 */
export const isValidPhoneNumber = (input: string): boolean => {
  const { valid } = parseAndFormatPhoneNumber(input)
  return valid
}
