import { useContext, useState } from 'react'

import { Box, List, Typography } from '@mui/material'

import { useQuery } from '@redwoodjs/web'

import { DayContext } from 'src/lib/dayContext'
import { dayjs } from 'src/lib/dayjs'
import { logger } from 'src/lib/logger'
import { NativeObjectTypes } from 'src/lib/objects'

import ContactAvatar from '../../ContactAvatar/ContactAvatar'
import Row from '../../Row/Row'

const GET_NOTES_FOR_OPPORTUNITY = gql`
  query GetNotesForOpportunity($opportunityId: String!, $workspaceId: String!) {
    getNotesForOpportunity(
      opportunityId: $opportunityId
      workspaceId: $workspaceId
    ) {
      id
      plainTextValue
      userId
      createdAt
      updatedAt
      parentReferenceKey
      referencedObjectIds
      summary
      meta_sourceUserId
      meta_sourceDescription
    }
  }
`

function getWorkspaceMemberFromId(userId: string, workspaces: any[]) {
  for (const workspace of workspaces) {
    for (const member of workspace.members) {
      if (member.id === userId) {
        return member
      }
    }
  }
}

function parseReferenceKey(key: string) {
  const [type, id, path] = key.split(' : ')
  return { type, id, path }
}

const Note = ({ note, creator }) => {
  const [expanded] = useState(false)
  const { setSidebarObject } = useContext(DayContext)

  const canExpand = note.plainTextValue.length > 100
  const parent = note.parentReferenceKey
    ? parseReferenceKey(note.parentReferenceKey)
    : null

  let chip
  switch (parent.type) {
    case NativeObjectTypes.Opportunity:
      chip = `${
        creator?.coreContact?.firstName || creator?.email
      } added a note to this opportunity`
      break
    case NativeObjectTypes.Person:
      chip = `${
        creator?.coreContact?.firstName || creator?.email
      } added a note to ${parent.id}`
      break
    case NativeObjectTypes.Organization:
      chip = `${
        creator?.coreContact?.firstName || creator?.email
      } added a note to ${parent.id}`
      break
  }

  return (
    <Row
      key={note.id}
      sx={{
        px: 0,
        pt: 3,
        justifyContent: 'space-between',
        alignItems: 'top',
        cursor: 'pointer',
      }}
      onClick={() => {
        setSidebarObject({
          objectId: note.id,
          objectType: NativeObjectTypes.UserContext,
          workspaceId: note.workspaceId,
          properties: {
            ...note,
          },
        })
      }}
    >
      <Row
        gap={2}
        sx={{ alignItems: 'top' }}
      >
        <Box sx={{}}>
          <ContactAvatar
            size={32}
            email={creator?.email}
          />
        </Box>
        <Box>
          <Row sx={{ alignItems: 'top' }}>
            <Typography
              sx={{
                width: '100%',
                fontWeight: 600,
                fontSize: '11px',
                letterSpacing: '-0.17px',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              {`On ${dayjs(note.createdAt).format('MMMM D, YYYY')} ${chip}`}
            </Typography>
          </Row>

          <Typography
            variant="body1"
            sx={{
              height: canExpand && expanded ? 'auto' : '24px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              width: '572px',
            }}
          >
            {note.plainTextValue}
          </Typography>
        </Box>
      </Row>
    </Row>
  )
}

const OpportunityNotes = ({ opportunity }) => {
  const { selectedWorkspace, workspaces } = useContext(DayContext)

  const { data: notesData } = useQuery(GET_NOTES_FOR_OPPORTUNITY, {
    variables: {
      opportunityId: opportunity?.id,
      workspaceId: selectedWorkspace,
    },
    skip: !selectedWorkspace,
    onCompleted: (data) => {
      logger.dev({ data })
    },
  })

  const notes =
    [...(notesData?.getNotesForOpportunity || [])]
      .sort((a, b) => dayjs(b.createdAt).diff(dayjs(a.createdAt)))
      .filter(Boolean) || []

  return notes?.length > 0 ? (
    <List>
      {notes?.map((note) => (
        <Note
          key={note.id}
          note={{ ...note, title: opportunity?.title }}
          creator={getWorkspaceMemberFromId(note.userId, workspaces)}
        />
      ))}
    </List>
  ) : null
}

export default OpportunityNotes
