import { darken, lighten } from '@mui/material'

export type Opportunity = {
  id: string
  title: string
  type: string
  ownerEmail: string
  ownerId: string
  expectedCloseDate: Date
  primaryPerson: any
  createdAt: Date
  updatedAt: Date
  currentStatus: string
  expectedRevenue: number
  domain: string
  organization: any
  stageId: string
  position: number
  workspaceId: string
  notes: any[]
}

export interface OpportunityUpsertInput {
  workspaceId: string
  stageId: string
  position: number
  title: string
  type: string
  ownerId: string
  ownerEmail: string
  expectedCloseDate: Date
  primaryPerson: string
  currentStatus: string
  expectedRevenue: number
  domain: string
}

// interface StageUpsertInput {
//   title: string
//   pipelineId: string
//   position: number
//   entranceCriteria: string[]
//   likelihoodToClose: number
// }

// interface PipelineUpdateInput {
//   title?: string
// }

type PipelineViewType = 'board' | 'table' | 'list' | 'forecast' | 'actions'

export const toggleIconStyle = { height: '18px', width: '18px', flexShrink: 0 }
const stageColors = {
  0: '#4CAF50',
  1: '#8BC34A',
  2: '#FF5722',
  3: '#E91E63',
  4: '#9C27B0',
  5: '#673AB7',
  6: '#3F51B5',
  7: '#2196F3',
  8: '#03A9F4',
  9: '#00BCD4',
  10: '#009688',
}

const suggestedStageColors = [
  '#4CAF50',
  '#8BC34A',
  '#FF5722',
  '#E91E63',
  '#9C27B0',
  '#673AB7',
  '#3F51B5',
  '#2196F3',
  '#03A9F4',
  '#00BCD4',
  '#009688',
]

export const getStageColor = (stagePosition, suggested = false) => {
  return suggested
    ? suggestedStageColors[stagePosition % 10]
    : stageColors[stagePosition % 10]
}

export const getStageStyle = (stagePosition, suggested = false) => {
  const color = suggested
    ? suggestedStageColors[stagePosition % 10]
    : stageColors[stagePosition % 10]
  return {
    color: darken(color, 0.3),
    textAlign: 'left',
    justifyContent: 'left',
    border: `1px solid ${lighten(color, 0.7)}`,
    background: lighten(color, 0.92),
    fontWeight: 500,
    py: '4px',
    width: '100%',
    cursor: 'pointer',
    '&:hover': {
      background: lighten(color, 0.7),
      border: `1px solid ${lighten(color, 0.3)}`,
    },
  }
}

export interface PipelineFilters {
  id?: string
  view?: PipelineViewType
  forecastTimePeriod?: 'month' | 'quarter' | 'year'
  owner?: string
  oppType?: string
  table?: {
    pinnedColumns?: string[]
    columns?: {
      visibility?: string[]
      orderedFields?: string[]
    }
    sorting?: {
      field?: string
      direction?: 'asc' | 'desc'
    }
    filters?: any
  }
}

export const pipelineFilterWidths = {
  xs: '64px',
  sm: '96px',
  md: '128px',
  lg: '196px',
  xl: '256px',
}

export const shouldShowOpp = (opportunity, filters) => {
  let shouldShow = true

  if (
    !(
      !filters?.owner ||
      filters?.owner === 'allOwners' ||
      opportunity?.ownerEmail === filters.owner
    )
  ) {
    shouldShow = false
  }

  if (
    !(
      !filters?.oppType ||
      filters?.oppType === 'allOppTypes' ||
      opportunity?.type === filters.oppType
    )
  ) {
    shouldShow = false
  }

  return shouldShow
}
