import type { DayObjectSearchEntry } from 'types/graphql'
import type { DayObject } from 'types/graphql'

import { parseAndFormatPhoneNumber } from 'src/components/Properties/formattingAndValidation'

import { dayjs } from '../dayjs'
import { logger } from '../logger'
import {
  buildObjectReference,
  getMetadataFromRelationshipReference,
} from '../objects'
import {
  NativeObjectTypes,
  ObjectReadPolicy,
  ObjectUserIdPolicy,
  PropertySources,
  PropertyTypes,
  type NativeObjectType,
  type OrganizationObjectRootProperties,
} from '../objects'
import { StandardProperties } from '../Properties/properties'

const NativeObjectTypesReadPolicies = {
  [NativeObjectTypes.Person]: ObjectReadPolicy.SOURCE,
  [NativeObjectTypes.Organization]: ObjectReadPolicy.SOURCE,
  [NativeObjectTypes.Opportunity]: ObjectReadPolicy.SOURCE,
  [NativeObjectTypes.Pipeline]: ObjectReadPolicy.RECENCY,
  [NativeObjectTypes.Stage]: ObjectReadPolicy.RECENCY,
  [NativeObjectTypes.MeetingRecording]: ObjectReadPolicy.RECENCY,
  [NativeObjectTypes.MeetingRecordingClip]: ObjectReadPolicy.RECENCY,
  [NativeObjectTypes.Page]: ObjectReadPolicy.RECENCY,
  [NativeObjectTypes.Action]: ObjectReadPolicy.RECENCY,
  [NativeObjectTypes.GmailThread]: ObjectReadPolicy.RECENCY,
  [NativeObjectTypes.Workspace]: ObjectReadPolicy.RECENCY,
  [NativeObjectTypes.View]: ObjectReadPolicy.RECENCY,
}

const NativeObjectTypesUserIdPolicies = {
  [NativeObjectTypes.Person]: ObjectUserIdPolicy.FAVOR,
  [NativeObjectTypes.Organization]: ObjectUserIdPolicy.EMPTY,
  [NativeObjectTypes.Opportunity]: ObjectUserIdPolicy.EMPTY,
  [NativeObjectTypes.Pipeline]: ObjectUserIdPolicy.EMPTY,
  [NativeObjectTypes.Stage]: ObjectUserIdPolicy.EMPTY,
  [NativeObjectTypes.MeetingRecording]: ObjectUserIdPolicy.EMPTY,
  [NativeObjectTypes.Action]: ObjectUserIdPolicy.ALL,
  [NativeObjectTypes.WebPage]: ObjectUserIdPolicy.EMPTY,
}

export const NativeObjectGlobalWorkspacePolicies = {
  [NativeObjectTypes.Organization]: true,
  [NativeObjectTypes.Person]: true,
  [NativeObjectTypes.Opportunity]: false,
  [NativeObjectTypes.Pipeline]: false,
  [NativeObjectTypes.Stage]: false,
  [NativeObjectTypes.MeetingRecording]: false,
  [NativeObjectTypes.Action]: false,
  [NativeObjectTypes.WebPage]: false,
}

interface RelationshipEntry {
  key: string
  value: boolean
  updatedAt: number
  manual: boolean
  userId: string
}

const renderEmail = (value: string) => {
  try {
    const cleanString = value.trim().toLowerCase()
    if (cleanString.includes('@')) {
      return cleanString
    }
    return null
  } catch (error) {
    return null
  }
}

export const renderValue = (value: any, propertyType: string) => {
  switch (propertyType) {
    case PropertyTypes.Integer:
      try {
        return parseInt(value.toString(), 10)
      } catch (error) {
        logger.warn(`Error parsing integer value: ${value}`, { error })
        return null
      }
    case PropertyTypes.Float:
      try {
        return parseFloat(value.toString())
      } catch (error) {
        return null
      }
    case PropertyTypes.Currency:
      try {
        return parseFloat(value.toString())
      } catch (error) {
        return null
      }
    case PropertyTypes.Percent:
      try {
        return parseFloat(value.toString())
      } catch (error) {
        return null
      }
    case PropertyTypes.DateTime:
      try {
        return new Date(value)
      } catch (error) {
        return null
      }
    case PropertyTypes.Url:
      try {
        return new URL(value.toString())
      } catch (error) {
        return null
      }
    case PropertyTypes.Email:
      try {
        return renderEmail(value.toString())
      } catch (error) {
        return null
      }
    case PropertyTypes.Phone:
      try {
        return parseAndFormatPhoneNumber(value.toString()).e164
      } catch (error) {
        return null
      }
    case PropertyTypes.Boolean:
      try {
        return value.toString() === 'true'
      } catch (error) {
        return null
      }

    default:
      return value.toString()
  }
}

const buildCustomProperties = (
  customRows: any[],
  propertyDefinitions: any[]
) => {
  if (!propertyDefinitions || !customRows) {
    return {}
  }
  const userValueMap = {}
  const aiValueMap = {}
  const webValueMap = {}
  const allKeys = new Set<string>()
  const definitionsWithOptions = new Set<string>()
  const propertyDefinitionsByIds = {}
  for (const row of [...propertyDefinitions]) {
    propertyDefinitionsByIds[row.id] = row
  }
  for (const row of [...customRows].sort((a, b) => {
    return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
  })) {
    const [propertyDefinitionId, optionId] = row.name.split('/').slice(1)

    if (!propertyDefinitionId) {
      continue
    }

    // TODO: Redo this once we have a better way to handle reasoning
    const reasoning = row.meta_sourceDescription
    const updatedAt = new Date(row.updatedAt).getTime()

    allKeys.add(propertyDefinitionId)
    if (optionId) {
      definitionsWithOptions.add(propertyDefinitionId)
      if (!userValueMap[propertyDefinitionId]) {
        userValueMap[propertyDefinitionId] = {}
      }
      if (!aiValueMap[propertyDefinitionId]) {
        aiValueMap[propertyDefinitionId] = {}
      }
      if (!webValueMap[propertyDefinitionId]) {
        webValueMap[propertyDefinitionId] = {}
      }
      if (row.propertySourceId === PropertySources.AIGenerated) {
        if (aiValueMap[propertyDefinitionId][optionId] === undefined) {
          aiValueMap[propertyDefinitionId][optionId] = {
            id: optionId,
            value: row.value === 'true',
            reasoning,
            updatedAt,
            propertySourceId: row.propertySourceId,
          }
        }
      } else if (row.propertySourceId === PropertySources.ParallelAi) {
        if (webValueMap[propertyDefinitionId][optionId] === undefined) {
          webValueMap[propertyDefinitionId][optionId] = {
            id: optionId,
            value: row.value === 'true',
            reasoning,
            updatedAt,
            propertySourceId: row.propertySourceId,
          }
        }
      } else {
        if (userValueMap[propertyDefinitionId][optionId] === undefined) {
          userValueMap[propertyDefinitionId][optionId] = {
            id: optionId,
            value: row.value === 'true',
            reasoning: null,
            updatedAt,
            propertySourceId: row.propertySourceId,
          }
        }
      }
    } else {
      const propertyDefinition = propertyDefinitionsByIds[propertyDefinitionId]
      const propertyType = propertyDefinition?.propertyType?.id
      const value = renderValue(row.value, propertyType)
      if (value != null) {
        if (row.propertySourceId === PropertySources.AIGenerated) {
          if (aiValueMap[propertyDefinitionId] === undefined) {
            aiValueMap[propertyDefinitionId] = { value, reasoning, updatedAt }
          }
        } else if (row.propertySourceId === PropertySources.ParallelAi) {
          if (webValueMap[propertyDefinitionId] === undefined) {
            webValueMap[propertyDefinitionId] = { value, reasoning, updatedAt }
          }
        } else {
          if (userValueMap[propertyDefinitionId] === undefined) {
            userValueMap[propertyDefinitionId] = {
              value,
              reasoning: null,
              updatedAt,
            }
          }
        }
      }
    }
  }

  const customFieldOutput = {}

  for (const key of Array.from(allKeys)) {
    if (definitionsWithOptions.has(key)) {
      const allowMultipleValues =
        propertyDefinitionsByIds[key]?.propertyType?.id ===
        PropertyTypes.MultiPicklist

      const allOptionsIds = Array.from(
        new Set([
          ...Object.keys(userValueMap[key] || {}),
          ...Object.keys(aiValueMap[key] || {}),
          ...Object.keys(webValueMap[key] || {}),
        ])
      )
      let foundValue = false
      for (const optionId of allOptionsIds) {
        if (!customFieldOutput[key]) {
          customFieldOutput[key] = []
        }
        const userValue = userValueMap[key]?.[optionId]
        const aiValue = aiValueMap[key]?.[optionId]
        const webValue = webValueMap[key]?.[optionId]

        if (allowMultipleValues || !foundValue) {
          if (typeof userValue?.value === 'boolean') {
            customFieldOutput[key].push(userValue)
            foundValue = true
          } else if (typeof aiValue?.value === 'boolean') {
            customFieldOutput[key].push(aiValue)
            foundValue = true
          } else if (typeof webValue?.value === 'boolean') {
            customFieldOutput[key].push(webValue)
            foundValue = true
          }
        }
      }
    } else {
      if (propertyDefinitions?.length > 0) {
        const userValue = userValueMap[key]
        const aiValue = aiValueMap[key]
        const webValue = webValueMap[key]
        if (userValue) {
          customFieldOutput[key] = userValue
        } else if (userValue?.value !== false) {
          if (aiValue) {
            customFieldOutput[key] = aiValue
          } else if (webValue && aiValue?.value !== false) {
            customFieldOutput[key] = webValue
          }
        }
      }
    }
  }

  return customFieldOutput
}

const buildRelationships = (relationshipRows: any[]) => {
  if (relationshipRows.length === 0) {
    return []
  }
  const prioritizedMap: { [key: string]: RelationshipEntry } = {}
  for (const r of relationshipRows) {
    //logger.info(JSON.stringify({ r }, null, 2))
    if (r.name && r.objectId) {
      const metadata = getMetadataFromRelationshipReference(r.name)
      const entry: RelationshipEntry = {
        ...metadata,
        key: r.name,
        value: r.value === 'true',
        updatedAt: new Date(r.updatedAt).getTime(),
        manual: [PropertySources.ManualEntry, PropertySources.Import].includes(
          r.propertySourceId
        ),
        userId: r.meta_sourceUserId,
      }
      if (!prioritizedMap[r.name]) {
        prioritizedMap[r.name] = entry
      } else {
        if (
          entry.updatedAt > prioritizedMap[r.name].updatedAt &&
          entry.manual
        ) {
          prioritizedMap[r.name] = entry
        }
      }
    }
  }
  const finalRelationships = Object.values(prioritizedMap).filter((r) => r.key)
  return finalRelationships
}

const _buildPropertyRowsById = (rows: any[]) => {
  const propRowsById = {}
  for (const row of rows) {
    if (!propRowsById[row.objectId]) {
      propRowsById[row.objectId] = []
    }
    propRowsById[row.objectId].push(row)
  }
  return propRowsById
}

export const searchMetadataBuilders = {
  [NativeObjectTypes.Organization]: (obj: DayObject) => {
    if (!obj || !obj.properties) {
      logger.warn('Missing properties for organization object', {
        objectId: obj?.objectId,
      })
      return {
        label: obj?.objectId,
        description: '',
        photoUrl: null,
        searchText: '',
      }
    }

    const properties = (obj?.properties?.standard as any) || {}
    const name =
      properties?.name?.value || properties?.domain?.value || obj.objectId
    const description =
      properties?.aiDescription?.value || properties?.description?.value || ''
    const photoUrl = properties?.photoSquare?.value || null
    const domain = properties?.domain?.value || ''

    const searchMetadata = {
      label: name,
      description,
      photoUrl,
      searchText: [domain, name, description].filter(Boolean).join(' '),
    }

    return searchMetadata
  },

  [NativeObjectTypes.Person]: (obj: DayObject) => {
    if (!obj || !obj.properties) {
      logger.warn('Missing properties for person object', {
        objectId: obj?.objectId,
      })
      return {
        label: obj?.objectId,
        description: '',
        photoUrl: null,
        searchText: '',
      }
    }

    const properties = (obj?.properties?.standard as any) || {}

    // Concatenate first and last name for the label
    const firstName = properties?.firstName?.value || ''
    const lastName = properties?.lastName?.value || ''
    const email = properties?.email?.value || ''
    const name =
      [firstName, lastName].filter(Boolean).join(' ') ||
      properties?.email?.value

    // Use currentJobTitle as description, falling back to description and bio
    const showEmail = firstName && lastName
    const showSeparator =
      showEmail &&
      (properties?.currentJobTitle?.value ||
        properties?.description?.value ||
        properties?.bio?.value)
    const description =
      `${showEmail ? `${email}` : ''}${showSeparator ? ' - ' : ''}${properties?.currentJobTitle?.value || ''} ${properties?.description?.value || ''} ${properties?.bio?.value || ''}`.trim()

    // Use photoUrl for the photo
    const photoUrl = properties?.photoUrl?.value || null

    // Email and phone for additional search context
    const phone = properties?.phone?.value || ''

    const searchMetadata = {
      label: name,
      description,
      photoUrl,
      searchText: [name, description, email, phone].filter(Boolean).join(' '),
    }

    return searchMetadata
  },

  [NativeObjectTypes.Opportunity]: (obj: DayObject) => {
    if (!obj || !obj.properties) {
      logger.warn('Missing properties for opportunity object', {
        objectId: obj?.objectId,
      })
      return {
        label: 'Opportunity',
        description: '',
        photoUrl: null,
        searchText: '',
      }
    }

    const properties = (obj?.properties?.standard as any) || {}

    // Use name as the label
    const name = properties?.title?.value || 'Opportunity'

    // Use description for the description
    const currentStatus = properties?.currentSituation?.value?.[0] || ''

    // Amount and stage for additional context
    const amount = properties?.annualRevenue?.value || ''
    const expectedCloseDate = properties?.expectedCloseDate?.value || ''

    // Organization name if available
    const domain = properties?.domain?.value || ''

    const description =
      `${currentStatus} ${amount ? `Deal size: $${amount}` : ''} ${domain} ${expectedCloseDate ? `Expected close date: ${dayjs(expectedCloseDate).format('M/D/YY')}` : ''}`.trim()

    const searchMetadata = {
      label: name,
      description,
      // No photo for opportunities
      photoUrl: null,
      searchText: [name, description, amount, domain].filter(Boolean).join(' '),
    }

    return searchMetadata
  },
  [NativeObjectTypes.Pipeline]: (obj: DayObject) => {
    const properties = (obj?.properties?.standard as any) || {}
    if (!obj || !obj.properties) {
      logger.warn('Missing properties for pipeline object', {
        objectId: obj?.objectId,
      })
      return {
        label: obj?.objectId,
        description: '',
        photoUrl: null,
        searchText: '',
      }
    }
    const pipelineType = properties.pipelineType?.value
    const pipelineLabel = pipelineType
      ? `${NativeSuggestedPipelineTypes[pipelineType]?.label} Pipeline`
      : 'Pipeline (custom)'
    const pipelineDescription = properties.pipelineDescription?.value || ''
    const name = properties?.title?.value || 'Pipeline'
    const description = pipelineLabel + ' ' + pipelineDescription
    const photoUrl = null
    const searchMetadata = {
      label: name,
      description,
      photoUrl,
      searchText: [name, description, pipelineLabel, pipelineDescription]
        .filter(Boolean)
        .join(' '),
    }

    return searchMetadata
  },
  [NativeObjectTypes.Stage]: (obj: DayObject) => {
    if (!obj || !obj.properties) {
      logger.warn('Missing properties for stage object', {
        objectId: obj?.objectId,
      })
      return {
        label: obj?.objectId,
        description: '',
        photoUrl: null,
        searchText: '',
      }
    }

    const properties = (obj?.properties?.standard as any) || {}
    const name = properties?.name?.value || ''
    const description = properties?.description?.value || ''
    const photoUrl = properties?.photoUrl?.value || null

    const searchMetadata = {
      label: name,
      description,
      photoUrl,
      searchText: [name, description].filter(Boolean).join(' '),
    }

    return searchMetadata
  },
  [NativeObjectTypes.MeetingRecording]: (obj: DayObject) => {
    if (!obj || !obj.properties) {
      logger.warn('Missing properties for meeting recording object', {
        objectId: obj?.objectId,
      })
      return {
        label: obj?.objectId,
        description: '',
        photoUrl: null,
        searchText: '',
      }
    }

    const properties = (obj?.properties?.standard as any) || {}
    const title = properties?.title?.value || ''
    const description = properties?.description?.value || ''
    const participantString = properties?.participantString?.value || ''
    const domainString = properties?.domainString?.value || ''
    const searchText = [participantString, domainString]
      .filter(Boolean)
      .join(' ')

    return {
      label: title,
      description,
      photoUrl: null,
      searchText,
    }
  },
  [NativeObjectTypes.Page]: (obj: DayObject) => {
    if (!obj || !obj.properties) {
      logger.warn('Missing properties for page object', {
        objectId: obj?.objectId,
      })
    }
    const properties = (obj?.properties?.standard as any) || {}
    const title = properties?.title?.value || ''
    const peopleString = properties?.peopleString?.value || ''
    const domainsString = properties?.domainsString?.value || ''
    const searchText = [peopleString, domainsString].filter(Boolean).join(' ')
    return {
      label: title,
      description: `Created ${dayjs(properties?.createdAt?.value).format('MMM D, YYYY')}`,
      photoUrl: null,
      searchText,
    }
  },
}

const filterRows = (rows: any[], userId: string) => {
  if (!rows || !Array.isArray(rows)) return {}
  const filteredByIdByName = {}
  for (const row of rows) {
    const objectType = row.objectTypeId
    const policy: ObjectReadPolicy =
      NativeObjectTypesReadPolicies[objectType] || ObjectReadPolicy.RECENCY
    const userIdPolicy = NativeObjectTypesUserIdPolicies[objectType] || null
    const allowed =
      userIdPolicy === ObjectUserIdPolicy.ALL ||
      userIdPolicy === ObjectUserIdPolicy.FAVOR ||
      userIdPolicy === ObjectUserIdPolicy.EMPTY ||
      (userIdPolicy === ObjectUserIdPolicy.EXCLUSIVE && row.userId === userId)
    if (!allowed) {
      continue
    }
    if (!filteredByIdByName[row.objectId]) {
      filteredByIdByName[row.objectId] = {}
    }
    if (row.objectTypeId === objectType) {
      if (policy === ObjectReadPolicy.RECENCY) {
        if (!filteredByIdByName[row.objectId][row.name]) {
          filteredByIdByName[row.objectId][row.name] = row
        } else if (
          new Date(row.updatedAt).getTime() >
          new Date(
            filteredByIdByName[row.objectId][row.name].updatedAt
          ).getTime()
        ) {
          filteredByIdByName[row.objectId][row.name] = row
        }
      } else if (policy === ObjectReadPolicy.SOURCE) {
        if (!filteredByIdByName[row.objectId][row.name]) {
          filteredByIdByName[row.objectId][row.name] = row
        } else if (
          row.propertySource?.priority <
          filteredByIdByName[row.objectId][row.name].propertySource?.priority
        ) {
          filteredByIdByName[row.objectId][row.name] = row
        }
      }
    }
  }

  const output = {}
  for (const objectId of Object.keys(filteredByIdByName)) {
    output[objectId] = Object.values(filteredByIdByName[objectId])
  }

  return output
}

export const buildObject = ({
  objectType,
  objectId,
  workspaceId,
  rows,
  propertyDefinitions,
  userId = null,
}: {
  objectType: NativeObjectType
  objectId: string
  workspaceId: string
  rows: any[]
  propertyDefinitions: any[]
  userId?: string | null
}): DayObject => {
  const filteredRows = filterRows(rows, userId)
  const customRows = []
  const standardRows = []
  const relationshipRows = []
  for (const row of filteredRows[objectId] || []) {
    if (row.name.startsWith('custom/')) {
      customRows.push(row)
    } else if (row.name.startsWith('relationship/')) {
      relationshipRows.push(row)
    } else {
      standardRows.push(row)
    }
  }

  const customProperties = buildCustomProperties(
    customRows,
    propertyDefinitions
  )

  const relationships = buildRelationships(relationshipRows)

  const standardPropertiesArray = standardRows
    .map((row) => {
      const metadata = StandardProperties?.[objectType]?.[row.name]
      return {
        propertyId: row.name,
        value: metadata?.renderer
          ? metadata.renderer(row.value)
          : renderValue(row.value, metadata?.propertyTypeId),
        propertySource: row.propertySource,
        version: Number(row.version),
      }
    })
    .filter(Boolean)

  const standardProperties = Object.fromEntries(
    standardPropertiesArray.map((row) => [row.propertyId, row])
  )

  const object = {
    workspaceId,
    objectId,
    objectType,
    properties: {
      custom: customProperties,
      standard: standardProperties,
      relationships,
    },
  }

  return object
}

export const buildGqlObject = (object: DayObject) => {
  const builtGql = {}
  for (const rowName of Object.keys(object.properties?.standard || {})) {
    const row = object.properties?.standard?.[rowName]
    const path: OrganizationObjectRootProperties | null =
      StandardProperties?.[object.objectType]?.[rowName]?.path || null
    const field: string | null =
      StandardProperties?.[object.objectType]?.[rowName]?.fieldName || null
    if (path && field) {
      builtGql[path] = {
        ...builtGql[path],
        [field]: row.value,
      }
    } else if (path) {
      builtGql[path] = row.value
    } else if (field) {
      builtGql[field] = row.value
    } else {
      builtGql[rowName] = row.value
    }
  }
  const gqlObject = {
    ...builtGql,
    customProperties: object.properties?.custom || {},
    standardProperties: object.properties?.standard || {},
    relationships: object.properties?.relationships || {},
  }
  return gqlObject
}

export const buildObjectSearchEntry: (
  object: DayObject
) => DayObjectSearchEntry = (object) => {
  const searchMetadata =
    searchMetadataBuilders[object.objectType as NativeObjectType](object)

  const builtObject = {
    key: buildObjectReference({
      workspaceId: object.workspaceId,
      objectType: object.objectType,
      objectId: object.objectId,
    }),
    objectType: object.objectType as NativeObjectType,
    objectId: object.objectId,
    workspaceId: object.workspaceId,
    object,
    ...searchMetadata,
  }

  return builtObject
}
