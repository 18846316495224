import { useContext, useEffect } from 'react'

import {
  Alert,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormGroup,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'
import { IconColumns2 } from '@tabler/icons-react'

import { navigate, routes } from '@redwoodjs/router'

import Row from 'src/components/Row/Row'
import { DayContext } from 'src/lib/dayContext'

const PipelineChooser = ({
  selectedPipeline,
  selectedStage = null,
  pipelines = [],
  pipelineTitles = [],
  onChangePipeline,
  onChangeStage = (_stage: any) => {},
  label = 'Choose pipeline',
  valueTypographyProps = {},
  startIcon = null,
  loading = false,
  selectProps = {},
}) => {
  const { selectedWorkspace } = useContext(DayContext)

  const handleChange = (event) => {
    if (event.target.value === 'new') {
      navigate(routes.pipeline())
    }

    const pipeline = pipelineTitles.find((p) => p.id === event.target.value)
    if (pipeline?.id) {
      onChangePipeline(pipeline)
      onChangeStage(pipelines.find((p) => p.id === pipeline.id)?.stages?.[0])
    }
  }

  const handleChangeStage = (event: React.ChangeEvent<{ value: unknown }>) => {
    const stageId = event.target.value as string
    const stages = pipelines?.find((p) => p.id === selectedPipeline?.id)?.stages
    const stage = stages?.find((s) => s.id === stageId)
    if (stage?.id) onChangeStage(stage)
  }

  useEffect(() => {
    if (!selectedPipeline && pipelineTitles.length > 0) {
      onChangePipeline(pipelineTitles[0])
    }

    const pipeline = pipelines.find((p) => p.id === selectedPipeline?.id)
    if (!selectedStage && pipeline?.stages?.length > 0) {
      onChangeStage(pipeline?.stages[0])
    }
  }, [
    selectedPipeline,
    pipelineTitles,
    selectedStage,
    onChangePipeline,
    onChangeStage,
    pipelines,
  ])

  const containerSx = {
    '& .MuiSvgIcon-root': {
      opacity: {
        xs: 0,
        sm: 0,
        md: 0,
        lg: 1,
        xl: 1,
      },
    },
    '& .pipelineChooserItem .MuiTypography-root.MuiListItemText-primary': {
      noWrap: true,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      fontSize: '12px',
    },
  }

  const menuItemSx = {
    '& .MuiMenuItem-root': {
      maxWidth: '312px',
      overflow: 'hidden',
    },
  }

  return selectedPipeline?.id ? (
    <FormGroup sx={containerSx}>
      <FormControl fullWidth={true}>
        {label && <InputLabel id="pipelineChooser">{label}</InputLabel>}
        <Select
          fullWidth={true}
          id={'pipelineChooser'}
          label={label}
          value={selectedPipeline?.id}
          onChange={handleChange}
          renderValue={() => {
            return (
              <Row>
                {startIcon && <Box sx={{ mx: 1 }}>{startIcon}</Box>}
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: '12px',
                    ...valueTypographyProps,
                  }}
                >
                  {selectedPipeline?.title}
                </Typography>
              </Row>
            )
          }}
          MenuProps={{
            PaperProps: {
              sx: menuItemSx,
            },
          }}
          {...selectProps}
        >
          {pipelineTitles.map(
            (pipeline) =>
              !pipeline.isGeneric && (
                <MenuItem
                  value={pipeline.id}
                  key={`pipelineChooser_${pipeline.id}`}
                >
                  <ListItemText
                    primary={pipeline.title}
                    className="pipelineChooserItem"
                  />
                </MenuItem>
              )
          )}
          <MenuItem value="new">
            <Button
              size="small"
              variant="outlined"
              color="secondary"
              fullWidth={true}
            >
              + Create new pipeline
            </Button>
          </MenuItem>
        </Select>
      </FormControl>
      {selectedStage?.id &&
        pipelines
          .find((p) => p.id === selectedPipeline?.id)
          ?.stages?.some((stage) => stage.id === selectedStage.id) && (
          <FormControl sx={{ mt: 3 }}>
            <InputLabel id="stageChooser">Choose stage</InputLabel>
            <Select
              fullWidth={true}
              id={'stageChooser'}
              label="Choose stage"
              value={selectedStage?.id}
              onChange={(event) => handleChangeStage(event)}
              renderValue={() => {
                return (
                  <Typography sx={{ fontWeight: 500 }}>
                    {selectedStage?.title}
                  </Typography>
                )
              }}
              {...selectProps}
            >
              {pipelines
                .find((p) => p.id === selectedPipeline?.id)
                ?.stages?.map((stage) => (
                  <MenuItem
                    value={stage.id}
                    key={`stageChooser_${stage.id}`}
                  >
                    {stage.title}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}
    </FormGroup>
  ) : loading ? (
    <Box sx={{ height: '120px' }}>
      <CircularProgress
        color="secondary"
        size={36}
      />
    </Box>
  ) : (
    <Alert
      severity="info"
      icon={<IconColumns2 size={18} />}
      action={
        <Button
          onClick={() =>
            navigate(
              routes.objectDetail({
                workspaceId: selectedWorkspace,
                objectId: 'new',
                objectTypeSlug: 'pipelines',
              })
            )
          }
          size="small"
        >
          Create pipeline
        </Button>
      }
    >
      No pipelines found
    </Alert>
  )
}

export default PipelineChooser
