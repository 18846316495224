import { useContext } from 'react'

import { Box, CircularProgress, Typography } from '@mui/material'

import { navigate, routes } from '@redwoodjs/router'

import ObjectsContext from './ObjectsContext'

const SyncStatus = ({
  label = 'Building CRM index...',
}: {
  label?: string
}) => {
  const { syncState } = useContext(ObjectsContext)
  const anyBackfilling = syncState
    ? Object.values(syncState).some((syncState) => {
        return syncState.objectsNeedingSync > 0
      })
    : false

  const containerSx = {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    width: 'fit-content',
    height: 'fit-content',
    '& .sync-status-text': {
      fontSize: '11px',
      fontWeight: 500,
      letterSpacing: '-0.3px',
      opacity: 0.6,
    },
    cursor: 'pointer',
  }
  return syncState && anyBackfilling ? (
    <Box
      sx={containerSx}
      onClick={() => {
        navigate(routes.sync())
      }}
    >
      <CircularProgress size={14} />
      <Typography className="sync-status-text">{label}</Typography>
    </Box>
  ) : null
}

export default SyncStatus
