import { useMemo, useState } from 'react'

import { Box, Button, TextField, Typography } from '@mui/material'
import toast from 'react-hot-toast'

import { useMutation, useQuery } from '@redwoodjs/web'

import Row from 'src/components/Row/Row'

const GET_GLOBAL_ORGANIZATION_ROWS_FOR_LOGO_CHOOSER = gql`
  query GetGlobalOrganizationRowsForLogoChooser($domain: String!) {
    globalOrganizationRows(domain: $domain)
  }
`

const UPDATE_ORGANIZATION_LOGO = gql`
  mutation UpdateOrganizationLogo($domain: String!, $photoSquareUrl: String!) {
    setOrganizationPhotoSquare(domain: $domain, photoSquareUrl: $photoSquareUrl)
  }
`

const OrganizationLogoChooser = ({ domain }) => {
  const [manualLogoUrl, setManualLogoUrl] = useState('')
  const { data } = useQuery(GET_GLOBAL_ORGANIZATION_ROWS_FOR_LOGO_CHOOSER, {
    variables: { domain },
  })

  const [updateOrganizationLogo] = useMutation(UPDATE_ORGANIZATION_LOGO)

  const handleUpdateOrganizationLogo = (logoUrl) => {
    toast.promise(
      updateOrganizationLogo({
        variables: { domain, photoSquareUrl: logoUrl },
      }),
      {
        loading: 'Updating logo...',
        success: 'Logo updated!',
        error: 'Failed to update logo',
      }
    )
  }

  const alternateLogos = useMemo(() => {
    const rows = data?.globalOrganizationRows?.rows ?? []
    return [
      ...(rows
        ?.filter((row) => row.name === 'photoSquare')
        .map((row) => ({ value: row.value, label: row.propertySource.id })) ??
        []),
      ...(rows
        ?.filter((row) => row.name === 'photosOther')
        .flatMap((row) => JSON.parse(row.value))
        .flat() ?? []),
    ]
  }, [data])

  const currentLogo = useMemo(() => {
    return [...(data?.globalOrganizationRows?.rows || [])]
      ?.filter((row) => row.name === 'photoSquare')
      ?.sort(
        (a, b) => a.propertySource.priority - b.propertySource.priority
      )?.[0]?.value
  }, [data])

  return (
    alternateLogos && (
      <>
        <Box sx={{ width: '100%' }}>
          <Typography
            variant="h3"
            sx={{ mb: 2 }}
          >
            Current
          </Typography>
          <Box>
            <Box
              component="img"
              src={currentLogo}
              alt="Logo"
              sx={{
                width: 64,
                height: 64,
                objectFit: 'cover',
              }}
            />
          </Box>
        </Box>

        <Box>
          <Typography
            variant="h3"
            sx={{ my: 2 }}
          >
            Alternate Options
          </Typography>
          <Row
            gap={2}
            sx={{ mb: 2 }}
          >
            <TextField
              value={manualLogoUrl}
              onChange={(e) => setManualLogoUrl(e.target.value)}
              fullWidth={true}
            />
            <Button
              variant="outlined"
              onClick={() => handleUpdateOrganizationLogo(manualLogoUrl)}
              sx={{ flexShrink: 0, height: '52px' }}
            >
              Use manual logo
            </Button>
          </Row>

          {alternateLogos?.map((entry, index) => (
            <Row
              key={`alternate_logo_${index}`}
              sx={{
                p: 2,
                borderRadius: 2,
                border: (theme) => `1px solid ${theme.palette.divider}`,
                mb: 2,
              }}
              gap={2}
            >
              <Box
                component="img"
                src={entry.value}
                alt={entry.label}
                sx={{
                  width: 64,
                  height: 64,
                  objectFit: 'cover',
                }}
              />
              <Button
                variant="outlined"
                onClick={() => {
                  handleUpdateOrganizationLogo(entry.value)
                }}
              >
                Use instead
              </Button>
              <Typography sx={{ fontWeight: 500, letterSpacing: '-0.2px' }}>
                {entry.label}
              </Typography>
            </Row>
          ))}
        </Box>
      </>
    )
  )
}

export default OrganizationLogoChooser
