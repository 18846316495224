import { createContext } from 'react'

interface MeetingRecordingsContextProps {
  meetingRecordings: any[]
}

const MeetingRecordingsContext = createContext<MeetingRecordingsContextProps>({
  meetingRecordings: [],
})

export default MeetingRecordingsContext
