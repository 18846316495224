import { Avatar } from '@mui/material'
import type { TemplateOutputType } from 'types/graphql'

import { TemplateTypeMetadata, TemplateTypes } from 'src/lib/objects'
// Style constants to prevent re-renders
const tileAvatarSx = (size: number) => ({
  width: size,
  height: size,
  background: (theme) => theme.palette.background.default,
})

const chipAvatarSx = {
  background: (theme) => theme.palette.background.paper,
}

const defaultAvatarSx = {
  background: 'transparent',
  color: (theme) => theme.palette.text.primary,
}

const getIconProps = (variant: 'chip' | 'tile' | null, size: number) => {
  if (variant === 'tile') {
    return {
      size: size * 0.5,
      stroke: 2,
      style: {
        color: 'rgba(0, 0, 0, 0.54)',
      },
    }
  }
  if (variant === 'chip') {
    return {
      size: '12px',
      stroke: 2.45,
      style: {
        marginTop: '-1px',
      },
    }
  }
  return { size }
}

export const getTemplateIcon = ({
  templateType,
  variant = null,
  size = 16,
}: {
  templateType: TemplateOutputType
  variant?: 'chip' | 'tile' | null
  size?: number
}) => {
  const iconProps = getIconProps(variant, size)
  const avatarSx =
    variant === 'tile'
      ? tileAvatarSx(size)
      : variant === 'chip'
        ? chipAvatarSx
        : defaultAvatarSx

  const IconComponent =
    TemplateTypeMetadata[templateType]?.icon ||
    TemplateTypeMetadata[TemplateTypes.BASE].icon

  return (
    <Avatar
      src={null}
      sx={avatarSx}
      className="MuiChip-avatar"
    >
      {React.createElement(IconComponent, iconProps)}
    </Avatar>
  )
}
