import { getDayObjectsRecentByTypes } from 'src/components/Objects/searchIndex'

import { logger } from './logger'
import type { NativeObjectType } from './objects'
import type { SearchableObject } from './searchService'

const RECENT_OBJECTS_BASE_KEY = 'recent_sidebar_objects'
export const MAX_RECENT_OBJECTS = 10

const getStorageKey = (workspaceId: string, userId: string) =>
  `${RECENT_OBJECTS_BASE_KEY}_${workspaceId}_${userId}`

interface StoredRecentObject extends SearchableObject {
  _workspaceId: string // Internal field for storage
}

export const addToRecentObjects = (
  object: any,
  workspaceId: string,
  userId: string
) => {
  try {
    if (!object?.objectId || !object?.objectType || !workspaceId || !userId) {
      logger.warn('Missing required parameters for addToRecentObjects', {
        hasObject: !!object,
        hasWorkspaceId: !!workspaceId,
        hasUserId: !!userId,
      })
      return
    }

    const storageKey = getStorageKey(workspaceId, userId)
    const recentObjects = JSON.parse(
      localStorage.getItem(storageKey) || '[]'
    ) as StoredRecentObject[]

    // Remove any existing entry for this object
    const filteredObjects = recentObjects.filter(
      (obj: StoredRecentObject) =>
        !(
          obj.objectId === object.objectId &&
          obj.objectType === object.objectType
        )
    )

    // Add new object to front of array
    filteredObjects.unshift({
      ...object,
      _workspaceId: workspaceId, // Store workspaceId separately
      label:
        object.properties?.name ||
        object.properties?.title ||
        object.properties?.domain ||
        object.objectId,
      description: object.properties?.description,
      lastUpdated: new Date().getTime(),
    })

    // Keep only the most recent N objects
    const trimmedObjects = filteredObjects.slice(0, MAX_RECENT_OBJECTS)

    localStorage.setItem(storageKey, JSON.stringify(trimmedObjects))
  } catch (error) {
    logger.error('Error saving recent objects:', error)
  }
}

export const getRecentObjects = async (
  workspaceId: string,
  userId: string,
  objectTypeIds?: NativeObjectType[]
): Promise<SearchableObject[]> => {
  try {
    if (!workspaceId || !userId) {
      logger.warn('Missing required parameters for getRecentObjects', {
        hasWorkspaceId: !!workspaceId,
        hasUserId: !!userId,
      })
      return []
    }

    if (objectTypeIds?.length > 0) {
      logger.dev({ objectTypeIds })
      const results = await getDayObjectsRecentByTypes({
        workspaceId,
        objectTypes: objectTypeIds,
      })
      logger.dev({ results })
      const output = []
      for (const objectType of objectTypeIds) {
        if (results[objectType]) {
          output.push(...results[objectType])
        }
      }
      logger.dev({ output })
      return output
    } else {
      const storageKey = getStorageKey(workspaceId, userId)
      const recentObjects = JSON.parse(
        localStorage.getItem(storageKey) || '[]'
      ) as StoredRecentObject[]

      // Validate objects belong to this workspace and remove internal _workspaceId field
      return recentObjects
        .filter((obj: StoredRecentObject) => obj._workspaceId === workspaceId)
        .map(({ _workspaceId, ...obj }) => obj) // Remove _workspaceId when returning
    }
  } catch (error) {
    logger.error('Error getting recent objects:', error)
    return []
  }
}
