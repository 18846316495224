import type { ReactElement } from 'react'
import React, { createContext, useContext } from 'react'

import { logger } from 'src/lib/logger'

type ObjectCacheContextType = {
  getCachedObject: (key: string) => ReactElement | undefined
  setCachedObject: (key: string, object: ReactElement) => void
  clearCacheForObject: (objectId: string, objectType: string) => void
  clearCache: () => void
}

const MAX_CACHE_SIZE = 1000

class ObjectCache {
  private cache = new Map<string, ReactElement>()

  get(key: string) {
    return this.cache.get(key)
  }

  set(key: string, value: ReactElement) {
    if (this.cache.size >= MAX_CACHE_SIZE) {
      const firstKey = this.cache.keys().next().value
      this.cache.delete(firstKey)
    }
    this.cache.set(key, value)
  }

  clearForObject(objectId: string, objectType: string) {
    const keysToDelete: string[] = []

    // Find all keys that match this object
    for (const key of this.cache.keys()) {
      if (key.startsWith(`${objectType}:${objectId}:`)) {
        keysToDelete.push(key)
      }
    }

    // Delete found keys and log
    if (keysToDelete.length > 0) {
      keysToDelete.forEach((key) => this.cache.delete(key))
      logger.dev('Cleared cache entries for object:', {
        objectType,
        objectId,
        count: keysToDelete.length,
      })
    }
  }

  clear() {
    const size = this.cache.size
    this.cache.clear()
    if (size > 0) {
      logger.dev('Cleared entire object cache:', { entriesCleared: size })
    }
  }
}

const ObjectCacheContext = createContext<ObjectCacheContextType | null>(null)

export const ObjectCacheProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const cache = new ObjectCache()

  const value = {
    getCachedObject: (key: string) => cache.get(key),
    setCachedObject: (key: string, object: ReactElement) =>
      cache.set(key, object),
    clearCacheForObject: (objectId: string, objectType: string) =>
      cache.clearForObject(objectId, objectType),
    clearCache: () => cache.clear(),
  }

  return (
    <ObjectCacheContext.Provider value={value}>
      {children}
    </ObjectCacheContext.Provider>
  )
}

export const useObjectCache = () => {
  const context = useContext(ObjectCacheContext)
  if (!context) {
    throw new Error('useObjectCache must be used within an ObjectCacheProvider')
  }
  return context
}

export const getCacheKey = (params: {
  objectId: string
  objectType: string
  workspaceId: string
  size?: number
  variant?: string
  [key: string]: any
}): string => {
  try {
    const {
      objectId,
      objectType,
      workspaceId,
      size = 'default',
      variant = 'default',
      ...rest
    } = params

    // Validate required fields
    if (!objectId || !objectType || !workspaceId) {
      throw new Error('Missing required cache key parameters')
    }

    const extraParams = Object.entries(rest)
      .filter(([_, value]) => value != null) // Filter out null/undefined
      .sort(([a], [b]) => a.localeCompare(b))
      .map(([key, value]) => `${key}:${String(value)}`)
      .join('-')

    return `${objectType}:${objectId}:${workspaceId}:${size}:${variant}${
      extraParams ? `:${extraParams}` : ''
    }`
  } catch (error) {
    logger.error('Error generating cache key:', error, { params })
    // Return a fallback key that won't match anything in cache
    return `error:${Date.now()}`
  }
}
