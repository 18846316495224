import { useMemo, useRef } from 'react'

import ReactJson from '@microlink/react-json-view'
import { Box, Button, lighten, Typography } from '@mui/material'

import { useQuery } from '@redwoodjs/web'

import ContactAvatar from 'src/components/ContactAvatar/ContactAvatar'
import Row from 'src/components/Row/Row'
import SlackLogo from 'src/components/Slack/SlackLogo/SlackLogo'
import PlayerContainer from 'src/components/Video/PlayerContainer/PlayerContainer'
import { dayjs } from 'src/lib/dayjs'
import { logger } from 'src/lib/logger'

const GET_MEETING_RECORDING_FOR_ACTION_SOURCE = gql`
  query GetMeetingRecordingForActionSource($id: String!) {
    meetingRecording(id: $id) {
      id
      startedAt
      statusHistory {
        level
        status
        reason
        message
        createdAt
      }
      title
      startedAt
      endedAt
      summary {
        status
        output
      }
      video {
        private {
          thumbnail {
            status
            url
          }
          download {
            status
            urls {
              default
              quality
              url
            }
          }
          playbackId
          tokens {
            video
            thumbnail
            storyboard
          }
        }
      }
      clips {
        id
        title
        description
        video {
          private {
            thumbnail {
              status
              url
            }
            download {
              status
              urls {
                default
                quality
                url
              }
            }
            playbackId
            tokens {
              video
              thumbnail
              storyboard
            }
          }
        }
      }
      participants {
        email
        contact {
          objectType
          objectId
          properties
        }
      }
    }
  }
`

const GET_SLACK_EXCERPT_FOR_ACTION_SOURCE = gql`
  query GetSlackExcerptForActionSource(
    $actionSourceId: String!
    $workspaceId: String!
  ) {
    slackMessagesForActionSource(
      actionSourceId: $actionSourceId
      workspaceId: $workspaceId
    ) {
      id
      link
      channelName
      messages {
        id
        slackUser {
          id
          name
          email
        }
        text
        timestamp
      }
    }
  }
`

const ActionSourceMeetingRecordingPlayer = ({ meetingRecordingId, debug }) => {
  const playerRef = useRef(null)

  const { data: meetingRecordingData, refetch } = useQuery(
    GET_MEETING_RECORDING_FOR_ACTION_SOURCE,
    {
      variables: { id: meetingRecordingId },
      skip: !meetingRecordingId,
    }
  )

  const meetingRecording = meetingRecordingData?.meetingRecording

  return (
    <>
      <Box
        sx={{
          width: '325px',
          height: '200px',
          aspectRatio: 3 / 2,
          boxSizing: 'border-box',
          borderRadius: '8px',
          overflow: 'hidden',
        }}
      >
        <PlayerContainer
          ref={playerRef}
          playbackId={meetingRecording?.video?.private?.playbackId}
          tokens={meetingRecording?.video?.private?.tokens}
          style={{
            width: '325px',
            height: '200px',
            borderRadius: '8px',
          }}
        />
      </Box>
      {debug && (
        <Box>
          <Button onClick={() => refetch()}>Refetch</Button>
          <ReactJson src={meetingRecording} />
        </Box>
      )}
    </>
  )
}

const ActionSourceSlackExcerpt = ({
  actionSourceId,
  workspaceId,
  sourceInfo,
  debug,
}) => {
  const { data: slackExcerptData, refetch } = useQuery(
    GET_SLACK_EXCERPT_FOR_ACTION_SOURCE,
    {
      variables: { actionSourceId, workspaceId },
      skip: !actionSourceId || !workspaceId,

      onCompleted: (slackExcerptData) => {
        logger.dev({ slackExcerptData })
      },
    }
  )

  const slackExcerpt = slackExcerptData?.slackMessagesForActionSource
  const link = slackExcerpt?.link || sourceInfo?.slack?.link

  logger.dev({ slackExcerpt, link })

  return slackExcerpt && link ? (
    <>
      <Box
        onClick={() => {
          window.open(link, '_blank')
        }}
        sx={{
          backgroundColor: (theme) => theme.palette.background.paper,
          borderRadius: '4px',
          color: '#1d1c1d',
          cursor: 'pointer',
          overflow: 'hidden',
          border: `1px solid ${lighten('#85508b', 0.8)}`,
        }}
      >
        <Row
          sx={{
            justifyContent: 'space-between',
            backgroundColor: '#611e69',
            p: '8px 12px',
          }}
        >
          <Row gap={'4px'}>
            <SlackLogo size={14} />
          </Row>
          <Typography
            sx={{
              color: '#f8f8f8',
              fontSize: '13px',
              fontWeight: 600,
              lineHeight: '16px',
              letterSpacing: '-0.33px',
            }}
          >
            {slackExcerpt.channelName
              ? `#${slackExcerpt.channelName}`
              : 'Slack Conversation'}
          </Typography>
        </Row>
        <Box sx={{ width: '100%' }}>
          {slackExcerpt.messages.map((message) => (
            <Row
              key={message.id}
              gap={'12px'}
              sx={{
                p: '12px',
                alignItems: 'flex-start',
                backgroundColor:
                  message.id === sourceInfo?.slack?.slackHighlightMessageId
                    ? '#f8f8f8'
                    : 'transparent',
                width: '100%',
                '.slack-message-timestamp': {
                  color: '#898889',
                  fontSize: '10px',
                  lineHeight: '16px',
                  letterSpacing: '-0.22px',
                  display:
                    message.id === sourceInfo?.slack?.slackHighlightMessageId
                      ? 'block'
                      : 'none',
                },
                '&:hover': {
                  backgroundColor: '#f8f8f8',
                  '.slack-message-timestamp': {
                    display: 'block',
                  },
                },
              }}
            >
              <ContactAvatar
                email={message.slackUser.email}
                borderRadius={4}
                size={40}
              />
              <Box sx={{ width: '100%' }}>
                <Row
                  gap={'4px'}
                  sx={{ justifyContent: 'space-between', width: '100%' }}
                >
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: 600,
                      lineHeight: '16px',
                      letterSpacing: '-0.33px',
                    }}
                  >
                    {message.slackUser.name}
                  </Typography>
                  <Typography className="slack-message-timestamp">
                    {dayjs(message.timestamp).format('M/D/YY \u2022 h:mm A')}
                  </Typography>
                </Row>
                <Typography
                  sx={{
                    fontSize: '13px',
                    lineHeight: '16px',
                    letterSpacing: '-0.22px',
                  }}
                >
                  {message.text}
                </Typography>
              </Box>
            </Row>
          ))}
        </Box>
      </Box>
      {debug && (
        <Box>
          <Button onClick={() => refetch()}>Refetch</Button>
          <ReactJson src={slackExcerpt} />
        </Box>
      )}
    </>
  ) : null
}

const ActionSourceInfo = ({ action, showReasoning = true, debug = false }) => {
  const sources = useMemo(() => {
    return action?.source?.info || []
  }, [action])

  logger.dev({ sources })

  return sources.length > 0 && action.workspaceId && action.id ? (
    <Box>
      {sources
        .filter((source) => source.foundSource)
        .map((source) => (
          <Box
            key={
              source.meetingRecording?.meetingRecordingId ||
              source.slack?.slackChannelId
            }
          >
            {source.meetingRecording?.meetingRecordingId && (
              <ActionSourceMeetingRecordingPlayer
                meetingRecordingId={source.meetingRecording?.meetingRecordingId}
                debug={debug}
              />
            )}
            {source.slack?.slackChannelId && (
              <ActionSourceSlackExcerpt
                actionSourceId={action.id}
                workspaceId={action.workspaceId}
                sourceInfo={source}
                debug={debug}
              />
            )}
            {source.reasoning && showReasoning && (
              <Typography
                sx={{
                  fontSize: '12px',
                  lineHeight: '16px',
                  letterSpacing: '-0.22px',
                  mt: 1,
                }}
              >
                {source.reasoning}
              </Typography>
            )}
          </Box>
        ))}
    </Box>
  ) : null
}

export default ActionSourceInfo
