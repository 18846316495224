import MetadataChip from 'src/components/Chips/MetadataChip/MetadataChip'
import { getRelationshipSummaryTypeMetadataFromKey } from 'src/lib/relationshipSummary'

const RelationshipTypeChip = ({ type }) => {
  if (!type) return null
  const typeObject = getRelationshipSummaryTypeMetadataFromKey(type)
  const excludedTypes = [
    'OTHER',
    'INSUFFICIENT_DATA',
    'Other',
    'Insufficient Data',
    'other',
  ]
  return (
    typeObject &&
    !excludedTypes.includes(type) && (
      <MetadataChip
        state={{
          label: typeObject.label,
          value: typeObject.value,
        }}
        icon={typeObject.icon}
      />
    )
  )
}

export default RelationshipTypeChip
