import { IconButton } from '@mui/material'
import { IconArrowLeft } from '@tabler/icons-react'
import { IconArrowRight } from '@tabler/icons-react'

import Row from 'src/components/Row/Row'

import useSidebar from './useSidebar'
const History = () => {
  const { back, forward } = useSidebar()
  return (
    <Row>
      <IconButton
        disabled={!back.enabled}
        onClick={back.run}
      >
        <IconArrowLeft
          size={24}
          style={{ flexShrink: 0 }}
        />
      </IconButton>
      <IconButton
        disabled={!forward.enabled}
        onClick={forward.run}
      >
        <IconArrowRight
          size={24}
          style={{ flexShrink: 0 }}
        />
      </IconButton>
    </Row>
  )
}

export default History
