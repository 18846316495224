import {
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef,
  useCallback,
} from 'react'

import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@mui/material'
import { RiAddLine, RiCloseLine } from '@remixicon/react'
import { IconCircleFilled, IconPlus, IconX } from '@tabler/icons-react'
import { useConfirm } from 'material-ui-confirm'
import type { Opportunity, RoleType } from 'types/graphql'

import { useMutation, useQuery } from '@redwoodjs/web'

import ObjectTile from 'src/components/ObjectTile/ObjectTile'
import Row from 'src/components/Row/Row'
import SearchModal from 'src/components/Search/SearchModal/SearchModal'
import { actionChipStyle } from 'src/components/Sidebar/styles'
import { extractEmailDomain } from 'src/lib/contactFormatting'
import { DayContext } from 'src/lib/dayContext'
import { logger } from 'src/lib/logger'
import { NativeObjectTypes } from 'src/lib/objects'
import { ensureArray } from 'src/lib/safeUi'
import type { SearchableObject } from 'src/lib/searchService'

import { possibleRoles } from './possibleRoles'

const OPPORTUNITY_ROLES_QUERY = gql`
  query OpportunityRolesQuery($workspaceId: String!, $id: String!) {
    workspaceOpportunity(workspaceId: $workspaceId, id: $id) {
      id
      roles {
        personEmail
        roles
        lastContactAt
      }
      updatedAt
    }
  }
`

const UPSERT_OPPORTUNITY_ROLES_MUTATION = gql`
  mutation UpsertOpportunityRolesMutation(
    $input: UpsertOpportunityRolesInput!
  ) {
    upsertOpportunityRoles(input: $input) {
      id
      personRoles {
        personEmail
        roles
        lastContactAt
      }
      updatedAt
    }
  }
`

/*
  enum RoleType {
    DECISION_MAKER
    PRIMARY_CONTACT
    SUPPORTER
    DETRACTOR
    CHAMPION
    ECONOMIC_BUYER
    OWNER_EMAIL
    HURDLE
    OTHER
    IGNORE
    DIRECT_BENEFIT
  }
*/

const OpportunityRolesList = ({
  id,
  opportunity = null,
  showHeader = true,
  workspaceId,
}: {
  id: string
  opportunity?: Opportunity
  showHeader?: boolean
  workspaceId?: string
}) => {
  const confirm = useConfirm()
  const { workspaces, setSidebarObject } = useContext(DayContext)
  const [roles, setRoles] = useState<any[]>(opportunity?.roles || [])
  const [showAddPerson, setShowAddPerson] = useState(false)
  const initialLoadRef = useRef(false)
  const updatesPendingRef = useRef(false)

  const currentWorkspace = useMemo(() => {
    return workspaces.find((workspace) => workspace.id === workspaceId)
  }, [workspaces, workspaceId])

  const { data, loading } = useQuery(OPPORTUNITY_ROLES_QUERY, {
    variables: { workspaceId: opportunity?.workspaceId, id },
    skip: !id || !opportunity?.workspaceId, // || !!opportunity?.roles,
  })

  const isInternalEmail = useCallback(
    (email: string) => {
      const domain = extractEmailDomain(email)
      const isWorkspaceMember = currentWorkspace?.members?.some(
        (m) => m.email === email
      )
      const isSameDomain = currentWorkspace?.domains?.some(
        (d) => d.domain === domain
      )
      const isInternal = isWorkspaceMember || isSameDomain
      return isInternal
    },
    [currentWorkspace]
  )

  const getMergedRoles = useCallback(() => {
    logger.dev({ opportunity, data })
    const rolesByEmail: Record<string, Set<RoleType>> = {}
    for (const role of opportunity?.roles || []) {
      if (role.personEmail) {
        rolesByEmail[role.personEmail] = new Set(role.roles)
      }
    }
    for (const role of data?.workspaceOpportunity?.roles || []) {
      if (role.personEmail) {
        rolesByEmail[role.personEmail] = new Set([
          ...(rolesByEmail[role.personEmail] || []),
          ...role.roles,
        ])
      }
    }

    const output = Object.entries(rolesByEmail).map(([email, roles]) => {
      if (isInternalEmail(email)) {
        return null
      }
      return {
        personEmail: email,
        roles: Array.from(roles),
      }
    })
    return output.filter(Boolean)
  }, [opportunity, data, isInternalEmail])

  // Initial data load from query or opportunity prop
  useEffect(() => {
    if (!initialLoadRef.current) {
      if (
        Array.isArray(data?.opportunityRoles) ||
        Array.isArray(opportunity?.roles)
      ) {
        setRoles(getMergedRoles())
        initialLoadRef.current = true
      }
    }
  }, [data, loading, opportunity, id, getMergedRoles])

  // Handle role updates after initial load
  useEffect(() => {
    if (!initialLoadRef.current || updatesPendingRef.current) {
      return
    }

    if (!roles?.length || roles?.length === 0) {
      logger.warn(`Prevented update with empty roles for ${id}`)
      updatesPendingRef.current = false
      return
    }

    const validRoles = roles.filter(
      (role) => role?.personEmail && role.roles?.length > 0
    )

    if (validRoles.length === 0) {
      logger.warn(`No valid roles to update for ${id}`)
      updatesPendingRef.current = false
      return
    }
  }, [roles, data, id])

  const [upsertOpportunityRoles] = useMutation(
    UPSERT_OPPORTUNITY_ROLES_MUTATION
  )

  const handleRolesChange = useCallback(() => {
    if (!updatesPendingRef.current) {
      logger.warn(`Updates pending ref is false for ${id}`)
      return
    }
    if (!(roles?.length > 0)) {
      logger.warn(`No roles found for ${id}`)
      return
    }

    const validRoles = roles
      ?.filter(
        (role) =>
          role?.personEmail &&
          Array.isArray(role.roles) &&
          role.roles.length > 0
      )
      .map((role) => ({
        personEmail: role.personEmail,
        roles: role.roles.filter((r) => r !== 'IGNORE'),
      }))

    if (validRoles.length === 0) {
      logger.warn(`No valid roles to save for ${id}`)
      return
    }

    upsertOpportunityRoles({
      variables: {
        input: {
          id,
          workspaceId: opportunity?.workspaceId,
          personRoles: validRoles,
        },
      },
      onCompleted: () => {
        updatesPendingRef.current = false
      },
      onError: (error) => {
        logger.error(`Failed to update roles for ${id}:`, error)
        updatesPendingRef.current = false
      },
    })
  }, [roles, id, upsertOpportunityRoles, opportunity])

  useEffect(() => {
    if (updatesPendingRef.current) {
      handleRolesChange()
    }
  }, [roles, id, workspaceId, handleRolesChange])

  const shouldShowRoleEntry = useCallback(
    (role: any) => {
      const allWorkspaceMemberEmails = (currentWorkspace?.members || []).map(
        (member) => member?.email
      )
      if ((allWorkspaceMemberEmails || []).includes(role.personEmail)) {
        return false
      }
      const allDomains = new Set<string>(
        currentWorkspace?.domains
          ?.map((domain) => domain.domain)
          .filter(Boolean)
      )
      for (const email of allWorkspaceMemberEmails) {
        const emailDomain = extractEmailDomain(email)
        if (emailDomain) {
          allDomains.add(emailDomain)
        }
      }

      const domain = extractEmailDomain(role.personEmail)
      return !allDomains.has(domain)
    },
    [currentWorkspace]
  )

  const handleAddPerson = (result: SearchableObject) => {
    if (result.objectType !== NativeObjectTypes.Contact) {
      return
    }
    const email = result.objectId
    const defaultRoles =
      roles?.length === 0 ? ['PRIMARY_CONTACT'] : ['CHAMPION']
    updatesPendingRef.current = true
    setRoles((prevRoles) => [
      ...ensureArray(prevRoles),
      { personEmail: email, roles: defaultRoles },
    ])
    setShowAddPerson(false)
  }

  return (
    <>
      {!initialLoadRef.current ? (
        <CircularProgress
          color="secondary"
          size={24}
        />
      ) : (
        <Box sx={{ mb: 5, width: '100%' }}>
          <Row sx={{ justifyContent: 'space-between' }}>
            {showHeader && <Typography variant="h2">People</Typography>}
            <Row
              sx={{
                width: '100%',
                justifyContent: 'flex-end',
                gap: 1,
              }}
            >
              {roles.length === 0 ? (
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => setShowAddPerson(true)}
                  sx={actionChipStyle}
                >
                  Add people to enable AI
                </Button>
              ) : (
                <IconButton
                  onClick={() => setShowAddPerson(true)}
                  sx={{ p: '4px', borderRadius: '3px', mr: '-2px' }}
                >
                  <IconPlus size={18} />
                </IconButton>
              )}
            </Row>
          </Row>
          {roles &&
            roles.length > 0 &&
            roles?.map(
              (role, index) =>
                shouldShowRoleEntry(role) && (
                  <Box
                    key={`role_${index}_${role.personEmail}`}
                    sx={{ mb: 1 }}
                  >
                    <Row
                      sx={{
                        width: '100%',
                        height: '64px',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Box sx={{ width: '292px' }}>
                        <ObjectTile
                          objectType={NativeObjectTypes.Person}
                          objectId={role.personEmail}
                          workspaceId={opportunity?.workspaceId || workspaceId}
                          size={32}
                          onClick={() => {
                            setSidebarObject({
                              objectType: NativeObjectTypes.Person,
                              objectId: role.personEmail,
                              properties: {
                                email: role.personEmail,
                                fullName: role.name,
                              },
                            })
                          }}
                        />
                      </Box>
                      <Row sx={{ justifyContent: 'right' }}>
                        <FormControl
                          sx={{
                            '& .MuiOutlinedInput-notchedOutline': {
                              border: 'none',
                            },
                            '& .MuiInputLabel-root': {
                              top: '-12px',
                              left: '242px',
                            },
                            '& .MuiInputLabel-shrink': {
                              display: 'none',
                            },
                            '& .MuiSelect-multiple > .MuiBox-root': {
                              justifyContent: 'right',
                            },
                            '& .MuiMenu-paper, .MuiPopover-paper, .MuiMenu-paper, .MuiPaper-rounded':
                              {
                                borderRadius: '12px !important',
                              },
                            '& .MuiList-root': {
                              p: 0,
                              borderRadius: '12px',
                            },
                            width: '380px',
                            flexShrink: 0,
                          }}
                        >
                          <InputLabel sx={{}}>
                            <Row gap={1}>
                              <Chip
                                label="Add roles"
                                size="small"
                                variant="outlined"
                                icon={<RiAddLine size={14} />}
                                clickable={true}
                                color="secondary"
                                sx={{
                                  width: '116px',
                                }}
                              />
                            </Row>
                          </InputLabel>
                          <Select
                            placeholder="Select roles"
                            sx={{
                              mt: '0px !important',
                              '& .MuiSvgIcon-root, .MuiSvgIcon-fontSizeMedium, .MuiSelect-icon, .MuiSelect-iconStandard':
                                {
                                  display:
                                    role.roles?.length === 0
                                      ? 'none'
                                      : 'inline-block',
                                },
                            }}
                            disableUnderline={true}
                            multiple={true}
                            value={role.roles}
                            variant="standard"
                            onChange={(event) => {
                              const newRoles = event.target.value as RoleType[]
                              updatesPendingRef.current = true
                              setRoles((prevRoles) =>
                                ensureArray(prevRoles).map((r, i) =>
                                  i === index
                                    ? {
                                        ...r,
                                        roles: newRoles,
                                      }
                                    : r
                                )
                              )
                            }}
                            renderValue={(selected) => (
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexWrap: 'wrap',
                                  gap: 0.5,
                                }}
                              >
                                {selected.map((value) => (
                                  <Chip
                                    clickable={true}
                                    key={value}
                                    size="small"
                                    variant="outlined"
                                    icon={<IconCircleFilled size={8} />}
                                    label={
                                      possibleRoles.find((r) => r.key === value)
                                        ?.label
                                    }
                                    deleteIcon={
                                      <IconX
                                        size={16}
                                        onMouseDown={(event) =>
                                          event.stopPropagation()
                                        }
                                      />
                                    }
                                    onDelete={() => {
                                      updatesPendingRef.current = true
                                      setRoles(
                                        (prevRoles) =>
                                          prevRoles?.map((r, i) =>
                                            i === index
                                              ? {
                                                  ...r,
                                                  roles: r.roles.filter(
                                                    (role) => role !== value
                                                  ),
                                                }
                                              : r
                                          ) || []
                                      )
                                    }}
                                    sx={{
                                      mr: '8px',
                                      border: 'none',
                                      display: possibleRoles.find(
                                        (r) => r.key === value
                                      )?.showInChooser
                                        ? 'flex'
                                        : 'none',

                                      '& .MuiChip-label': {
                                        fontWeight: 500,
                                        letterSpacing: '-0.2px',
                                        fontSize: '11px',
                                        pl: '10px',
                                        color: (theme) =>
                                          theme.palette.text.secondary,
                                      },
                                      '& .MuiChip-icon': {
                                        mr: '-2px',
                                        height: '8px',
                                        width: '8px',
                                        flexShrink: 0,
                                        color: (theme) =>
                                          theme.palette[
                                            possibleRoles.find(
                                              (r) => r.key === value
                                            ).color
                                          ]?.main,
                                      },
                                    }}
                                  />
                                ))}
                              </Box>
                            )}
                          >
                            {possibleRoles.map(
                              ({ key, label, showInChooser }) => (
                                <MenuItem
                                  key={key}
                                  value={key}
                                  sx={{
                                    display: showInChooser ? 'flex' : 'none',
                                  }}
                                >
                                  <Checkbox
                                    checked={role.roles?.indexOf(key) > -1}
                                  />
                                  <ListItemText primary={label} />
                                </MenuItem>
                              )
                            )}
                          </Select>
                        </FormControl>
                        {role.roles?.filter(
                          (role) =>
                            possibleRoles.find((r) => r.key === role)
                              ?.showInChooser
                        )?.length === 0 && (
                          <Tooltip
                            title="Remove contact"
                            arrow={true}
                          >
                            <IconButton
                              sx={{ px: '3px' }}
                              onClick={async () => {
                                try {
                                  if (roles?.length < 8) {
                                    await confirm({
                                      title: 'Remove person from opportunity',
                                      description:
                                        'Are you sure you want to remove this person from the opportunity?',
                                    })
                                  }
                                  updatesPendingRef.current = true
                                  setRoles(
                                    (prevRoles) =>
                                      prevRoles?.filter(
                                        (_, i) => i !== index
                                      ) || []
                                  )
                                } catch (e) {
                                  logger.warn(e)
                                }
                              }}
                            >
                              <RiCloseLine size={18} />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Row>
                    </Row>
                  </Box>
                )
            )}
        </Box>
      )}
      <SearchModal
        workspaceId={workspaceId || opportunity?.workspaceId}
        open={showAddPerson}
        onClose={() => setShowAddPerson(false)}
        forcedObjectTypes={[NativeObjectTypes.Contact]}
        onSelect={handleAddPerson}
        targetObjectType={NativeObjectTypes.Opportunity}
        contextualActions={[
          {
            label: 'Add to Opportunity',
            onClick: handleAddPerson,
          },
        ]}
        allowCreation={true}
      />
    </>
  )
}

export default OpportunityRolesList
