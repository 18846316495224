export const excludedSenderNames = [
  'superhuman@mail.joinsuperhuman.ai',
  '@tasks.clickup.com',
  '@convo.trulia.com',
  '@property.booking.com',
  '@data.cbinsights.com',
  '.lt02.net',
  '@setup.interseller.io',
  '@unsub.',
  '@mg.upwork.com',
  'mail.toptal.com',
  'm.heyapollo.io',
  'm.outboundsquad.com',
  '@unsubscribe',
  '@m.hsms',
  'hubspotemail.net',
  'early@',
  'growth@',
  'coounselors@',
  'founders@',
  'concierge@',
  'reception@',
  'health@',
  'verifications@',
  'slack-email.com',
  'amtrak@e-mail.amtrak.com',
  'americanexpress@',
  'discover@airbnb.com',
  '@hello.sevenrooms.com',
  'hs-send.com',
  'bounce+',
  'bounces+',
  'deactivated',
  'shopifyemail.com',
  '@amazonses.com',
  'bounces.google.com',
  '@inbox.copper.com',
  '@eg.expedia.com',
  'newsletters@',
  '@hq.bill.com',
  '@e.chase.com',
  'no.reply',
  'noreply',
  'surveys@',
  'survey@',
  'paperlesspost@',
  '@ctmail.',
  'on.crisp.email',
  '@assistant.day.ai',
  '@mail.tasks.clickup.com',
  'orders@',
  '@e.starbucks.com',
  'mx.monday.com',
  '@docusign.net',
  'docs.google.com',
  'crm@',
  '@hubspotfree.hs-send.com',
  '.hubspotstarter.net',
  '.comms.thinkific.com',
  'calendar.google.com',
  'onboarding@',
  'schedule@',
  'noreply@',
  'support@',
  '@hubspotfree.hs-send.com',
  'meet@',
  '@chime.aws',
  'staff@',
  'notify@',
  'info@',
  'abuse@',
  'academic.administrator@',
  'accessibility@',
  'account@',
  'accounting@',
  'accounts@',
  'admin.office@',
  'salesmanagers@',
  'coreteam@',
  'solutions@',
  '-squad-',
  'engteam@',
  'enterprise-solutions-unit@',
  'administracion@',
  'administration@',
  'administrator@',
  'administrators@',
  'adminoffice@',
  'admins@',
  'admissions@',
  'adops@',
  'advertise@',
  'advertisingsales@',
  'advisor@',
  'adwords@',
  'affiliates@',
  'agents@',
  'alarm@',
  'alarms@',
  'alert@',
  'alerts@',
  'all.employees@',
  'all.staff@',
  'all.users@',
  'all@',
  'allemployees@',
  'all-employees@',
  'allhands@',
  'all-pms@',
  'allstaff@',
  'all-staff@',
  'allstudents@',
  'allteachers@',
  'allteam@',
  'all-team@',
  'allusers@',
  'alumni@',
  'amministrazione@',
  'analytics@',
  'api@',
  'app@',
  'application@',
  'apps@',
  'backend-dev@',
  'billing@',
  'biz@',
  'bizdev@',
  'bookings@',
  'brandsolutions@',
  'bugs@',
  'business@',
  'businessdevelopment@',
  'career@',
  'channel-sales@',
  'citymanagers@',
  'commissions@',
  'compliance@',
  'contact.us@',
  'contact@',
  'contacto@',
  'contactus@',
  'contact-us@',
  'contractor@',
  'contractors@',
  'contracts@',
  'co-op@',
  'corporate@',
  'customer.services@',
  'customercare@',
  'customerfeedback@',
  'customerservice@',
  'customerservices@',
  'customersuccess@',
  'customersupport@',
  'custserv@',
  'dean@',
  'delivery@',
  'dev@',
  'developer@',
  'developers@',
  'development@',
  'devnull@',
  'devops@',
  'devs@',
  'devteam@',
  'directory@',
  'dns@',
  'domainmanagement@',
  'domains@',
  'editor@',
  'editorial@',
  'editors@',
  'education@',
  'emergency@',
  'engineering@',
  'engineers@',
  'enq@',
  'enqs@',
  'enquire@',
  'enquires@',
  'enquiry@',
  'enquiries@',
  'enquries@',
  'enterprise@',
  'error@',
  'everyone@',
  'fbl@',
  'feedback@',
  'finance@',
  'frontdesk@',
  'frontend@',
  'ftp@',
  'gen.enquiries@',
  'general.enquiries@',
  'general@',
  'generalenquiries@',
  'genoffice@',
  'head.office@',
  'head@',
  'headmaster@',
  'headoffice@',
  'headteacher@',
  'hello@',
  'help@',
  'helpdesk@',
  'helpline@',
  'hosting@',
  'hostmaster@',
  'hr@',
  'infodesk@',
  'INFORMATICA@',
  'information@',
  'informatique@',
  'informativo@',
  'infos@',
  'info-uk@',
  'inoc@',
  'inquires@',
  'inquiries@',
  'institute@',
  'insurance@',
  'integration@',
  'interns@',
  'investorrelations@',
  'investors@',
  'invoice@',
  'invoices@',
  'ispfeedback@',
  'ispsupport@',
  'it@',
  'ithelp@',
  'jira@',
  'jobs@',
  'kontakt@',
  'law@',
  'lawyers@',
  'leadership@',
  'leadership-team@',
  'legal@',
  'list@',
  'list-request@',
  'maildaemon@',
  'mailerdaemon@',
  'main.office@',
  'management.team@',
  'management_team@',
  'management-group@',
  'managers@',
  'marketing@',
  'marketing-ops@',
  'marketingteam@',
  'marketing-team@',
  'media@',
  'meetup@',
  'members@',
  'mgmt@',
  'newbusiness@',
  'nobody@',
  'noc@',
  'NOEMAIL@',
  'noemailadress@',
  'NOMAIL@',
  'noreply@',
  'no-reply@',
  'null@',
  'office.admin@',
  'office@',
  'officeadmin@',
  'officestaff@',
  'operations@',
  'ops@',
  'orders@',
  'payment@',
  'payments@',
  'paypal@',
  'payroll@',
  'partner@',
  'partners@',
  'partnerships@',
  'phish@',
  'phishing@',
  'planning@',
  'post@',
  'postbox@',
  'postfix@',
  'postmaster@',
  'pr@',
  'press@',
  'prime@',
  'principal@',
  'print@',
  'privacy@',
  'product.growth@',
  'product.management@',
  'product.managers@',
  'product.team@',
  'projectmanagers@',
  'qa@',
  'reception@',
  'recruit@',
  'recruiting@',
  'recruitment@',
  'registrar@',
  'remove@',
  'request@',
  'reservation@',
  'reservations@',
  'root@',
  'sale@',
  'sales.uk@',
  'sales@',
  'salesengineers@',
  'salesforce@',
  'salesinfo@',
  'salesteam@',
  'sales-team@',
  'salesuk@',
  'sales-uk@',
  'school.office@',
  'schooladmin@',
  'schoolinfo@',
  'schoolmail@',
  'secretary@',
  'security@',
  'server@',
  'service@',
  'services@',
  'shipping@',
  'sysadmin@',
  'systems@',
  'smtp@',
  'solicitors@',
  'solutions@',
  'subscribe@',
  'suggestions@',
  'support@',
  'teamleads@',
  'tech@',
  'technical@',
  'techops@',
  'techsupport@',
  'theoffice@',
  'trouble@',
  'ukinfo@',
  'uk-info@',
  'uksales@',
  'undisclosed-recipients@',
  'unsubscribe@',
  'usenet@',
  'users@',
  'uucp@',
  'vendors@',
  'venmo@',
  'watercooler@',
  'webadmin@',
  'webmaster@',
  'webteam@',
  'welcome@',
  'whois@',
  'www@',
  'HBOMax@',
  'newsletter@',
  'news@',
  'market-updates@',
  'ship@',
  'cargurus@',
  'email@',
  'notifications@',
  'team@',
  'do_not_reply@',
]
