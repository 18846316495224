import { useContext } from 'react'

import { Box, Button, IconButton, Typography } from '@mui/material'
import {
  RiFacebookFill,
  RiLinkedinFill,
  RiTwitterFill,
  RiWindowLine,
  RiYoutubeFill,
} from '@remixicon/react'

import { useQuery } from '@redwoodjs/web'

import OrganizationProperties from 'src/components/Organizations/OrganizationProperties/OrganizationProperties'
import RowSplit from 'src/components/RowSplit/RowSplit'
import { DayContext } from 'src/lib/dayContext'
import { NativeObjectTypes } from 'src/lib/objects'

import DomainAvatar from '../../DomainAvatar/DomainAvatar'
import Row from '../../Row/Row'

const GET_CORE_COMPANY_FOR_SIDEBAR = gql`
  query GetCoreCompanyForSidebar($domain: String!) {
    coreCompany(domain: $domain) {
      id
      name
      domain
      description
      aiDescription
      promises
      industry
      employeeCount
      annualRevenue
      funding
      address
      city
      state
      country
      postalCode
      colorVibrant
      colorDarkVibrant
      colorLightVibrant
      colorMuted
      colorDarkMuted
      colorLightMuted
      photoSquare
      photoIcon
      photoBanner
      stockTicker
      socialTwitter
      socialLinkedIn
      socialFacebook
      socialYouTube
      createdAt
      updatedAt
      edgarCik
      crunchbaseEntityId
      similarDomains
    }
  }
`

const SidebarOrganization = ({ domain, showTitle = true }) => {
  const { setSidebarObject } = useContext(DayContext)
  const { data } = useQuery(GET_CORE_COMPANY_FOR_SIDEBAR, {
    variables: {
      domain,
    },
    skip: !domain,
  })

  const header = { fontSize: '0.8rem', fontWeight: 500 }

  return data?.coreCompany?.domain ? (
    <Box sx={{ mt: 2 }}>
      {showTitle && (
        <Row>
          <DomainAvatar
            photoUrl={data?.coreCompany?.photoSquare}
            companyColor={data?.coreCompany?.colorVibrant}
            domain={domain}
            size={64}
            borderRadius={0}
          />
          <Box sx={{ ml: 2, width: '100%' }}>
            <Typography
              sx={{ fontWeight: 600, fontSize: '1.0rem', mb: '10px' }}
            >
              {data.coreCompany.name}
            </Typography>
            <Button
              variant="outlined"
              color="primary"
              fullWidth={true}
              size="small"
              onClick={() => {
                setSidebarObject({
                  objectType: NativeObjectTypes.Organization,
                  objectId: data.coreCompany.domain,
                  properties: data.coreCompany,
                })
              }}
            >
              view more
            </Button>
          </Box>
        </Row>
      )}
      <Typography sx={{ fontWeight: 300, fontSize: '0.8rem', my: 2 }}>
        {data.coreCompany.aiDescription || data.coreCompany.description || ''}
      </Typography>
      {(data.coreCompany.socialTwitter ||
        data.coreCompany.socialLinkedIn ||
        data.coreCompany.socialFacebook) && (
        <RowSplit
          sx={{
            mb: 2,
            '& .MuiIconButton-root': {
              padding: '4px',
              borderRadius: '2px',
            },
          }}
          left={<Typography sx={header}>Social</Typography>}
          right={
            <Row>
              <IconButton
                onClick={() =>
                  window.open(`https://${data.coreCompany.domain}`)
                }
              >
                <RiWindowLine />
              </IconButton>
              {data.coreCompany.socialTwitter && (
                <IconButton
                  onClick={() => window.open(data.coreCompany.socialTwitter)}
                >
                  <RiTwitterFill
                    style={{
                      color: '#1DA1F2',
                    }}
                  />
                </IconButton>
              )}
              {data.coreCompany.socialLinkedIn && (
                <IconButton
                  onClick={() => window.open(data.coreCompany.socialLinkedIn)}
                >
                  <RiLinkedinFill
                    style={{
                      color: '#0077B5',
                    }}
                  />
                </IconButton>
              )}

              {data.coreCompany.socialYouTube && (
                <IconButton
                  onClick={() => window.open(data.coreCompany.socialYouTube)}
                >
                  <RiYoutubeFill
                    style={{
                      color: '#FF0000',
                    }}
                  />
                </IconButton>
              )}

              {data.coreCompany.socialFacebook && (
                <IconButton
                  onClick={() => window.open(data.coreCompany.socialFacebook)}
                >
                  <RiFacebookFill
                    style={{
                      color: '#4267B2',
                    }}
                  />
                </IconButton>
              )}
            </Row>
          }
        />
      )}
      <OrganizationProperties coreCompany={data.coreCompany} />
    </Box>
  ) : (
    <Typography sx={{ fontSize: '0.8rem', fontWeight: 300 }}>
      Day.ai does not have any information on this organization, but we are
      working on it!
    </Typography>
  )
}

export default SidebarOrganization
