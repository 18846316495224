import { useAuth } from 'src/auth'
import { ungatedForDayObjects } from 'src/lib/gates'
import { logger } from 'src/lib/logger'

import SearchModalV1 from './SearchModalV1'
import SearchModalV2 from './SearchModalV2'

const SearchModal = (props) => {
  const { currentUser } = useAuth()

  logger.dev({ props })

  const ungated = ungatedForDayObjects(currentUser)
  return ungated ? <SearchModalV2 {...props} /> : <SearchModalV1 {...props} />
}

export default SearchModal
