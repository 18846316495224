import { useEffect, useMemo, useState } from 'react'

import { Avatar, type AvatarProps } from '@mui/material'
import { IconCircleFilled } from '@tabler/icons-react'
import type { CRMObject } from 'types/graphql'

import { getObjectMetadata } from 'src/lib/indexedDb'
import { logger } from 'src/lib/logger'
import type { NativeObjectType } from 'src/lib/objects'
import { NativeObjectTypes, ObjectTypeMetadata } from 'src/lib/objects'

import ContactAvatar from '../ContactAvatar'
import DomainAvatar from '../DomainAvatar/DomainAvatar'

const useObjectMetadata = (
  workspaceId: string,
  objectId: string,
  objectType: NativeObjectType,
  providedAvatarUrl?: string | null
) => {
  const [metadata, setMetadata] = useState<Record<string, any> | null>(null)
  const [isLoading, setIsLoading] = useState(!providedAvatarUrl)

  useEffect(() => {
    let ignore = false

    // If we have a provided avatarUrl, use that instead of fetching
    if (providedAvatarUrl !== undefined) {
      setMetadata({ avatarUrl: providedAvatarUrl })
      setIsLoading(false)
      return
    }

    const fetchMetadata = async () => {
      if (!objectId) {
        setIsLoading(false)
        return
      }

      try {
        const result = await getObjectMetadata({
          workspaceId,
          objectType,
          objectId,
        })

        if (!ignore) {
          setMetadata(result)
          setIsLoading(false)
        }
      } catch (error) {
        logger.error('Failed to fetch object metadata', error)
        if (!ignore) {
          setIsLoading(false)
        }
      }
    }

    setIsLoading(true)
    fetchMetadata()

    return () => {
      ignore = true
    }
  }, [workspaceId, objectId, objectType, providedAvatarUrl])

  return { metadata, isLoading }
}

const defaultSize = 24

const ObjectAvatar = ({
  workspaceId,
  crmObject,
  avatarProps,
  avatarUrl,
  size = defaultSize,
}: {
  workspaceId: string
  crmObject: CRMObject
  avatarProps: AvatarProps
  avatarUrl?: string | null
  size?: number
}): React.ReactNode => {
  // this component's only job is to get the proper avatar url from the crmObject
  // and pass it to a MUI Avatar component. It should do as little as possible
  // to provide flexibility for the caller.

  // we have three ways to get the avatar url, in order of preference:
  // 1. from the avatarUrl prop
  // 2. from the metadata cache
  // 3. by querying for the object

  // first, try to get the avatar url from metadata
  const { metadata } = useObjectMetadata(
    workspaceId,
    crmObject.objectId,
    crmObject.objectType as NativeObjectType,
    avatarUrl
  )

  const finalAvatarUrl = avatarUrl || metadata?.avatarUrl

  // if we don't have the avatar from the metadata, we need to query for the object
  // and get the avatar url from there

  const fallbackAvatar = useMemo(() => {
    const objectIcon =
      ObjectTypeMetadata[crmObject.objectType]?.icon || IconCircleFilled
    return (
      <Avatar
        src={null}
        sx={{
          background: (theme) => theme.palette.background.default,
        }}
      >
        {React.createElement(objectIcon, {
          size: '12px',
          stroke: 2.45,
          style: {
            marginTop: '-1px',
          },
        })}
      </Avatar>
    )
  }, [crmObject.objectType])

  return crmObject.objectType === NativeObjectTypes.Organization ? (
    <DomainAvatar
      domain={crmObject.objectId}
      size={size}
    />
  ) : crmObject.objectType === NativeObjectTypes.Person ? (
    <ContactAvatar
      email={crmObject.objectId}
      size={size}
    />
  ) : finalAvatarUrl ? (
    <Avatar
      src={finalAvatarUrl}
      {...avatarProps}
    />
  ) : (
    fallbackAvatar
  )
}

export default ObjectAvatar
