import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)

import { logger } from 'src/lib/logger'

import { timeAgoShort } from './time'

const MAX_STRING_LENGTH = 150

export const linkedInPostUrl = (urn) => {
  return `https://www.linkedin.com/feed/update/${urn}`
}

export const ellipsize = (text, chars) => {
  if (!text) return ''
  if (text.length < chars) return text

  return `${text.slice(0, chars - 1)} ...`
}

export const pluralize = (
  number: number,
  singular: string,
  plural?: string
) => {
  if (!(!!number && !!singular)) {
    return null
  }

  plural = plural ? plural : `${singular}s`

  return number === 1 ? singular : plural
}

export const oxfordList = (
  values: Array<string | number>,
  limit?: number,
  limitText?: string
) => {
  let limitIndex
  if (values.length < 2) {
    return String(values)
  } else if (values.length === 2) {
    return values.join(' and ')
  } else if (!!limit && values.length > limit) {
    const extra = values.length - limit
    limitIndex = limit
    limitText = limitText
      ? limitText
      : `, and ${extra} ${pluralize(extra, 'other')}`
  } else {
    limitIndex = -1
    limitText = `, and ${values[values.length - 1]}`
  }

  return values.slice(0, limitIndex).join(', ') + limitText
}

export const jsonDisplay = (obj: unknown) => {
  return (
    <pre>
      <code>{JSON.stringify(obj, null, 2)}</code>
    </pre>
  )
}

export const truncate = (value: string | number) => {
  let output = value?.toString() ?? ''

  if (output.length > MAX_STRING_LENGTH) {
    output = output.substring(0, MAX_STRING_LENGTH) + '...'
  }

  return output
}

export const jsonTruncate = (obj: unknown) => {
  return truncate(JSON.stringify(obj, null, 2))
}

export const timeTag = (time) => {
  if (!time) {
    return null
  }

  const date = new Date(time)
  const formattedDate = `${date.getMonth() + 1}.${date.getDate()}.${date
    .getFullYear()
    .toString()
    .substr(-2)}`

  return formattedDate
}

export const checkboxInputTag = (checked: boolean) => {
  return (
    <input
      type="checkbox"
      checked={checked}
      disabled
    />
  )
}

export function formatEventTime(timestamp) {
  if (!timestamp) return ''
  try {
    const eventDate = new Date(timestamp)

    // Options for day, month, year, hour, and minute
    const dateOptions = {
      weekday: 'long' as 'long' | 'short' | 'narrow',
      year: 'numeric' as 'numeric' | '2-digit',
      month: 'long' as 'long' | 'short' | 'narrow',
      day: 'numeric' as 'numeric' | '2-digit',
      hour: 'numeric' as 'numeric' | '2-digit',
      minute: '2-digit' as 'numeric' | '2-digit',
      hour12: true,
    }

    // Formatter for date and time
    const dateFormatter = new Intl.DateTimeFormat('en-US', dateOptions)

    // Get the formatted date and time
    let formattedDateTime = dateFormatter.format(eventDate)

    // Add ordinal suffix to day
    formattedDateTime = formattedDateTime.replace(
      /(\d+)(?=\D+\d+$)/,
      (_, day) => day + getOrdinalSuffix(day)
    )

    return formattedDateTime
  } catch (error) {
    logger.error('Error formatting event time', error)
    return ''
  }
}

export function formatEventTimeForTable(timestamp) {
  if (!timestamp) return ''
  const eventDate = dayjs(timestamp)

  return eventDate.format('M/D/YY')
}

// Helper function to get the ordinal suffix for the day
export function getOrdinalSuffix(day) {
  const j = day % 10,
    k = day % 100
  if (j === 1 && k !== 11) {
    return 'st'
  }
  if (j === 2 && k !== 12) {
    return 'nd'
  }
  if (j === 3 && k !== 13) {
    return 'rd'
  }
  return 'th'
}

export const calculateSpeakerPercentage = (transcript) => {
  // Step 1: Calculate the total speaking time for each speaker
  const speakerTimes = transcript.utterances.reduce((acc, utterance) => {
    const duration = utterance.words.reduce(
      (durationAcc, word) =>
        durationAcc + (word.endSeconds - word.startSeconds),
      0
    )

    if (acc[utterance.speaker]) {
      acc[utterance.speaker] += duration
    } else {
      acc[utterance.speaker] = duration
    }

    return acc
  }, {})

  // Step 2: Calculate the total duration of the meeting
  const totalDuration = Object.values(speakerTimes).reduce(
    (acc, time) => acc + time,
    0
  )

  // Step 3: Calculate the percentage of time each speaker spoke
  const speakerPercentages = Object.entries(speakerTimes).map(
    ([speaker, time]) => {
      return {
        speaker,
        percentage: ((time / totalDuration) * 100).toFixed(2), // rounding to 2 decimal places
      }
    }
  )

  return speakerPercentages
}

export const getEmailFromParticipants = (
  participants,
  usernameFromRecording
) => {
  const participant = participants.find((participant) => {
    return participant.usernameFromRecording === usernameFromRecording
  })

  return participant ? participant.email : null
}

export const getContactFromParticipants = (
  participants,
  usernameFromRecording
) => {
  const participant = participants.find((participant) => {
    return participant.usernameFromRecording === usernameFromRecording
  })

  return participant ? participant.contact : null
}

export type DocumentLink = {
  url: string
  label: string
}

export const clampStyle = (rows) => {
  return {
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: rows,
    WebkitBoxOrient: 'vertical',
    textOverflow: 'ellipsis',
  }
}

export const getCompanyLogoUrl = ({ domain, photoUrl }): string => {
  const s3BucketUrl = 'https://core-company-photos.s3.amazonaws.com/'
  if (!domain && !photoUrl) return ''
  if (domain === 'sequoiacap.com')
    return 'https://pbs.twimg.com/profile_images/1468287449130422278/kv_Og2d2_400x400.png'
  if (domain === 'day.ai')
    return `${process.env.HOST}/logos/logo-blue-inverse.png`
  if (photoUrl) {
    if (photoUrl.startsWith('logos/')) return `${s3BucketUrl}${photoUrl}`
    else if (photoUrl.startsWith('http')) return photoUrl
  }
  return ``
}

function extractUrlsWithLabels(escapedHtml: string): DocumentLink[] {
  if (!escapedHtml) return []

  const html = escapedHtml?.replace(/\\"/g, '"')

  const linkWithLabelRegex = /<a href="([^"]+?)"[^>]*>(.*?)<\/a>/gi
  const links: DocumentLink[] = []
  const seenUrls = new Set()
  let match

  // Regex to capture text immediately before the link
  const precedingTextRegex =
    /(?:<br>|<p>|\.|\s|^)([^<.:]*):\s*<a href="[^"]+?"/i

  while ((match = linkWithLabelRegex.exec(html)) !== null) {
    let url = match[1].trim()
    let label = match[2].trim()

    // Remove extra characters from URL
    url = url.replace(/<\/a>|"/g, '')

    if (seenUrls.has(url)) {
      continue
    }
    seenUrls.add(url)

    // Use preceding text as label if URL and label are the same
    if (url === label || label.includes(url) || label.startsWith('http')) {
      const precedingText = html.substring(0, match.index)
      const precedingTextMatch = precedingText.match(precedingTextRegex)
      if (precedingTextMatch && precedingTextMatch[1]) {
        label = precedingTextMatch[1].trim()
      } else if (url.includes('cancel')) {
        label = 'Cancel'
      } else if (url.includes('reschedule')) {
        label = 'Reschedule'
      } else {
        // Extract domain and TLD from URL
        const domainMatch = url.match(/https?:\/\/([^\/]+)/)
        label = domainMatch ? domainMatch[1] : 'Link'
      }
    }

    links.push({ url, label })
  }

  return links
}

function extractZoomUrls(text: string): string[] {
  if (!text) return []

  const zoomUrlRegex = /https:\/\/[\w.-]*zoom\.us\/j\/\d+[^\s<"]*/gi
  const seenUrls = new Set()

  return [...text.matchAll(zoomUrlRegex)]
    .map((match) => {
      // Extract the URL and remove potential trailing HTML tags or quotes
      const cleanUrl = match[0].replace(/<\/?[a-z]+>|"/gi, '').trim()
      return cleanUrl
    })
    .filter((url) => {
      if (seenUrls.has(url)) {
        return false // Skip if URL is already seen
      }
      seenUrls.add(url)
      return true
    })
}

export const extractLinkedInUrls = (text: string): string[] => {
  if (!text) return []
  const linkedInUrlRegex =
    /https?:\/\/(www\.)?linkedin\.com\/in\/[a-zA-Z0-9-_%]+/gi
  return text.match(linkedInUrlRegex) || []
}

export const parseEventDescription = (
  description: string,
  includeZoom = false
) => {
  const documentLinks = extractUrlsWithLabels(description)
  const zoomUrls = extractZoomUrls(description)
  const linkedInUrls = extractLinkedInUrls(description)

  const links = []

  // Zoom URLs
  if (includeZoom) {
    zoomUrls.forEach((url) => {
      links.push({
        type: 'zoom',
        label: 'Join Zoom Meeting',
        url,
      })
    })
  }
  // Reschedule URLs
  documentLinks.forEach((link) => {
    if (link.label.toLowerCase().includes('reschedule')) {
      links.push({
        type: 'reschedule',
        label: link.label,
        url: link.url,
      })
    }
  })

  // Cancel URLs
  documentLinks.forEach((link) => {
    if (link.label.toLowerCase().includes('cancel')) {
      links.push({
        type: 'cancel',
        label: link.label,
        url: link.url,
      })
    }
  })

  // LinkedIn URLs (assuming they are already extracted somewhere else)
  for (const url of linkedInUrls.filter(Boolean)) {
    // Remove trailing slash if present
    const cleanUrl = url.replace(/\/+$/, '')
    // Extract the username by splitting the URL by slashes and taking the last part
    const username = cleanUrl.split('/').pop()
    // Use @username as the label, defaulting to 'LinkedIn Profile' if the username is not found
    const label = username ? `@${username}` : 'LinkedIn Profile'

    links.push({
      type: 'linkedIn',
      label: label,
      url: cleanUrl,
    })
  }

  const documentServices = [
    { type: 'googleDrive', domain: 'drive.google.com' },
    { type: 'canva', domain: 'canva.com' },
    { type: 'dropbox', domain: 'dropbox.com' },
    { type: 'notion', domain: 'notion.so' },
    { type: 'coda', domain: 'coda.io' },
    { type: 'clickup', domain: 'clickup.com' },
    { type: 'monday', domain: 'monday.com' },
    { type: 'atlassian', domain: 'atlassian.com' },
  ]

  // Document links for Google Drive, Canva, and Dropbox
  documentLinks.forEach((link) => {
    documentServices.forEach((service) => {
      if (link.url.includes(service.domain)) {
        links.push({
          type: service.type,
          label: link.label,
          url: link.url,
        })
      }
    })
  })

  return links
}

export const displayName = (contact) => {
  if (contact.properties.firstName) {
    return `${contact.properties.firstName} ${
      contact.properties.lastName || ''
    }`
  } else return contact.objectId
}

function processDate(dt) {
  let ago
  try {
    const date = new Date(dt)
    if (isNaN(date.getTime())) {
      logger.warn('Not a valid date: ' + dt)
    }
    ago = timeAgoShort(date)
  } catch (error) {
    logger.error('Error processing date', error)
    ago = '' // or handle it in another appropriate way
  }
  return ago
}

export const meetingNotesClassificationLabel = (classification) => {
  return classification
    .replace(/_/g, ' ')
    .toLowerCase()
    .replace(/\b\w/g, (char) => char.toUpperCase())
}

export const lastContact = (contact) => {
  let dt
  if (contact.properties?.lastEmailAny) {
    dt = contact.properties.lastEmailAny
  }
  if (
    contact.properties?.lastMet &&
    contact.properties?.lastEmailAny < contact.properties.lastMet
  ) {
    dt = contact.properties.lastMet
  }
  if (!dt) return ''

  const ago = processDate(dt)

  return ago ? `${ago} ago` : ''
}

export function formatSeconds(seconds) {
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = Math.floor(seconds % 60)
  return `${minutes.toString().padStart(1, '0')}:${remainingSeconds
    .toString()
    .padStart(2, '0')}`
}

export const toCurrencyDisplay = (value) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value)
}
