import { useCallback, memo, useState, useContext, useMemo } from 'react'

import { Box, Chip, IconButton, Tooltip, Typography } from '@mui/material'
import {
  IconCopy,
  IconEye,
  IconFile,
  IconLock,
  IconMailShare,
  IconPencil,
  IconUsers,
} from '@tabler/icons-react'
import toast from 'react-hot-toast'
import type { GmailDraftInput } from 'types/graphql'

import { navigate, routes } from '@redwoodjs/router'
import { useMutation, useQuery } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import ObjectSharingPopover from 'src/components/ObjectSharingPopover'
import EmbeddedAiPageEditor from 'src/components/Page/EmbeddedAiPageEditor/EmbeddedAiPageEditor'
import PageActions from 'src/components/Page/PageActions/PageActions'
import {
  CREATE_EMAIL_DRAFT,
  CONVERT_PAGE_TO_KNOWLEDGE,
  CONVERT_PAGE_TO_TEMPLATE,
} from 'src/components/Pages/mutations'
import { GET_ACTION_FOR_DRAFT, GET_PAGE } from 'src/components/Pages/queries'
import TemplateTypeChooserMenu from 'src/components/Pages/TemplateTypeChooserMenu'
import Row from 'src/components/Row/Row'
import TemplateIcon from 'src/components/Templates/TemplateIcon/TemplateIcon'
import { PageProvider } from 'src/hooks/usePage'
import { usePage } from 'src/hooks/usePage/usePage'
import { DayContext } from 'src/lib/dayContext'
import { ungatedForKnowledge, ungatedForTemplates } from 'src/lib/gates'
import { NativeObjectTypes, TemplateTypes } from 'src/lib/objects'

import SidebarButton from '../SidebarButton/SidebarButton'
import SidebarLoader from '../SidebarLoader/SidebarLoader'
import { actionButtonStyle } from '../styles'

const ellipsizedSx = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: `${7 * 69}px`,
}

const SidebarPageHeader = memo(({ onClose }: { onClose: () => void }) => {
  const { currentUser } = useAuth()
  const { workspaces, selectedWorkspace } = useContext(DayContext)

  const workspaceName = workspaces.find(
    ({ id }) => id === selectedWorkspace
  )?.name

  const [sharingPopoverAnchorEl, setSharingPopoverAnchorEl] =
    useState<HTMLButtonElement | null>(null)

  const [templateTypeAnchorEl, setTemplateTypeAnchorEl] =
    useState<HTMLElement | null>(null)
  const { page, refetch, ungatedForButtons, userHasEditorAccess, parentQuery } =
    usePage()

  const [createEmailDraft] = useMutation(CREATE_EMAIL_DRAFT)
  const [convertPageToTemplate] = useMutation(CONVERT_PAGE_TO_TEMPLATE)
  const [convertPageToKnowledge] = useMutation(CONVERT_PAGE_TO_KNOWLEDGE)

  const handleMaximize = useCallback(() => {
    navigate(routes.pageDetail({ id: page?.id }))
    onClose()
  }, [page?.id, onClose])

  const handleToggleTemplate = useCallback(
    (templateType = null) => {
      toast.promise(
        convertPageToTemplate({
          variables: {
            id: page?.id,
            workspaceId: page?.workspaceId,
            outputType: templateType,
            undo: !!page?.templateType,
          },
        }),
        {
          loading: 'Converting to template',
          success: !page?.templateType
            ? 'Page converted to template'
            : 'Template removed',
          error: 'Failed to convert to template',
        }
      )
    },
    [convertPageToTemplate, page?.id, page?.templateType, page?.workspaceId]
  )

  const handleToggleKnowledge = useCallback(() => {
    toast.promise(
      convertPageToKnowledge({
        variables: {
          id: page?.id,
          workspaceId: page?.workspaceId,
          undo: page?.isKnowledge,
        },
      }),
      {
        loading: 'Converting to knowledge',
        success: 'Page converted to knowledge',
        error: 'Failed to convert to knowledge',
      }
    )
  }, [convertPageToKnowledge, page?.id, page?.isKnowledge, page?.workspaceId])

  const { data: parentAction } = useQuery(GET_ACTION_FOR_DRAFT, {
    variables: {
      workspaceId: selectedWorkspace,
      id: page?.parentObject?.objectId,
    },
    skip:
      !page?.parentObject?.objectId ||
      page?.parentObject?.objectType !== NativeObjectTypes.Action,
  })

  const { data: template } = useQuery(GET_PAGE, {
    variables: {
      workspaceId: selectedWorkspace,
      id: page?.sourceTemplate?.id,
    },
    skip: !page?.sourceTemplate?.id,
  })

  const draft = useMemo<GmailDraftInput | null>(() => {
    return {
      subject: page?.title,
      body: page?.contentHtml,
      to: parentAction?.action?.people
        ?.map((person) => person?.email)
        .filter(Boolean),
      cc: [],
      bcc: [],
      threadId:
        page?.sourceTemplate?.templateType === TemplateTypes.EMAIL
          ? parentAction?.action?.channel?.id
          : undefined,
    }
  }, [
    page?.title,
    page?.sourceTemplate?.templateType,
    page?.contentHtml,
    parentAction?.action?.channel?.id,
    parentAction?.action?.people,
  ])

  const handleDraftEmail = useCallback(() => {
    toast.promise(
      createEmailDraft({
        variables: {
          workspaceId: selectedWorkspace,
          draft: draft,
        },
      }),
      {
        loading: 'Creating email draft',
        success: 'Email draft created',
        error: 'Failed to create email draft',
      }
    )
  }, [createEmailDraft, selectedWorkspace, draft])

  const handleCopyToClipboard = useCallback(() => {
    const text = page?.contentHtml || page?.title || ''
    navigator.clipboard.writeText(text)
    toast.success('Copied to clipboard')
  }, [page?.contentHtml, page?.title])

  const shouldRenderDraftEmail = useMemo(() => {
    return !page?.isKnowledge && !page?.templateType
  }, [page?.isKnowledge, page?.templateType])

  const buttons = [
    /*
            <Chip
              size="small"
              variant="outlined"
              label={showPrompt ? 'Hide revise' : 'Revise with Day.ai'}
              onClick={handleTogglePrompt}
              sx={actionButtonStyle}
              icon={<IconSparkles />}
            />
            <Chip
              size="small"
              variant="outlined"
              label={
                showVersionHistory ? 'Hide version history' : 'Version history'
              }
              onClick={handleToggleVersionHistory}
              sx={actionButtonStyle}
              icon={<IconHistory size={16} />}
              className={showVersionHistory ? 'selected' : ''}
            />
            <Chip
              size="small"
              variant="outlined"
              label={'Create web page'}
              onClick={handleCreateWebPage}
              sx={actionButtonStyle}
              icon={<IconWorld />}
            />
            */
    {
      shouldRender: !page?.isKnowledge && ungatedForTemplates(currentUser),
      jsx: (
        <>
          <Chip
            key="template-button"
            size="small"
            variant="outlined"
            label={page?.templateType ? 'Remove template' : 'Save as template'}
            onClick={(e) => {
              if (page?.templateType) {
                handleToggleTemplate()
              } else {
                setTemplateTypeAnchorEl(e.currentTarget)
              }
            }}
            sx={actionButtonStyle}
            icon={<IconFile />}
            disabled={!userHasEditorAccess}
          />
          <TemplateTypeChooserMenu
            key="template-type-chooser"
            anchorEl={templateTypeAnchorEl}
            onSelect={(templateType) => {
              handleToggleTemplate(templateType)
              setTemplateTypeAnchorEl(null)
            }}
            onClose={() => setTemplateTypeAnchorEl(null)}
          />
        </>
      ),
    },
    {
      shouldRender: !page?.templateType && ungatedForKnowledge(currentUser),
      jsx: (
        <Chip
          key="knowledge-button"
          size="small"
          variant="outlined"
          label={
            page?.isKnowledge ? 'Remove from Knowledge' : 'Save to Knowledge'
          }
          onClick={handleToggleKnowledge}
          sx={actionButtonStyle}
          icon={<IconFile />}
          disabled={!userHasEditorAccess}
        />
      ),
    },
    {
      shouldRender: shouldRenderDraftEmail,
      jsx: (
        <Chip
          key="draft-email-button"
          size="small"
          variant="outlined"
          label={'Draft email'}
          onClick={handleDraftEmail}
          sx={actionButtonStyle}
          icon={<IconMailShare />}
        />
      ),
    },
    {
      shouldRender: true,
      jsx: (
        <Chip
          key="copy-button"
          size="small"
          variant="outlined"
          label={'Copy'}
          onClick={handleCopyToClipboard}
          sx={actionButtonStyle}
          icon={<IconCopy />}
        />
      ),
    },
  ]

  return (
    page && (
      <Box
        sx={{
          height: ungatedForButtons ? '96px' : '64px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Row
          sx={{
            px: '12px',
            justifyContent: 'space-between',
            height: '36px',
          }}
        >
          <Row
            gap={1}
            sx={{ height: '36px' }}
          >
            {template?.workspacePage?.templateType || page?.templateType ? (
              <TemplateIcon
                templateType={
                  template?.workspacePage?.templateType || page?.templateType
                }
                size={24}
              />
            ) : (
              <IconFile
                size={24}
                style={{ flexShrink: 0 }}
              />
            )}
            <Typography
              variant="h2"
              sx={ellipsizedSx}
            >
              {page?.title}
            </Typography>
            {!userHasEditorAccess && (
              <Tooltip title={'Viewing'}>
                <span>
                  <IconEye size={16} />
                </span>
              </Tooltip>
            )}
          </Row>
          <Row sx={{ justifyContent: 'flex-end', height: '100%' }}>
            <Tooltip
              title={
                page?.authorization?.workspace.isShared
                  ? `Shared with ${workspaceName}`
                  : page?.authorization?.users.some(
                        ({ id }) => id !== currentUser.id
                      )
                    ? `Shared with ${
                        page?.authorization?.users.filter(
                          ({ id }) => id !== currentUser.id
                        ).length
                      } other ${
                        page?.authorization?.users.filter(
                          ({ id }) => id !== currentUser.id
                        ).length === 1
                          ? 'person'
                          : 'people'
                      }`
                    : `Only shared with you`
              }
              arrow={true}
            >
              <SidebarButton
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                  setSharingPopoverAnchorEl(event.currentTarget)
                }}
                icon={
                  page?.authorization?.workspace.isShared ? (
                    <IconUsers size={16} />
                  ) : page?.authorization?.users.some(
                      ({ id }) => id !== currentUser.id
                    ) ? (
                    <IconUsers size={16} />
                  ) : (
                    <IconLock size={16} />
                  )
                }
                label={
                  page?.authorization?.workspace.isShared
                    ? `Shared`
                    : page?.authorization?.users.some(
                          ({ id }) => id !== currentUser.id
                        )
                      ? `Shared`
                      : `Private`
                }
              />
            </Tooltip>
            <ObjectSharingPopover
              open={Boolean(sharingPopoverAnchorEl)}
              anchorElement={sharingPopoverAnchorEl}
              onClose={() => setSharingPopoverAnchorEl(null)}
              workspaceId={selectedWorkspace}
              objectId={page.id}
              objectType={NativeObjectTypes.Page}
              authorization={page?.authorization}
              parentQuery={parentQuery}
              isPublic={!!page.madeExternalAt}
            />
            <Tooltip title={'Open in full page view'}>
              <span>
                <IconButton
                  onClick={handleMaximize}
                  sx={{
                    p: '4px',
                    borderRadius: '4px',
                  }}
                >
                  <IconPencil size={16} />
                </IconButton>
              </span>
            </Tooltip>
            <>
              <PageActions
                page={page}
                refetch={refetch}
                orientation={'vertical'}
              />
            </>
          </Row>
        </Row>
        {ungatedForButtons && (
          <Row
            sx={{
              px: '12px',
              mt: 1,
            }}
            gap={0.5}
          >
            {buttons
              .filter((button) => button.shouldRender)
              .map((button) => button.jsx)}
          </Row>
        )}
      </Box>
    )
  )
})

const SidebarPageContent = () => {
  const { token, ungatedForButtons, userHasEditorAccess } = usePage()

  return token ? (
    <Box
      sx={{
        height: ungatedForButtons ? 'calc(100% - 96px)' : 'calc(100% - 64px)',
        px: 3,
        py: 0,
      }}
    >
      <EmbeddedAiPageEditor userHasEditorAccess={userHasEditorAccess} />
    </Box>
  ) : (
    <SidebarLoader />
  )
}

const SidebarLayoutPage = ({
  crmObject,
  onClose,
}: {
  crmObject: any
  onClose: () => void
}) => {
  const pageId = crmObject.objectId
  const workspaceId = crmObject.workspaceId

  const boxSx = {
    height: '100%',
    overflow: 'hidden',
  }

  return (
    pageId &&
    workspaceId && (
      <Box
        sx={boxSx}
        key={pageId}
      >
        <PageProvider
          pageId={pageId}
          workspaceId={workspaceId}
        >
          <Box sx={boxSx}>
            <SidebarPageHeader onClose={onClose} />
            <SidebarPageContent />
          </Box>
        </PageProvider>
      </Box>
    )
  )
}

export default SidebarLayoutPage
