import { IconButton } from '@mui/material'
import { IconArrowLeft, IconArrowRight } from '@tabler/icons-react'

import Row from 'src/components/Row/Row'

const SidebarHistory = ({ history }: { history: any }) => {
  return (
    <Row>
      <IconButton
        disabled={!history.hasBack}
        onClick={history.handleBack}
      >
        <IconArrowLeft
          size={24}
          style={{ flexShrink: 0 }}
        />
      </IconButton>
      <IconButton
        disabled={!history.hasForward}
        onClick={history.handleForward}
      >
        <IconArrowRight
          size={24}
          style={{ flexShrink: 0 }}
        />
      </IconButton>
    </Row>
  )
}

export default SidebarHistory
