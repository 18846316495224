import { useContext } from 'react'

import { Box, Typography } from '@mui/material'

import { NativeObjectFormatters } from 'src/lib/contactFormatting'
import { DayContext } from 'src/lib/dayContext'
import { NativeObjectTypes } from 'src/lib/objects'

import ContactAvatar from './ContactAvatar'

const WorkspaceMemberTile = ({
  workspaceId,
  userId,
  email,
  subtitle,
}: {
  workspaceId: string
  userId?: string
  email?: string
  subtitle?: string
}) => {
  const { workspaces } = useContext(DayContext)

  const workspace = workspaces.find((w) => w.id === workspaceId)
  const member =
    (userId && workspace?.members.find((m) => m.id === userId)) ||
    (email && workspace?.members.find((m) => m.email === email))

  if (!member) {
    return null
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <ContactAvatar
        email={member.email}
        size={32}
      />
      <Box sx={{ ml: 1.5, width: 'calc(100% - 56px)' }}>
        <Typography
          sx={{
            width: '100%',
            fontWeight: 500,
            fontSize: '0.8rem',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          {member.coreContact?.firstName
            ? NativeObjectFormatters[NativeObjectTypes.Contact].label({
                properties: member.coreContact,
              })
            : member.email}
        </Typography>
        {subtitle && (
          <Typography
            sx={{
              width: '100%',
              fontWeight: 400,
              fontSize: '0.6rem',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              ml: 0.1,
            }}
          >
            {subtitle}
          </Typography>
        )}
      </Box>
    </Box>
  )
}

export default WorkspaceMemberTile
