import { lighten } from '@mui/material'
const universalTextMargin = '40px'

// Typography System Configuration
const baselineGrid = 4 // 4px grid
const fluid = {
  min: 320, // Min viewport width
  max: 1200, // Max viewport width
}

// Fluid typography calculator
const fluidCalc = (minSize, maxSize) => {
  const slope = (maxSize - minSize) / (fluid.max - fluid.min)
  const base = minSize - slope * fluid.min
  return `clamp(${minSize}px, ${base}px + ${slope * 100}vw, ${maxSize}px)`
}

// Baseline grid alignment calculator
const calculateBaseline = (fontSize, lineHeight) => {
  const baselineNumber = Math.ceil((fontSize * lineHeight) / baselineGrid)
  return (baselineNumber * baselineGrid) / fontSize
}

// Advanced typography settings
const fontSettings = {
  optimizeLegibility: {
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
    textRendering: 'optimizeLegibility',
    textSizeAdjust: '100%',
  },
  kerning: {
    fontKerning: 'normal',
    fontFeatureSettings: "'kern' 1, 'liga' 1, 'calt' 1, 'cv01' 1, 'ss01' 1",
  },
  numbers: {
    fontVariantNumeric: 'proportional-nums tabular-nums lining-nums',
  },
}

// Typography scale with optical adjustments
const typography = {
  display: {
    size: '48px',
    weight: 600,
    letterSpacing: '-2.4px',
    lineHeight: calculateBaseline(48, 1.1),
  },
  h1: {
    size: '30px',
    weight: 600,
    letterSpacing: '-1.5px',
    lineHeight: calculateBaseline(30, 1.3),
  },
  h2: {
    size: '24px',
    weight: 600,
    letterSpacing: '-1.2px',
    lineHeight: calculateBaseline(24, 1.35),
  },
  h3: {
    size: '18px',
    weight: 600,
    letterSpacing: '-0.9px',
    lineHeight: calculateBaseline(18, 1.4),
  },
  h4: {
    size: '16px',
    weight: 500,
    letterSpacing: '-0.5px',
    lineHeight: calculateBaseline(16, 1.45),
  },
  body: {
    size: '12px',
    weight: 400,
    letterSpacing: '-0.22px',
    lineHeight: calculateBaseline(12, 1.6),
  },
}

// Main editor styles
export const editorStyle = {
  // Base container optimizations
  height: '100%',
  overflowY: 'auto',
  pb: '72px',
  ...fontSettings.optimizeLegibility,
  ...fontSettings.kerning,

  // Font loading optimizations
  '@font-face': {
    fontFamily: 'Poppins',
    fontDisplay: 'optional',
    fontWeight: '400 600',
    fontStretch: '75% 125%',
    src: 'local("Poppins")',
  },

  // Editor core
  '& .tiptap': {
    outline: 'none !important',
    // Establish vertical rhythm
    '& > * + *': {
      marginTop: `${baselineGrid * 4}px`,
    },

    // Advanced typography features
    fontFeatureSettings: "'kern' 1, 'liga' 1, 'calt' 1, 'ss01' 1",
    fontVariantLigatures: 'common-ligatures contextual',
    fontOpticalSizing: 'auto',

    // Paragraph styles with optical adjustments
    '& p': {
      fontSize: typography.body.size,
      lineHeight: typography.body.lineHeight,
      letterSpacing: typography.body.letterSpacing,
      fontWeight: typography.body.weight,
      marginBottom: `${baselineGrid * 3}px`,
      hyphens: 'auto',
      overflowWrap: 'break-word',

      '@supports (hanging-punctuation: first)': {
        hangingPunctuation: 'first',
      },

      '& strong': {
        fontWeight: 600,
        letterSpacing: '-0.3px', // Slightly tighter for bold
      },

      '& a': {
        color: (theme) => theme.palette.secondary.main,
        cursor: 'pointer',
        textUnderlineOffset: '0.15em',
        textDecorationThickness: '1px',
        transition: 'text-decoration-thickness 0.2s',

        '&:hover': {
          textDecorationThickness: '2px',
        },
      },
    },

    // Headings with fluid typography
    '& h1': {
      fontSize: fluidCalc(24, 30),
      lineHeight: typography.h1.lineHeight,
      letterSpacing: typography.h1.letterSpacing,
      fontWeight: typography.h1.weight,
      color: 'text.primary',

      '&.title': {
        fontSize: {
          xs: fluidCalc(24, 32),
          sm: fluidCalc(26, 34),
          md: fluidCalc(28, 36),
          lg: fluidCalc(30, 38),
          xl: fluidCalc(32, 40),
        },
        letterSpacing: '-1.8px',
      },
    },

    '& h2': {
      fontSize: fluidCalc(20, 24),
      lineHeight: typography.h2.lineHeight,
      letterSpacing: typography.h2.letterSpacing,
      fontWeight: typography.h2.weight,
    },

    '& h3': {
      fontSize: typography.h3.size,
      lineHeight: typography.h3.lineHeight,
      letterSpacing: typography.h3.letterSpacing,
      fontWeight: typography.h3.weight,
    },

    '& h4': {
      fontSize: typography.h4.size,
      lineHeight: typography.h4.lineHeight,
      letterSpacing: typography.h4.letterSpacing,
      fontWeight: typography.h4.weight,
      color: 'text.secondary',
    },

    // Lists with advanced typographic refinements
    '& ul': {
      listStyleType: 'disc',
      padding: 0,
      marginLeft: `${baselineGrid * 8}px`,

      '& ul': {
        listStyleType: 'circle',
        marginLeft: `${baselineGrid * 6}px`,
        marginTop: `${baselineGrid}px`,
        marginBottom: `${baselineGrid}px`,

        '& ul': {
          listStyleType: 'square',
        },
      },
    },

    '& ol': {
      listStyleType: 'decimal',
      padding: 0,
      marginLeft: `${baselineGrid * 8}px`,
      counterReset: 'list-counter',

      '& ol': {
        listStyleType: 'lower-alpha',
        marginLeft: `${baselineGrid * 6}px`,
        marginTop: `${baselineGrid}px`,
        marginBottom: `${baselineGrid}px`,

        '& ol': {
          listStyleType: 'lower-roman',
        },
      },
    },

    '& ul, & ol': {
      '& li': {
        marginBottom: `${baselineGrid}px`,
        position: 'relative',
        paddingLeft: `${baselineGrid}px`,

        '&::marker': {
          color: (theme) => theme.palette.text.secondary,
          fontSize: '0.9em',
        },

        // Ensure paragraphs in list items are properly aligned
        '& p': {
          margin: 0,
          padding: 0,
        },

        // Custom counters for nested ordered lists
        '@counter-style custom-counter': {
          system: 'extends decimal',
          suffix: '.',
        },
      },

      // Task list refinements
      '&[data-type="taskList"]': {
        marginLeft: `${baselineGrid * 4}px`,
        listStyleType: 'none',

        '& li': {
          display: 'grid',
          gridTemplateColumns: 'auto 1fr',
          gap: `${baselineGrid * 2}px`,
          alignItems: 'start',
          marginLeft: 0,

          '& label': {
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            paddingTop: '2px',

            '& input[type="checkbox"]': {
              margin: 0,
              transform: 'scale(1.1)',
              accentColor: (theme) => theme.palette.primary.main,
            },
          },
        },
      },
    },

    // Blockquotes with refined typography
    '& blockquote': {
      borderLeft: (theme) => `6px solid ${theme.palette.divider}`,
      margin: `${baselineGrid * 6}px 0`,
      padding: `${baselineGrid * 4}px`,
      fontStyle: 'italic',
      position: 'relative',

      '&::before': {
        content: '"""',
        position: 'absolute',
        top: `-${baselineGrid * 2}px`,
        left: `-${baselineGrid}px`,
        fontSize: '3em',
        opacity: 0.1,
      },

      '& p': {
        lineHeight: '2.0',
        margin: 0,
      },
    },

    // Tables with consistent typography
    '& table': {
      width: '100%',
      borderCollapse: 'collapse',
      ...fontSettings.numbers,
      margin: `${baselineGrid * 6}px 0`,

      '& th': {
        fontWeight: 600,
        letterSpacing: '-0.3px',
        textAlign: 'left',
        borderBottom: (theme) => `2px solid ${theme.palette.divider}`,
        padding: `${baselineGrid * 2}px ${baselineGrid * 3}px`,
      },

      '& td': {
        padding: `${baselineGrid * 2}px ${baselineGrid * 3}px`,
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        verticalAlign: 'top',
      },

      '&.day-ai-table-text': {
        borderRadius: '6px',
        overflow: 'hidden',
        border: (theme) => `1px solid ${theme.palette.divider}`,

        '& th, & td': {
          '&:first-of-type': {
            paddingLeft: `${baselineGrid * 4}px`,
          },
          '&:last-of-type': {
            paddingRight: `${baselineGrid * 4}px`,
          },
        },
      },
    },

    // Code blocks with monospace optimizations
    '& pre': {
      fontFamily: 'JetBrains Mono, Consolas, Monaco, monospace',
      fontSize: '0.9em',
      lineHeight: 1.5,
      padding: `${baselineGrid * 4}px`,
      borderRadius: '4px',
      overflow: 'auto',
      backgroundColor: (theme) => theme.palette.background.paper,
      border: (theme) => `1px solid ${theme.palette.divider}`,

      '& code': {
        fontFeatureSettings: "'calt' 1, 'ss01' 1, 'ss02' 1, 'ss03' 1, 'ss04' 1",
      },
    },

    // Drop cap support
    '& .has-drop-cap:first-letter': {
      float: 'left',
      fontSize: '3.6em',
      lineHeight: '0.68',
      fontWeight: 600,
      margin: '0.1em 0.1em 0 0',
      padding: `0 ${baselineGrid}px`,
    },

    // Small caps support
    '& .small-caps': {
      fontFeatureSettings: "'smcp' 1, 'kern' 1",
      letterSpacing: '0.05em',
    },

    // Print optimizations
    '@media print': {
      '& a[href]::after': {
        content: '" (" attr(href) ")"',
        fontSize: '0.9em',
        fontWeight: 'normal',
      },
      '& h1, & h2, & h3, & h4': {
        pageBreakAfter: 'avoid',
        pageBreakInside: 'avoid',
      },
      '& table, & figure': {
        pageBreakInside: 'avoid',
      },
      '& p, & h2, & h3': {
        orphans: 3,
        widows: 3,
      },
    },
  },
}

export const tiptapButtonSx = {
  '&.MuiButtonBase-root.MuiButton-root.MuiButton-sizeSmall': {
    borderRadius: '0px !important',
    minWidth: '22px',
    p: 1,

    color: (theme) => theme.palette.text.primary,
    '&:disabled': {
      color: (theme) => theme.palette.text.disabled,
    },
    '& .MuiSvgIcon-root': {
      fontSize: '1.0rem',
    },
    '& .remixicon-icon, .remixicon, tabler-icon': {
      height: '16px',
      width: '16px',
    },
    '&.is-active': {
      background: (theme) => theme.palette.action.selected,
    },
  },
}

export const tiptapBubbleSx = {
  boxShadow: `
  0 1px 2px rgba(136, 161, 183, 0.07),
  0 2px 4px rgba(136, 161, 183, 0.07),
  0 4px 8px rgba(136, 161, 183, 0.07),
  0 8px 16px rgba(136, 161, 183, 0.07),
  0 16px 32px rgba(136, 161, 183, 0.07),
  0 32px 64px rgba(136, 161, 183, 0.07)
`,
  border: (theme) => `1px solid ${theme.palette.divider}`,
  borderRadius: '4px',
  background: (theme) => theme.palette.background.paper,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  flexDirection: 'row',
}

export const tiptapFloaterSx = {
  boxShadow: `
  0 1px 2px rgba(136, 161, 183, 0.07),
  0 2px 4px rgba(136, 161, 183, 0.07),
  0 4px 8px rgba(136, 161, 183, 0.07),
  0 8px 16px rgba(136, 161, 183, 0.07),
  0 16px 32px rgba(136, 161, 183, 0.07),
  0 32px 64px rgba(136, 161, 183, 0.07)
`,
  border: (theme) => `1px solid ${theme.palette.divider}`,
  borderRadius: '4px',
  background: (theme) => theme.palette.background.paper,
  '& .MuiListItemIcon-root': {
    minWidth: '24px',
  },

  '& .add-to-crm': {
    py: '4px',
    px: 1,
  },
}

export const nodeViewContainerSx = {
  border: () => `3px solid transparent`,
  borderRadius: '6px',
  height: '88px',
  overflow: 'hidden',
  width: '100%',
  cursor: 'pointer',
  pt: 2,
  pr: universalTextMargin,
  transition: 'all 0.25s linear',
  alignItems: 'flex-start',
  justifyContent: 'left',
  '&.editable:hover': {
    border: (theme) => `3px solid ${theme.palette.divider}`,

    cursor: 'grab',
    '&:hover .drag-handle .MuiSvgIcon-root, .drag-handle .remixicon-icon': {
      color: (theme) => theme.palette.text.secondary,
    },
    '& .drag-handle': {
      opacity: 1,
    },
  },
}

export const nodeDragHandleSx = {
  transition: 'all 0.4s linear',
  opacity: 0,
  px: '7px',
  overflow: 'hidden',
  flexShrink: 0,
  '& .MuiSvgIcon-root .remixicon-icon': {
    color: (theme) => lighten(theme.palette.text.secondary, 0.6),
  },
}

export const nodeTitleSx = {
  display: 'block',
  fontWeight: 600,
  letterSpacing: '-0.6px',
  fontSize: '1.0rem',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  width: '100%',
}

export const nodeDescriptionSx = {
  display: 'block',
  fontSize: '11px !important',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  width: '100%',
  letterSpacing: '-0.22px',
}

export const nodeTextDetailsContainerSx = {
  width: `calc(100%)`,
  overflow: 'hidden',
  flexShrink: 1,
  pl: 2,
}
