export const GET_OPPORTUNITY_FOR_SIDEBAR = gql`
  query SidebarGetOpportunityForSidebar($workspaceId: String!, $id: String!) {
    workspaceOpportunity(workspaceId: $workspaceId, id: $id) {
      id
      title
      workspaceId
      ownerEmail
      expectedCloseDate
      expectedRevenue
      status
      modelUpdatedAt
      roles {
        personEmail
        roles
      }
      goals {
        content
        source {
          sourceId
          sourceType
        }
      }
      impactOfChange {
        content
        source {
          sourceId
          sourceType
        }
      }
      budgetAndTimeline {
        content
        source {
          sourceId
          sourceType
        }
      }
      stage
      stages
      recommendedStage {
        expectedCloseDate
        expectedRevenue
        proofOfPayment
        readyToProgress
        reasoningforStage
        stageId
        nextStageEntranceCriteriaStatus {
          criteria
          reasoning
          met
        }
      }
      challenges {
        challenge
        solution
        source {
          sourceId
          sourceType
        }
      }
      risks {
        content
        source {
          sourceId
          sourceType
        }
      }
      competition {
        content
        source {
          sourceId
          sourceType
        }
      }
      decisionProcess {
        content
        source {
          sourceId
          sourceType
        }
      }
      customProperties
    }
  }
`
