import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import Fuse from 'fuse.js'

dayjs.extend(relativeTime)

import { logger } from './logger'
import {
  NativeObjectTypes,
  ObjectTypeMetadata,
  type NativeObjectType,
} from './objects'
import { NativeSuggestedPipelineTypes } from './relationshipSummary'

// Add type augmentation for Fuse.js to include index methods
declare module 'fuse.js' {
  interface FuseIndex<_T> {
    toJSON(): any
  }

  interface FuseConstructor {
    createIndex<T>(keys: string[], docs: T[]): FuseIndex<T>
    parseIndex<T>(index: any): FuseIndex<T>
  }

  interface FuseClass {
    new <T>(
      list: T[],
      options: Fuse.IFuseOptions<T>,
      index?: FuseIndex<T>
    ): Fuse<T>
  }

  interface Fuse<T> {
    getIndex(): { toJSON(): any }
    search(pattern: string): Array<{ item: T; score?: number }>
  }

  const Fuse: FuseClass & FuseConstructor
}

const DB_NAME = 'dayDB'
const DB_VERSION = 3
const STORE_NAME = 'searchIndex'
const SEARCH_INDEX_KEY = 'searchIndex'
const INDEX_METADATA_KEY = 'indexMetadata'
const FUSE_INDEX_KEY = 'fuseIndex'

interface IndexMetadata {
  lastBuilt: number
}

export interface SearchableObject {
  objectType: NativeObjectType
  objectId: string
  label: string
  description?: string
  photoUrl?: string
  avatarUrl?: string
  properties: any
  lastUpdated: number
}

let fuseInstance: Fuse<SearchableObject> | null = null
let cachedObjects: SearchableObject[] | null = null
let indexMetadata: IndexMetadata | null = null

// Base options that can be serialized
const searchOptions = {
  keys: ['label'],
  includeScore: true,
  threshold: 0.3,
  distance: 100,
  ignoreLocation: false,
  findAllMatches: true,
  useExtendedSearch: true,
  minMatchCharLength: 2,
}

// Function to create Fuse instance with complete options
function createFuseInstance(
  objects: SearchableObject[],
  index?: Fuse.FuseIndex<SearchableObject>
) {
  return new Fuse(
    objects,
    {
      ...searchOptions,
      keys: [
        {
          name: 'label',
          weight: 1,
        },
      ],
    },
    index
  )
}

async function openDB(): Promise<IDBDatabase> {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(DB_NAME, DB_VERSION)

    request.onerror = () => reject(request.error)
    request.onsuccess = () => resolve(request.result)

    request.onupgradeneeded = (event) => {
      const db = (event.target as IDBOpenDBRequest).result
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        db.createObjectStore(STORE_NAME)
      }
    }
  })
}

async function loadIndexFromDB(): Promise<SearchableObject[] | null> {
  try {
    const db = await openDB()
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(STORE_NAME, 'readonly')
      const store = transaction.objectStore(STORE_NAME)
      const request = store.get(SEARCH_INDEX_KEY)

      request.onerror = () => {
        logger.error('Error loading search index:', request.error)
        reject(request.error)
      }
      request.onsuccess = () => resolve(request.result)
    })
  } catch (error) {
    logger.error('Failed to load search index from IndexedDB:', error)
    return null
  }
}

async function saveIndexToDB(objects: SearchableObject[]): Promise<void> {
  try {
    const db = await openDB()
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(STORE_NAME, 'readwrite')
      const store = transaction.objectStore(STORE_NAME)
      const request = store.put(objects, SEARCH_INDEX_KEY)

      request.onerror = () => {
        logger.error('Error saving search index:', request.error)
        reject(request.error)
      }
      request.onsuccess = () => resolve()
    })
  } catch (error) {
    logger.error('Failed to save search index to IndexedDB:', error)
  }
}

async function saveIndexMetadata(metadata: IndexMetadata): Promise<void> {
  try {
    const db = await openDB()
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(STORE_NAME, 'readwrite')
      const store = transaction.objectStore(STORE_NAME)
      const request = store.put(metadata, INDEX_METADATA_KEY)

      request.onerror = () => {
        logger.error('Error saving index metadata:', request.error)
        reject(request.error)
      }
      request.onsuccess = () => resolve()
    })
  } catch (error) {
    logger.error('Failed to save index metadata:', error)
  }
}

async function loadIndexMetadata(): Promise<IndexMetadata | null> {
  try {
    const db = await openDB()
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(STORE_NAME, 'readonly')
      const store = transaction.objectStore(STORE_NAME)
      const request = store.get(INDEX_METADATA_KEY)

      request.onerror = () => {
        logger.error('Error loading index metadata:', request.error)
        reject(request.error)
      }
      request.onsuccess = () => resolve(request.result)
    })
  } catch (error) {
    logger.error('Failed to load index metadata:', error)
    return null
  }
}

async function saveFuseIndex(_index: Fuse<SearchableObject>): Promise<void> {
  try {
    const db = await openDB()
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(STORE_NAME, 'readwrite')
      const store = transaction.objectStore(STORE_NAME)
      // Create and serialize the index
      const fuseIndex = Fuse.createIndex<SearchableObject>(
        ['label'],
        cachedObjects || []
      )
      // Store the raw index data without additional stringification
      const request = store.put(fuseIndex.toJSON(), FUSE_INDEX_KEY)

      request.onerror = () => {
        logger.error('Error saving Fuse index:', request.error)
        reject(request.error)
      }
      request.onsuccess = () => resolve()
    })
  } catch (error) {
    logger.error('Failed to save Fuse index:', error)
  }
}

async function loadFuseIndex(): Promise<Fuse.FuseIndex<SearchableObject> | null> {
  try {
    const db = await openDB()
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(STORE_NAME, 'readonly')
      const store = transaction.objectStore(STORE_NAME)
      const request = store.get(FUSE_INDEX_KEY)

      request.onerror = () => {
        logger.error('Error loading Fuse index:', request.error)
        reject(request.error)
      }
      request.onsuccess = () => {
        try {
          if (!request.result) return resolve(null)
          // No need to parse, the result is already the index data
          const fuseIndex = Fuse.parseIndex<SearchableObject>(request.result)
          resolve(fuseIndex)
        } catch (e) {
          logger.error('Failed to parse Fuse index:', e)
          resolve(null)
        }
      }
    })
  } catch (error) {
    logger.error('Failed to load Fuse index:', error)
    return null
  }
}

// Load the cached index and initialize Fuse with it
export async function loadCachedIndex(): Promise<SearchableObject[] | null> {
  if (!cachedObjects) {
    const [objects, serializedIndex] = await Promise.all([
      loadIndexFromDB(),
      loadFuseIndex(),
    ])
    cachedObjects = objects
    indexMetadata = await loadIndexMetadata()

    if (cachedObjects) {
      const fuseIndex = serializedIndex
        ? Fuse.parseIndex<SearchableObject>(serializedIndex)
        : null

      // Use createFuseInstance instead of direct Fuse constructor
      fuseInstance = createFuseInstance(cachedObjects, fuseIndex)

      if (fuseIndex) {
        logger.dev('Loaded pre-built Fuse index')
      }
    }
  }
  return cachedObjects
}

// Add validation helpers
function ensureString(value: any): string | null {
  if (typeof value === 'string') return value
  if (value === null || value === undefined) return null
  // If it's an object or array, return null instead of trying to stringify it
  if (typeof value === 'object') return null
  // For numbers, booleans, etc, convert to string
  return String(value)
}

export async function populateSearchIndex(
  pipelines: any[] = [],
  organizations: any[] = [],
  people: any[] = [],
  meetingRecordings: any[] = [],
  pages: any[] = [],
  preserveExisting = true
) {
  logger.info('Populating search index...')

  // Load existing objects if we want to preserve them
  let existingObjects: SearchableObject[] = []
  if (preserveExisting) {
    if (!cachedObjects) {
      await loadCachedIndex()
    }
    if (cachedObjects) {
      existingObjects = [...cachedObjects]
    }
  }

  // Create a map of object IDs to track what we're adding
  const objectMap = new Map<string, boolean>()

  // Create a new array for the updated objects
  const objects: SearchableObject[] = []
  const counts: Record<string, number> = {}

  // Function to add an object and track it
  const addObject = (obj: SearchableObject) => {
    const key = `${obj.objectType}:${obj.objectId}`
    objectMap.set(key, true)
    objects.push(obj)
  }

  // Add pipelines and opportunities
  for (const pipeline of pipelines.filter((p) => p.title && !p.isGeneric)) {
    addObject({
      objectType: NativeObjectTypes.Pipeline,
      objectId: pipeline.id,
      label:
        ensureString(pipeline.title) ||
        ObjectTypeMetadata[NativeObjectTypes.Pipeline].label,
      description: ensureString(
        `${NativeSuggestedPipelineTypes[pipeline?.type]?.label} Pipeline`
      ),
      properties: { ...pipeline },
      lastUpdated: Date.now(),
    })
    counts[NativeObjectTypes.Pipeline] =
      (counts[NativeObjectTypes.Pipeline] || 0) + 1

    // Add opportunities from each pipeline stage
    for (const stage of pipeline.stages || []) {
      for (const opportunity of stage.opportunities || []) {
        addObject({
          objectType: NativeObjectTypes.Opportunity,
          objectId: opportunity.id,
          label:
            ensureString(opportunity.title) ||
            ObjectTypeMetadata[NativeObjectTypes.Opportunity].label,
          description: ensureString(
            `Opportunity in the ${pipeline.title} pipeline`
          ),
          properties: { ...opportunity },
          lastUpdated: Date.now(),
        })
        counts[NativeObjectTypes.Opportunity] =
          (counts[NativeObjectTypes.Opportunity] || 0) + 1
      }
    }
  }

  // Add organizations
  for (const org of organizations) {
    const domain = org.domain
    if (!domain) {
      logger.warn('Organization missing domain', { org })
      continue
    }

    addObject({
      objectType: NativeObjectTypes.Organization,
      objectId: domain,
      label: ensureString(org.name) || domain,
      lastUpdated: Date.now(),
      properties: {
        domain,
        name: org.name || domain,
      },
    })
  }

  // Add people
  for (const person of people) {
    if (!person.email) {
      logger.warn('Person missing email', { person })
      continue
    }

    const description =
      ensureString(person.title) ||
      ensureString(person.currentJobTitle) ||
      ensureString(person.currentCompanyName) ||
      ensureString(person.organizations?.[0]?.name) ||
      ensureString(person.organizations?.[0]?.domain)

    addObject({
      objectType: NativeObjectTypes.Contact,
      objectId: person.email,
      label: ensureString(person.fullName || person.name) || person.email,
      description,
      photoUrl: ensureString(person.photoUrl),
      avatarUrl: ensureString(person.photoUrl || person.avatarUrl),
      properties: { ...person },
      lastUpdated: Date.now(),
    })
    counts[NativeObjectTypes.Contact] =
      (counts[NativeObjectTypes.Contact] || 0) + 1
  }

  // Add meeting recordings
  for (const recording of meetingRecordings) {
    const title =
      ensureString(recording.calendarEvents?.[0]?.GoogleEvent?.title) ||
      ensureString(recording.title) ||
      ObjectTypeMetadata[NativeObjectTypes.MeetingRecording].label

    const description =
      ensureString(recording.summary?.output?.Suggested_Title) ||
      ensureString(recording.summary?.output?.Summary_Short) ||
      ensureString(recording.description) ||
      title

    addObject({
      objectType: NativeObjectTypes.MeetingRecording,
      objectId: recording.id,
      label: title,
      description,
      properties: { ...recording },
      lastUpdated: new Date(recording.startedAt).getTime(),
    })
    counts[NativeObjectTypes.MeetingRecording] =
      (counts[NativeObjectTypes.MeetingRecording] || 0) + 1
  }

  // Add pages
  for (const page of pages) {
    const description =
      ensureString(page.description) ||
      ensureString(page.summary) ||
      ensureString(
        `Page created ${new Date(page.createdAt).toLocaleDateString()}`
      )

    addObject({
      objectType: NativeObjectTypes.Page,
      objectId: page.id,
      label:
        ensureString(page.title) ||
        ObjectTypeMetadata[NativeObjectTypes.Page].label,
      description,
      properties: { ...page },
      lastUpdated: new Date(page.createdAt).getTime(),
    })
    counts[NativeObjectTypes.Page] = (counts[NativeObjectTypes.Page] || 0) + 1
  }

  // Add existing objects that weren't included in the new data
  if (preserveExisting) {
    for (const obj of existingObjects) {
      const key = `${obj.objectType}:${obj.objectId}`
      if (!objectMap.has(key)) {
        objects.push(obj)
      }
    }
  }

  // Update the in-memory index and Fuse instance
  cachedObjects = objects
  fuseInstance = new Fuse(objects, searchOptions)
  indexMetadata = { lastBuilt: Date.now() }

  // Save to IndexedDB in the background
  await Promise.all([
    saveIndexToDB(objects),
    saveIndexMetadata(indexMetadata),
    saveFuseIndex(fuseInstance),
  ]).catch((error) => {
    logger.error('Failed to save search index:', error)
  })
}

export async function search(query: string): Promise<SearchableObject[]> {
  if (!fuseInstance) {
    await loadCachedIndex()
    if (cachedObjects && !fuseInstance) {
      fuseInstance = createFuseInstance(cachedObjects)
    }
  }

  if (!query || !fuseInstance) {
    return []
  }

  const searchTerms = query.toLowerCase().split(/[\s-]+/)
  const results = fuseInstance.search(query)

  return results
    .map((result) => {
      const item = result.item
      const fuseScore = result.score ?? 1
      const label = item.label.toLowerCase()
      const words = label.split(/[\s-]+/)

      // Calculate exact match score with stronger word boundary emphasis
      const exactMatchScore = (() => {
        // Perfect word match gets highest score
        if (words.some((word) => word === query.toLowerCase())) return 1

        // Exact word start matches get high score
        if (words.some((word) => word.startsWith(query.toLowerCase())))
          return 0.8

        // Count partial matches with lower scores
        const matchCount = searchTerms.reduce((count, term) => {
          const hasExactWordMatch = words.some((word) => word === term)
          const hasWordStartMatch = words.some((word) => word.startsWith(term))
          return count + (hasExactWordMatch ? 1 : hasWordStartMatch ? 0.5 : 0)
        }, 0)

        return (matchCount / searchTerms.length) * 0.6 // Cap partial matches lower
      })()

      // Increase type priority influence
      const typePriorityScore = (() => {
        switch (item.objectType) {
          case NativeObjectTypes.Contact:
            return 1.0 // Increased from 0.8
          case NativeObjectTypes.Organization:
            return 0.1 // Decreased from 0.2
          default:
            return 0
        }
      })()

      // Adjust weights to emphasize type priority more
      const compositeScore =
        exactMatchScore * 0.4 + // 40% weight for exact matches (down from 50%)
        typePriorityScore * 0.4 + // 40% weight for type priority (up from 30%)
        (1 - fuseScore) * 0.2 // 20% weight for fuse score (unchanged)

      return {
        ...result,
        compositeScore,
        // Debug info
        _debug: {
          exactMatchScore,
          typePriorityScore,
          fuseScore,
          label,
          words,
          searchTerms,
        },
      }
    })
    .sort((a, b) => b.compositeScore - a.compositeScore)
    .map((result) => {
      return result.item
    })
}

// Export the non-debounced search function directly
export { search as debouncedSearch }

export function getIndexMetadata(): IndexMetadata | null {
  return indexMetadata
}

/**
 * Adds a new object to the search index or updates it if it already exists
 * @param object The object to add to the search index
 * @returns True if the object was added/updated successfully
 */
export async function addObjectToSearchIndex(
  object: SearchableObject
): Promise<boolean> {
  // Ensure the index is loaded
  if (!cachedObjects) {
    await loadCachedIndex()
  }

  // If still no cached objects, initialize with an empty array
  if (!cachedObjects) {
    cachedObjects = []
  }

  // Check if the object already exists
  const objectIndex = cachedObjects.findIndex(
    (obj) =>
      obj.objectType === object.objectType && obj.objectId === object.objectId
  )

  if (objectIndex >= 0) {
    // Update existing object
    cachedObjects[objectIndex] = {
      ...cachedObjects[objectIndex],
      ...object,
      lastUpdated: Date.now(),
    }
  } else {
    // Add new object
    cachedObjects.push({
      ...object,
      lastUpdated: object.lastUpdated || Date.now(),
    })
  }

  // Update the Fuse instance
  if (fuseInstance) {
    fuseInstance = createFuseInstance(cachedObjects)
  } else {
    fuseInstance = new Fuse(cachedObjects, searchOptions)
  }

  // Save to IndexedDB in the background
  saveIndexToDB(cachedObjects).catch((error) => {
    logger.error(
      'Failed to save updated search index after adding object:',
      error
    )
    return false
  })

  return true
}

/**
 * Updates an existing object in the search index with new data
 * @param objectType The type of object to update
 * @param objectId The unique identifier for the object
 * @param updatedData The new data to merge with the existing object
 * @returns True if the object was updated, false if not found or index not loaded
 */
export async function updateObjectInSearchIndex(
  objectType: NativeObjectType,
  objectId: string,
  updatedData: Partial<SearchableObject>
): Promise<boolean> {
  // Ensure the index is loaded
  if (!cachedObjects) {
    await loadCachedIndex()
  }

  // If still no cached objects, initialize with an empty array
  if (!cachedObjects) {
    cachedObjects = []
  }

  // Find the object index
  const objectIndex = cachedObjects.findIndex(
    (obj) => obj.objectType === objectType && obj.objectId === objectId
  )

  if (objectIndex === -1) {
    // Object doesn't exist, create a new one if we have enough data
    if (updatedData.label) {
      logger.dev('Creating new object in search index', {
        objectType,
        objectId,
      })

      // Create a new object with the provided data
      const newObject: SearchableObject = {
        objectType,
        objectId,
        label: updatedData.label,
        description: updatedData.description || null,
        photoUrl: updatedData.photoUrl || null,
        avatarUrl: updatedData.avatarUrl || updatedData.photoUrl || null,
        properties: updatedData.properties || {},
        lastUpdated: Date.now(),
      }

      // Add the new object to the index
      return addObjectToSearchIndex(newObject)
    } else {
      return false
    }
  }

  // Update the object with new data
  cachedObjects[objectIndex] = {
    ...cachedObjects[objectIndex],
    ...updatedData,
    lastUpdated: Date.now(),
  }

  // Update the Fuse instance
  if (fuseInstance) {
    fuseInstance = createFuseInstance(cachedObjects)
  }

  // Save to IndexedDB in the background
  saveIndexToDB(cachedObjects).catch((error) => {
    logger.error('Failed to save updated search index:', error)
  })

  return true
}

/**
 * Retrieves an object from the search index by its type and ID
 * @param objectType The type of object to retrieve (e.g., NativeObjectTypes.Contact)
 * @param objectId The unique identifier for the object
 * @param workspaceId Optional workspace ID to filter by
 * @returns The SearchableObject if found, or null if not found or index not loaded
 */
export async function getObjectById(
  objectType: NativeObjectType,
  objectId: string,
  workspaceId?: string
): Promise<SearchableObject | null> {
  // Ensure the index is loaded
  if (!cachedObjects) {
    await loadCachedIndex()
  }

  // If still no cached objects, return null
  if (!cachedObjects) {
    logger.warn('Search index not loaded, cannot retrieve object', {
      objectType,
      objectId,
      workspaceId,
    })
    return null
  }

  // Find the object that matches both type and ID
  const foundObject = cachedObjects.find(
    (obj) =>
      obj.objectType === objectType &&
      obj.objectId === objectId &&
      // If workspaceId is provided, check if it matches in properties
      (!workspaceId || obj.properties?.workspaceId === workspaceId)
  )

  if (!foundObject) {
    logger.dev('Object not found in search index', {
      objectType,
      objectId,
      workspaceId,
    })
    return null
  }

  return foundObject
}
