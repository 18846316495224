import { useContext, useEffect } from 'react'

import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material'
import { IconMailFilled, IconMailOpened } from '@tabler/icons-react'
import dayjs from 'dayjs'

import { useQuery } from '@redwoodjs/web'

import BaseChip from 'src/components/Chips/BaseChip'
import MetadataChip from 'src/components/Chips/MetadataChip/MetadataChip'
import Row from 'src/components/Row/Row'
import { DayContext } from 'src/lib/dayContext'

import { sidebarBodyTextStyle } from '../styles'

const GET_GMAIL_THREADS_FOR_SIDEBAR = gql`
  query GetPersonSidebarGmailThreads($email: String!, $workspaceId: String!) {
    getGmailThreadsForPerson(email: $email, workspaceId: $workspaceId) {
      id
      subject
      gmailThreadId
      internalDate
      externalUrl
      workAccountEmail
      sharedWithWorkspace
      summary {
        summary
      }
      messages {
        id
        subject
        snippet
        internalDate
        recipients {
          address
          type
        }
      }
      recipients {
        email
      }
    }
  }
`

const SidebarEmailHistory = ({
  email,
  workspaceId,
  setCount,
}: {
  email: string
  workspaceId: string
  setCount?: React.Dispatch<React.SetStateAction<number>>
}) => {
  const theme = useTheme()
  const { setSidebarObject } = useContext(DayContext)
  const { data, loading } = useQuery(GET_GMAIL_THREADS_FOR_SIDEBAR, {
    variables: { email, workspaceId },
    skip: !email || !workspaceId,
    onCompleted: ({ getGmailThreadsForPerson }) => {
      setCount(getGmailThreadsForPerson?.length || 0)
    },
  })

  const threadCount = data?.getGmailThreadsForPerson?.length || 0

  useEffect(() => {
    setCount(threadCount)
  }, [threadCount, setCount])

  return (
    <Box>
      {!(data?.getGmailThreadsForPerson?.length > 0) ? (
        <>
          {loading ? (
            <Row
              sx={{
                justifyContent: 'flex-start',
                alignItems: 'center',
                height: '48px',
              }}
            >
              <CircularProgress size={24} />
            </Row>
          ) : (
            <Typography
              sx={{
                ...sidebarBodyTextStyle,
                p: 0,
                m: 0,
              }}
            >
              No email history found
            </Typography>
          )}
        </>
      ) : (
        <Table sx={{ height: '100%' }}>
          <TableBody
            sx={{
              '& .MuiTableCell-root': {
                '&:first-of-type': {
                  px: 0,
                },
                '&:last-of-type': {
                  px: 0,
                },
              },
            }}
          >
            {data &&
              data.getGmailThreadsForPerson.map((thread, index) => {
                const summary = thread.summary?.summary
                return (
                  <TableRow key={`${thread.id}-${index}`}>
                    <TableCell sx={{ maxWidth: '200px' }}>
                      <Row sx={{ alignItems: 'flex-start', height: '100%' }}>
                        <BaseChip
                          label={thread.messages[0].subject || '(No subject)'}
                          fullWidth={false}
                          icon={
                            thread.unread ? (
                              <IconMailFilled
                                style={{
                                  marginRight: '4px',
                                  flexShrink: 0,
                                }}
                              />
                            ) : (
                              <IconMailOpened
                                style={{
                                  marginRight: '4px',
                                  flexShrink: 0,
                                }}
                              />
                            )
                          }
                          onClick={() => {
                            setSidebarObject({
                              objectType: 'native_gmailthread',
                              objectId: thread.id,
                              properties: thread,
                            })
                          }}
                        />
                      </Row>
                    </TableCell>
                    <TableCell sx={{ fontSize: '11px !important', mt: 0 }}>
                      <span
                        style={{
                          whiteSpace: 'nowrap',
                          fontWeight: 600,
                          marginRight: '6px',
                        }}
                      >
                        {dayjs(
                          new Date(thread.messages[0].internalDate)
                        ).format('M/D/YY')}
                      </span>
                      {summary?.oneSentenceSummary || '(No summary)'}
                    </TableCell>
                    <TableCell>
                      <Row sx={{ alignItems: 'flex-start', height: '100%' }}>
                        <MetadataChip
                          state={{
                            label: thread.sharedWithWorkspace
                              ? 'Shared with workspace'
                              : 'Private to you',
                            value: null,
                            color: thread.sharedWithWorkspace
                              ? theme.palette.success.main
                              : null,
                          }}
                        />
                      </Row>
                    </TableCell>
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      )}
    </Box>
  )
}

export default SidebarEmailHistory
