import { useContext } from 'react'

import SidebarContext, { type SidebarContextValue } from './SidebarContext'

const useSidebar = (): SidebarContextValue => {
  const context = useContext(SidebarContext)

  if (!context) {
    throw new Error('useSidebar must be used within a SidebarProvider')
  }

  return context
}

export default useSidebar
