import { useContext } from 'react'

import { Box } from '@mui/material'

import { useQuery } from '@redwoodjs/web'

import { DayContext } from 'src/lib/dayContext'
import { ACTION_FIELDS_FRAGMENT } from 'src/lib/fragments'
import { logger } from 'src/lib/logger'

import SidebarActions from '../../Sidebar/SidebarActions/SidebarActions'

const GET_ACTIONS_FOR_CONTACT_SIDEBAR = gql`
  query GetActionsForContactSidebar(
    $peopleEmails: [String!]!
    $workspaceId: String!
  ) {
    actionsForPeople(
      input: { peopleEmails: $peopleEmails, workspaceId: $workspaceId }
    ) {
      ...ActionFields
    }
  }
  ${ACTION_FIELDS_FRAGMENT}
`

const ContactActions = ({ email }: { email: string }) => {
  const { selectedWorkspace } = useContext(DayContext)
  const { data, refetch } = useQuery(GET_ACTIONS_FOR_CONTACT_SIDEBAR, {
    variables: {
      peopleEmails: [email],
      workspaceId: selectedWorkspace,
    },
    skip: !selectedWorkspace || !email,
  })

  const finalActions = data?.actionsForPeople

  logger.dev({ finalActions })

  return Array.isArray(finalActions) && finalActions?.length > 0 ? (
    <Box sx={{ mt: 0, '& .MuiBox-root': { mt: 0 } }}>
      <SidebarActions
        actions={finalActions}
        onUpdate={refetch}
      />
    </Box>
  ) : (
    `No active actions found for ${email}`
  )
}

export default ContactActions
