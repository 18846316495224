import { useCallback, useContext, useEffect, useMemo, useReducer } from 'react'

import { useMutation } from '@apollo/client'
import type { FetchResult } from '@apollo/client'
import {
  Box,
  Button,
  ListItemText,
  ListItemButton,
  List,
  TextField,
  Typography,
  ListItemIcon,
} from '@mui/material'
import { IconBulb } from '@tabler/icons-react'
import toast from 'react-hot-toast'
import type { CRMObject, WorkspaceUserContext } from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import {
  CREATE_CONTEXT,
  UPDATE_CONTEXT,
} from 'src/components/Context/mutations'
import { GET_ORGANIZATION_FOR_CONTEXT_SIDEBAR } from 'src/components/Context/queries'
import { extractEmailDomain } from 'src/lib/contactFormatting'
import { DayContext } from 'src/lib/dayContext'
import { dayjs } from 'src/lib/dayjs'
import { ungatedForTemplates } from 'src/lib/gates'
import { logger } from 'src/lib/logger'
import {
  NativeObjectTypes,
  ObjectTypeMetadata,
  type NativeObjectType,
} from 'src/lib/objects'
import type { SearchableObject } from 'src/lib/searchService'

import { ADD_TEMPLATE_TO_WORKSPACE_USER_CONTEXT } from '../../Pages/mutations'
import { GET_TEMPLATES_FOR_WORKSPACE_USER_CONTEXT } from '../../Pages/queries'
import Row from '../../Row/Row'
import SidebarObjectList from '../../Sidebar/SidebarObjectList/SidebarObjectList'
import TemplatePicker from '../../Templates/TemplatePicker/TemplatePicker'
import ObjectContextManager from '../../Threads/ObjectContextManager/ObjectContextManager'
import WorkspaceMemberChip from '../../WorkspaceMemberChip/WorkspaceMemberChip'

import { contextReducer, initializeState } from './reducer'

const STYLES = {
  headerBox: {
    justifyContent: 'space-between',
    py: 1,
  },
  titlePrefix: {
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: '-0.7px',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    textOverflow: 'ellipsis',
    wordWrap: 'break-word',
    overflow: 'hidden',
    width: '100%',
    whiteSpace: 'normal',
  },
  titleIcon: {
    height: '28px',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
  },
  title: {
    WebkitLineClamp: 1,
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    textOverflow: 'ellipsis',
    wordWrap: 'break-word',
    overflow: 'hidden',
    lineHeight: '140%',
  },
} as const

const SidebarLayoutUserContext = ({
  crmObject,
  hideSearch = false,
  title = null,
  onUpdate = null,
}: {
  crmObject: Partial<CRMObject>
  hideSearch?: boolean
  title?: string | null
  onUpdate?: (any) => void | null
}) => {
  const { currentUser } = useAuth()
  const { workspacePeople, selectedWorkspace, setSidebarObject } =
    useContext(DayContext)

  const [state, dispatch] = useReducer(
    contextReducer,
    {
      title,
      hideSearch,
      context: crmObject.properties,
      workspacePeople,
    },
    initializeState
  )

  const { data: organizationData } = useQuery(
    GET_ORGANIZATION_FOR_CONTEXT_SIDEBAR,
    {
      variables: {
        workspaceId: selectedWorkspace,
        orgId: state.objectId,
      },
      skip:
        !state.objectId || state.objectType !== NativeObjectTypes.Organization,
    }
  )

  const { data: templatesData, refetch: refetchTemplates } = useQuery(
    GET_TEMPLATES_FOR_WORKSPACE_USER_CONTEXT,
    {
      variables: {
        workspaceId: selectedWorkspace,
        contextId: state.contextEdit?.id,
      },
      skip: !state.contextEdit?.id || !selectedWorkspace,
    }
  )

  const [updateContext] = useMutation(UPDATE_CONTEXT)
  const [addTemplateToWorkspaceUserContext] = useMutation(
    ADD_TEMPLATE_TO_WORKSPACE_USER_CONTEXT
  )

  const isCreator = useMemo(() => {
    return (
      currentUser?.id === state.contextEdit?.userId ||
      !state.contextEdit?.userId
    )
  }, [currentUser, state.contextEdit])

  const handleSave = () => {
    const input = {
      plainTextValue: state.contextEdit?.plainTextValue,
      workspaceId: selectedWorkspace,
      parentReferenceKey: state.contextEdit?.parentReferenceKey,
      referencedObjectIds: state.contextEdit?.referencedObjectIds || [],
    }
    toast.promise(
      updateContext({ variables: { id: state.contextEdit.id, input } }),
      {
        loading: 'Saving context...',
        success: ({
          data,
        }: {
          data: { updateWorkspaceUserContext: WorkspaceUserContext }
        }) => {
          dispatch({
            type: 'UPDATE_SUCCEEDED',
          })
          onUpdate({
            objectId: data.updateWorkspaceUserContext.id,
            objectType: NativeObjectTypes.UserContext,
            workspaceId: selectedWorkspace,
            properties: {
              ...data.updateWorkspaceUserContext,
              new: false,
            },
          })
          return 'Context saved!'
        },
        error: 'Error saving context',
      }
    )
  }

  const [createContext] = useMutation(CREATE_CONTEXT)

  const handleCreate = () => {
    const referencedObjectIds = state.contextEdit?.referencedObjectIds || []
    if (referencedObjectIds.length === 0) {
      if (state.objectType === NativeObjectTypes.Person) {
        const domain = extractEmailDomain(state.objectId)
        if (domain) {
          referencedObjectIds.push(
            `${NativeObjectTypes.Organization} : ${domain} : root`
          )
        }
      }
    }

    const input: any = {
      parentReferenceKey: state.contextEdit?.parentReferenceKey,
      plainTextValue: state.contextEdit?.plainTextValue.trim(),
      referencedObjectIds,
      workspaceId: selectedWorkspace,
    }

    delete input.new
    toast.promise(createContext({ variables: { input } }), {
      loading: 'Creating context...',
      success: ({
        data,
      }: FetchResult<{ createWorkspaceUserContext: WorkspaceUserContext }>) => {
        onUpdate({
          objectId: data.createWorkspaceUserContext.id,
          objectType: NativeObjectTypes.UserContext,
          workspaceId: selectedWorkspace,
          properties: { ...data.createWorkspaceUserContext },
        })
        return 'Context created!'
      },
      error: (error: Error) => {
        logger.error('Failed to create context', error)
        return 'Error creating context'
      },
    })
  }

  const organization = useMemo(
    () => organizationData?.workspaceOrganization,
    [organizationData]
  )

  useEffect(() => {
    if (organization?.name) {
      dispatch({ type: 'ORGANIZATION_FETCHED', name: organization.name })
    }
  }, [organization?.name])

  const handleSelectObject = (result: SearchableObject) => {
    // For workspace actions (like Action Instructions), the objectType is Workspace but objectId is the actual type
    const parentReferenceKey =
      result.objectType === NativeObjectTypes.Workspace
        ? `${result.objectType} : ${result.objectId} : root`
        : `${result.objectType} : ${result.objectId} : root`

    dispatch({
      type: 'SEARCH_ITEM_SELECTED',
      parentReferenceKey,
      result,
    })
  }

  const handleSetTemplate = useCallback(
    async (template: any) => {
      if (!template) {
        return
      }
      try {
        await addTemplateToWorkspaceUserContext({
          variables: {
            workspaceId: selectedWorkspace,
            pageId: template.id,
            contextId: state.contextEdit?.id,
          },
        })
        refetchTemplates()
      } catch (error) {
        toast.error('Error adding template to context')
      }
    },
    [
      addTemplateToWorkspaceUserContext,
      selectedWorkspace,
      state.contextEdit?.id,
      refetchTemplates,
    ]
  )

  const handleWorkspaceAction = (
    objectType: NativeObjectType,
    pathKey: string
  ) => {
    dispatch({
      type: 'WORKSPACE_OBJECT_SELECTED',
      objectType,
      pathKey,
    })
  }

  const includePadding = !state.contextEdit?.new

  return (
    state.contextEdit && (
      <Box sx={{ px: includePadding ? 3 : 0 }}>
        {state.objectTitle && (
          <Row sx={STYLES.headerBox}>
            <Row sx={STYLES.titlePrefix}>
              <Box sx={{ width: '100%' }}>
                <Typography sx={STYLES.titlePrefix}>
                  {`Add context to ${
                    state.objectType === NativeObjectTypes.Workspace
                      ? 'your workspace'
                      : ObjectTypeMetadata[state.objectType].label
                  }`}
                </Typography>

                <Row gap={'4px'}>
                  {state.objectType && state.objectId && (
                    <Box sx={STYLES.titleIcon}>
                      {React.createElement(
                        ObjectTypeMetadata[
                          state.objectType === NativeObjectTypes.Workspace
                            ? state.objectId
                            : state.objectType
                        ].icon,
                        { size: 20, stroke: 2.5 }
                      )}
                    </Box>
                  )}
                  <Typography
                    variant="h3"
                    sx={STYLES.title}
                  >
                    {state.objectTitle}
                  </Typography>
                </Row>
              </Box>
            </Row>
          </Row>
        )}
        {state.objectType && state.objectId && (
          <Row
            gap={2}
            sx={{ height: '64px' }}
          >
            <Box sx={{ opacity: 0.8 }}>
              <IconBulb size={24} />
            </Box>
            <Box sx={{ opacity: 0.9 }}>
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 600,
                  lineHeight: '130%',
                  letterSpacing: '-0.42px',
                }}
              >
                Day.ai learns & adapts from this context
              </Typography>
              <Typography
                sx={{
                  fontSize: '14px',
                  fontStyle: 'italic',
                  fontWeight: 400,
                  lineHeight: '130%',
                  letterSpacing: '-0.42px',
                }}
              >
                Add insights, instructions, details and corrections{' '}
                {state.objectTitle ? `to ${state.objectTitle}` : ''}
              </Typography>
            </Box>
          </Row>
        )}

        {!state.objectType || !state.objectId ? (
          <Box>
            {!state.hideSearch && (
              <Box sx={{ px: 3, mb: 2 }}>
                <ObjectContextManager
                  parentObject={null}
                  contextObjects={[]}
                  dismissedObjects={[]}
                  handleSetContextObjects={({ contextObjects }) => {
                    if (contextObjects.length > 0) {
                      handleSelectObject({
                        objectType: contextObjects[0]
                          .objectType as NativeObjectType,
                        objectId: contextObjects[0].objectId,
                        properties: {},
                        lastUpdated: Date.now(),
                        label: '',
                      })
                    }
                  }}
                  showStatus={false}
                  workspaceId={selectedWorkspace}
                  contextForObjectType={state.objectType as NativeObjectType}
                />
              </Box>
            )}
            {!state.hideSearch && (
              <Typography
                variant="h3"
                sx={{ px: 3, mt: 2 }}
              >
                ... or, add context to your workspace:
              </Typography>
            )}
            <List sx={null}>
              {Object.entries(ObjectTypeMetadata).map(
                ([objectType, metadata]) => {
                  // Skip if no paths or no ROOT path
                  if (!metadata.paths) return null

                  return (
                    <Box
                      key={objectType}
                      sx={{
                        mb: 2,
                        height: '100%',
                      }}
                    >
                      <Typography variant="h4">{metadata.label}</Typography>
                      {Object.entries(metadata.paths).map(
                        ([pathKey, pathMetadata]) => (
                          <ListItemButton
                            key={`${objectType}-${pathKey}`}
                            onClick={() =>
                              handleWorkspaceAction(
                                objectType as NativeObjectType,
                                pathKey
                              )
                            }
                          >
                            <ListItemIcon sx={{ minWidth: '28px' }}>
                              {React.createElement(metadata.icon, {
                                size: 20,
                                stroke: 2.5,
                              })}
                            </ListItemIcon>
                            <ListItemText primary={`${pathMetadata.label}`} />
                          </ListItemButton>
                        )
                      )}
                    </Box>
                  )
                }
              )}
            </List>
          </Box>
        ) : (
          <Box sx={{}}>
            <TextField
              placeholder="Add context"
              multiline={true}
              fullWidth={true}
              rows={6}
              disabled={!isCreator}
              value={state.contextEdit?.plainTextValue}
              onChange={(e) => {
                const clipboardData = (e.nativeEvent as ClipboardEvent)
                  .clipboardData
                if (clipboardData) {
                  logger.dev('paste detected')
                }
                dispatch({
                  type: 'UPDATE_CONTEXT_TEXT',
                  payload: e.target.value,
                })
              }}
            />
            <Row
              sx={{ justifyContent: 'space-between', mt: 2, alignItems: 'top' }}
            >
              {state.contextEdit.id && ungatedForTemplates(currentUser) && (
                <Box>
                  <Typography variant="h4">Templates for drafts</Typography>
                  <SidebarObjectList
                    objects={
                      templatesData?.templatesForWorkspaceUserContext
                        ?.filter(Boolean)
                        .map((page) => ({
                          objectType: NativeObjectTypes.Page,
                          objectId: page.id,
                          workspaceId: selectedWorkspace,
                          properties: page,
                        })) || []
                    }
                    workspaceId={selectedWorkspace}
                    onObjectClick={setSidebarObject}
                  />
                </Box>
              )}
              {isCreator &&
                state.contextEdit.id &&
                ungatedForTemplates(currentUser) && (
                  <TemplatePicker
                    workspaceId={selectedWorkspace}
                    setTemplate={handleSetTemplate}
                    hiddenTemplateIds={templatesData?.templatesForWorkspaceUserContext.map(
                      (template) => template.id
                    )}
                  />
                )}
            </Row>
            {state.objectType !== NativeObjectTypes.Workspace && (
              <ObjectContextManager
                parentObject={null}
                contextObjects={
                  state.contextEdit?.referencedObjectIds?.map((ref: string) => {
                    const [objectType, objectId] = ref.split(' : ')
                    return { objectType, objectId }
                  }) || []
                }
                handleSetContextObjects={({ contextObjects }) => {
                  dispatch({
                    type: 'UPDATE_REFERENCED_OBJECTS',
                    payload: {
                      objectReferences: contextObjects.map((obj) => ({
                        objectType: obj.objectType as NativeObjectType,
                        objectId: obj.objectId,
                        path: 'root',
                      })),
                    },
                  })
                }}
                dismissedObjects={[]}
                showStatus={false}
                contextForObjectType={NativeObjectTypes.UserContext}
                workspaceId={selectedWorkspace}
              />
            )}

            <Row
              gap={1}
              sx={{ height: '72px' }}
            >
              {isCreator ? (
                <Button
                  fullWidth={true}
                  size="large"
                  onClick={() => {
                    if (state.contextEdit?.id) {
                      handleSave()
                    } else {
                      handleCreate()
                    }
                  }}
                  variant="contained"
                  disableElevation={true}
                  disabled={!isCreator || !state.hasEdits}
                >
                  {state.contextEdit?.id ? 'Save' : 'Add'}
                </Button>
              ) : (
                <>
                  <Typography
                    variant="h5"
                    sx={{ flexShrink: 0 }}
                  >
                    Added {dayjs(state.contextEdit?.createdAt).fromNow()} by{' '}
                  </Typography>
                  <WorkspaceMemberChip userId={state.contextEdit?.userId} />
                </>
              )}
            </Row>
          </Box>
        )}
      </Box>
    )
  )
}

export default SidebarLayoutUserContext
