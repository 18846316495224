import { useEffect, useMemo, useRef } from 'react'

import { Box, Divider, Typography } from '@mui/material'
import { lighten } from '@mui/material/styles'

import { useQuery } from '@redwoodjs/web'

import ObjectFeedback from 'src/components/ObjectFeedback/ObjectFeedback'
import Row from 'src/components/Row/Row'
import { dayjs } from 'src/lib/dayjs'
import { logger } from 'src/lib/logger'

const GET_OBJECT_REASONINGS = gql`
  query ObjectReasonings(
    $objectType: String!
    $objectId: String!
    $workspaceId: String!
  ) {
    objectReasonings(
      objectType: $objectType
      objectId: $objectId
      workspaceId: $workspaceId
    ) {
      id
      createdAt
      workspaceId
      headline
      description
      objectType
      objectId
      path
      version
    }
  }
`

const SidebarObjectConsole = ({
  objectType,
  objectId,
  workspaceId,
}: {
  objectType: string
  objectId: string
  workspaceId: string
}) => {
  const { data, refetch } = useQuery(GET_OBJECT_REASONINGS, {
    variables: {
      objectType,
      objectId,
      workspaceId,
    },
    skip: !objectType || !objectId || !workspaceId,
    onCompleted: ({ objectReasonings }) => {
      logger.dev('objectReasonings', objectReasonings)
    },
  })

  useEffect(() => {
    refetch()
  }, [objectType, objectId, workspaceId, refetch])

  const reasonings = data?.objectReasonings || []
  const previousTimestamp = useRef<number | null>(null)

  const containerSx = useMemo(() => {
    return {
      fontSize: '11px',
      letterSpacing: '-0.3px',
      border: () => `1px solid ${'#1C1B22'}`,
      backgroundColor: lighten('#21202E', 0.05),
      overflowY: 'auto',
      height: 'calc(100vh - 56px)',

      fontFamily: 'Fira Code !important',
      fontWeight: 500,
      '& .reasoning-headline': {
        fontSize: '12px',
        color: '#ffca85',
        fontWeight: 600,
      },
      '& .reasoning-description': {
        fontSize: '12px',
        color: '#EDECEE',
        p: 1,

        wordWrap: 'break-word',
        whiteSpace: 'pre-line',
      },
    }
  }, [])

  return (
    <Box sx={containerSx}>
      {reasonings &&
        reasonings.map((reasoning) => {
          const timestamp = reasoning.createdAt
          const toRender = (
            <Box key={reasoning.id}>
              <Row sx={{ justifyContent: 'space-between' }}>
                <Row
                  sx={{ p: 1, pb: '2px' }}
                  gap={'6px'}
                >
                  <Typography className="reasoning-headline">
                    {reasoning.headline}
                  </Typography>
                </Row>
                <Row
                  sx={{ alignItems: 'flex-start' }}
                  gap={'6px'}
                >
                  <ObjectFeedback
                    objectType={objectType}
                    objectId={objectId}
                    workspaceId={workspaceId}
                    path={reasoning.path}
                    version={reasoning.version}
                    variant="inline"
                  />
                </Row>
              </Row>
              <Box>
                <Typography className="reasoning-description">
                  <Box
                    component="span"
                    sx={{
                      color: '#f594ff',
                      mr: '4px',
                      fontWeight: 500,
                      fontStyle: 'italic',
                    }}
                  >
                    Day.ai reasoning:
                  </Box>
                  {reasoning.description}
                </Typography>
              </Box>

              {previousTimestamp.current !== timestamp && (
                <Box sx={{ my: 2 }}>
                  <Row
                    sx={{
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '10px',
                        color: '#bebdc1',
                        fontWeight: 500,
                        px: '7px',
                        pb: '2px',
                      }}
                    >
                      {dayjs(reasoning.createdAt).format('MM/DD/YYYY HH:mm:ss')}
                    </Typography>
                  </Row>
                  <Divider sx={{ borderColor: '#636365' }} />
                </Box>
              )}
            </Box>
          )
          previousTimestamp.current = timestamp
          return toRender
        })}
    </Box>
  )
}

export default SidebarObjectConsole
