import { lighten } from '@mui/material'

export const sidebarSx = {
  position: 'fixed',
  zIndex: 1000,
  background: 'white',
  width: `${8 * 90}px`,
  height: '100vh',
  overflowY: 'auto',
  top: 0,
  right: 0,
  p: 0,
  m: 0,
  boxShadow: `
0 1px 2px rgba(136, 161, 183, 0.07),
0 2px 4px rgba(136, 161, 183, 0.07),
0 4px 8px rgba(136, 161, 183, 0.07),
0 8px 16px rgba(136, 161, 183, 0.07),
0 16px 32px rgba(136, 161, 183, 0.07),
0 32px 64px rgba(136, 161, 183, 0.07)`,
  borderLeft: (theme) => `1px solid ${theme.palette.divider}`,
  borderTopLeftRadius: '12px',
  borderBottomLeftRadius: '12px',
  //transition: 'all 0.4s ease',
  '& .sidebar-outer': {
    opacity: open ? 1 : 0,
    //filter: open ? 'saturate(100%)' : 'saturate(0%)',
    //transition: 'all 1.2s ease',
    height: '100%',
    overflow: 'hidden',
  },
  '& .sidebar-navigation': {
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
    height: '56px',
    px: '12px',
    backgroundColor: 'transparent',
  },
  '& .sidebar-content': {
    height: 'calc(100% - 56px)',
    overflow: 'hidden',
  },
  '& .sidebar-content-mode-view': {
    '& .sidebar-content-mode-view-inner': {
      height: 'calc(100vh - 136px)',
      overflowY: 'auto',
    },
  },
  '& .header-row': {
    justifyContent: 'space-between',
    px: '20px',
    alignItems: 'flex-start',
    width: '100%',

    '& .main-avatar': {
      width: '52px',
      height: '52px',
    },

    '& .object-title-row': {
      justifyContent: 'space-between',
      width: '608px',
      '& h2': {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        flexShrink: 1,
      },
      '& .action-buttons': {
        justifyContent: 'flex-end',
        gap: 1,
      },
    },

    '& .description': {
      fontSize: '12px',
      fontWeight: 400,
      opacity: 0.8,
      letterSpacing: '-0.33px',
      width: '480px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      flexShrink: 1,
      pt: '4px',
    },
  },
  '& .section-title': {
    fontSize: '16px',
    fontWeight: 600,
    letterSpacing: '-0.4px',
  },
  '& .section': {
    mt: 3,
    '& .section-header-row': {
      justifyContent: 'space-between',
      alignItems: 'center',
      mb: 0,
      height: '26px',
      px: '12px',
      borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
    },
    '& .expand-collapse-button': {
      fontWeight: 500,
      minWidth: '60px',
      py: 0,
      '& .MuiButton-endIcon': {
        mr: '-2px',
      },

      '& .expand-arrow': {
        transition: 'transform 0.3s ease',
        transform: 'rotate(0deg)',
        height: '16px',
        width: '16px',
        color: lighten('#354052', 0.2),
      },
      '& .expand-arrow.expanded': {
        transform: 'rotate(-90deg)',
      },
    },

    '& .properties': {
      background: (theme) => theme.palette.background.default,
      display: 'flex',
      flexDirection: 'column',
      gap: 2,
      px: '16px',
      py: 5,
      '& .property-row': {
        display: 'flex',
        flexDirection: 'row',
        minHeight: '36px',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '656px',
        overflow: 'hidden',
        '& .property-row-content': {
          alignItems: 'flex-start',
          px: '12px',
          gap: 2,
          background: (theme) => theme.palette.background.paper,
          borderRadius: '4px',
          boxShadow: (theme) => theme.shadows[1],
          mb: '2px',
          mr: '2px',
        },
        '&:hover': {
          '& .property-edit-container .property-edit-inner': {
            opacity: 1,
          },
        },
        '& .property-name': {
          fontWeight: 600,
          letterSpacing: '-0.4px',
          textAlign: 'left',
          width: '104px',
          flexShrink: 0,
          fontSize: '12px',
          py: '7px',
        },
        '& .property-edit-container': {
          width: '32px',
          flexShrink: 0,
          pt: '5px',
          transition: 'opacity 0.3s ease',
          '& .property-edit-inner': {
            opacity: 0.6,
            '& .tabler-icon': {
              height: '16px',
              width: '16px',
            },
          },
        },
        '& .property-value': {
          fontWeight: 400,
          width: '480px',
          flexShrink: 0,
          py: '7px',
          overflow: 'hidden',

          '& .property-display-label': {
            fontSize: '12px',
            fontWeight: 400,
            letterSpacing: '-0.33px',
            opacity: 0.8,
            wordWrap: 'break-word',
            overflow: 'visible',
            textOverflow: 'unset',
            flexGrow: 1,
            whiteSpace: 'normal',
            width: '480px',
          },
        },
      },
    },
  },

  '& .padded': {
    px: '12px',
  },
}
