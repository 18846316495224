import { Mark } from '@tiptap/core'
import { mergeAttributes } from '@tiptap/react'

const aiMark = Mark.create({
  name: 'aiMark',
  renderHTML: ({ HTMLAttributes }) => {
    return ['span', mergeAttributes(HTMLAttributes, { class: 'ai-mark' })]
  },
})

export default aiMark
