import { PAGE_FIELDS_FRAGMENT } from './fragments'

export const GET_PAGE = gql`
  query GetPage($workspaceId: String!, $id: String!) {
    workspacePage(workspaceId: $workspaceId, id: $id) {
      ...PageFields
      authorization {
        workspace {
          isShared
        }
        users {
          id
          accessLevel
        }
      }
    }
  }
  ${PAGE_FIELDS_FRAGMENT}
`

export const GET_WORKSPACE_TEMPLATES = gql`
  query WorkspaceTemplates($workspaceId: String!) {
    workspaceTemplates(workspaceId: $workspaceId) {
      ...PageFields
    }
  }
  ${PAGE_FIELDS_FRAGMENT}
`

export const GET_TEMPLATES_FOR_WORKSPACE_USER_CONTEXT = gql`
  query TemplatesForWorkspaceUserContext(
    $contextId: String!
    $workspaceId: String!
  ) {
    templatesForWorkspaceUserContext(
      contextId: $contextId
      workspaceId: $workspaceId
    ) {
      ...PageFields
    }
  }
  ${PAGE_FIELDS_FRAGMENT}
`

export const GET_TEMPLATES_FOR_OUTPUT_TYPE = gql`
  query TemplatesForOutputType(
    $outputType: TemplateOutputType!
    $workspaceId: String!
  ) {
    templatesForOutputType(outputType: $outputType, workspaceId: $workspaceId) {
      ...PageFields
    }
  }
  ${PAGE_FIELDS_FRAGMENT}
`

export const GET_PAGES_FOR_SEARCH = gql`
  query GetPagesForSearchModal($workspaceId: String!) {
    pages(workspaceId: $workspaceId) {
      id
      title
      emoji
      domains
      createdAt
      people
    }
  }
`

export const GET_PAGES_FOR_LIBRARY = gql`
  query GetPagesForLibrary($workspaceId: String!) {
    pages(workspaceId: $workspaceId) {
      id
      title
      contentJson
      madeExternalAt
      publishedForUserAt
      createdAt
      updatedAt
      domains
      people
      emoji
      headerImage
      ownerEmail
      crmObjects {
        objectId
        objectType
        workspaceId
      }
      parentObject {
        objectId
        objectType
        workspaceId
      }
      shortLinkHash
      authorization {
        workspace {
          isShared
        }
        users {
          id
          accessLevel
        }
      }
    }
  }
`

export const GET_PAGES_FOR_ACTION = gql`
  query GetPagesForAction(
    $workspaceId: String!
    $objectType: String!
    $objectId: String!
  ) {
    pagesForParentObject(
      workspaceId: $workspaceId
      objectType: $objectType
      objectId: $objectId
    ) {
      id
      title
      objectId: id
      objectType: __typename
      properties: contentJson
      sourceTemplate {
        id
        templateType
      }
      objectType: __typename
      properties: contentJson
    }
  }
`

export const GET_ACTION_FOR_DRAFT = gql`
  query GetActionForDraft($id: String!, $workspaceId: String!) {
    action(id: $id, workspaceId: $workspaceId) {
      id
      workspaceId
      people
      channel {
        id
        label
      }
    }
  }
`

export const GET_SUGGESTED_CONTEXT_OBJECTS = gql`
  query GetSuggestedContextObjects(
    $parentReferenceKey: String!
    $workspaceId: String!
    $referencedObjectsKeys: [String!]!
    $prompt: String
    $dismissedObjectKeys: [String!]!
  ) {
    suggestedContextObjects(
      parentReferenceKey: $parentReferenceKey
      workspaceId: $workspaceId
      referencedObjectsKeys: $referencedObjectsKeys
      prompt: $prompt
      dismissedObjectKeys: $dismissedObjectKeys
    ) {
      objectsToAdd {
        objectId
        objectType
        workspaceId
      }
      objectsToSuggest {
        objectId
        objectType
        workspaceId
      }
    }
  }
`
