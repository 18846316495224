import { useContext } from 'react'

import {
  Autocomplete,
  Avatar,
  Box,
  Chip,
  TextField,
  Typography,
} from '@mui/material'
import type { WorkspaceMember } from 'types/graphql'

import { NativeObjectFormatters } from 'src/lib/contactFormatting'
import { DayContext } from 'src/lib/dayContext'
import { NativeObjectTypes } from 'src/lib/objects'

import Row from '../Row/Row'

const renderLabel = (option) => {
  return `${NativeObjectFormatters[NativeObjectTypes.Contact].label({
    properties: option.coreContact,
  })}`
}

const WorkspaceMemberSelect = ({
  onSelect,
  value,
  exclude = [],
  size = 'medium',
  label = 'Select workspace members',
  disabled,
  sx = {},
}: {
  onSelect: (member: WorkspaceMember[]) => void
  value: WorkspaceMember[]
  exclude?: string[] // Can be WorkspaceMember email or id
  size?: 'small' | 'medium'
  label?: string
  disabled?: boolean
  sx?: any
}) => {
  const { selectedWorkspace, workspaces } = useContext(DayContext)

  const workspace = workspaces?.find(
    (workspace) => workspace.id === selectedWorkspace
  )
  const options = workspace
    ? workspace.members?.filter(
        (member) =>
          member.status === 'ACTIVE' &&
          !exclude.includes(member.email) &&
          !exclude.includes(member.id)
      )
    : []

  const showAvatars = options.length < 100

  return (
    <Autocomplete
      sx={{
        ...sx,
        flexGrow: 1,
        '& .MuiChip-root': {
          borderRadius: '6px',
          height: '26px',
          py: 0,
          my: 0,
          '& .MuiAvatar-root': {
            ml: '6px',
          },
          '& .MuiChip-deleteIcon': {
            fontSize: '18px',
            mr: '6px',
          },
          '& .MuiChip-label': {
            fontSize: '0.7rem',
            fontWeight: 600,
            my: '4px',
            mr: '4px',
          },
        },
      }}
      options={options}
      getOptionLabel={renderLabel}
      onChange={(e, v) => onSelect(v as WorkspaceMember[])}
      multiple={true}
      getOptionKey={(option) => option.id}
      disabled={disabled}
      value={value}
      renderInput={(params) => (
        <TextField
          {...params}
          size={size}
          label={label}
          variant="outlined"
        />
      )}
      renderOption={(props, option) => (
        <Row
          sx={{
            fontSize: '0.7rem',
            px: 1,
            position: 'relative',
            zIndex: 9999,
          }}
          {...props}
        >
          {showAvatars && (
            <Avatar
              sx={{ width: 24, height: 24 }}
              src={option.coreContact.photo}
            />
          )}
          <Row sx={{ justifyContent: 'space-between', flexGrow: 1 }}>
            <Box
              sx={{
                ml: showAvatars ? 1 : 0,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                fontWeight: 600,
                fontSize: '0.8rem',
              }}
            >
              {renderLabel(option)}
            </Box>
            <Typography
              sx={{
                fontWeight: 300,
                fontSize: '0.7rem',
                fontStyle: 'italic',
                color: 'text.secondary',
              }}
            >
              {option.email}
            </Typography>
          </Row>
        </Row>
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            key={`workspaceMemberSelected_${option.id}`}
            icon={
              <Avatar
                src={option.coreContact.photo}
                sx={{
                  width: 16,
                  height: 16,
                  mr: 2,
                }}
              />
            }
            variant="outlined"
            color="primary"
            label={renderLabel(option)}
            size="medium"
            {...getTagProps({ index })}
          />
        ))
      }
      slotProps={{}}
    />
  )
}

export default WorkspaceMemberSelect
