export const PROPERTY_DEFINITIONS = gql`
  query ObjectPropertyDefinitions($workspaceId: String!) {
    objectPropertyDefinitions(workspaceId: $workspaceId) {
      id
      workspaceId
      name
      description
      objectTypeId
      propertyTypeId
      useWeb
      aiManaged
      propertyTypeId
      aiManaged
      options {
        id
        name
        description
      }
      createdAt
      updatedAt
    }
  }
`

export const PROPERTY_DEFINITIONS_WITH_TYPE = gql`
  query ObjectPropertyDefinitionsWithPropertyType($workspaceId: String!) {
    objectPropertyDefinitions(workspaceId: $workspaceId) {
      id
      workspaceId
      name
      description
      objectTypeId
      propertyTypeId
      propertyType {
        id
        name
        description
      }
      useWeb
      aiManaged
      propertyTypeId
      aiManaged
      options {
        id
        name
        description
      }
      createdAt
      updatedAt
    }
  }
`

export const OBJECT_PROPERTY_DEFINITIONS_BY_TYPE = gql`
  query ObjectPropertyDefinitionsByType(
    $workspaceId: String!
    $objectTypeId: String!
  ) {
    objectPropertyDefinitionsByObjectType(
      workspaceId: $workspaceId
      objectTypeId: $objectTypeId
    ) {
      id
      name
      description
      workspaceId
      objectTypeId
      propertyTypeId
      aiManaged
      useWeb
      options {
        name
        description
        id
      }
    }
  }
`

export const PROPERTY_DEFINITION_HISTORY = gql`
  query PropertyDefinitionHistory(
    $workspaceId: String!
    $propertyDefinitionId: String!
  ) {
    propertyDefinitionHistory(
      workspaceId: $workspaceId
      propertyDefinitionId: $propertyDefinitionId
    ) {
      id
      backfills {
        id
        status
        createdAt
        updatedAt
        userId
      }
      updates {
        objectId
        value
        sources
        reasoning
        createdAt
        propertySource
        option {
          id
          name
          description
        }
      }
    }
  }
`

export const STANDARD_PROPERTY_DEFINITIONS = gql`
  query StandardPropertyDefinitions($workspaceId: String!) {
    getStandardPropertyDefinitions(workspaceId: $workspaceId) {
      id
      name
      description
      propertyTypeId
      propertyType {
        id
        name
        description
      }
    }
  }
`

export const ALL_PROPERTY_DEFINITIONS_BY_TYPE = gql`
  query AllPropertyDefinitionsByType(
    $workspaceId: String!
    $objectType: String!
  ) {
    getAllPropertyDefinitionsByType(
      workspaceId: $workspaceId
      objectType: $objectType
    )
  }
`
export const CONTEXT_FOR_PROPERTY = gql`
  query ContextForProperty(
    $workspaceId: String!
    $objectId: String!
    $objectType: String!
    $propertyDefinitionId: String!
  ) {
    getContextForProperty(
      workspaceId: $workspaceId
      objectId: $objectId
      objectType: $objectType
      propertyDefinitionId: $propertyDefinitionId
    ) {
      id
      plainTextValue
      userId
      createdAt
      updatedAt
    }
  }
`
