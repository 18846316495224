import { useContext, useEffect, useState } from 'react'

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Tooltip,
} from '@mui/material'
import { RiCalendarView } from '@remixicon/react'
import { IconSettings } from '@tabler/icons-react'

import { DayContext } from 'src/lib/dayContext'

import WorkAccountCalendarRecordingSettings from '../WorkAccount/WorkAccountCalendarRecordingSettings/WorkAccountCalendarRecordingSettings'

const MeetingRecordingCalendarSettings = ({
  refetch,
  openIfNoRules = false,
  showLabel = true,
  component = null,
}) => {
  const { workAccounts } = useContext(DayContext)
  const [expanded, setExpanded] = useState(false)

  const hasAnyAutoRecordSettings = () => {
    return workAccounts?.some(
      (workAccount) => workAccount.calendarAutoRecordSettings?.mode !== 'NONE'
    )
  }

  useEffect(() => {
    if (openIfNoRules && !hasAnyAutoRecordSettings()) {
      setExpanded(true)
    }
  }, [workAccounts])

  const handleExpand = () => {
    setExpanded((prev) => {
      return !prev
    })
  }

  const handleRefetch = () => {
    refetch()
    setExpanded(false)
  }

  return (
    workAccounts?.length > 0 && (
      <>
        <Tooltip
          title="Change recording settings for your calendar(s)"
          arrow={true}
          placement="bottom"
        >
          <Box
            onClick={handleExpand}
            sx={{ width: '100%' }}
          >
            {component ? (
              component
            ) : (
              <Button
                size="small"
                fullWidth={true}
                sx={{
                  '& .remixicon': {
                    mx: '6px',
                  },
                }}
              >
                <IconSettings size={16} />
                {showLabel && (
                  <Box
                    component="span"
                    sx={{ fontSize: '0.7rem' }}
                  >
                    Recording settings
                  </Box>
                )}
              </Button>
            )}
          </Box>
        </Tooltip>
        <Dialog
          open={expanded}
          onClose={handleExpand}
          sx={{}}
        >
          <DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
            <RiCalendarView
              style={{
                height: '24px',
                width: '24px',
                marginRight: '8px',
              }}
            />
            Calendar Recording Settings
          </DialogTitle>
          <DialogContent>
            {workAccounts.map((workAccount, index) => (
              <Box
                key={index}
                sx={{ mb: 4, mt: 2 }}
              >
                <WorkAccountCalendarRecordingSettings
                  workAccount={workAccount}
                  refetch={handleRefetch}
                />
              </Box>
            ))}
          </DialogContent>
        </Dialog>
      </>
    )
  )
}

export default MeetingRecordingCalendarSettings
