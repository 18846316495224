import { logger } from 'src/lib/logger'

import { PROPERTY_ACTIONS } from './types'

const propertiesReducer = (state, action) => {
  switch (action.type) {
    case PROPERTY_ACTIONS.SET_INITIAL_STATE:
      logger.dev('PROPERTY_ACTIONS.SET_INITIAL_STATE', {
        action: action.payload,
      })
      return {
        ...state,
        properties: action.payload,
        pendingUpdates: {},
      }
    case PROPERTY_ACTIONS.UPDATE_PROPERTY: {
      const { propertyDefinitionId, optionId, value } = action.payload

      return {
        ...state,
        properties: {
          ...state.properties,
          [propertyDefinitionId]: value,
        },
        pendingUpdates: {
          ...state.pendingUpdates,
          [`${propertyDefinitionId}-${optionId}`]: true,
        },
      }
    }
    case PROPERTY_ACTIONS.UPDATE_PROPERTY_SUCCESS: {
      const { [action.payload.key]: _, ...remainingPendingUpdates } =
        state.pendingUpdates
      return {
        ...state,
        pendingUpdates: remainingPendingUpdates,
      }
    }
    case PROPERTY_ACTIONS.UPDATE_PROPERTY_ERROR: {
      const { propertyDefinitionId, optionId, value } = action.payload
      const currentValues = Array.isArray(
        state.properties[propertyDefinitionId]
      )
        ? [...state.properties[propertyDefinitionId]]
        : []

      // Revert the change by either removing or adding back the optionId
      const revertedValues = value
        ? currentValues.filter((id) => id !== optionId)
        : [...currentValues, optionId]

      return {
        ...state,
        properties: {
          ...state.properties,
          [propertyDefinitionId]: revertedValues,
        },
        pendingUpdates: {
          ...state.pendingUpdates,
          [`${propertyDefinitionId}-${optionId}`]: false,
        },
      }
    }
    default:
      return state
  }
}

export default propertiesReducer
