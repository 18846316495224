import { useMemo } from 'react'

import { Box } from '@mui/material'

const defaultSx = {}
const BodyContainer = ({ children, sx = defaultSx }) => {
  const containerSx = useMemo(() => {
    return {
      height: '100vh',
      width: '100%',
      overflow: 'hidden',
      px: '20px',
      background: (theme) => theme.palette.background.paper,
      ...sx,
    }
  }, [sx])
  return <Box sx={containerSx}>{children}</Box>
}

export default BodyContainer
