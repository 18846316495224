import { IconCheck, IconEye, IconLock } from '@tabler/icons-react'

export const pageStates = {
  PUBLIC: {
    label: 'Public',
    color: 'primary',
    variant: 'filled',
    icon: <IconEye />,
    sx: {},
  },
  PUBLISHED: {
    label: 'Workspace',
    color: 'primary',
    variant: 'outlined',
    icon: <IconCheck />,
    sx: {
      border: 'none',
    },
  },
  DRAFT: {
    label: 'Private',
    color: 'secondary',
    variant: 'outlined',
    sx: {},
    icon: <IconLock />,
  },
}

export const fullHeightSx = {
  height: '100%',
  width: '100%',
}
