/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState } from 'react'

import { Cross } from './Icons'

const Label = () => {
  const [isVisible, setIsVisible] = useState(true)

  const handleHide = () => {
    setIsVisible(false)
  }

  return (
    <>
      {isVisible && (
        <div className="relative flex items-center justify-between gap-4 bg-blue py-[13.5px] pe-9  ps-3 sm:gap-7 md:justify-center md:py-3">
          <p className="inline-block text-xs text-white md:text-lg">
            Day.ai raises $4M from Sequoia Capital to Reimagine CRM for the AI
            Age
          </p>
          <a
            href="/sequoia-backs-ai-native-crm"
            aria-label="Read more about Day.ai"
            className="me-3 whitespace-nowrap text-xs text-white underline underline-offset-2 transition-all duration-300 hover:text-opacity-80 sm:me-0 sm:text-base"
          >
            Read more
          </a>
          <span
            className="absolute end-0 top-1/2 me-3 -translate-y-1/2 cursor-pointer transition-all duration-300 hover:scale-110 md:me-5"
            onClick={handleHide}
          >
            <Cross />
          </span>
        </div>
      )}
    </>
  )
}

export default Label
