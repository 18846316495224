import { useCallback, useEffect, useMemo, useReducer } from 'react'

import {
  Avatar,
  Box,
  Chip,
  CircularProgress,
  darken,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  List,
  ListItemButton,
  TextField,
  Typography,
} from '@mui/material'
import {
  IconCircleFilled,
  IconBuildingPlus,
  IconUserPlus,
  IconX,
} from '@tabler/icons-react'
import { debounce } from 'lodash'
import { getDomain } from 'tldts'
import type { CRMObject, DayObjectSearchEntry } from 'types/graphql'

import { useAuth } from 'src/auth'
import MultiObjectChip from 'src/components/Chips/MultiObjectChip/MultiObjectChip'
import { searchWithFuseIndex } from 'src/components/Objects/searchIndex'
import SyncStatus from 'src/components/Objects/SyncStatus'
import Row from 'src/components/Row/Row'
import { actionButtonStyle } from 'src/components/Sidebar/styles'
import ThreadCreateButton from 'src/components/Threads/ThreadCreateButton/ThreadCreateButton'
import { dayjs } from 'src/lib/dayjs'
import { logger } from 'src/lib/logger'
import {
  NativeObjectTypes,
  ObjectTypeMetadata,
  type NativeObjectType,
} from 'src/lib/objects'
import { buildGqlObject } from 'src/lib/Objects/build'
import { getRecentObjects, addToRecentObjects } from 'src/lib/recentObjects'

const SECTION_LABELS = {
  [NativeObjectTypes.Organization]: {
    label: ObjectTypeMetadata[NativeObjectTypes.Organization].pluralLabel,
    icon: ObjectTypeMetadata[NativeObjectTypes.Organization].icon,
  },
  [NativeObjectTypes.Contact]: {
    label: ObjectTypeMetadata[NativeObjectTypes.Contact].pluralLabel,
    icon: ObjectTypeMetadata[NativeObjectTypes.Contact].icon,
  },
  [NativeObjectTypes.MeetingRecording]: {
    label: 'Meetings',
    icon: ObjectTypeMetadata[NativeObjectTypes.MeetingRecording].icon,
  },
  [NativeObjectTypes.Pipeline]: {
    label: ObjectTypeMetadata[NativeObjectTypes.Pipeline].pluralLabel,
    icon: ObjectTypeMetadata[NativeObjectTypes.Pipeline].icon,
  },
  [NativeObjectTypes.Opportunity]: {
    label: ObjectTypeMetadata[NativeObjectTypes.Opportunity].pluralLabel,
    icon: ObjectTypeMetadata[NativeObjectTypes.Opportunity].icon,
  },
  [NativeObjectTypes.Page]: {
    label: ObjectTypeMetadata[NativeObjectTypes.Page].pluralLabel,
    icon: ObjectTypeMetadata[NativeObjectTypes.Page].icon,
  },
}

const MAX_RESULTS_PER_TYPE = 5

const isValidEmail = (value: string): boolean => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
}

const isValidDomain = (value: string): boolean => {
  // Remove any protocol and www prefix
  const cleanValue = value
    .trim()
    ?.toLowerCase()
    ?.replace(/^(https?:\/\/)?(www\.)?/, '')

  // Get the domain using tldts
  const domain = getDomain(cleanValue)
  return !!domain
}

const cleanDomain = (value: string): string => {
  const cleanValue = value
    .trim()
    ?.toLowerCase()
    ?.replace(/^(https?:\/\/)?(www\.)?/, '')
  return getDomain(cleanValue) || cleanValue
}

interface SearchModalProps {
  open?: boolean
  onClose?: () => void
  onSelect?: (result: CRMObject) => void
  contextualActions?: {
    label: string
    icon?: React.ReactNode
    onClick: (result: CRMObject) => void
  }[]
  forcedObjectTypes?: NativeObjectType[]
  allowCreation?: boolean
  targetObjectType?: NativeObjectType
  workspaceId: string
  hiddenObjectIds?: string[]
  closeOnSelect?: boolean
}

const DEBOUNCE_MS = 300 // Balance between responsiveness and UI stability

interface SearchState {
  query: string
  results: DayObjectSearchEntry[]
  loading: boolean
  selectedTypes: Set<NativeObjectType>
  isValidFreeSoloInput: boolean
  recentObjects: DayObjectSearchEntry[]
  isTyping: boolean
}

type SearchAction =
  | { type: 'SET_QUERY'; payload: string }
  | { type: 'SET_RESULTS'; payload: DayObjectSearchEntry[] }
  | { type: 'SET_LOADING'; payload: boolean }
  | { type: 'TOGGLE_TYPE'; payload: NativeObjectType }
  | { type: 'SET_TYPES'; payload: Set<NativeObjectType> }
  | { type: 'SET_RECENT_OBJECTS'; payload: DayObjectSearchEntry[] }
  | {
      type: 'VALIDATE_INPUT'
      payload: { query: string; results: DayObjectSearchEntry[] }
    }
  | { type: 'RESET' }
  | { type: 'BATCH_UPDATE'; payload: Partial<SearchState> }
  | { type: 'SET_TYPING'; payload: boolean }

const initialState = (forcedObjectTypes?: NativeObjectType[]): SearchState => ({
  query: '',
  results: [],
  loading: false,
  selectedTypes: forcedObjectTypes
    ? new Set<NativeObjectType>(forcedObjectTypes)
    : new Set<NativeObjectType>(),
  isValidFreeSoloInput: false,
  recentObjects: [],
  isTyping: false,
})

function searchReducer(state: SearchState, action: SearchAction): SearchState {
  switch (action.type) {
    case 'SET_QUERY':
      return {
        ...state,
        query: action.payload,
        isTyping: true,
        // Clear results and validation if query is empty
        ...(action.payload === '' && {
          results: [],
          isValidFreeSoloInput: false,
          isTyping: false,
        }),
      }
    case 'SET_TYPING':
      return {
        ...state,
        isTyping: action.payload,
      }
    case 'SET_RESULTS':
      return {
        ...state,
        results: action.payload,
      }
    case 'SET_LOADING':
      return {
        ...state,
        loading: action.payload,
      }
    case 'TOGGLE_TYPE': {
      const newTypes = new Set(state.selectedTypes)
      if (newTypes.has(action.payload)) {
        newTypes.delete(action.payload)
      } else {
        newTypes.add(action.payload)
      }
      // If no types selected, show everything
      return {
        ...state,
        selectedTypes: newTypes.size === 0 ? new Set() : newTypes,
      }
    }
    case 'SET_TYPES':
      return {
        ...state,
        selectedTypes: action.payload,
      }
    case 'SET_RECENT_OBJECTS':
      return {
        ...state,
        recentObjects: action.payload,
      }
    case 'VALIDATE_INPUT':
      return {
        ...state,
        isValidFreeSoloInput:
          action.payload.results.length === 0 &&
          (isValidEmail(action.payload.query) ||
            isValidDomain(action.payload.query)),
      }
    case 'RESET':
      return {
        ...initialState(
          state.selectedTypes.size > 0 ? Array.from(state.selectedTypes) : []
        ),
      }
    case 'BATCH_UPDATE':
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

const SearchModalV2 = ({
  open = false,
  onClose,
  onSelect,
  contextualActions = [],
  forcedObjectTypes = [],
  allowCreation = false,
  workspaceId,
  targetObjectType,
  hiddenObjectIds = [],
  closeOnSelect = true,
}: SearchModalProps) => {
  const { currentUser } = useAuth()

  const finalHiddenObjectIds = useMemo(() => {
    return [...hiddenObjectIds, 'CREATE', 'EDIT', 'create', 'edit']
  }, [hiddenObjectIds])

  const [state, dispatch] = useReducer(
    searchReducer,
    forcedObjectTypes,
    initialState
  )

  const {
    query,
    results,
    loading,
    selectedTypes,
    isValidFreeSoloInput,
    recentObjects,
    isTyping,
  } = state

  // Load cached index immediately when modal opens
  useEffect(() => {
    if (!open) return

    const handler = async () => {
      const typesToGet =
        selectedTypes.size > 0
          ? Array.from(selectedTypes)
          : Object.values(NativeObjectTypes)
      logger.dev({ typesToGet })
      const recent = await getRecentObjects(
        workspaceId,
        currentUser?.id,
        typesToGet as NativeObjectType[]
      )
      dispatch({ type: 'SET_RECENT_OBJECTS', payload: recent })
    }
    handler()
  }, [open, workspaceId, currentUser?.id, selectedTypes])

  // Memoize the search handler to prevent recreation
  const debouncedSearchHandler = useMemo(
    () =>
      debounce(
        async (searchQuery: string, dispatch: React.Dispatch<SearchAction>) => {
          // Set typing to false since debounce period has passed
          dispatch({ type: 'SET_TYPING', payload: false })

          // Handle empty query
          if (!searchQuery) {
            dispatch({ type: 'SET_RESULTS', payload: [] })
            dispatch({ type: 'SET_LOADING', payload: false })
            return
          }

          // Start loading
          dispatch({ type: 'SET_LOADING', payload: true })

          try {
            const results = await searchWithFuseIndex({
              workspaceId,
              query: searchQuery,
            })
            dispatch({ type: 'SET_RESULTS', payload: results ?? [] })
            dispatch({
              type: 'VALIDATE_INPUT',
              payload: { query: searchQuery, results: results ?? [] },
            })
          } catch (error) {
            logger.error('Search error:', error)
            dispatch({ type: 'SET_RESULTS', payload: [] })
          } finally {
            dispatch({ type: 'SET_LOADING', payload: false })
          }
        },
        DEBOUNCE_MS
      ),
    [workspaceId]
  )

  // Handle query changes immediately for UI responsiveness
  const handleQueryChange = useCallback(
    (newQuery: string) => {
      // Cancel any pending searches
      debouncedSearchHandler.cancel()

      // Only update the query text immediately
      dispatch({ type: 'SET_QUERY', payload: newQuery })

      // Trigger the debounced search
      debouncedSearchHandler(newQuery, dispatch)
    },
    [debouncedSearchHandler]
  )

  // Reset state when modal closes
  useEffect(() => {
    if (!open) {
      dispatch({ type: 'RESET' })
    }
  }, [open])

  // Clean up the debounced handler
  useEffect(() => {
    return () => {
      debouncedSearchHandler.cancel()
    }
  }, [debouncedSearchHandler])

  // Separate effect for validation state
  useEffect(() => {
    if (!query) {
      dispatch({ type: 'VALIDATE_INPUT', payload: { query: '', results: [] } })
      return
    }
    dispatch({
      type: 'VALIDATE_INPUT',
      payload: { query: query, results: results },
    })
  }, [query, results])

  const handleClose = useCallback(() => {
    dispatch({ type: 'RESET' })
    onClose?.()
  }, [onClose])

  const supportedTypes = useMemo(() => {
    return Object.keys(SECTION_LABELS)
  }, [])

  const handleSelect = useCallback(
    (result: any) => {
      let crmObject: CRMObject | null = null
      if (result.object) {
        const gqlObject = buildGqlObject(result.object)
        crmObject = {
          objectId: result.objectId,
          objectType: result.objectType,
          workspaceId,
          properties: gqlObject,
        }
      } else {
        crmObject = result
      }

      if (onSelect) {
        onSelect(crmObject)
      }
      if (closeOnSelect) {
        onClose?.()
      }
      addToRecentObjects(crmObject, workspaceId, currentUser?.id)
    },
    [onSelect, onClose, workspaceId, currentUser?.id, closeOnSelect]
  )

  const handleTypeToggle = useCallback((type: NativeObjectType) => {
    dispatch({ type: 'TOGGLE_TYPE', payload: type })
  }, [])

  const handleViewAll = useCallback((type: NativeObjectType) => {
    dispatch({ type: 'SET_TYPES', payload: new Set([type]) })
  }, [])

  const groupedResults = useMemo(() => {
    const groups: Record<string, DayObjectSearchEntry[]> = {}
    const itemsToGroup = query ? results : recentObjects
    const filteredResults =
      forcedObjectTypes.length > 0
        ? itemsToGroup.filter((r) => forcedObjectTypes.includes(r.objectType))
        : selectedTypes.size > 0
          ? itemsToGroup.filter((r) => selectedTypes.has(r.objectType))
          : itemsToGroup

    // Group results and track best composite scores
    const groupScores: Record<string, number> = {}
    filteredResults?.forEach((result: any) => {
      if (!groups[result.objectType]) {
        groups[result.objectType] = []
        groupScores[result.objectType] = result.compositeScore ?? 0
      }
      groups[result.objectType].push(result)
      // Track best (highest) composite score for the group
      if ((result.compositeScore ?? 0) > groupScores[result.objectType]) {
        groupScores[result.objectType] = result.compositeScore ?? 0
      }
    })

    // Sort groups by best composite score first
    const sortedGroups: Record<string, DayObjectSearchEntry[]> = {}
    Object.entries(groups)
      .sort(([, resultsA], [, resultsB]) => {
        // Get the best composite score from each group
        const bestScoreA = Math.max(
          ...resultsA.map((r) => (r as any).compositeScore ?? 0)
        )
        const bestScoreB = Math.max(
          ...resultsB.map((r) => (r as any).compositeScore ?? 0)
        )

        // Sort by composite score (higher is better)
        return bestScoreB - bestScoreA
      })
      .forEach(([type, typeResults]) => {
        sortedGroups[type] = typeResults
      })

    return sortedGroups
  }, [query, results, recentObjects, selectedTypes, forcedObjectTypes])

  const containerSx = {
    p: 0,
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',

    '& .search-input-container': {
      m: '0px',
      mb: 0,
      width: 'calc(100%)',
      borderRadius: 0,
      '& .search-input': {
        '& .MuiInputBase-root': {
          display: 'flex',
          height: '41px',
          padding: '8px 16px',
          alignItems: 'center',
          gap: '4px',
          alignSelf: 'stretch',
          borderRadius: 0,
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          background: '#FFF',
          boxShadow: '4px 4px 20px 0px rgba(0, 0, 0, 0.05)',
          fontSize: '0.9rem',
          letterSpacing: '-0.3px',
          fontWeight: 500,
          '& input': {
            p: 0,
            height: '100%',
          },
          '&::placeholder': {
            color: 'text.secondary',
            opacity: 0.8,
            ml: '-4px',
          },
        },
      },
    },

    '& .type-filter-chips': {
      display: 'flex',
      alignItems: 'center',
      width: 'calc(100% - 24px)',
      gap: 1,
      mx: '12px',
      my: 0,
      height: '42px',
      flexShrink: 0,
      overflowY: 'hidden',
      overflowX: 'auto',
      flexWrap: 'nowrap',
      justifyContent: 'space-between',
      '& .type-filter-chip': {
        borderRadius: '5px',
        justifyContent: 'center',
        border: (theme) => `1px solid ${theme.palette.divider}`,
        flexGrow: 1,
        '& .MuiTypography-root': {
          fontSize: '12px',
          fontStyle: 'normal',
          fontWeight: selectedTypes.size === 0 ? 500 : 500,
          lineHeight: '130%',
          letterSpacing: '-0.4px',
        },
      },
    },

    '& .recent-and-sync-status': {
      justifyContent: 'space-between',
      pl: 2,
      '& .recent-header': {
        fontSize: '16px',
        fontWeight: 600,
        letterSpacing: '-0.5px',
        color: 'text.secondary',
        opacity: 0.9,
      },

      '& .sync-status-container': {
        pr: 2,
      },
    },

    '& .MuiListItemButton-root': {
      px: '12px',
      py: 1,
      width: '100%',
      height: '50px',
      '& > *': {
        width: '100%',
        minWidth: 0,
        height: '100%',
      },

      '& .result-action-container': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        gap: 1,
        width: '156px',
        flexShrink: 0,
        pl: 2,
      },
      '& .result-action-button': {
        flexShrink: 0,
        width: '100%',
        '& .MuiButtonBase-root': {
          width: '100%',
        },
      },
      '&:hover .result-action-button': {
        display: 'flex',
      },
    },

    '& .search-results-header': {
      px: 2,
      pt: '12px',
      pb: '4px',
      display: 'flex',
      alignItems: 'center',
      gap: '4px',
      color: 'text.secondary',
      position: 'sticky',
      top: 0,
      zIndex: 1,
      fontSize: '13px',
      fontWeight: 600,
      lineHeight: '140%',
      letterSpacing: '-0.24px',
      '& .count': {
        opacity: 0.5,
        fontWeight: 400,
      },
    },

    '& .search-results-list': {
      p: 0,
      flex: 1,
      overflowY: 'auto',
      height: '514px',
      flexShrink: 0,
      '&::-webkit-scrollbar': { width: '2px' },
    },

    '& .result-item': {
      height: '36px',
      '& .result-item-container': {
        width: '100%',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: 0.5,
        '& .result-item-content': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          height: '24px',
          overflow: 'hidden',
          '& .result-avatar-container': {
            width: '20px',
            height: '20px',
            flexShrink: 0,
            '& .MuiAvatar-root': {
              width: '20px',
              height: '20px',
            },
          },
          '& .result-icon-container': {
            width: '20px',
            height: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexShrink: 0,
            '& .tabler-icon': {
              width: '16px',
              height: '16px',
            },
          },
          '& .result-description, .result-label, .result-updated-at': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            width: '380px',
            fontSize: '12px',
            fontWeight: 400,
            letterSpacing: '-0.3px',
          },
          '& .result-label': {
            fontWeight: 600,
            width: '212px',
          },
          '& .result-updated-at-container': {
            width: '56px',
            '& .result-updated-at': {
              opacity: 0.7,
            },
          },
          '& .result-description': {
            width: '390px',
            fontSize: '11px',
          },
        },
      },
    },
  }

  const dialogPaperProps = {
    sx: {
      height: '620px',
      width: '800px',
      maxWidth: '800px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      flexShrink: 0,
      m: 0,
      maxHeight: 'none',
      borderRadius: '12px',
    },
  }

  const toggleStyle = (isSelected: boolean) => ({
    display: 'flex',
    padding: '0px 8px',
    alignItems: 'center',
    gap: '4px',
    height: '24px',
    cursor: 'pointer',
    borderRadius: '4px',
    background: (theme) =>
      isSelected ? theme.palette.primary.main : 'transparent',
    opacity: isSelected ? 1 : 0.8,
    boxShadow: (theme) => (isSelected ? theme.shadows[1] : 'none'),
    transition: 'all 0.2s ease',
    '&:hover': {
      opacity: 1,
    },
    flexShrink: 0,
    '& .MuiTypography-root': {
      opacity: isSelected ? 1 : 0.8,
      fontSize: '11px',
      fontStyle: 'normal',
      fontWeight: isSelected ? 500 : 500,
      lineHeight: '130%',
      letterSpacing: '-0.22px',
      width: 'auto',

      color: (theme) =>
        isSelected
          ? theme.palette.primary.contrastText
          : theme.palette.text.secondary,
      transition: 'all 0.2s ease',
    },
    '& .tabler-icon': {
      color: (theme) =>
        isSelected
          ? theme.palette.primary.contrastText
          : theme.palette.text.secondary,
      transition: 'all 0.2s ease',
    },
  })

  return (
    workspaceId && (
      <>
        <Dialog
          open={open}
          onClose={handleClose}
          PaperProps={dialogPaperProps}
        >
          <DialogContent sx={containerSx}>
            <Box className="search-input-container">
              <TextField
                className="search-input"
                autoFocus
                fullWidth
                placeholder="Search"
                value={query}
                onChange={(e) => handleQueryChange(e.target.value)}
                variant="standard"
                InputProps={{
                  disableUnderline: true,

                  endAdornment: (
                    <Box
                      sx={{
                        display: 'flex',
                        gap: '4px',
                        alignItems: 'center',
                        mr: -1,
                        transform: 'translateY(2px)',
                      }}
                    >
                      {loading && (
                        <CircularProgress
                          size={16}
                          sx={{ opacity: 0.5 }}
                        />
                      )}
                      {query && (
                        <IconButton
                          size="small"
                          onClick={() => {
                            dispatch({ type: 'SET_QUERY', payload: '' })
                            dispatch({ type: 'SET_LOADING', payload: false })
                          }}
                        >
                          <IconX size={16} />
                        </IconButton>
                      )}
                      {!query && (
                        <Box
                          sx={{
                            display: 'flex',
                            height: '24px',
                            padding: '4px',
                            pt: 0,
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '4px',
                            color: 'text.secondary',
                            opacity: 0.5,
                          }}
                        >
                          <Typography
                            variant="caption"
                            sx={{
                              fontSize: '12px',
                              fontWeight: 500,
                              letterSpacing: '-0.3px',
                              border: (theme) =>
                                `1px solid ${darken(theme.palette.divider, 0.2)}`,
                              borderRadius: '4px',
                              background: (theme) =>
                                theme.palette.background.default,
                              px: 0.5,
                              py: 0,
                            }}
                          >
                            ⌘K
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  ),
                }}
              />
            </Box>

            {/* Type filter chips - only show if no forcedObjectTypes */}
            {forcedObjectTypes.length === 0 && (
              <Box className="type-filter-chips">
                <Box
                  onClick={() =>
                    dispatch({ type: 'SET_TYPES', payload: new Set() })
                  }
                  sx={toggleStyle(selectedTypes.size === 0)}
                  className="type-filter-chip"
                >
                  <Typography>Everything</Typography>
                </Box>
                {Object.entries(SECTION_LABELS).map(([type, info]) => {
                  const isSelected = selectedTypes.has(type as NativeObjectType)
                  return (
                    <Box
                      key={type}
                      onClick={() => handleTypeToggle(type as NativeObjectType)}
                      sx={toggleStyle(isSelected)}
                      className="type-filter-chip"
                    >
                      {React.createElement(info.icon, {
                        size: 12,
                        style: { opacity: isSelected ? 1 : 0.7 },
                      })}
                      <Typography>{info.label}</Typography>
                    </Box>
                  )
                })}
              </Box>
            )}

            <Row className="recent-and-sync-status">
              {!query && (
                <Typography className="recent-header">
                  Recently Updated
                </Typography>
              )}

              <Box className="sync-status-container">
                <SyncStatus />
              </Box>
            </Row>

            <List className="search-results-list">
              {Object.entries(groupedResults ?? {}).map(
                ([type, typeResults]) => {
                  if (!supportedTypes.includes(type)) return null
                  const displayResults =
                    selectedTypes.size > 0
                      ? typeResults
                      : typeResults.slice(0, MAX_RESULTS_PER_TYPE)
                  const hasMore =
                    selectedTypes.size === 0 &&
                    typeResults.length > MAX_RESULTS_PER_TYPE &&
                    !!query

                  const sectionInfo = SECTION_LABELS[type] || {
                    label: type,
                    icon: ObjectTypeMetadata[type]?.icon || IconCircleFilled,
                  }

                  return (
                    <Box
                      key={type}
                      sx={{
                        '& .result-action-button': {
                          display: 'none',
                          flexShrink: 0,
                        },
                      }}
                    >
                      {selectedTypes.size !== 1 && (
                        <Box>
                          <Typography
                            variant="body2"
                            className="search-results-header"
                          >
                            {React.createElement(sectionInfo.icon, {
                              size: 14,
                              style: {
                                opacity: 0.5,
                                marginTop: '-1px',
                                display: 'none',
                              },
                            })}
                            <span className="section-label">
                              {sectionInfo.label}
                            </span>{' '}
                            <span className="count">
                              {typeResults?.length ?? 0}
                            </span>
                          </Typography>
                          <Divider />
                        </Box>
                      )}
                      {displayResults?.map((result) =>
                        finalHiddenObjectIds.includes(result.objectId) ||
                        !result.label ? null : (
                          <ListItemButton
                            key={`${result.objectType}-${result.objectId}`}
                            onClick={() => handleSelect(result)}
                            className="result-item"
                          >
                            <Box className="result-item-container">
                              <Box className="result-item-content">
                                <Box
                                  sx={{
                                    width: 'calc(100% - 156px)',
                                    flexShrink: 1,
                                    overflow: 'hidden',
                                    display: 'flex',
                                    flexDirection: 'column',
                                  }}
                                >
                                  <Row gap={1}>
                                    {result.photoUrl ? (
                                      <Box className="result-avatar-container">
                                        <Avatar src={result.photoUrl} />
                                      </Box>
                                    ) : (
                                      <Box className="result-icon-container">
                                        {React.createElement(
                                          ObjectTypeMetadata[result.objectType]
                                            .icon,
                                          { size: 16 }
                                        )}
                                      </Box>
                                    )}
                                    <Box className="result-object-chip-container">
                                      <Typography className="result-label">
                                        {result.label}
                                      </Typography>
                                    </Box>
                                    <Box className="result-updated-at-container">
                                      <Typography className="result-updated-at">
                                        {result.object?.updatedAt
                                          ? dayjs(
                                              result.object.updatedAt
                                            ).format('M/D/YY')
                                          : ''}
                                      </Typography>
                                    </Box>
                                    <Typography className="result-description">
                                      {result.description}
                                    </Typography>
                                  </Row>
                                </Box>
                                <Box className="result-action-container">
                                  {contextualActions.length > 0 ? (
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        gap: 1,
                                        ml: 2,
                                        width: '100%',
                                        flexShrink: 0,
                                        justifyContent: 'flex-end',
                                        flexDirection: 'row',
                                      }}
                                    >
                                      {contextualActions
                                        .filter(Boolean)
                                        .map((action, index) => (
                                          <Chip
                                            key={index}
                                            size="small"
                                            className="result-action-button"
                                            icon={
                                              action.icon ||
                                              (targetObjectType &&
                                                React.createElement(
                                                  ObjectTypeMetadata[
                                                    targetObjectType
                                                  ].icon,
                                                  { size: 16 }
                                                ))
                                            }
                                            onClick={(e) => {
                                              e.stopPropagation()
                                              action.onClick({
                                                objectId: result.objectId,
                                                objectType: result.objectType,
                                                properties: result?.object
                                                  ? buildGqlObject(
                                                      result?.object
                                                    )
                                                  : result?.properties || {},
                                              })
                                            }}
                                            sx={{
                                              ...actionButtonStyle,
                                              height: '20px',
                                            }}
                                            label={action.label}
                                          />
                                        ))}
                                    </Box>
                                  ) : targetObjectType &&
                                    ObjectTypeMetadata[targetObjectType]
                                      ?.icon ? (
                                    <Row className="result-action-container">
                                      <Chip
                                        size="small"
                                        variant="outlined"
                                        icon={
                                          targetObjectType &&
                                          React.createElement(
                                            ObjectTypeMetadata[targetObjectType]
                                              .icon,
                                            { size: 16 }
                                          )
                                        }
                                        sx={actionButtonStyle}
                                        label={`Add to ${ObjectTypeMetadata[targetObjectType].label}`}
                                        className="result-action-button"
                                        onClick={(e) => {
                                          e.stopPropagation()
                                          if (closeOnSelect) {
                                            handleClose()
                                          }
                                        }}
                                      />
                                    </Row>
                                  ) : (
                                    <Row className="result-action-container">
                                      <Box
                                        className="result-action-button"
                                        onClick={(e) => {
                                          e.stopPropagation()
                                          if (closeOnSelect) {
                                            handleClose()
                                          }
                                        }}
                                      >
                                        <ThreadCreateButton
                                          workspaceId={workspaceId}
                                          sourceObject={{
                                            objectId: result.objectId,
                                            objectType: result.objectType,
                                            properties: result?.object
                                              ? buildGqlObject(result?.object)
                                              : result?.properties || {},
                                          }}
                                          otherObjects={[]}
                                          title="Create thread"
                                          onThreadCreated={() => {
                                            handleClose()
                                          }}
                                          sx={{
                                            height: '20px',
                                          }}
                                        />
                                      </Box>
                                    </Row>
                                  )}
                                </Box>
                              </Box>
                            </Box>
                          </ListItemButton>
                        )
                      )}
                      {hasMore && (
                        <Box sx={{ p: 1 }}>
                          <MultiObjectChip
                            crmObjects={typeResults?.map((result) => ({
                              objectId: result.objectId,
                              objectType: result.objectType,
                              properties: result?.object
                                ? buildGqlObject(result?.object)
                                : result?.properties || {},
                            }))}
                            workspaceId={workspaceId}
                            onClick={() =>
                              handleViewAll(type as NativeObjectType)
                            }
                            label={`View all ${typeResults.length} ${SECTION_LABELS[type]?.label}`}
                          />
                        </Box>
                      )}
                    </Box>
                  )
                }
              )}

              {query &&
                Object.keys(groupedResults ?? {}).length === 0 &&
                !loading &&
                !isTyping &&
                allowCreation &&
                (isValidFreeSoloInput ? (
                  <ListItemButton
                    onClick={() => {
                      const cleanedEmail = query.includes('@')
                        ? query.trim().toLowerCase()
                        : null
                      const cleanedDomain = !query.includes('@')
                        ? cleanDomain(query)
                        : null
                      handleSelect({
                        objectType: query.includes('@')
                          ? NativeObjectTypes.Contact
                          : NativeObjectTypes.Organization,
                        objectId: cleanedEmail || cleanedDomain,
                        label: cleanedEmail || cleanedDomain,
                        lastUpdated: Date.now(),
                        properties: cleanedEmail
                          ? { email: cleanedEmail }
                          : { domain: cleanedDomain },
                      })
                    }}
                    sx={{
                      px: '12px',
                      py: 1,
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      gap: 2,
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                        width: '100%',
                      }}
                    >
                      {query.includes('@') ? (
                        <IconUserPlus size={30} />
                      ) : (
                        <IconBuildingPlus size={30} />
                      )}
                      <Box>
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontSize: '0.9rem',
                          }}
                        >
                          Add {query.includes('@') ? 'Person' : 'Organization'}
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: 400,
                            fontSize: '0.7rem',
                            color: 'text.secondary',
                          }}
                        >
                          {query.includes('@') ? query : cleanDomain(query)}
                        </Typography>
                      </Box>
                    </Box>
                  </ListItemButton>
                ) : (
                  <Box component="div">
                    <ListItemButton
                      disabled
                      sx={{
                        px: '12px',
                        py: 1,
                        width: '100%',
                        opacity: 0.8,
                        '&.Mui-disabled': {
                          opacity: 0.8,
                        },
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 2,
                          width: '100%',
                        }}
                      >
                        <IconUserPlus size={30} />
                        <Box>
                          <Typography
                            sx={{
                              fontWeight: 500,
                              fontSize: '0.9rem',
                            }}
                          >
                            Add Person
                          </Typography>
                          <Typography
                            sx={{
                              fontWeight: 400,
                              fontSize: '0.7rem',
                              color: (theme) => theme.palette.secondary.main,
                            }}
                          >
                            Type a full email address to create
                          </Typography>
                        </Box>
                      </Box>
                    </ListItemButton>
                    <ListItemButton
                      disabled
                      sx={{
                        px: '12px',
                        py: 1,
                        width: '100%',
                        opacity: 0.8,
                        '&.Mui-disabled': {
                          opacity: 0.8,
                        },
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 2,
                          width: '100%',
                        }}
                      >
                        <IconBuildingPlus size={30} />
                        <Box>
                          <Typography
                            sx={{
                              fontWeight: 500,
                              fontSize: '0.9rem',
                            }}
                          >
                            Add Organization
                          </Typography>
                          <Typography
                            sx={{
                              fontWeight: 400,
                              fontSize: '0.7rem',
                              color: (theme) => theme.palette.secondary.main,
                            }}
                          >
                            Type a valid web domain to create
                          </Typography>
                        </Box>
                      </Box>
                    </ListItemButton>
                  </Box>
                ))}
            </List>
          </DialogContent>
        </Dialog>
      </>
    )
  )
}

export default SearchModalV2
