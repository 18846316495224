import { useContext, useMemo } from 'react'

import { Box, Chip, darken, IconButton, Tooltip } from '@mui/material'
import { IconPlus, IconTrophy } from '@tabler/icons-react'
import type { Organization } from 'types/graphql'

import { navigate, routes } from '@redwoodjs/router'

import Row from 'src/components/Row/Row'
import SidebarButton from 'src/components/Sidebar/SidebarButton/SidebarButton'
import { DayContext } from 'src/lib/dayContext'
import { NativeObjectTypes } from 'src/lib/objects'

import { getStageColor } from '../Pipeline'

// const SHOW_SUGGESTED_OPP = false

const OpportunityDomainChip = ({
  organization,
  size = 'small',
  direction = 'row',
  openSidebar = false,
}: {
  organization: Organization
  size?: 'small' | 'medium'
  direction?: 'row' | 'column'
  openSidebar?: boolean
}) => {
  const { setSidebarObject, pipelines, selectedWorkspace } =
    useContext(DayContext)

  // const suggestedStage = organization?.overview?.['status/recommendedStage']
  const existingOpportunities = useMemo(() => {
    if (!pipelines && !organization?.domain) return []

    const opps = []
    for (const pipeline of pipelines.filter(Boolean) || []) {
      for (const stage of pipeline.stages.filter(Boolean) || []) {
        for (const opportunity of stage.opportunities.filter(Boolean) || []) {
          if (opportunity?.domain === organization?.domain) {
            opps.push({
              id: opportunity.id,
              title: opportunity.title,
              type: opportunity.type,
              pipelineTitle: pipeline.title,
              pipelineId: pipeline.id,
              stagePosition: stage.position,
              stageTitle: stage.title,
              workspaceId: pipeline.workspaceId,
              crmObject: {
                objectId: opportunity.id,
                objectType: NativeObjectTypes.Opportunity,
                properties: {
                  ...opportunity,
                  pipelineId: pipeline.id,
                  hasRevenue: pipeline.hasRevenue,
                },
              },
            })
          }
        }
      }
    }

    return opps
  }, [pipelines, organization])

  const OpportunityChip = ({
    opportunity,
    index,
  }: {
    opportunity: any
    index: number
  }) => {
    return (
      <Box className="opportunity-chip">
        <Tooltip
          title={`${opportunity.pipelineTitle} pipeline, ${opportunity.stageTitle} stage`}
          arrow={true}
        >
          <Chip
            clickable={true}
            onClick={() => {
              if (openSidebar) {
                setSidebarObject(opportunity.crmObject)
              } else {
                navigate(
                  routes.objectDetail({
                    objectId: opportunity.pipelineId,
                    objectTypeSlug: 'pipelines',
                    workspaceId: opportunity.workspaceId,
                  })
                )
              }
            }}
            size={size}
            variant="outlined"
            key={`opportunity_${index}`}
            label={`${opportunity.title}`}
            sx={{
              pl: '0px',
              justifyContent: 'flex-start',
              '& .MuiChip-icon': {
                color: darken(getStageColor(opportunity.stagePosition), 0.1),
                mr: '-2px',
                ml: '6px',
                mt: '-2px',
                flexShrink: 0,
                height: '10px',
                width: '10px',
              },
            }}
          />
        </Tooltip>
      </Box>
    )
  }

  return (
    <>
      {existingOpportunities?.length > 0 ? (
        <Row
          sx={{
            width: '100%',
            flexDirection: direction,
            justifyContent: 'flex-end',
            flexWrap: 'wrap',
            alignItems: 'flex-start',
          }}
          gap={'4px'}
        >
          {existingOpportunities?.map((opportunity, index) => (
            <OpportunityChip
              opportunity={opportunity}
              index={index}
              key={`opportunity_${index}`}
            />
          ))}
          <IconButton
            onClick={() => {
              setSidebarObject({
                mode: 'create',
                objectType: NativeObjectTypes.Opportunity,
                objectId: 'CREATE',
                workspaceId: selectedWorkspace,
                properties: { domain: organization.domain },
              })
            }}
            sx={{
              p: '3px',
              borderRadius: '3px',
            }}
          >
            <IconPlus size={16} />
          </IconButton>
        </Row>
      ) : (
        <>
          {organization && (
            <SidebarButton
              onClick={() => {
                setSidebarObject({
                  mode: 'create',
                  objectType: NativeObjectTypes.Opportunity,
                  objectId: 'CREATE',
                  workspaceId: selectedWorkspace,
                  properties: {
                    domain: organization.domain,
                  },
                })
              }}
              icon={<IconTrophy />}
              label="Opportunity"
            />
          )}
        </>
      )}
    </>
  )
}

export default OpportunityDomainChip
