import { useCallback, useEffect, useMemo, useState } from 'react'

import {
  Box,
  Chip,
  darken,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography,
} from '@mui/material'
import {
  IconCircleFilled,
  IconPlus,
  IconPaperclip,
  IconX,
} from '@tabler/icons-react'
import type { CRMObject } from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import ObjectChipRow from 'src/components/Chips/ObjectChipRow/ObjectChipRow'
import PageContextDebug from 'src/components/Pages/PageContextDebug/PageContextDebug'
import { GET_SUGGESTED_CONTEXT_OBJECTS } from 'src/components/Pages/queries'
import Row from 'src/components/Row/Row'
import SearchModal from 'src/components/Search/SearchModal/SearchModal'
import { actionButtonStyle } from 'src/components/Sidebar/styles'
import MeetingRecordingsProvider from 'src/hooks/useMeetingRecordings/MeetingRecordingsProvider'
import {
  generateReferenceKey,
  ObjectTypeMetadata,
  type NativeObjectType,
} from 'src/lib/objects'
import { useSuggestedContextReducer } from 'src/lib/pages'
import type { SearchableObject } from 'src/lib/searchService'

import ThreadContextDebug from '../ThreadContextDebug/ThreadContextDebug'
import { contextStatusMetadata } from '../threads'
import { ContextStatus } from '../V2/Provider/ThreadContext'

interface ObjectContextManagerProps {
  parentObject: Partial<CRMObject> | null
  prompt?: string
  contextObjects: Partial<CRMObject>[]
  handleSetContextObjects: ({
    contextObjects,
    dismissedObjects,
  }: {
    contextObjects: Partial<CRMObject>[]
    dismissedObjects: Partial<CRMObject>[]
  }) => void
  dismissedObjects: Partial<CRMObject>[]
  showStatus?: boolean
  contextStatus?: ContextStatus
  showDebug?: boolean
  workspaceId?: string
  contextForObjectType?: NativeObjectType
  debugComponent?: 'thread' | 'page'
  showSonnet?: boolean
  justifyExpandButton?: 'start' | 'end'
  closeOnSelect?: boolean
  contextUsedPercentage?: number
}

const ObjectContextManager = ({
  parentObject,
  prompt,
  contextObjects,
  handleSetContextObjects,
  dismissedObjects,
  showStatus = true,
  contextStatus,
  showDebug = false,
  workspaceId,
  contextForObjectType = null,
  debugComponent = 'thread',
  showSonnet = false,
  justifyExpandButton = 'start',
  closeOnSelect = true,
  contextUsedPercentage = null,
}: ObjectContextManagerProps) => {
  const [isSearchOpen, setIsSearchOpen] = useState(false)
  const [isDebugOpen, setIsDebugOpen] = useState(false)
  const [expanded, setExpanded] = useState(false)

  const { state, dispatch } = useSuggestedContextReducer({
    parentObject,
    initialContextObjects: contextObjects,
    initialDismissedObjects: dismissedObjects,
    workspaceId,
  })

  const { data } = useQuery(GET_SUGGESTED_CONTEXT_OBJECTS, {
    variables: {
      parentReferenceKey: generateReferenceKey(state.parentObject),
      workspaceId,
      referencedObjectsKeys: state.referencedObjects.map((o) =>
        generateReferenceKey(o)
      ),
      prompt,
      dismissedObjectKeys: state.dismissedObjects.map((o) =>
        generateReferenceKey(o)
      ),
    },
    skip: !state.parentObject?.objectId || !workspaceId,
    onCompleted: (data) => {
      console.log('data', data)
    },
  })

  useEffect(() => {
    if (data?.suggestedContextObjects) {
      dispatch({
        type: 'SUGGESTED_CONTEXT_RETURNED',
        payload: data.suggestedContextObjects,
      })
    }
  }, [data, dispatch])

  useEffect(() => {
    if (handleSetContextObjects) {
      handleSetContextObjects({
        contextObjects: state.referencedObjects,
        dismissedObjects: state.dismissedObjects,
      })
    }
  }, [state.referencedObjects, state.dismissedObjects, handleSetContextObjects])

  const handleAddContext = useCallback(
    (result: SearchableObject) => {
      dispatch({
        type: 'CONTEXT_OBJECTS_ADDED',
        payload: [result],
      })
    },
    [dispatch]
  )

  const handleRemoveContext = useCallback(
    (result: SearchableObject) => {
      dispatch({
        type: 'CONTEXT_OBJECT_DISMISSED',
        payload: result,
      })
    },
    [dispatch]
  )

  const status = contextStatus ? contextStatusMetadata[contextStatus] : null

  const buttonSx = { ...actionButtonStyle, height: '24px', py: 0 }

  const contextUsedPercentageStatusColor = useMemo(() => {
    if (typeof contextUsedPercentage === 'number') {
      if (contextUsedPercentage > 90 || contextUsedPercentage === 0) {
        return 'warning'
      } else if (contextUsedPercentage > 70) {
        return 'secondary'
      } else {
        return 'success'
      }
    }
    return 'secondary'
  }, [contextUsedPercentage])

  const containerSx = useMemo(() => {
    return {
      flexWrap: 'wrap',
      width: '100%',
      '& .object-context-manager-view-sources': {
        cursor: 'pointer',
        gap: 0.5,
        width: '100%',
        pr: '12px',
        '& .object-context-manager-view-sources-toggle': {
          fontSize: '11px',
          fontWeight: 500,
          color: (theme) => theme.palette.text.secondary,
        },
        '& .object-context-manager-view-sources-toggle-count': {
          fontSize: '11px',
          fontWeight: 600,
          color: (theme) => theme.palette.text.secondary,
          mr: '4px',
        },
      },
      '& .object-context-manager-container-expanded': {
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        width: '100%',
        flexShrink: 1,
        background: (theme) => darken(theme.palette.background.default, 0.05),
        py: '12px',
        px: '4px',
        border: (theme) => `1px solid ${theme.palette.divider}`,
        borderBottom: 'none',
      },
      '& .object-context-manager-view-sources-toggle-container': {
        '& .MuiTypography-root, .tabler-icon': {
          opacity: 0.8,
        },
        width: '100%',
        justifyContent: `flex-${justifyExpandButton}`,
        cursor: 'pointer',
        '& .object-context-manager-view-sources-toggle-container-content': {
          gap: '6px',
          '&.expanded': {
            px: '6px',
            pt: '2px',
            pb: '4px',
            mb: '-10px',
            borderRadius: '4px',
            position: 'relative',
            zIndex: 100,
            border: (theme) => `1px solid ${theme.palette.divider}`,
            borderBottom: (theme) =>
              `1px solid ${darken(theme.palette.background.default, 0.05)}`,
            borderBottomLeftRadius: '0px',
            borderBottomRightRadius: '0px',
            background: (theme) =>
              darken(theme.palette.background.default, 0.05),
          },
        },
      },
      '& .object-context-manager-status-percentage-container': {
        gap: 1,
        justifyContent: 'flex-end',
        pt: '4px',
        cursor: 'pointer',
        '& .MuiLinearProgress-root': {
          width: '80px',
          height: '6px',
          borderRadius: '4px',
        },
        '& .MuiTypography-root': {
          fontSize: '11px',
          fontWeight: 600,
          color: (theme) => theme.palette.text.secondary,
          opacity: 0.6,
        },
      },
    }
  }, [justifyExpandButton])

  return (
    <>
      <Row
        sx={containerSx}
        gap={1}
        className="object-context-manager-container"
      >
        <Row
          onClick={() => setExpanded((prev) => !prev)}
          className="object-context-manager-view-sources"
        >
          {showSonnet && (
            <Box
              component="img"
              src="/3-7-sonnet.png"
              sx={{
                height: '12px',
                ml: '10px',
                mt: '-2px',
                opacity: 0.8,
              }}
            />
          )}
          <Row
            gap={'6px'}
            className={`object-context-manager-view-sources-toggle-container`}
          >
            <Row
              className={`object-context-manager-view-sources-toggle-container-content ${expanded ? 'expanded' : ''}`}
            >
              <Typography className="object-context-manager-view-sources-toggle">
                {!expanded && (
                  <span className="object-context-manager-view-sources-toggle-count">
                    {state.referencedObjects.length}
                  </span>
                )}
                {expanded
                  ? 'Hide sources'
                  : `Source${state.referencedObjects.length > 1 ? 's' : ''}`}
              </Typography>
              {expanded ? <IconX size={16} /> : <IconPaperclip size={16} />}
            </Row>
          </Row>
        </Row>

        {expanded && (
          <Row className="object-context-manager-container-expanded">
            <ObjectChipRow
              workspaceId={workspaceId}
              onAdd={handleAddContext}
              onRemove={handleRemoveContext}
              objects={state.referencedObjects}
              suggestedObjects={state.suggestedObjects}
              maxToShow={100}
              fullWidth={true}
              showSidebar={true}
              action={
                <IconButton
                  onClick={() => setIsSearchOpen(true)}
                  sx={buttonSx}
                >
                  <IconPaperclip size={14} />
                </IconButton>
              }
            />

            {showStatus && status && (
              <Row
                sx={{ justifyContent: 'flex-end' }}
                gap={1}
              >
                {typeof contextUsedPercentage === 'number' &&
                contextStatus === ContextStatus.SUCCESS ? (
                  <Tooltip
                    title={`${contextUsedPercentage}% of your context limit used. Click to view the raw text context sent to the LLM.`}
                    arrow={true}
                    placement="top"
                  >
                    <div>
                      <Row
                        gap={1}
                        className="object-context-manager-status-percentage-container"
                        onClick={() => showDebug && setIsDebugOpen(true)}
                      >
                        <LinearProgress
                          variant="determinate"
                          value={contextUsedPercentage}
                          color={contextUsedPercentageStatusColor}
                        />
                        <Typography>{contextUsedPercentage}%</Typography>
                      </Row>
                    </div>
                  </Tooltip>
                ) : (
                  <Chip
                    label={status.label}
                    size="small"
                    variant="outlined"
                    icon={<IconCircleFilled size={8} />}
                    sx={{
                      border: 'none',
                      '& .MuiChip-icon': {
                        color: (theme) => theme.palette[status.color].main,
                        flexShrink: 0,
                        height: '8px',
                        width: '8px',
                        mr: '-2px',
                      },
                    }}
                    onClick={() => showDebug && setIsDebugOpen(true)}
                  />
                )}
              </Row>
            )}

            {showDebug && debugComponent === 'thread' && (
              <ThreadContextDebug
                open={isDebugOpen}
                setOpen={setIsDebugOpen}
              />
            )}

            {showDebug && debugComponent === 'page' && (
              <PageContextDebug
                open={isDebugOpen}
                setOpen={setIsDebugOpen}
              />
            )}
          </Row>
        )}
      </Row>
      <MeetingRecordingsProvider>
        <SearchModal
          open={isSearchOpen}
          onClose={() => setIsSearchOpen(false)}
          onSelect={handleAddContext}
          workspaceId={workspaceId}
          hiddenObjectIds={contextObjects.map((o) => o.objectId)}
          closeOnSelect={closeOnSelect}
          contextualActions={[
            {
              label: contextForObjectType
                ? `Add to ${ObjectTypeMetadata[contextForObjectType].label}`
                : 'Add to Context',
              icon: contextForObjectType ? (
                React.createElement(
                  ObjectTypeMetadata[contextForObjectType].icon,
                  {
                    size: 16,
                  }
                )
              ) : (
                <IconPlus size={16} />
              ),
              onClick: (result) => handleAddContext(result),
            },
          ]}
        />
      </MeetingRecordingsProvider>
    </>
  )
}

export default ObjectContextManager
