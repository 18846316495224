export type SidebarMode = 'view' | 'create' | 'edit'

export interface SidebarObject {
  mode?: SidebarMode
  objectType: string // Using string for now to maintain compatibility
  objectId: string
  workspaceId?: string
  properties?: any // Keep any for now to maintain compatibility
  context?: {
    pipelineId?: string
    stageId?: string
    [key: string]: any // Allow other context properties
  }
}

// Type guard to check if an object is a SidebarObject
export function isSidebarObject(obj: any): obj is SidebarObject {
  return (
    obj &&
    typeof obj.objectType === 'string' &&
    typeof obj.objectId === 'string'
  )
}
