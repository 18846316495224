export const buildReasoningKey = ({
  workspaceId,
  objectId,
  objectType,
  path,
}: {
  workspaceId: string
  objectId: string
  objectType: string
  path: string
}) => [workspaceId, objectId, objectType, path].join(' : ')

export const parseReasoningKey = (key: string) => {
  const [workspaceId, objectId, objectType, path] = key.split(' : ')
  return { workspaceId, objectId, objectType, path }
}
