export const GET_ORGANIZATION_FOR_CONTEXT_SIDEBAR = gql`
  query GetOrganizationForContextSidebar(
    $workspaceId: String!
    $orgId: String!
  ) {
    workspaceOrganization(workspaceId: $workspaceId, domain: $orgId) {
      id
      name
      domain
      employeeCount
      about {
        description
        aiDescription
        phoneNumbers
        isHiring
        industryType
        founded
        employeesFrom
        employeesTo
        selling
      }
      headquarters {
        address
        city
        state
        country
        postalCode
        latitude
        longitude
      }
      purpose {
        differentiators
        marketingPromises
        missionAndVision
      }
      people {
        fullName
        email
        currentJobTitle
        photoUrl
      }
      roles {
        name
        email
        role
      }
      opportunities {
        id
        workspaceId
        title
        pipelineId
        pipelineTitle
        stage
        updatedAt
      }
      pages {
        id
        title
      }
      photos {
        square
      }
      relationship {
        upcomingEvents
        quotes {
          personEmail
          text
          meetingId
        }
        oneSentenceSummary
        proofOfPayment
        sensitiveToWhom
        sensitiveReasoning
        warmth
        origin
      }
      links {
        facebook
        x
        instagram
        linkedIn
        website
        websiteResolvedUrl
      }
      lifecycle {
        pipelineType
        stageType
      }
    }
  }
`

export const GET_CONTEXT_FOR_WORKSPACE = gql`
  query GetContextForWorkspace($workspaceId: String!) {
    getContextForWorkspace(workspaceId: $workspaceId) {
      id
      workspaceId
      plainTextValue
      createdAt
      updatedAt
      parentReferenceKey
      userId
    }
  }
`
