import type { Theme } from '@mui/material'

export const contextStatusMetadata = {
  loading: {
    label: 'Loading context...',
    color: 'secondary',
  },
  loaded: {
    label: 'Context loaded',
    color: 'success',
  },
  error_capacity: {
    label: 'Context capacity limit reached',
    color: 'warning',
  },
  error_unknown: {
    label: 'Error loading context',
    color: 'error',
  },
}

export const htmlStyles = (theme: Theme) => ({
  '& a': {
    color: theme.palette.secondary.main,
  },
  '& strong': {
    fontWeight: 600,
    letterSpacing: '-0.3px',
  },
  '& p': {
    mb: 2,
    py: 0,
    fontWeight: 400,
    letterSpacing: '-0.22px',
    '&:last-child': {
      mb: 0,
    },
  },
  '& ul': {
    py: 2,
    listStyleType: 'disc',
    pl: 4,
    '& li': {
      pb: 1,
    },
  },
  '& h1': {
    mt: 2,
    fontSize: '18px',
    fontWeight: 600,
    letterSpacing: '-0.3px',
  },
  '& h2': {
    mt: 2,
    mb: 1,
    fontSize: '16px',
    fontWeight: 600,
    letterSpacing: '-0.3px',
  },
  '& h3': {
    mt: 2,
    mb: 1,
    fontSize: '14px',
    fontWeight: 600,
    letterSpacing: '-0.3px',
  },
  '& h4': {
    mt: 2,
    mb: 1,
    fontSize: '12px',
    fontWeight: 600,
    letterSpacing: '-0.3px',
  },
})

export const aiMessageStyle = () => ({
  height: 'auto',
  minHeight: '12px',
  background: (theme) => theme.palette.background.paper,
  borderRadius: '6px',
  border: 'none',
  overflow: 'hidden',
  py: 1,
  boxShadow: (theme) => theme.shadows[3],

  '& .thread-message-text': {
    lineHeight: '1.5',
    letterSpacing: '-0.3px',
  },
})
