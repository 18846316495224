import React, { useState, useEffect } from 'react'

import { BackToTopArrow } from './Icons'
const BackToTop = () => {
  const [isVisible, setIsVisible] = useState(false)
  const handleScroll = () => {
    if (window.scrollY > 200) {
      setIsVisible(true)
    } else {
      setIsVisible(false)
    }
  }
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }
  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])
  return (
    <button
      aria-label="back to top"
      className={`back_to_top bg-matt_purple fixed right-6  z-50 flex h-9 w-9 items-center justify-center rounded-full  border-2 border-blue bg-white p-3 text-blue transition-all duration-200  hover:scale-110 hover:bg-blue hover:text-white sm:h-10 sm:w-10  lg:h-12 lg:w-12 ${
        isVisible
          ? ' bottom-6 scale-100 opacity-100'
          : '-bottom-20 scale-0 opacity-0'
      }`}
      onClick={scrollToTop}
    >
      <span>
        <BackToTopArrow />
      </span>
    </button>
  )
}
export default BackToTop
