import { useContext } from 'react'

import ThreadContext from './ThreadContext'

const useThread = () => {
  const context = useContext(ThreadContext)
  if (!context) {
    throw new Error('useThread must be used within a ThreadProvider')
  }
  return context
}

export default useThread
