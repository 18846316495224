import { useContext } from 'react'

import { DayContext } from 'src/lib/dayContext'

import ObjectsContext from './ObjectsContext'

const useObjects = () => {
  const { selectedWorkspace: workspaceId } = useContext(DayContext)
  const context = useContext(ObjectsContext)
  return {
    ...context,
    workspaceId,
  }
}

export default useObjects
