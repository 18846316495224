import { PAGE_FIELDS_FRAGMENT } from '../Pages/fragments'

export const GET_WORKSPACE_TEMPLATES = gql`
  query GetWorkspaceTemplates($workspaceId: String!) {
    workspaceTemplates(workspaceId: $workspaceId) {
      ...PageFields
    }
  }
  ${PAGE_FIELDS_FRAGMENT}
`
