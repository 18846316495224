import {
  useCallback,
  useContext,
  useMemo,
  useReducer,
  memo,
  useState,
} from 'react'

import {
  Box,
  Chip,
  IconButton,
  InputAdornment,
  List,
  ListItemButton,
  TextField,
  Tooltip,
} from '@mui/material'
import {
  IconArrowBackUp,
  IconChevronDown,
  IconChevronUp,
  IconCircleArrowUpFilled,
  IconPlus,
} from '@tabler/icons-react'
import { toast } from 'react-hot-toast'
import type {
  ActionChannelType,
  ActionPromptOutput,
  CRMObject,
  Page,
  TemplateOutputType,
} from 'types/graphql'

import { useMutation, useQuery } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import Row from 'src/components/Row/Row'
import ObjectContextManager from 'src/components/Threads/ObjectContextManager/ObjectContextManager'
import { DayContext } from 'src/lib/dayContext'
import { ungatedForTemplates } from 'src/lib/gates'
import { logger } from 'src/lib/logger'
import {
  NativeObjectTypes,
  TemplateTypeMetadata,
  TemplateTypes,
} from 'src/lib/objects'
import type { NativeObjectType } from 'src/lib/objects'

import ObjectChip from '../Chips/ObjectChip/ObjectChip'
import { CREATE_PAGE } from '../Pages/mutations'
import { GET_WORKSPACE_TEMPLATES } from '../Pages/queries'
import TemplateTypeChooserMenu from '../Pages/TemplateTypeChooserMenu'
import type { ContextStatus } from '../Threads/V2/Provider/ThreadContext'

const containerWrapperSx = {
  mb: 2,
  mx: 1,
  mt: 1,
  borderRadius: '12px',
  boxShadow: `
  0 1px 3px rgba(0, 0, 0, 0.12),
  0 2px 6px rgba(0, 0, 0, 0.08),
  0 4px 12px rgba(0, 0, 0, 0.05)
`,
}

const templateTypeForChannelType = (channelType: string) => {
  switch (channelType) {
    case 'GMAIL':
      return 'EMAIL'
    case 'EMAIL':
      return 'EMAIL'
    case 'SLACK':
      return 'SLACK'
    case 'PAGE':
      return 'INTERNAL_PAGE'
    default:
      return 'INTERNAL_PAGE'
  }
}

const upperContainerSx = {
  background: (theme) => theme.palette.background.paper,
  borderTopLeftRadius: '12px',
  borderTopRightRadius: '12px',
  border: (theme) => `1px solid ${theme.palette.divider}`,
  borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
  p: 1,
  position: 'relative',
}

const lowerContainerSx = {
  background: (theme) => theme.palette.background.paper,
  borderBottomLeftRadius: '12px',
  borderBottomRightRadius: '12px',
  border: (theme) => `1px solid ${theme.palette.divider}`,
  borderTop: 'none',
  p: 1,
  position: 'relative',
  justifyContent: 'space-between',
  alignItems: 'top',
}

const navigationButtonsSx = {
  position: 'absolute',
  top: 8,
  right: 8,
  display: 'flex',
  gap: 0.5,
  zIndex: 10,
}

const promptContainerSx = {
  position: 'relative',
  width: '100%',
  height: 0,
  zIndex: 20,
}

const actionButtonContainerSx = {
  position: 'absolute',
  right: 8,
  bottom: 8,
}

const rootBoxSx = {
  position: 'relative',
}

const getTextFieldInputSx = () => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'end',
  p: '4px',
  pt: '12px',
  pl: '12px',
  letterSpacing: '-0.55px',
  fontWeight: 400,
  color: 'text.primary',
})

interface PromptState {
  template: Page | null
  isEdited: boolean
  prompt: string
  originalTemplate: Page | null
  originalPrompt: string
}

type PromptAction =
  | { type: 'SET_TEMPLATE'; payload: Page | null }
  | { type: 'UPDATE_TEMPLATE'; payload: Page | null }
  | { type: 'EDIT_PROMPT'; payload: string }
  | { type: 'UNDO' }

const promptReducer = (
  state: PromptState,
  action: PromptAction
): PromptState => {
  switch (action.type) {
    case 'SET_TEMPLATE':
      return {
        ...state,
        template:
          state.template?.id === action.payload?.id ? null : action.payload,
      }
    case 'UPDATE_TEMPLATE':
      return {
        ...state,
        template: action.payload,
      }
    case 'EDIT_PROMPT':
      return {
        ...state,
        isEdited: true,
        prompt: action.payload,
      }
    case 'UNDO': {
      return {
        ...state,
        template: state.originalTemplate ? { ...state.originalTemplate } : null,
        isEdited: false,
        prompt: state.originalPrompt,
      }
    }
    default:
      return state
  }
}

interface UndoButtonProps {
  isVisible: boolean
  onUndoClick: () => void
}

interface ActionButtonProps {
  actionButton: React.ReactNode
}

const MemoizedObjectContextManager = memo(ObjectContextManager)

const UndoButton = memo(({ isVisible, onUndoClick }: UndoButtonProps) => (
  <Box sx={navigationButtonsSx}>
    {isVisible && (
      <IconButton
        size="small"
        onClick={onUndoClick}
      >
        <IconArrowBackUp size={16} />
      </IconButton>
    )}
  </Box>
))

const ActionButtonContainer = memo(({ actionButton }: ActionButtonProps) => (
  <Row sx={actionButtonContainerSx}>{actionButton}</Row>
))

interface PromptInputProps {
  suggestedPrompts?: ActionPromptOutput[]
  onSubmit?: (state: PromptState, dispatch: any) => void
  placeholder?: string
  // Context manager props
  parentObject?: { objectType: NativeObjectType; objectId: string }
  workspaceId?: string
  contextStatus?: 'loading' | 'loaded' | 'error_capacity' | 'error_unknown'
  showStatus?: boolean
  showDebug?: boolean
  debugComponent?: 'page' | 'thread'
  contextObjects?: Partial<CRMObject>[]
  dismissedObjects?: Partial<CRMObject>[]
  handleSetContextObjects?: ({
    contextObjects,
    dismissedObjects,
  }: {
    contextObjects: Partial<CRMObject>[]
    dismissedObjects: Partial<CRMObject>[]
  }) => void
  // Optional action button to render in top right
  actionButton?: React.ReactNode
  selectedChannelType?: ActionChannelType | null
  setSelectedChannelType?: React.Dispatch<
    React.SetStateAction<ActionChannelType | null>
  >
}

const PromptInput = ({
  suggestedPrompts = [],
  parentObject,
  onSubmit,
  placeholder = "Let's take action. What should we make?",
  workspaceId,
  contextStatus,
  showStatus,
  showDebug,
  debugComponent,
  actionButton,
  selectedChannelType,
  setSelectedChannelType,
  contextObjects,
  dismissedObjects,
  handleSetContextObjects,
}: PromptInputProps) => {
  const { currentUser } = useAuth()

  const initialState: PromptState = {
    template: (suggestedPrompts?.[0]?.template as Page) || null,
    isEdited: false,
    prompt: suggestedPrompts?.[0]?.shortPrompt || '',
    originalTemplate: (suggestedPrompts?.[0]?.template as Page) || null,
    originalPrompt: suggestedPrompts?.[0]?.shortPrompt || '',
  }

  const [state, dispatch] = useReducer(promptReducer, initialState)
  const [showTemplateTooltip, setShowTemplateTooltip] = useState(false)

  const [showTemplates, setShowTemplates] = useState(false)

  const { setSidebarObject } = useContext(DayContext)
  const [templateTypeAnchorEl, setTemplateTypeAnchorEl] =
    useState<HTMLElement | null>(null)

  const { data: templates } = useQuery(GET_WORKSPACE_TEMPLATES, {
    variables: { workspaceId },
    skip: !ungatedForTemplates(currentUser),
  })

  const textFieldInputSx = useMemo(() => getTextFieldInputSx(), [])
  const [createPage] = useMutation(CREATE_PAGE)

  const handleAddTemplate = useCallback(
    async (templateType: TemplateOutputType) => {
      if (!workspaceId || !templateType) {
        return
      }

      try {
        const result = await toast.promise(
          createPage({
            variables: {
              input: {
                workspaceId,
                title: `Untitled ${TemplateTypeMetadata[templateType].label} template`,
                contentJson: {
                  type: 'doc',
                  content: [
                    {
                      type: 'title',
                      content: [
                        {
                          type: 'text',
                        },
                      ],
                    },
                    {
                      type: 'paragraph',
                      content: [
                        {
                          text: 'Add your template here...',
                          type: 'text',
                        },
                      ],
                    },
                  ],
                },
                objectType: parentObject.objectType,
                objectId: parentObject.objectId,
                contentHtml: '<p>Add your template here...</p>',
                templateType,
              },
            },
          }),
          {
            loading: 'Creating template...',
            success: 'Template created!',
            error: 'Failed to create template',
          }
        )

        if (result.data?.createPage) {
          const newSidebarPageObject = {
            objectId: result.data.createPage.id,
            objectType: NativeObjectTypes.Page,
            workspaceId,
          }

          setSidebarObject(newSidebarPageObject)
        }
      } catch (error) {
        logger.error('Failed to create template:', error)
      }
    },
    [createPage, setSidebarObject, workspaceId, parentObject]
  )

  const handleTemplateSelect = useCallback(
    (selectedTemplate: Partial<Page> | null) => {
      dispatch({
        type: 'SET_TEMPLATE',
        payload: selectedTemplate as Page,
      })
    },
    []
  )

  const handleUndoClick = () => {
    dispatch({
      type: 'UNDO',
    })
  }

  const handleTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value !== state.prompt) {
      dispatch({
        type: 'EDIT_PROMPT',
        payload: e.target.value,
      })
    }
  }

  const handleSubmit = () => {
    if (onSubmit) {
      if (state.prompt && (state.template?.id || selectedChannelType)) {
        if (onSubmit) {
          onSubmit(state, dispatch)
        }
      }
      if (
        state.prompt &&
        (ungatedForTemplates(currentUser)
          ? !state.template?.id
          : !selectedChannelType)
      ) {
        setShowTemplateTooltip(true)
        setTimeout(() => setShowTemplateTooltip(false), 3000)
      }
    }
  }

  return (
    <Box sx={rootBoxSx}>
      <Box sx={containerWrapperSx}>
        <Box sx={upperContainerSx}>
          {suggestedPrompts?.length > 0 && (
            <Box sx={promptContainerSx}>
              <UndoButton
                isVisible={state.isEdited}
                onUndoClick={handleUndoClick}
              />
            </Box>
          )}

          <TextField
            multiline={true}
            minRows={2}
            maxRows={5}
            fullWidth={true}
            value={state.prompt}
            variant="standard"
            placeholder={suggestedPrompts?.length > 0 ? '' : placeholder}
            onChange={handleTextFieldChange}
            onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault()
                handleSubmit()
              }
            }}
            InputProps={{
              disableUnderline: true,
              sx: textFieldInputSx,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    onClick={handleSubmit}
                  >
                    <IconCircleArrowUpFilled size={20} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          {actionButton && (
            <ActionButtonContainer actionButton={actionButton} />
          )}
        </Box>
        <Row sx={lowerContainerSx}>
          <Box sx={{ width: '100%' }}>
            {!showTemplates ? (
              <MemoizedObjectContextManager
                parentObject={parentObject}
                contextObjects={contextObjects}
                dismissedObjects={dismissedObjects}
                handleSetContextObjects={handleSetContextObjects}
                workspaceId={workspaceId}
                contextStatus={contextStatus as ContextStatus}
                showStatus={showStatus}
                showDebug={showDebug}
                debugComponent={debugComponent}
              />
            ) : (
              <List sx={{ px: 1, py: 0, width: '100%' }}>
                {ungatedForTemplates(currentUser)
                  ? templates?.workspaceTemplates?.map((template) => (
                      <ListItemButton
                        key={template.id}
                        sx={{
                          p: 0,
                          borderRadius: '12px',
                          pr: 2,
                          width: '100%',
                          height: '30px',
                        }}
                        selected={state.template?.id === template.id}
                        onClick={() => {
                          handleTemplateSelect(template)
                          setShowTemplates(false)
                        }}
                      >
                        <ObjectChip
                          workspaceId={workspaceId}
                          crmObject={{
                            objectId: template.id,
                            objectType: NativeObjectTypes.Page,
                            properties: template,
                          }}
                        />
                      </ListItemButton>
                    ))
                  : ['EMAIL', 'SLACK', 'INTERNAL_PAGE'].map((c) => (
                      <ListItemButton
                        key={c}
                        selected={selectedChannelType === c}
                        sx={{
                          p: 0,
                          borderRadius: '12px',
                          pr: 2,
                          width: '100%',
                          height: '30px',
                        }}
                        onClick={() => {
                          setSelectedChannelType((prev) =>
                            prev === c ? null : (c as ActionChannelType)
                          )
                          setShowTemplates(false)
                        }}
                      >
                        <ObjectChip
                          workspaceId={workspaceId}
                          crmObject={{
                            objectId: null,
                            objectType: NativeObjectTypes.Page,
                            properties: {
                              label: TemplateTypeMetadata[c]?.label,
                              templateType: templateTypeForChannelType(
                                c as ActionChannelType
                              ),
                            },
                          }}
                        />
                      </ListItemButton>
                    ))}
              </List>
            )}
          </Box>

          <Row sx={{ alignItems: 'top' }}>
            {ungatedForTemplates(currentUser) && showTemplates && (
              <IconButton
                size="small"
                onClick={(e) => setTemplateTypeAnchorEl(e.currentTarget)}
                sx={{
                  height: 'fit-content',
                  border: (theme) => `1px solid ${theme.palette.divider}`,
                  borderRadius: '8px',
                  mr: 1,
                }}
              >
                <IconPlus size={16} />
                <TemplateTypeChooserMenu
                  key="template-type-chooser"
                  anchorEl={templateTypeAnchorEl}
                  onSelect={(templateType) => {
                    handleAddTemplate(templateType)
                    setTemplateTypeAnchorEl(null)
                  }}
                  onClose={() => setTemplateTypeAnchorEl(null)}
                />
              </IconButton>
            )}
            <Box onClick={(_e) => setShowTemplates((prev) => !prev)}>
              <Tooltip
                arrow
                placement="top"
                title="Choose output format"
                open={showTemplateTooltip}
                disableHoverListener
                disableTouchListener
              >
                <Chip
                  clickable={true}
                  variant="outlined"
                  size="small"
                  sx={{
                    border: (theme) => `1px solid ${theme.palette.divider}`,
                    p: 0,
                  }}
                  label={
                    <Row>
                      {ungatedForTemplates(currentUser) ? (
                        <ObjectChip
                          workspaceId={workspaceId}
                          key={`${state.template?.id}-${showTemplates}`}
                          crmObject={{
                            objectId: state.template?.id,
                            objectType: NativeObjectTypes.Page,
                            properties: {
                              label: state.template?.id
                                ? state.template.title
                                : showTemplates
                                  ? 'Close'
                                  : 'Select output format',
                              templateType: TemplateTypes.BASE,
                              ...state.template,
                            },
                          }}
                        />
                      ) : (
                        <ObjectChip
                          workspaceId={workspaceId}
                          key={selectedChannelType}
                          crmObject={{
                            objectId: null,
                            objectType: NativeObjectTypes.Page,
                            properties: {
                              label: selectedChannelType
                                ? TemplateTypeMetadata[
                                    templateTypeForChannelType(
                                      selectedChannelType as ActionChannelType
                                    )
                                  ]?.label
                                : showTemplates
                                  ? 'Close'
                                  : 'Select output format',
                              templateType: templateTypeForChannelType(
                                selectedChannelType as ActionChannelType
                              ),
                            },
                          }}
                        />
                      )}
                      {showTemplates ? (
                        <IconChevronUp size={16} />
                      ) : (
                        <IconChevronDown size={16} />
                      )}
                    </Row>
                  }
                />
              </Tooltip>
            </Box>
          </Row>
        </Row>
      </Box>
    </Box>
  )
}

export default memo(PromptInput)
