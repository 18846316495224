import {
  NativeObjectTypes,
  ObjectTypeMetadata,
  type NativeObjectType,
} from 'src/lib/objects'
import type { SearchableObject } from 'src/lib/searchService'

interface WorkspaceUserContext {
  id?: string | null
  createdAt?: Date
  updatedAt?: Date
  workspaceId?: string
  userId?: string
  plainTextValue?: string
  parentReferenceKey?: string
  timeframeStart?: Date
  timeframeEnd?: Date
  referencedObjectIds?: string[]
  summary?: string
  meta_sourceUserId?: string
  meta_sourceDescription?: string

  // Client-side only
  new?: boolean
}

interface ContextState {
  hideSearch: boolean
  objectTitle: string | null
  contextEdit: WorkspaceUserContext | null
  hasEdits: boolean
  objectType: NativeObjectType | null
  objectId: string | null
  onUpdate?: () => void
}

type ContextAction =
  | {
      type: 'UPDATE_CONTEXT_TEXT'
      payload: string
    }
  | {
      type: 'SEARCH_ITEM_SELECTED'
      parentReferenceKey: string
      result: SearchableObject
    }
  | {
      type: 'WORKSPACE_OBJECT_SELECTED'
      objectType: NativeObjectType
      pathKey: string
    }
  | {
      type: 'UPDATE_REFERENCED_OBJECTS'
      payload: {
        objectReferences: Array<{
          objectType: NativeObjectType
          objectId: string
          path: string
        }>
      }
    }
  | {
      type: 'ORGANIZATION_FETCHED'
      name: string
    }
  | {
      type: 'CREATE_CONTEXT'
      payload: WorkspaceUserContext
    }
  | {
      type: 'UPDATE_SUCCEEDED'
    }

export const parseUserContextReferenceKey = (key: string) => {
  const [type, id, path] = key.split(' : ')
  return { type, id, path }
}

export const contextReducer = (state: ContextState, action: ContextAction) => {
  switch (action.type) {
    case 'UPDATE_CONTEXT_TEXT':
      return {
        ...state,
        contextEdit: {
          ...state.contextEdit,
          plainTextValue: action.payload,
        },
        hasEdits: true,
      } as ContextState
    case 'SEARCH_ITEM_SELECTED':
      return {
        ...state,
        hasEdits: false,
        showSearch: false,
        objectType: action.result.objectType as NativeObjectType,
        objectId: action.result.objectId,
        objectTitle:
          action.result.properties?.name ||
          action.result.properties?.fullName ||
          action.result.objectId,
        contextEdit: {
          ...state.contextEdit,
          parentReferenceKey: action.parentReferenceKey,
        },
      } as ContextState
    case 'WORKSPACE_OBJECT_SELECTED': {
      const metadata = ObjectTypeMetadata[action.objectType]
      const pathMetadata = metadata.paths?.[action.pathKey]
      const label = pathMetadata?.label || `${metadata.label} Instructions`

      const parentReferenceKey = `${NativeObjectTypes.Workspace} : ${action.objectType} : ${pathMetadata.key || 'root'}`

      return {
        ...state,
        contextEdit: {
          ...state.contextEdit,
          parentReferenceKey: parentReferenceKey,
        },
        objectType: NativeObjectTypes.Workspace,
        objectId: action.objectType,
        objectTitle: `${ObjectTypeMetadata[action.objectType].label} - ${label}`,
        showSearch: false,
        hasEdits: false,
      } as ContextState
    }
    case 'UPDATE_REFERENCED_OBJECTS':
      return {
        ...state,
        hasEdits: true,
        contextEdit: {
          ...state.contextEdit,
          referencedObjectIds: action.payload.objectReferences.map(
            (ref) => `${ref.objectType} : ${ref.objectId} : ${ref.path}`
          ),
        },
      } as ContextState
    case 'ORGANIZATION_FETCHED':
      return {
        ...state,
        objectTitle: action.name,
      }
    case 'CREATE_CONTEXT': {
      state.onUpdate?.()

      return {
        ...state,
        objectId: action.payload.id,
        contextEdit: action.payload,
        objectType: NativeObjectTypes.UserContext,
        objectTitle: action.payload.plainTextValue,
        hasEdits: false,
      } as ContextState
    }
    case 'UPDATE_SUCCEEDED':
      return {
        ...state,
        hasEdits: false,
      }
  }
}

const getContextSidebarTitle = ({ id, type, path, workspacePeople }) => {
  if (!id || !type || !path) {
    return null
  }

  const metadata = ObjectTypeMetadata[id]

  if (path && type === NativeObjectTypes.Workspace) {
    const pathKey = Object.keys(metadata.paths || {}).find(
      (key) => metadata.paths[key].key === path
    )
    if (pathKey && metadata.paths) {
      return `${metadata.pluralLabel} - ${metadata.paths[pathKey].label}`
    }
  } else {
    if (type === NativeObjectTypes.Person) {
      const person = workspacePeople.find((p) => p.email === id)
      return person?.fullName || person?.email
    } else if (type === NativeObjectTypes.Organization) {
      return id
    } else if (type === NativeObjectTypes.Workspace) {
      return metadata.paths?.ROOT?.label || `${metadata.label} Instructions`
    } else {
      return ObjectTypeMetadata[type].label
    }
  }
}

export const initializeState = ({
  title,
  hideSearch,
  context,
  workspacePeople,
}) => {
  const { type, id, path } = context?.parentReferenceKey
    ? parseUserContextReferenceKey(context?.parentReferenceKey)
    : { type: null, id: null, path: null }

  return {
    contextEdit: {
      ...context,
      plainTextValue: context?.new ? '' : context?.plainTextValue,
    },
    hasEdits: false,
    hideSearch,
    onUpdate: context?.onUpdate,
    objectId: id,
    objectType: type,
    objectTitle:
      title ||
      context?.title ||
      getContextSidebarTitle({ id, type, path, workspacePeople }),
  } as ContextState
}
