import { useMemo } from 'react'

import { Box } from '@mui/material'

const defaults = {
  sx: {},
  gap: null,
  children: null,
  otherProps: {},
}
const Row = ({
  sx = defaults.sx,
  gap = defaults.gap,
  children = defaults.children,
  ...otherProps
}) => {
  const finalSx = useMemo(() => {
    return {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      ...sx,
    }
  }, [sx])

  const memoizedOtherProps = useMemo(() => {
    return {
      gap,
      ...otherProps,
    }
  }, [gap, otherProps])

  return (
    <Box
      sx={finalSx}
      {...memoizedOtherProps}
    >
      {children}
    </Box>
  )
}

export default Row
