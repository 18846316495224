import type { TemplateOutputType } from 'types/graphql'

import { getTemplateIcon } from './utils'

const TemplateIcon = ({
  templateType,
  variant = null,
  size = 16,
}: {
  templateType: TemplateOutputType
  variant?: 'chip' | 'tile' | null
  size?: number
}) => {
  return getTemplateIcon({ templateType, variant, size })
}

export default TemplateIcon
