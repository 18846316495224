import { useContext, useMemo } from 'react'

import { Chip } from '@mui/material'
import { IconX } from '@tabler/icons-react'

import { useQuery } from '@redwoodjs/web'

import { isFreemailDomain } from 'src/lib/contactFormatting'
import { DayContext } from 'src/lib/dayContext'
import { deepMerge, NativeObjectTypes } from 'src/lib/objects'

import DomainAvatar from '../../DomainAvatar/DomainAvatar'

// get coreCompany from domain
const GET_ORGANIZATION_FOR_CHIP = gql`
  query GetOrganizationForChip($domain: String!, $workspaceId: String!) {
    workspaceOrganization(domain: $domain, workspaceId: $workspaceId) {
      id
      name
      domain
      colors {
        id
        colorVibrant
        colorDarkVibrant
        colorLightVibrant
        colorMuted
        colorDarkMuted
        colorLightMuted
      }
      photos {
        id
        square
      }
    }
  }
`

const OrganizationChip = ({
  domain,
  name = null,
  variant = 'outlined',
  size = 'medium',
  sx = {},
  showSidebar = true,
  onRemove = null,
}: {
  domain: string
  name?: string
  variant?: 'filled' | 'outlined'
  size?: 'medium' | 'small'
  sx?: any
  showSidebar?: boolean
  onRemove?: (domain: string) => void
}) => {
  const isFreemail = isFreemailDomain(domain)

  const { setSidebarObject, selectedWorkspace, orgsByDomain } =
    useContext(DayContext)
  const { data } = useQuery(GET_ORGANIZATION_FOR_CHIP, {
    variables: { domain, workspaceId: selectedWorkspace },
    skip:
      isFreemail ||
      !domain ||
      !selectedWorkspace ||
      (!!orgsByDomain?.[domain]?.photos?.square &&
        !!orgsByDomain?.[domain]?.name),
  })
  const organization = useMemo(
    () =>
      deepMerge(
        orgsByDomain?.[domain] || {},
        data?.workspaceOrganization || {}
      ),
    [domain, orgsByDomain, data]
  )

  const iconSize = size === 'small' ? 16 : 20

  return (
    !isFreemail &&
    domain && (
      <Chip
        variant={variant}
        onDelete={onRemove ? () => onRemove(domain) : undefined}
        deleteIcon={onRemove ? <IconX size={16} /> : null}
        icon={
          <DomainAvatar
            domain={domain}
            photoUrl={organization ? organization?.photos?.square : null}
            size={iconSize}
            companyColor={
              organization ? organization?.colors?.colorVibrant : null
            }
            borderRadius={50}
            sx={{
              mr: '-2px',
            }}
          />
        }
        label={organization?.name || name || domain}
        clickable={showSidebar}
        size={size}
        onClick={(e) => {
          if (!showSidebar) {
            return
          }

          e.stopPropagation()
          setSidebarObject({
            objectType: NativeObjectTypes.Organization,
            objectId: domain,
            properties: {
              ...organization,
              domain,
            },
          })
        }}
        sx={{
          px: '4px',
          justifyContent: 'start',
          borderRadius: '50px',
          background: 'transparent',
          fontSize: size === 'small' ? '0.75rem' : '0.8rem',
          ...(!showSidebar ? { border: 'none' } : {}),
          ...sx,
        }}
      />
    )
  )
}

export default OrganizationChip
