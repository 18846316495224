import { useMemo, useState } from 'react'

import { IconButton, ListItemText, Menu, MenuItem } from '@mui/material'
import { IconPlus, IconX } from '@tabler/icons-react'

import type { RelationshipDirection, RelationshipType } from 'src/lib/objects'
import {
  buildRelationshipReference,
  NativeRelationshipTypes,
  RelationshipStageDisplay,
} from 'src/lib/objects'

const excludedTypes = ['INTERNAL', 'PERSONAL', 'OTHER', 'MENTOR']

const RelationshipChooser = ({
  workspaceId,
  objectType,
  objectId,
  onCreate,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [selectedStage, setSelectedStage] = useState<string | null>(null)

  const options = useMemo(() => {
    if (!selectedStage) return []

    const allOptions = Object.entries(NativeRelationshipTypes).flatMap(
      ([type, directionSet]) =>
        Object.entries(directionSet).flatMap(([direction, stageSet]) => {
          const stageData = stageSet[selectedStage]
          if (
            !stageData ||
            excludedTypes.includes(type) ||
            excludedTypes.includes(direction)
          ) {
            return []
          }
          return [
            {
              key: buildRelationshipReference({
                type: type as RelationshipType,
                direction: direction as RelationshipDirection,
                stage: stageData.key,
              }),
              label: stageData.label,
              type,
              direction,
              stageKey: selectedStage,
            },
          ]
        })
    )

    // Deduplicate by label, keeping the first occurrence
    const uniqueByLabel = Object.values(
      allOptions.reduce(
        (acc, curr) => {
          if (!acc[curr.label]) {
            acc[curr.label] = curr
          }
          return acc
        },
        {} as Record<string, (typeof allOptions)[number]>
      )
    )

    return uniqueByLabel
  }, [selectedStage])

  return (
    <>
      <IconButton
        onClick={(e) => {
          e.stopPropagation()
          setAnchorEl(e.currentTarget)
        }}
        sx={{ flexShrink: 0, p: '4px', borderRadius: '3px' }}
      >
        <IconPlus size={16} />
      </IconButton>
      <Menu
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null)
        }}
        anchorEl={anchorEl}
      >
        {selectedStage ? (
          <MenuItem
            sx={{
              borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
            }}
          >
            <ListItemText
              primaryTypographyProps={{
                fontWeight: 600,
                letterSpacing: '-0.3px',
              }}
              secondaryTypographyProps={{
                sx: {
                  opacity: 0.8,
                  fontSize: '11px',
                  letterSpacing: '-0.3px',
                },
              }}
              primary={RelationshipStageDisplay[selectedStage].primary}
              //secondary={RelationshipStageDisplay[selectedStage].secondary}
            />
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation()
                setSelectedStage(null)
              }}
            >
              <IconX size={16} />
            </IconButton>
          </MenuItem>
        ) : (
          Object.entries(RelationshipStageDisplay).map(([key, value]) => (
            <MenuItem
              key={key}
              onClick={() => setSelectedStage(key)}
            >
              <ListItemText
                primaryTypographyProps={{
                  sx: {
                    fontWeight: 600,
                    letterSpacing: '-0.4px',
                  },
                }}
                secondaryTypographyProps={{
                  sx: {
                    opacity: 0.8,
                    fontSize: '11px',
                    letterSpacing: '-0.3px',
                  },
                }}
                primary={value.primary}
                secondary={value.secondary}
              />
            </MenuItem>
          ))
        )}
        {selectedStage &&
          options.map((option) => (
            <MenuItem
              key={option.key}
              onClick={(e) => {
                e.stopPropagation()
                const input = {
                  workspaceId,
                  objectType,
                  objectId,
                  relationship: {
                    type: option.type,
                    stage: option.stageKey,
                    direction: option.direction,
                    value: true,
                  },
                }
                onCreate(input)
                setAnchorEl(null)
              }}
            >
              {option.label}
            </MenuItem>
          ))}
      </Menu>
    </>
  )
}

export default RelationshipChooser
