import { memo, useMemo } from 'react'

import { Avatar, Chip } from '@mui/material'
import { useQuery, useZero } from '@rocicorp/zero/react'
import type { DayObject } from 'types/graphql'

import { useAuth } from 'src/auth'
import { logger } from 'src/lib/logger'
import {
  globalWorkspaceId,
  ObjectTypeMetadata,
  PropertyTypes,
  type NativeObjectType,
} from 'src/lib/objects'
import { buildObject, buildObjectSearchEntry } from 'src/lib/Objects/build'
import type { Schema } from 'src/zero/schema'

import { SidebarMode } from './ObjectSidebar/SidebarContext'
import useSidebar from './ObjectSidebar/useSidebar'
import { DayObjectVariants } from './types'

const DayObjectComponent = ({
  object,
  variant = DayObjectVariants.CHIP,
  onClick = (_) => {},
  openSidebar = false,
}: {
  object: Partial<DayObject>
  variant?: DayObjectVariants
  onClick?: (object: Partial<DayObject>) => void
  openSidebar?: boolean
}) => {
  const { currentUser: user } = useAuth()
  const { setSidebarObject } = useSidebar()

  const z = useZero<Schema>()

  const [rowsResult] = useQuery(
    z.query.objectProperty
      .where(({ and, cmp, not }) =>
        and(
          cmp(
            'workspaceId',
            'IN',
            [globalWorkspaceId, object.workspaceId].filter(Boolean)
          ),
          cmp('objectTypeId', object.objectType),
          cmp('objectId', object.objectId),
          not(cmp('propertyTypeId', PropertyTypes.DeleteMarker))
        )
      )
      .orderBy('updatedAt', 'desc'),
    {
      enabled: !!object.objectId && !!object.workspaceId && !!object.objectType,
    }
  )

  const [dayObject, displayObject] = useMemo(() => {
    if (rowsResult) {
      const builtObject = buildObject({
        objectType: object.objectType as NativeObjectType,
        objectId: object.objectId,
        workspaceId: object.workspaceId,
        rows: [...rowsResult],
        propertyDefinitions: [],
        userId: user?.id,
      })
      const builtSearchEntry = buildObjectSearchEntry(builtObject)

      return [builtObject, builtSearchEntry]
    }
  }, [rowsResult, object, user])

  const chipSx = {
    flexShrink: 1,
    justifyContent: 'flex-start',
    width: 'auto',
    '& .MuiChip-avatar': {
      width: '12px',
      height: '12px',
      borderRadius: '50%',
      background: (theme) => theme.palette.background.default,
      flexShrink: 0,
    },
    cursor: openSidebar || onClick ? 'pointer' : 'default',
    border: (theme) =>
      openSidebar || onClick ? `1px solid ${theme.palette.divider}` : 'none',
  }

  return rowsResult && displayObject?.objectId ? (
    <>
      {variant === DayObjectVariants.CHIP && (
        <Chip
          onMouseDown={(e) => {
            e.stopPropagation()
            if (onClick) {
              onClick(dayObject)
            }

            if (openSidebar) {
              logger.dev('Opening sidebar', { dayObject })
              setSidebarObject({
                object: dayObject,
                mode: SidebarMode.VIEW,
              })
            }
          }}
          avatar={
            displayObject.photoUrl ? (
              <Avatar
                src={displayObject.photoUrl}
                alt={displayObject.label}
              />
            ) : null
          }
          icon={
            displayObject.photoUrl
              ? null
              : React.createElement(ObjectTypeMetadata[object.objectType].icon)
          }
          label={displayObject.label || ''}
          size="small"
          variant="outlined"
          sx={chipSx}
        />
      )}
      {variant === DayObjectVariants.TILE && <></>}
    </>
  ) : (
    <>
      {variant === DayObjectVariants.CHIP && (
        <Chip
          label=""
          size="small"
          variant="outlined"
        />
      )}
    </>
  )
}

const DayUiObject = memo(DayObjectComponent, (prev, next) => {
  return (
    prev.object.objectId === next.object.objectId &&
    prev.object.objectType === next.object.objectType &&
    prev.object.workspaceId === next.object.workspaceId &&
    prev.variant === next.variant
  )
})

export default DayUiObject
