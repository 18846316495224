import {
  IconBolt,
  IconBriefcase,
  IconBuildingSkyscraper,
  IconColumns,
  IconColumns2,
  IconTrophy,
  IconUser,
  IconVideo,
  IconFiles,
  IconBubbleText,
  IconCalendar,
  IconCode,
  IconBrandSlack,
  IconMail,
  IconFile,
  IconBook,
  IconMessageCheck,
  IconGlobe,
} from '@tabler/icons-react'
import { merge } from 'lodash'
import type { CRMObject } from 'types/graphql'

import { ActionTypes } from './actions'
import { logger } from './logger'
import { NativeSuggestedPipelineTypes } from './relationshipSummary'

export const GlobalWorkspace = 'global'
export const globalWorkspaceId = process.env.HOST.includes('localhost')
  ? 'e6a2b090-01a9-4659-b504-7ace0f621c74'
  : 'cb5f969e-9045-47f8-b4fc-02afdc2bced1'

export const NativeObjectTypes = {
  Person: 'native_contact',
  Contact: 'native_contact',
  Organization: 'native_organization',
  Opportunity: 'native_opportunity',
  Pipeline: 'native_pipeline',
  Stage: 'native_stage',
  MeetingRecording: 'native_meetingrecording',
  MeetingRecordingClip: 'native_meetingrecordingclip',
  Page: 'native_page',
  Action: 'native_action',
  Workspace: 'native_workspace',
  View: 'native_view',
  Event: 'native_calendarevent',
  GmailThread: 'native_gmailthread',
  UserContext: 'native_usercontext',
  PropertyDefinition: 'property_definition',
  SlackChannel: 'native_slackchannel',
  WebPage: 'native_webpage',
} as const

export type NativeObjectType =
  (typeof NativeObjectTypes)[keyof typeof NativeObjectTypes]

export const PropertyTypes = {
  Address: 'address',
  Boolean: 'boolean',
  Calculated: 'calculated',
  Combobox: 'combobox',
  Currency: 'currency',
  Email: 'email',
  MultiPicklist: 'multipicklist',
  Percent: 'percent',
  Picklist: 'picklist',
  Phone: 'phone',
  ObjectReference: 'reference',
  TextArea: 'textarea',
  Url: 'url',
  DateTime: 'datetime',
  Integer: 'int',
  Float: 'float',
  DeleteMarker: 'deletemarker',
} as const

export const PropertySources = {
  ManualEntry: 'manual',
  Import: 'import',
  AIGenerated: 'ai_generated',
  GoogleContacts: 'google_contacts',
  GoogleCalendar: 'google_calendar',
  Gmail: 'gmail',
  Form: 'form',
  Serp: 'serp',
  ExactBuyer: 'exact_buyer',
  Clearbit: 'clearbit',
  FullContact: 'fullcontact',
  ReverseContact: 'reverse_contact',
  CoreSignal: 'coresignal',
  Legacy: 'legacy',
  ParallelAi: 'parallel_ai',
}

export const ObjectAccessLevels = {
  Owner: 'owner',
  FullAccess: 'fullAccess',
  Editor: 'editor',
  Collaborator: 'collaborator',
  Viewer: 'viewer',
} as const

type ObjectPath = {
  key: string
  label: string
}

type ObjectTypeMetadataType = {
  icon: React.ComponentType<any>
  label: string
  pluralLabel: string
  slug: string
  webEnabled: boolean
  paths?: {
    [key: string]: ObjectPath
  }
  syncEnabled: boolean
}

export const OBJECT_URI_SEPARATOR = ' : '

export const buildObjectReference = ({
  workspaceId,
  objectType,
  objectId,
}: {
  workspaceId: string
  objectType: string
  objectId: string
}) => [workspaceId, objectType, objectId].join(OBJECT_URI_SEPARATOR)

export const parseObjectReference = (
  reference: string
): {
  workspaceId: string
  objectType: string
  objectId: string
} => {
  if (!reference) {
    logger.warn('parseObjectReference: reference is null')
    return null
  }

  const [workspaceId, objectType, objectId] =
    reference.split(OBJECT_URI_SEPARATOR)

  return {
    workspaceId,
    objectType,
    objectId,
  }
}

export const ObjectTypeMetadata: Record<
  NativeObjectType,
  ObjectTypeMetadataType
> = {
  [NativeObjectTypes.Organization]: {
    icon: IconBuildingSkyscraper,
    label: 'Organization',
    pluralLabel: 'Organizations',
    slug: 'organizations',
    webEnabled: true,
    syncEnabled: true,
  },
  [NativeObjectTypes.Person]: {
    icon: IconUser,
    label: 'Person',
    pluralLabel: 'People',
    slug: 'people',
    webEnabled: true,
    syncEnabled: true,
  },
  [NativeObjectTypes.Opportunity]: {
    icon: IconTrophy,
    label: 'Opportunity',
    pluralLabel: 'Opportunities',
    slug: 'opportunities',
    webEnabled: false,
    paths: {
      CREATION: {
        key: 'opportunities/create',
        label: 'Creation & Assignment',
      },
    },
    syncEnabled: true,
  },
  [NativeObjectTypes.Pipeline]: {
    icon: IconColumns2,
    label: 'Pipeline',
    pluralLabel: 'Pipelines',
    slug: 'pipelines',
    webEnabled: true,
    syncEnabled: true,
  },
  [NativeObjectTypes.Stage]: {
    icon: IconColumns,
    label: 'Stage',
    pluralLabel: 'Stages',
    slug: 'stages',
    webEnabled: true,
    syncEnabled: true,
  },
  [NativeObjectTypes.Workspace]: {
    icon: IconBriefcase,
    label: 'Workspace',
    pluralLabel: 'Workspaces',
    slug: 'workspace',
    webEnabled: true,
    syncEnabled: false,
  },
  [NativeObjectTypes.Action]: {
    icon: IconBolt,
    label: 'Action',
    pluralLabel: 'Actions',
    slug: 'actions',
    webEnabled: true,
    paths: {
      ROOT: {
        key: 'root',
        label: 'Action Creation & Assignment',
      },
      ...ActionTypes,
    },
    syncEnabled: false,
  },
  [NativeObjectTypes.MeetingRecording]: {
    icon: IconVideo,
    label: 'Meeting Recording',
    pluralLabel: 'Meeting Recordings',
    slug: 'recordings',
    webEnabled: false,
    syncEnabled: true,
  },
  [NativeObjectTypes.Page]: {
    icon: IconFiles,
    label: 'Page',
    pluralLabel: 'Pages',
    slug: 'pages',
    webEnabled: false,
    syncEnabled: true,
  },
  [NativeObjectTypes.UserContext]: {
    icon: IconBubbleText,
    label: 'Context',
    pluralLabel: 'Context Entries',
    slug: 'context',
    webEnabled: false,
    syncEnabled: false,
  },
  [NativeObjectTypes.MeetingRecordingClip]: {
    icon: IconVideo,
    label: 'Meeting Recording Clip',
    pluralLabel: 'Meeting Recording Clips',
    slug: 'recording-clips',
    webEnabled: false,
    syncEnabled: false,
  },
  [NativeObjectTypes.View]: {
    icon: IconColumns,
    label: 'View',
    pluralLabel: 'Views',
    slug: 'views',
    webEnabled: false,
    syncEnabled: false,
  },
  [NativeObjectTypes.Event]: {
    icon: IconCalendar,
    label: 'Calendar Event',
    pluralLabel: 'Calendar Events',
    slug: 'events',
    webEnabled: false,
    syncEnabled: false,
  },
  [NativeObjectTypes.GmailThread]: {
    icon: IconBubbleText,
    label: 'Gmail Thread',
    pluralLabel: 'Gmail Threads',
    slug: 'gmail',
    webEnabled: false,
    syncEnabled: false,
  },
  [NativeObjectTypes.PropertyDefinition]: {
    icon: IconCode,
    label: 'Property Definition',
    pluralLabel: 'Property Definitions',
    slug: 'properties',
    webEnabled: false,
    syncEnabled: false,
  },
  [NativeObjectTypes.SlackChannel]: {
    icon: IconBrandSlack,
    label: 'Slack Channel',
    pluralLabel: 'Slack Channels',
    slug: 'slack',
    webEnabled: false,
    syncEnabled: false,
  },
  [NativeObjectTypes.WebPage]: {
    icon: IconGlobe,
    label: 'Web Page',
    pluralLabel: 'Web Pages',
    slug: 'webpages',
    webEnabled: false,
    syncEnabled: false,
  },
}

export enum StageTypes {
  AWARENESS = 'AWARENESS',
  CONNECTION = 'CONNECTION',
  NEEDS_IDENTIFICATION = 'NEEDS_IDENTIFICATION',
  PROPOSAL = 'PROPOSAL',
  EVALUATION = 'EVALUATION',
  CONSIDERATION_NEGOTIATION = 'CONSIDERATION_NEGOTIATION',
  CLOSED_WON = 'CLOSED_WON',
  CLOSED_LOST = 'CLOSED_LOST',
  CUSTOMER_SUCCESS = 'CUSTOMER_SUCCESS',
}

export const NativePipelineTypes = {
  NEW_CUSTOMER: {
    title: 'Sales Pipeline',
    type: 'NEW_CUSTOMER',
    opportunityPrompt: `This opportunity is related to \${userCompany} selling to \${contactCompany} in order to acquire them as a new customer who has never purchased from \${userCompany} before and is not currently using the product/service. Example: An introductory video call with \${contactCompany} to discuss \${userCompany}'s SaaS product.`,
  },
  EXISTING_CUSTOMER: {
    title: 'Upsell & Cross-sell Pipeline',
    type: 'EXISTING_CUSTOMER',
    opportunityPrompt: `This opportunity is related to \${userCompany} talking to or selling to \${contactCompany}, which is an existing customer. An existing customer is someone who already uses the product/service and has paid for the service/product. This category includes upselling, cross-selling, expanding services, or renewing contracts. Example: A follow-up email from \${userCompany} proposing an upgraded package to \${contactCompany}.`,
  },
  FINANCING_INVESTMENT: {
    title: 'Fundraising Pipeline',
    type: 'FINANCING_INVESTMENT',
    opportunityPrompt: `This opportunity is related to VC funding, founder/company/startup fundraising, or any other investment relationship. Example: A pitch meeting with \${contactCompany}, a potential investor, to secure seed funding for \${userCompany}.`,
  },
  VENTURE_CAPITAL: {
    title: 'Venture Capital Pipeline',
    type: 'VENTURE_CAPITAL',
    opportunityPrompt: `This opportunity is one where \${userCompany} is considering investing in \${contactCompany}.`,
  },
  PARTNER: {
    title: 'Partnerships Pipeline',
    type: 'PARTNER',
    opportunityPrompt: `This opportunity involves establishing or maintaining a collaborative business relationship where one company provides specialized services or expertise to support another company's projects or clients. Unlike vendor procurement, this is a more integrated partnership where both \${userCompany} and \${contactCompany} may work together on client-facing projects. It's not a direct customer relationship, investment, or recruitment. Example: A meeting to discuss terms of a partnership where a software development firm will provide specialized coding services for a digital agency's client projects.`,
  },
  RECRUITING: {
    title: 'Recruiting Pipeline',
    type: 'RECRUITING',
    opportunityPrompt: `This opportunity is related to \${userCompany} hiring \${person?.fullName} (\${person?.email}) as a new employee.`,
  },
  PERSONAL: {
    title: 'Personal Pipeline',
    type: 'PERSONAL',
    opportunityPrompt: `This opportunity involves a personal interaction, not a business one. For instance, it could be about a user's children's school, their vacation planning, a family event, a purchase made for the family and not for \${userCompany}. It's not about \${userCompany}, rather it's about a person interacting with \${contactCompany}.`,
  },
  NONE: {
    title: 'Other',
    type: 'NONE',
    opportunityPrompt: `There is no specific pipeline that fits this opportunity. Use this when the interaction doesn't align with any of the above categories.`,
  },
  OTHER: {
    title: 'Custom pipeline (rename this)',
    type: 'OTHER',
    opportunityPrompt: `The opportunity fits a pipeline that is not listed here. Use this when there's a clear purpose, but it doesn't match the defined pipelines. It could be networking, or something unknown or unclassifiable.`,
  },
}

export const TemplateTypes = {
  EMAIL: 'EMAIL',
  SLACK: 'SLACK',
  INTERNAL_PAGE: 'INTERNAL_PAGE',
  KNOWLEDGE: 'KNOWLEDGE',
  BASE: 'BASE',
}

export const TemplateTypeMetadata: Record<
  string,
  {
    icon: React.ComponentType<any>
    label: string
    pluralLabel: string
    slug: string
    color: string
  }
> = {
  [TemplateTypes.EMAIL]: {
    icon: IconMail,
    label: 'Email',
    pluralLabel: 'Emails',
    slug: 'email',
    color: 'success',
  },
  [TemplateTypes.SLACK]: {
    icon: IconBrandSlack,
    label: 'Slack',
    pluralLabel: 'Slacks',
    slug: 'slack',
    color: 'warning',
  },
  [TemplateTypes.INTERNAL_PAGE]: {
    icon: IconFile,
    label: 'Internal Page',
    pluralLabel: 'Internal Pages',
    slug: 'internal-page',
    color: 'secondary',
  },
  [TemplateTypes.KNOWLEDGE]: {
    icon: IconBook,
    label: 'Knowledge',
    pluralLabel: 'Knowledge',
    slug: 'knowledge',
    color: 'info',
  },
  [TemplateTypes.BASE]: {
    icon: IconMessageCheck,
    label: 'Base Template',
    pluralLabel: 'Base Templates',
    slug: 'base',
    color: 'disabled',
  },
}

export const ActionSourceLabels = {
  AI_GENERATED: 'AI',
  MEETING_RECORDING: 'Meeting',
  GMAIL_THREAD: 'Gmail',
  SLACK_CHANNEL: 'Slack',
  NOTE: 'Note',
  OPPORTUNITY: 'Opp',
  MANUAL: 'Manual',
  ZAPIER: 'Zapier',
  API: 'API',
}

export const ActionChannelLabels = {
  EMAIL: 'Email',
  GMAIL: 'Gmail',
  SLACK: 'Slack',
  MEETING: 'Meeting',
  NOTE: 'Note',
  PAGE: 'Page',
}

export type RelationshipDirection = 'TO' | 'FROM' | 'REFERRER'

export const RelationshipDirectionalities: Record<
  string,
  RelationshipDirection
> = {
  To: 'TO',
  From: 'FROM',
  Referrer: 'REFERRER',
} as const

export type RelationshipStage = 'PROSPECTIVE' | 'EXISTING' | 'ABANDONED'

export const RelationshipStages: Record<string, RelationshipStage> = {
  Prospective: 'PROSPECTIVE',
  Existing: 'EXISTING',
  Abandoned: 'ABANDONED',
} as const

export type RelationshipType =
  | 'CUSTOMER'
  | 'INVESTMENT'
  | 'PARTNER'
  | 'MENTOR'
  | 'INTERNAL'
  | 'PERSONAL'
  | 'OTHER'

export const RelationshipTypes: Record<string, RelationshipType> = {
  Customer: 'CUSTOMER',
  Investment: 'INVESTMENT',
  Partner: 'PARTNER',
  Mentor: 'MENTOR',
  Internal: 'INTERNAL',
  Personal: 'PERSONAL',
  Other: 'OTHER',
} as const

export const RelationshipTypeLabels: Record<RelationshipType, string> = {
  CUSTOMER: 'Customer',
  INVESTMENT: 'Investment',
  PARTNER: 'Partner',
  MENTOR: 'Mentor',
  INTERNAL: 'Internal',
  PERSONAL: 'Personal',
  OTHER: 'Other',
}

export const RelationshipStageDisplay: Record<
  RelationshipStage,
  { primary: string; secondary: string }
> = {
  PROSPECTIVE: {
    primary: 'Prospective',
    secondary: 'eg. Sales prospect, potential investor or referrer etc',
  },
  EXISTING: {
    primary: 'Existing',
    secondary: 'eg. Current customer, partner, referrer, investor, etc',
  },
  ABANDONED: {
    primary: 'Former',
    secondary: 'eg. Cancelled customer, former partner etc',
  },
}

/*
 workspaceId: xyz-123


pixelmachinery.com
["CUSTOMER : TO : PROSPECTIVE" = true, "CUSTOMER : FROM : EXISTING" = true]

sarah@pillar.vc
["MENTOR : MUTUAL : EXISTING" = true, "CUSTOMER : TO : PROSPECTIVE" = true, "INVESTMENT : FROM : EXISTING" = true,
 "INVESTMENT : FROM : PROSPECTIVE" = true, "INVESTMENT : REFERRER : PROSPECTIVE" = true,
 "CUSTOMER : REFERRER : EXISTING" = true]

 niriksha@hprime.co
 ["CUSTOMER : REFERRER : EXISTING" = true]

 brightmindsneuropsych.com
 ["CUSTOMER : TO : EXISTING" = true]

 */

export type RelationshipTypeMetadata = {
  label: string
  key: string
  pipelineType: keyof typeof NativeSuggestedPipelineTypes | null
}

export type RelationshipEdge = {
  key: string
  type: RelationshipType
  stage: RelationshipStage
  direction: RelationshipDirection
  label: string
  pipelineType: keyof typeof NativeSuggestedPipelineTypes | null
}

type RelationshipTypeStructure = Record<
  RelationshipType,
  Record<
    RelationshipDirection,
    Record<RelationshipStage, RelationshipTypeMetadata>
  >
>

export const NativeRelationshipTypes: RelationshipTypeStructure = {
  [RelationshipTypes.Customer as RelationshipType]: {
    [RelationshipDirectionalities.To as RelationshipDirection]: {
      [RelationshipStages.Prospective as RelationshipStage]: {
        label: 'Prospective Customer',
        key: 'PROSPECTIVE_CUSTOMER',
        pipelineType: NativeSuggestedPipelineTypes.NEW_CUSTOMER.key,
      },
      [RelationshipStages.Existing as RelationshipStage]: {
        label: 'Current Customer',
        key: 'EXISTING_CUSTOMER',
        pipelineType: NativeSuggestedPipelineTypes.EXISTING_CUSTOMER.key,
      },
      [RelationshipStages.Abandoned as RelationshipStage]: {
        label: 'Former Customer',
        key: 'CANCELLED_CUSTOMER',
        pipelineType: null,
      },
    },
    [RelationshipDirectionalities.From as RelationshipDirection]: {
      [RelationshipStages.Prospective as RelationshipStage]: {
        label: 'Prospective Vendor',
        key: 'PROSPECTIVE_VENDOR',
        pipelineType: NativeSuggestedPipelineTypes.VENDOR_PROCUREMENT.key,
      },
      [RelationshipStages.Existing as RelationshipStage]: {
        label: 'Current Vendor',
        key: 'EXISTING_VENDOR',
        pipelineType: NativeSuggestedPipelineTypes.VENDOR_PROCUREMENT.key,
      },
      [RelationshipStages.Abandoned as RelationshipStage]: {
        label: 'Former Vendor',
        key: 'CANCELLED_VENDOR',
        pipelineType: null,
      },
    },
    [RelationshipDirectionalities.Referrer as RelationshipDirection]: {
      [RelationshipStages.Prospective as RelationshipStage]: {
        label: 'Prospective Customer Referrer',
        key: 'PROSPECTIVE_CUSTOMER_REFERRER',
        pipelineType: null,
      },
      [RelationshipStages.Existing as RelationshipStage]: {
        label: 'Current Customer Referrer',
        key: 'EXISTING_CUSTOMER_REFERRER',
        pipelineType: null,
      },
      [RelationshipStages.Abandoned as RelationshipStage]: {
        label: 'Former Customer Referrer',
        key: 'FORMER_CUSTOMER_REFERRER',
        pipelineType: null,
      },
    },
  },
  [RelationshipTypes.Investment as RelationshipType]: {
    [RelationshipDirectionalities.From as RelationshipDirection]: {
      [RelationshipStages.Prospective as RelationshipStage]: {
        label: 'Prospective Investor',
        key: 'PROSPECTIVE_INVESTMENT',
        pipelineType: NativeSuggestedPipelineTypes.FINANCING_INVESTMENT.key,
      },
      [RelationshipStages.Existing as RelationshipStage]: {
        label: 'Current Investor',
        key: 'EXISTING_INVESTMENT',
        pipelineType: NativeSuggestedPipelineTypes.FINANCING_INVESTMENT.key,
      },
      [RelationshipStages.Abandoned as RelationshipStage]: {
        label: 'Former Investor',
        key: 'FORMER_INVESTMENT',
        pipelineType: NativeSuggestedPipelineTypes.FINANCING_INVESTMENT.key,
      },
    },
    [RelationshipDirectionalities.To as RelationshipDirection]: {
      [RelationshipStages.Prospective as RelationshipStage]: {
        label: 'Prospective Investment',
        key: 'PROSPECTIVE_INVESTMENT',
        pipelineType: NativeSuggestedPipelineTypes.VENTURE_CAPITAL.key,
      },
      [RelationshipStages.Existing as RelationshipStage]: {
        label: 'Current Portfolio Company',
        key: 'EXISTING_INVESTMENT',
        pipelineType: NativeSuggestedPipelineTypes.VENTURE_CAPITAL.key,
      },
      [RelationshipStages.Abandoned as RelationshipStage]: {
        label: 'Previous Portfolio Company',
        key: 'PREVIOUS_INVESTMENT',
        pipelineType: null,
      },
    },
    [RelationshipDirectionalities.Referrer as RelationshipDirection]: {
      [RelationshipStages.Prospective as RelationshipStage]: {
        label: 'Prospective Investment Referrer',
        key: 'PROSPECTIVE_INVESTMENT_REFERRER',
        pipelineType: null,
      },
      [RelationshipStages.Existing as RelationshipStage]: {
        label: 'Current Investment Referrer',
        key: 'EXISTING_INVESTMENT_REFERRER',
        pipelineType: null,
      },
      [RelationshipStages.Abandoned as RelationshipStage]: {
        label: 'Former Investment Referrer',
        key: 'FORMER_INVESTMENT_REFERRER',
        pipelineType: null,
      },
    },
  },
  [RelationshipTypes.Partner as RelationshipType]: {
    [RelationshipDirectionalities.To as RelationshipDirection]: {
      [RelationshipStages.Prospective as RelationshipStage]: {
        label: 'Prospective Partner',
        key: 'PROSPECTIVE_PARTNER',
        pipelineType: NativeSuggestedPipelineTypes.PARTNER.key,
      },
      [RelationshipStages.Existing as RelationshipStage]: {
        label: 'Current Partner',
        key: 'EXISTING_PARTNER',
        pipelineType: NativeSuggestedPipelineTypes.PARTNER.key,
      },
      [RelationshipStages.Abandoned as RelationshipStage]: {
        label: 'Former Partner',
        key: 'FORMER_PARTNER',
        pipelineType: null,
      },
    },
    [RelationshipDirectionalities.From as RelationshipDirection]: {
      [RelationshipStages.Prospective as RelationshipStage]: {
        label: 'Prospective Partner',
        key: 'PROSPECTIVE_PARTNER',
        pipelineType: NativeSuggestedPipelineTypes.PARTNER.key,
      },
      [RelationshipStages.Existing as RelationshipStage]: {
        label: 'Current Partner',
        key: 'EXISTING_PARTNER',
        pipelineType: NativeSuggestedPipelineTypes.PARTNER.key,
      },
      [RelationshipStages.Abandoned as RelationshipStage]: {
        label: 'Former Partner',
        key: 'FORMER_PARTNER',
        pipelineType: null,
      },
    },
    [RelationshipDirectionalities.Referrer as RelationshipDirection]: {
      [RelationshipStages.Prospective as RelationshipStage]: {
        label: 'Prospective Partner Referrer',
        key: 'PROSPECTIVE_PARTNER_REFERRER',
        pipelineType: null,
      },
      [RelationshipStages.Existing as RelationshipStage]: {
        label: 'Current Partner Referrer',
        key: 'EXISTING_PARTNER_REFERRER',
        pipelineType: null,
      },
      [RelationshipStages.Abandoned as RelationshipStage]: {
        label: 'Former Partner Referrer',
        key: 'FORMER_PARTNER_REFERRER',
        pipelineType: null,
      },
    },
  },
  [RelationshipTypes.Mentor as RelationshipType]: {
    [RelationshipDirectionalities.To as RelationshipDirection]: {
      [RelationshipStages.Prospective as RelationshipStage]: {
        label: 'Prospective Mentee',
        key: 'PROSPECTIVE_MENTEE',
        pipelineType: null,
      },
      [RelationshipStages.Existing as RelationshipStage]: {
        label: 'Current Mentee',
        key: 'EXISTING_MENTEE',
        pipelineType: null,
      },
      [RelationshipStages.Abandoned as RelationshipStage]: {
        label: 'Former Mentee',
        key: 'FORMER_MENTEE',
        pipelineType: null,
      },
    },
    [RelationshipDirectionalities.From as RelationshipDirection]: {
      [RelationshipStages.Prospective as RelationshipStage]: {
        label: 'Prospective Mentor',
        key: 'PROSPECTIVE_MENTOR',
        pipelineType: null,
      },
      [RelationshipStages.Existing as RelationshipStage]: {
        label: 'Current Mentor',
        key: 'EXISTING_MENTOR',
        pipelineType: null,
      },
      [RelationshipStages.Abandoned as RelationshipStage]: {
        label: 'Former Mentor',
        key: 'FORMER_MENTOR',
        pipelineType: null,
      },
    },
    [RelationshipDirectionalities.Referrer as RelationshipDirection]: {
      [RelationshipStages.Prospective as RelationshipStage]: {
        label: 'Prospective Mentor Referrer',
        key: 'PROSPECTIVE_MENTOR_REFERRER',
        pipelineType: null,
      },
      [RelationshipStages.Existing as RelationshipStage]: {
        label: 'Current Mentor Referrer',
        key: 'EXISTING_MENTOR_REFERRER',
        pipelineType: null,
      },
      [RelationshipStages.Abandoned as RelationshipStage]: {
        label: 'Former Mentor Referrer',
        key: 'FORMER_MENTOR_REFERRER',
        pipelineType: null,
      },
    },
  },
  [RelationshipTypes.Internal as RelationshipType]: {
    [RelationshipDirectionalities.To as RelationshipDirection]: {
      [RelationshipStages.Prospective as RelationshipStage]: {
        label: 'Prospective Team Member',
        key: 'PROSPECTIVE_INTERNAL',
        pipelineType: NativeSuggestedPipelineTypes.RECRUITING.key,
      },
      [RelationshipStages.Existing as RelationshipStage]: {
        label: 'Current Team Member',
        key: 'EXISTING_INTERNAL',
        pipelineType: null,
      },
      [RelationshipStages.Abandoned as RelationshipStage]: {
        label: 'Former Team Member',
        key: 'FORMER_INTERNAL',
        pipelineType: null,
      },
    },
    [RelationshipDirectionalities.From as RelationshipDirection]: {
      [RelationshipStages.Prospective as RelationshipStage]: {
        label: 'Prospective Team Member',
        key: 'PROSPECTIVE_INTERNAL',
        pipelineType: NativeSuggestedPipelineTypes.RECRUITING.key,
      },
      [RelationshipStages.Existing as RelationshipStage]: {
        label: 'Current Team Member',
        key: 'EXISTING_INTERNAL',
        pipelineType: null,
      },
      [RelationshipStages.Abandoned as RelationshipStage]: {
        label: 'Former Team Member',
        key: 'FORMER_INTERNAL',
        pipelineType: null,
      },
    },
    [RelationshipDirectionalities.Referrer as RelationshipDirection]: {
      [RelationshipStages.Prospective as RelationshipStage]: {
        label: 'Prospective Team Referrer',
        key: 'PROSPECTIVE_INTERNAL_REFERRER',
        pipelineType: null,
      },
      [RelationshipStages.Existing as RelationshipStage]: {
        label: 'Current Team Referrer',
        key: 'EXISTING_INTERNAL_REFERRER',
        pipelineType: null,
      },
      [RelationshipStages.Abandoned as RelationshipStage]: {
        label: 'Former Team Referrer',
        key: 'FORMER_INTERNAL_REFERRER',
        pipelineType: null,
      },
    },
  },
  [RelationshipTypes.Personal as RelationshipType]: {
    [RelationshipDirectionalities.To as RelationshipDirection]: {
      [RelationshipStages.Prospective as RelationshipStage]: {
        label: 'Personal Contact',
        key: 'PROSPECTIVE_PERSONAL',
        pipelineType: null,
      },
      [RelationshipStages.Existing as RelationshipStage]: {
        label: 'Personal Contact',
        key: 'EXISTING_PERSONAL',
        pipelineType: null,
      },
      [RelationshipStages.Abandoned as RelationshipStage]: {
        label: 'Former Personal Contact',
        key: 'FORMER_PERSONAL',
        pipelineType: null,
      },
    },
    [RelationshipDirectionalities.From as RelationshipDirection]: {
      [RelationshipStages.Prospective as RelationshipStage]: {
        label: 'Personal Contact',
        key: 'PROSPECTIVE_PERSONAL',
        pipelineType: null,
      },
      [RelationshipStages.Existing as RelationshipStage]: {
        label: 'Personal Contact',
        key: 'EXISTING_PERSONAL',
        pipelineType: null,
      },
      [RelationshipStages.Abandoned as RelationshipStage]: {
        label: 'Former Personal Contact',
        key: 'FORMER_PERSONAL',
        pipelineType: null,
      },
    },
    [RelationshipDirectionalities.Referrer as RelationshipDirection]: {
      [RelationshipStages.Prospective as RelationshipStage]: {
        label: 'Prospective Personal Referrer',
        key: 'PROSPECTIVE_PERSONAL_REFERRER',
        pipelineType: null,
      },
      [RelationshipStages.Existing as RelationshipStage]: {
        label: 'Current Personal Referrer',
        key: 'EXISTING_PERSONAL_REFERRER',
        pipelineType: null,
      },
      [RelationshipStages.Abandoned as RelationshipStage]: {
        label: 'Former Personal Referrer',
        key: 'FORMER_PERSONAL_REFERRER',
        pipelineType: null,
      },
    },
  },
  [RelationshipTypes.Other as RelationshipType]: {
    [RelationshipDirectionalities.To as RelationshipDirection]: {
      [RelationshipStages.Prospective as RelationshipStage]: {
        label: 'Other Contact',
        key: 'PROSPECTIVE_OTHER',
        pipelineType: null,
      },
      [RelationshipStages.Existing as RelationshipStage]: {
        label: 'Other Contact',
        key: 'EXISTING_OTHER',
        pipelineType: null,
      },
      [RelationshipStages.Abandoned as RelationshipStage]: {
        label: 'Former Other Contact',
        key: 'FORMER_OTHER',
        pipelineType: null,
      },
    },
    [RelationshipDirectionalities.From as RelationshipDirection]: {
      [RelationshipStages.Prospective as RelationshipStage]: {
        label: 'Other Contact',
        key: 'PROSPECTIVE_OTHER',
        pipelineType: null,
      },
      [RelationshipStages.Existing as RelationshipStage]: {
        label: 'Other Contact',
        key: 'EXISTING_OTHER',
        pipelineType: null,
      },
      [RelationshipStages.Abandoned as RelationshipStage]: {
        label: 'Former Other Contact',
        key: 'FORMER_OTHER',
        pipelineType: null,
      },
    },
    [RelationshipDirectionalities.Referrer as RelationshipDirection]: {
      [RelationshipStages.Prospective as RelationshipStage]: {
        label: 'Prospective Contact Referrer',
        key: 'PROSPECTIVE_OTHER_REFERRER',
        pipelineType: null,
      },
      [RelationshipStages.Existing as RelationshipStage]: {
        label: 'Current Contact Referrer',
        key: 'EXISTING_OTHER_REFERRER',
        pipelineType: null,
      },
      [RelationshipStages.Abandoned as RelationshipStage]: {
        label: 'Former Contact Referrer',
        key: 'FORMER_OTHER_REFERRER',
        pipelineType: null,
      },
    },
  },
} as const as RelationshipTypeStructure

export const buildUserContextReferenceKey = (
  type: string,
  id: string,
  path?: string
) => `${type} : ${id} : ${path || 'root'}`

export const buildRelationship = ({
  type,
  direction,
  stage,
}: {
  type: RelationshipType
  direction: RelationshipDirection
  stage: RelationshipStage
}): RelationshipEdge => {
  if (!type || !direction || !stage) {
    return null
  }
  const metadata = NativeRelationshipTypes?.[type]?.[direction]?.[stage]
  if (!metadata) {
    logger.warn('No metadata found')
    logger.dev({ type, direction, stage })
    return null
  }
  return {
    key: buildRelationshipReference({
      type,
      direction,
      stage,
    }),
    label: metadata.label,
    pipelineType: metadata.pipelineType,
    type,
    direction,
    stage,
  }
}

export const buildRelationshipReference = ({
  type,
  direction,
  stage,
}: {
  type: RelationshipType
  direction: RelationshipDirection
  stage: RelationshipStage
}) => {
  return `relationship/${direction}/${type}/${stage}`
}

export const parseRelationshipReference = (reference: string) => {
  if (!reference || typeof reference !== 'string') {
    logger.warn('Invalid relationship reference - must be a non-empty string')
    return null
  }

  const parts = reference.split('/')
  if (parts.length !== 4) {
    logger.warn(
      'Invalid relationship reference format - must contain exactly 4 parts separated by "/" (direction/type/stage)'
    )
    return null
  }

  const [_, direction, type, stage] = parts

  if (!type || !direction || !stage) {
    logger.warn('Invalid relationship reference - all parts must be non-empty')
    return null
  }

  return { type, direction, stage }
}

const removeNulls = (obj: any): any => {
  if (!obj || typeof obj !== 'object') return obj

  return Object.entries(obj).reduce(
    (acc, [key, value]) => {
      // Recursively clean nested objects
      if (value && typeof value === 'object' && Object.keys(value).length > 0) {
        const cleaned = removeNulls(value)
        if (Object.keys(cleaned).length > 0) {
          acc[key] = cleaned
        }
      }
      // Keep non-null values
      else if (value !== null) {
        acc[key] = value
      }
      return acc
    },
    Array.isArray(obj) ? [] : {}
  )
}

export const deepMerge = (existing: any, incoming: any): any => {
  // Remove null values from incoming object
  const sanitizedIncoming = removeNulls(incoming)

  const result = merge({}, existing, sanitizedIncoming)

  return result
}

export const getMetadataFromRelationshipReference = (key: string) => {
  const relationship = parseRelationshipReference(key)
  return NativeRelationshipTypes[relationship?.type]?.[
    relationship?.direction
  ]?.[relationship?.stage]
}

export const getRelationshipFromPipelineType = (pipelineType: string) => {
  if (!pipelineType) {
    logger.warn('No pipelineType provided')
    return null
  }

  for (const [type, directionMap] of Object.entries(NativeRelationshipTypes)) {
    for (const [direction, stageMap] of Object.entries(directionMap)) {
      for (const [stage, metadata] of Object.entries(stageMap)) {
        if (metadata.pipelineType === pipelineType) {
          return {
            type: type as RelationshipType,
            direction: direction as RelationshipDirection,
            stage: stage as RelationshipStage,
            label: metadata.label,
            pipelineType: metadata.pipelineType,
          }
        }
      }
    }
  }

  logger.warn(
    `No relationship configuration found for pipelineType: ${pipelineType}`
  )
  return null
}

export const getEndStateRelationshipByPipelineType = (pipelineType: string) => {
  const relationship = getRelationshipFromPipelineType(pipelineType)
  return {
    ...NativeRelationshipTypes[relationship?.type]?.[relationship?.direction]?.[
      RelationshipStages.Existing
    ],
    type: relationship?.type,
    direction: relationship?.direction,
    stage: RelationshipStages.Existing,
  }
}

export const ActiveActionStatuses = ['UNREAD', 'READ']

export const generateReferenceKey = (
  object: CRMObject,
  workspaceId?: string
) => {
  if (
    !object ||
    (!object.workspaceId && !workspaceId) ||
    !object.objectType ||
    !object.objectId
  ) {
    return null
  }
  return `${object.workspaceId || workspaceId} : ${object.objectType} : ${object.objectId}`
}

export enum ObjectReadPolicy {
  RECENCY = 'RECENCY',
  SOURCE = 'SOURCE',
}

export enum ObjectUserIdPolicy {
  FAVOR = 'FAVOR',
  EMPTY = 'EMPTY',
  EXCLUSIVE = 'EXCLUSIVE',
  ALL = 'ALL',
}

export enum ValidPipelineSetupStages {
  AWARENESS = 'AWARENESS',
  CONNECTION = 'CONNECTION',
  NEEDS_IDENTIFICATION = 'NEEDS_IDENTIFICATION',
  EVALUATION = 'EVALUATION',
  CONSIDERATION_NEGOTIATION = 'CONSIDERATION_NEGOTIATION',
  CLOSED_WON = 'CLOSED_WON',
  CLOSED_LOST = 'CLOSED_LOST',
}

export type PropertyTypeKey = keyof typeof PropertyTypes

export enum OrganizationObjectRootProperties {
  None = null,
  Root = '',
  Status = 'status',
  Relationship = 'relationship',
  Lifecycle = 'lifecycle',
  Purpose = 'purpose',
  Codes = 'codes',
  About = 'about',
  Headquarters = 'headquarters',
  Colors = 'colors',
  Photos = 'photos',
  Links = 'links',
  Legacy = 'legacy',
}

export enum OpportunityRoleTypes {
  DECISION_MAKER = 'DECISION_MAKER',
  PRIMARY_CONTACT = 'PRIMARY_CONTACT',
  CHAMPION = 'CHAMPION',
  SUPPORTER = 'SUPPORTER',
  DETRACTOR = 'DETRACTOR',
  OWNER_EMAIL = 'OWNER_EMAIL',
  HURDLE = 'HURDLE',
  ECONOMIC_BUYER = 'ECONOMIC_BUYER',
  OTHER = 'OTHER',
  IGNORE = 'IGNORE',
  DIRECT_BENEFIT = 'DIRECT_BENEFIT',
}

export const KeyOpportunityRoleTypes = [
  OpportunityRoleTypes.DECISION_MAKER,
  OpportunityRoleTypes.PRIMARY_CONTACT,
  OpportunityRoleTypes.CHAMPION,
  OpportunityRoleTypes.DETRACTOR,
  OpportunityRoleTypes.ECONOMIC_BUYER,
]

export const OpportunityRoleLabels = {
  [OpportunityRoleTypes.DECISION_MAKER]: 'Decision Maker',
  [OpportunityRoleTypes.PRIMARY_CONTACT]: 'Primary Contact',
  [OpportunityRoleTypes.CHAMPION]: 'Champion',
  [OpportunityRoleTypes.SUPPORTER]: 'Supporter',
  [OpportunityRoleTypes.DETRACTOR]: 'Skeptic',
  [OpportunityRoleTypes.OWNER_EMAIL]: 'Owner',
  [OpportunityRoleTypes.HURDLE]: 'Hurdle',
  [OpportunityRoleTypes.ECONOMIC_BUYER]: 'Economic Buyer',
  [OpportunityRoleTypes.OTHER]: 'Other',
  [OpportunityRoleTypes.IGNORE]: 'Ignore',
  [OpportunityRoleTypes.DIRECT_BENEFIT]: 'Benefits from deal',
}

export const getUserAuthorizations = ({
  currentUser,
  authorization,
  workspace,
}) => {
  const userMemberInfo = (workspace?.members || []).find(
    (member) => member.id === currentUser.id
  )
  const userRole = (workspace?.roles || []).find(
    (role) => role.id === userMemberInfo?.roleId
  ) || { permissions: [] }

  const userHasDirectOwnerOrFullAccess = !!(authorization?.users || []).find(
    (user) =>
      user.id === currentUser.id &&
      (
        [ObjectAccessLevels.Owner, ObjectAccessLevels.FullAccess] as string[]
      ).includes(user.accessLevel)
  )

  const userHasIndirectOwnerOrFullAccess =
    authorization?.workspace?.isShared &&
    userRole?.permissions?.some(
      (permission) =>
        permission.type === 'OBJECT_TYPE' &&
        permission.permission === 'fullAccess' &&
        permission.scope === '*'
    )

  const userHasOwnerOrFullAccess =
    userHasDirectOwnerOrFullAccess || userHasIndirectOwnerOrFullAccess

  const userHasDirectEditorAccess = !!(authorization?.users || []).find(
    (user) =>
      user.id === currentUser.id &&
      ([ObjectAccessLevels.Editor] as string[]).includes(user.accessLevel)
  )

  const userHasEditorAccess =
    userHasOwnerOrFullAccess || userHasDirectEditorAccess

  return {
    userHasOwnerOrFullAccess,
    userHasIndirectOwnerOrFullAccess,
    userHasEditorAccess,
  }
}
