export const getMeetingPlatform = (meetingJoinLink: string) => {
  if (!meetingJoinLink) {
    return null
  }
  if (meetingJoinLink?.toLowerCase().includes('zoom')) {
    return 'ZOOM'
  } else if (meetingJoinLink?.toLowerCase().includes('meet.google')) {
    return 'GOOGLE_MEET'
  } else {
    return 'MICROSOFT_TEAMS'
  }
}

export const formatMeetingJoinLink = ({
  meetingJoinLink,
  calendarEmail,
}: {
  meetingJoinLink: string
  calendarEmail: string
}) => {
  const meetingPlatform = getMeetingPlatform(meetingJoinLink)

  if (meetingPlatform === 'GOOGLE_MEET') {
    // By default, google will use the first logged in google account
    // in your account switcher dropdown to join a meeting, which
    // might not be the right google account. We can force it to use
    // the google account that is associated with the calendar event
    // via the authuser query parameter.
    return `${meetingJoinLink}?authuser=${encodeURIComponent(calendarEmail)}`
  } else {
    return meetingJoinLink
  }
}
