import { VIEW_FIELDS_FRAGMENT } from './fragments'

export const CREATE_VIEW_MUTATION = gql`
  mutation CreateViewMutation($input: CreateViewInput!) {
    createView(input: $input) {
      ...ViewFields
    }
  }
  ${VIEW_FIELDS_FRAGMENT}
`

export const UPDATE_VIEW_MUTATION = gql`
  mutation UpdateViewMutation($id: String!, $input: UpdateViewInput!) {
    updateView(id: $id, input: $input) {
      ...ViewFields
    }
  }
  ${VIEW_FIELDS_FRAGMENT}
`

export const DELETE_VIEW_MUTATION = gql`
  mutation DeleteViewMutation($id: String!, $workspaceId: String!) {
    deleteView(id: $id, workspaceId: $workspaceId) {
      id
    }
  }
`

export const SHARE_VIEW_MUTATION = gql`
  mutation ShareViewMutation(
    $id: String!
    $sharingTargetId: String!
    $sharingType: String!
  ) {
    shareView(
      id: $id
      sharingTargetId: $sharingTargetId
      sharingType: $sharingType
    ) {
      id
    }
  }
`

export const UPDATE_VIEW_PIN_MUTATION = gql`
  mutation UpdateViewPinMutation(
    $id: String!
    $workspaceId: String!
    $position: Int
    $objectType: String!
  ) {
    updateViewPin(
      id: $id
      workspaceId: $workspaceId
      position: $position
      objectType: $objectType
    )
  }
`
