import { useContext, useState } from 'react'

import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip,
} from '@mui/material'
import { RiFileCopyLine, RiShareForwardLine } from '@remixicon/react'
import { useConfirm } from 'material-ui-confirm'
import toast from 'react-hot-toast'

import { routes } from '@redwoodjs/router'
import { useMutation, useQuery } from '@redwoodjs/web'

import { DayContext } from 'src/lib/dayContext'
import { logger } from 'src/lib/logger'
import { computeFinalMetaState } from 'src/lib/meetingRecordingFormatting'

import SidebarButton from '../Sidebar/SidebarButton/SidebarButton'

const SHARE_MEETING_RECORDING_AS_PAGE = gql`
  mutation ShareMeetingRecordingAsPage(
    $meetingRecordingId: String!
    $workspaceId: String!
    $googleEventId: String
  ) {
    shareMeetingRecordingAsPage(
      meetingRecordingId: $meetingRecordingId
      workspaceId: $workspaceId
      googleEventId: $googleEventId
    ) {
      id
      createdAt
      updatedAt
      ownerEmail
      title
      publishedForUserAt
      madeExternalAt
      contentJson
      contentHtml
      workspaceId
      shortLinkHash
    }
  }
`

const WORKSPACE_SHARE_MEETING_RECORDING_AS_PAGE = gql`
  mutation WorkspaceShareMeetingRecordingAsPage(
    $meetingRecordingId: String!
    $workspaceId: String!
    $googleEventId: String
  ) {
    shareMeetingRecordingAsPage: workspaceShareMeetingRecordingAsPage(
      meetingRecordingId: $meetingRecordingId
      workspaceId: $workspaceId
      googleEventId: $googleEventId
    ) {
      id
      createdAt
      updatedAt
      ownerEmail
      title
      publishedForUserAt
      madeExternalAt
      contentJson
      contentHtml
      workspaceId
      shortLinkHash
    }
  }
`

const GET_MEETING_RECORDING_FOR_SHARE_ACTION = gql`
  query GetMeetingRecordingForShareAction($id: String!) {
    meetingRecording(id: $id) {
      statusHistory {
        status
        createdAt
        reason
        message
      }
    }
  }
`

const GET_WORKSPACE_MEETING_RECORDING_FOR_SHARE_ACTION = gql`
  query GetWorkspaceMeetingRecordingForShareAction(
    $workspaceId: String!
    $id: String!
  ) {
    meetingRecording: workspaceMeetingRecording(
      workspaceId: $workspaceId
      id: $id
    ) {
      statusHistory {
        status
        createdAt
        reason
        message
      }
    }
  }
`

const MeetingRecordingShareAsPage = ({
  event,
  meetingRecordingId,
  workspaceMode = false,
  useIcon = false,
  sx = {},
}) => {
  const { selectedWorkspace } = useContext(DayContext)
  const confirm = useConfirm()
  const [shareUrl, setShareUrl] = useState<string | null>()

  const { data: nonWorkspaceMeetingRecordingData } = useQuery(
    GET_MEETING_RECORDING_FOR_SHARE_ACTION,
    {
      variables: {
        id: meetingRecordingId,
      },
      skip: !(meetingRecordingId && !workspaceMode),
    }
  )

  const { data: workspaceMeetingRecordingData } = useQuery(
    GET_WORKSPACE_MEETING_RECORDING_FOR_SHARE_ACTION,
    {
      variables: {
        id: meetingRecordingId,
        workspaceId: selectedWorkspace,
      },
      skip: !(workspaceMode && meetingRecordingId),
    }
  )

  const meetingRecordingData = workspaceMode
    ? workspaceMeetingRecordingData
    : nonWorkspaceMeetingRecordingData

  const [
    nonWorkspaceShareMeetingRecordingAsPage,
    { data: nonWorkspaceShareMeetingRecordingAsPageData },
  ] = useMutation(SHARE_MEETING_RECORDING_AS_PAGE)

  const [
    workspaceShareMeetingRecordingAsPage,
    { data: workspaceShareMeetingRecordingAsPageData },
  ] = useMutation(WORKSPACE_SHARE_MEETING_RECORDING_AS_PAGE)

  const shareMeetingRecordingAsPage = workspaceMode
    ? workspaceShareMeetingRecordingAsPage
    : nonWorkspaceShareMeetingRecordingAsPage

  const shareMeetingRecordingAsPageData = workspaceMode
    ? workspaceShareMeetingRecordingAsPageData
    : nonWorkspaceShareMeetingRecordingAsPageData

  const currentStatus =
    meetingRecordingData?.meetingRecording?.statusHistory?.length > 0
      ? computeFinalMetaState({
          meetingRecording: meetingRecordingData?.meetingRecording,
        })
      : null

  //const meetingState = currentStatus ? statusMetadata[currentStatus] : null

  const handleShareMeetingAsPage = () => {
    confirm({
      title: 'Share meeting recording & notes',
      description:
        'The full contents of this recording will be made public, and you will receive a link that anyone with the link can access. You will find this page in your user folder in the left-hand navigation. Please note that you have the option to make the page private at any future time as per your preference.',
    })
      .then(() => {
        const sharePromise = shareMeetingRecordingAsPage({
          variables: {
            meetingRecordingId,
            workspaceId: selectedWorkspace,
            googleEventId: event?.id,
          },
          onCompleted: (result) => {
            const url = `${process.env.HOST}${routes.pageShortLink({
              shortLinkHash: result.shareMeetingRecordingAsPage.shortLinkHash,
            })}`
            setShareUrl(url)
          },
        })

        toast.promise(sharePromise, {
          loading: 'Sharing meeting recording...',
          success: 'Meeting recording shared successfully!',
          error: 'Failed to share meeting recording.',
        })
      })
      .catch(() => {
        logger.dev('User declined to share')
      })
  }

  return (
    currentStatus === 'READY' && (
      <>
        {useIcon ? (
          <Tooltip
            arrow={true}
            title="Quick share as Page"
          >
            <IconButton
              sx={{ ...sx, p: '4px', borderRadius: '2px', mr: '4px' }}
              disabled={!meetingRecordingId}
              onClick={handleShareMeetingAsPage}
            >
              <RiShareForwardLine size={20} />
            </IconButton>
          </Tooltip>
        ) : (
          <SidebarButton
            label={'Share recording'}
            icon={<RiShareForwardLine size={16} />}
            onClick={handleShareMeetingAsPage}
          />
        )}
        <Dialog
          open={!!shareUrl}
          onClose={() => setShareUrl(null)}
        >
          <DialogTitle>Share meeting recording & notes</DialogTitle>
          <DialogContent>
            <TextField
              value={shareUrl}
              fullWidth={true}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <RiFileCopyLine
                    style={{ marginLeft: '16px', cursor: 'pointer' }}
                    onClick={() => {
                      navigator.clipboard.writeText(shareUrl)
                      toast.success('Copied to clipboard')
                    }}
                  />
                ),
              }}
            />
            {shareMeetingRecordingAsPageData?.shareMeetingRecordingAsPage
              ?.id && (
              <Box
                sx={{
                  mt: 2,
                  textAlign: 'center',
                  '& a': {
                    color: (theme) => theme.palette.secondary.main,
                  },
                }}
              >
                or{' '}
                <a
                  href={routes.pageDetail({
                    id: shareMeetingRecordingAsPageData
                      ?.shareMeetingRecordingAsPage?.id,
                  })}
                >
                  {' '}
                  edit page
                </a>
              </Box>
            )}
          </DialogContent>
        </Dialog>
      </>
    )
  )
}

export default MeetingRecordingShareAsPage
