import ContextDebug from 'src/components/ContextDebug/ContextDebug'
import { usePage } from 'src/hooks/usePage/usePage'

const PageContextDebug = ({
  open,
  setOpen,
}: {
  open: boolean
  setOpen: (open: boolean) => void
}) => {
  const { contextString, tokenCount, handleGetPageContext, page, tokenLimit } =
    usePage()

  return (
    <ContextDebug
      label="Page Context"
      contextString={contextString}
      tokenCount={tokenCount}
      onRefetch={() => handleGetPageContext(page.id)}
      tokenLimit={tokenLimit}
      open={open}
      setOpen={setOpen}
    />
  )
}

export default PageContextDebug
