import { useContext } from 'react'

import { IconButton } from '@mui/material'
import { IconPencil } from '@tabler/icons-react'

import { DayContext } from 'src/lib/dayContext'
import { logger } from 'src/lib/logger'

const StandardPropertyViewMoreButton = ({
  currentValue,
  objectType,
  objectId,
  workspaceId,
  path,
}: {
  currentValue: string
  objectType: string
  objectId: string
  workspaceId: string
  path: string
}) => {
  const { setSidebarObject } = useContext(DayContext)

  const handleClick = () => {
    const propertyDetailObject = {
      objectType,
      objectId,
      workspaceId,
      properties: {
        propertyDefinition: {
          id: path,
          currentValue: {
            value: currentValue,
          },
        },
        workspaceId,
      },
      mode: 'examine',
    }
    logger.dev({ propertyDetailObject })
    setSidebarObject(propertyDetailObject)
  }

  return (
    <IconButton onClick={handleClick}>
      <IconPencil size={14} />
    </IconButton>
  )
}

export default StandardPropertyViewMoreButton
