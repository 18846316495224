import { useContext } from 'react'

import SearchModal from 'src/components/Search/SearchModal/SearchModal'
import { DayContext } from 'src/lib/dayContext'
import { logger } from 'src/lib/logger'
import { NativeObjectTypes } from 'src/lib/objects'

const TiptapBlockBySearch = ({ open, editor, onClose }) => {
  const { selectedWorkspace } = useContext(DayContext)

  const handleAddToPage = (searchResult) => {
    logger.dev('Search result for block:', searchResult)

    const block = {
      type: 'objectChip',
      attrs: {
        objectId: searchResult.objectId,
        objectType: searchResult.objectType,
        displayName: searchResult.label,
      },
    }
    logger.dev('Creating block with attrs:', block.attrs)
    const transaction = editor.state.tr.insert(
      editor.state.selection.from,
      editor.schema.nodeFromJSON(block)
    )
    editor.view.dispatch(transaction)

    const endPosition = transaction.selection.$anchor.end()
    editor.chain().focus().setTextSelection(endPosition).run()
    onClose()
  }

  return (
    selectedWorkspace && (
      <SearchModal
        open={open}
        onClose={onClose}
        onSelect={handleAddToPage}
        allowCreation={true}
        targetObjectType={NativeObjectTypes.Page}
        workspaceId={selectedWorkspace}
      />
    )
  )
}

export default TiptapBlockBySearch
