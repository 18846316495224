import { useState } from 'react'

import { Box, Chip, Typography } from '@mui/material'

import { dayjs } from 'src/lib/dayjs'

const OrgLastActivity = ({ organization, timeline }) => {
  const [expanded, setExpanded] = useState(false)

  const lastTimelineItem = React.useMemo(() => {
    const now = dayjs()
    return (
      timeline?.find(
        (item) => item?.timestamp && dayjs(item.timestamp).isBefore(now)
      ) ?? null
    )
  }, [timeline])

  const timeLabel =
    dayjs(lastTimelineItem?.timestamp).fromNow()?.charAt(0).toUpperCase() +
    dayjs(lastTimelineItem?.timestamp).fromNow()?.slice(1)

  let typeLabel
  switch (lastTimelineItem?.type) {
    case 'note':
      typeLabel = `${lastTimelineItem?.actor} added context: `
      break
    case 'meeting':
      typeLabel = `there was a meeting with ${organization?.name}`
      break
    case 'email':
      typeLabel = `there was an email with ${lastTimelineItem?.actor}`
      break
    case 'event':
      typeLabel = `there was an meeting with ${organization?.name}`
      break
    default:
      typeLabel = ''
  }

  return (
    <Box>
      <Typography>
        {expanded && lastTimelineItem?.expandedText
          ? lastTimelineItem?.expandedText
          : `${timeLabel || ''} ${typeLabel || ''} ${lastTimelineItem?.label || ''}`}
        {lastTimelineItem?.expandedText && (
          <Chip
            onClick={() => setExpanded((prev) => !prev)}
            label={expanded ? 'view less' : 'view more'}
            color="secondary"
            size="small"
            variant="outlined"
            sx={{ height: '14px', border: 'none', mt: '-2px' }}
          />
        )}
      </Typography>
    </Box>
  )
}

export default OrgLastActivity
