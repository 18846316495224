export const viewManagerContainerSx = {
  height: '32px',
  alignItems: 'flex-start',
  '& .view-manager-tabs-container': {
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    zIndex: 10,
    overflow: 'visible',
    position: 'relative',
    '& .MuiTabs-indicator': {
      display: 'none',
    },
    '& .view-tab': {
      '& .MuiTypography-root': {
        fontWeight: 600,
        '&.Mui-selected': {
          fontWeight: 600,
        },
      },
    },
  },
}

export const tabsContainerSx = {}

export const refreshButtonSx = {
  p: '4px',
  borderRadius: '3px',
}
