import { useContext, useMemo, useState } from 'react'

import ReactJson from '@microlink/react-json-view'
import { Box, IconButton, Tab, Tabs, Tooltip, Typography } from '@mui/material'
import { RiAdminLine } from '@remixicon/react'
import { IconBubbleText, IconCircleFilled } from '@tabler/icons-react'

import { routes } from '@redwoodjs/router'
import { useQuery } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import ActionCreateDialog from 'src/components/Actions/ActionCreateDialog/ActionCreateDialog'
import AiFeature from 'src/components/AiFeature/AiFeature'
import DomainAvatar from 'src/components/DomainAvatar/DomainAvatar'
import InteractionsTimeline from 'src/components/InteractionsTimeline/InteractionsTimeline'
import OrganizationActions from 'src/components/OrganizationActions/OrganizationActions'
import OrganizationPeople from 'src/components/OrganizationPeople/OrganizationPeople'
import { getKeyRoleEmailsWithFallback } from 'src/components/OrganizationPeople/organizationRoles'
import OrganizationLogoChooser from 'src/components/Organizations/OrganizationLogoChooser/OrganizationLogoChooser'
import OrganizationOneSentence from 'src/components/Organizations/OrganizationOneSentence/OrganizationOneSentence'
import { buildTimelineItems } from 'src/components/Organizations/organizations'
import OrganizationSocialLinks from 'src/components/Organizations/OrganizationSocialLinks/OrganizationSocialLinks'
import OrgLastActivity from 'src/components/OrgLastActivity/OrgLastActivity'
import OpportunityDomainChip from 'src/components/Pipeline/OpportunityDomainChip/OpportunityDomainChip'
import RelationshipOrgSidebar from 'src/components/Relationships/RelationshipOrgSidebar/RelationshipOrgSidebar'
import ThreadCreateButton from 'src/components/Threads/ThreadCreateButton/ThreadCreateButton'
import { extractEmailDomain } from 'src/lib/contactFormatting'
import { DayContext } from 'src/lib/dayContext'
import { isInternalUser } from 'src/lib/gates'
import { logger } from 'src/lib/logger'
import { deepMerge, NativeObjectTypes } from 'src/lib/objects'
import { modernTabStyles } from 'src/lib/style'

import Row from '../../Row/Row'
import SidebarCustomProperties from '../SidebarCustomProperties/SidebarCustomProperties'
import SidebarSlack from '../SidebarSlack/SidebarSlack'
import { actionButtonStyle, sidebarBodyTextStyle } from '../styles'

const GET_ORGANIZATION_FOR_ORG_SIDEBAR = gql`
  query GetOrganizationForOrgSidebar($workspaceId: String!, $orgId: String!) {
    workspaceOrganization(workspaceId: $workspaceId, domain: $orgId) {
      id
      name
      domain
      employeeCount
      customProperties
      about {
        id
        description
        aiDescription
        phoneNumbers
        isHiring
        industryType
        founded
        employeesFrom
        employeesTo
        selling
      }
      headquarters {
        id
        address
        city
        state
        country
        postalCode
        latitude
        longitude
      }
      purpose {
        id
        differentiators
        marketingPromises
        missionAndVision
      }
      people {
        id
        fullName
        email
        currentJobTitle
        photoUrl
      }
      roles {
        id
        name
        email
        role
        reasoning
      }
      opportunities {
        id
        workspaceId
        title
        pipelineId
        pipelineTitle
        stage
        updatedAt
      }
      pages {
        id
        title
      }
      photos {
        id
        square
      }
      relationship {
        id
        upcomingEvents
        quotes {
          personEmail
          text
          meetingId
        }
        oneSentenceSummary
        proofOfPayment
        sensitiveToWhom
        sensitiveReasoning
        warmth
        origin
        types {
          id
          label
          createdAt
          key
          value
          userId
        }
      }
      links {
        id
        facebook
        x
        instagram
        linkedIn
        website
        websiteResolvedUrl
      }
      lifecycle {
        id
        pipelineType
        stageType
      }
      updatedAt
    }
  }
`

const GET_ORGANIZATION_HISTORY_FOR_INTERACTIONS_TIMELINE = gql`
  query getOrganizationHistoryForInteractionsTimeline(
    $workspaceId: String!
    $orgId: String!
  ) {
    organizationHistory(orgId: $orgId, workspaceId: $workspaceId) {
      domain
      domainGmailThreadSummaries
      meetingRecordings
      events
      notes {
        id
        content
        createdAt
        context {
          id
          parentReferenceKey
          createdAt
          updatedAt
          userId
          workspaceId
          plainTextValue
          referencedObjectIds
        }
        person {
          firstName
          lastName
          email
          photo
        }
      }
    }
  }
`

const SidebarLayoutOrganization = ({
  domain,
  setOpen,
  workspaceId,
}: {
  domain: string
  setOpen: (open: boolean) => void
  workspaceId: string
}) => {
  const { currentUser } = useAuth()
  const { orgsByDomain, peopleByEmail, workspaces, setSidebarObject } =
    useContext(DayContext)

  const [adminDebug, setAdminDebug] = useState(null)
  const [tab, setTab] = useState('status')

  const domainPeople = useMemo(() => {
    return Object.entries(peopleByEmail)
      .map(([email, person]) => {
        if (extractEmailDomain(email) === domain) {
          return person
        }
      })
      .filter(Boolean)
  }, [peopleByEmail, domain])

  const {
    data,
    loading: loadingTimeline,
    refetch: refetchTimeline,
  } = useQuery(GET_ORGANIZATION_HISTORY_FOR_INTERACTIONS_TIMELINE, {
    variables: {
      orgId: domain,
      workspaceId,
    },
    skip: !workspaceId || !domain,
  })
  const timeline = useMemo(() => {
    if (data?.organizationHistory) {
      return buildTimelineItems(data?.organizationHistory)
    }
    return []
  }, [data])

  const { data: orgData, refetch } = useQuery(
    GET_ORGANIZATION_FOR_ORG_SIDEBAR,
    {
      variables: {
        workspaceId,
        orgId: domain,
      },
      skip: !workspaceId || !domain,
      pollInterval: process.env.HOST.includes('localhost') ? undefined : 5000,
    }
  )

  const organization = useMemo(() => {
    const merged = deepMerge(
      orgsByDomain[domain],
      orgData?.workspaceOrganization
    )
    return merged
  }, [orgData?.workspaceOrganization, orgsByDomain, domain])

  const emailsForThreadCreation = useMemo(() => {
    return getKeyRoleEmailsWithFallback({
      organization,
      workspaces,
      peopleByEmail,
    })
  }, [organization, peopleByEmail, workspaces])

  logger.dev('SidebarLayoutOrganization', {
    organization,
    emailsForThreadCreation,
  })

  return (
    <>
      <Row
        sx={{
          justifyContent: 'space-between',
          px: 3,
          pb: '4px',
          alignItems: 'flex-start',
        }}
      >
        <Row
          gap={2}
          sx={{ pt: 2 }}
        >
          <DomainAvatar
            domain={domain}
            size={52}
          />
          <Box sx={{ width: '410px', overflow: 'hidden' }}>
            <Row gap={1}>
              <Typography
                variant="h1"
                sx={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  flexShrink: 1,
                  fontSize: '1.5rem',
                }}
              >
                {organization?.name || organization?.domain || domain}
              </Typography>
              <OrganizationSocialLinks
                organization={organization}
                iconSize={10}
              />

              <AiFeature
                workspaceId={workspaceId}
                featureKey={NativeObjectTypes.Organization}
                objectId={organization?.domain || domain}
                variant="outlined"
                size={16}
                lastUpdated={organization?.updatedAt}
                sx={{ p: '6px' }}
              />
            </Row>

            <Typography>{organization?.domain || domain}</Typography>
          </Box>
        </Row>
        <Row
          sx={{ justifyContent: 'flex-end', pt: 2 }}
          gap={1}
        >
          {isInternalUser(currentUser) && (
            <Tooltip title="Debug organization">
              <IconButton
                onClick={() =>
                  setAdminDebug((prev) => {
                    if (!prev) {
                      return 'logo'
                    }
                    return null
                  })
                }
              >
                <RiAdminLine size={14} />
              </IconButton>
            </Tooltip>
          )}
          <ThreadCreateButton
            workspaceId={workspaceId}
            sourceObject={{
              objectType: NativeObjectTypes.Organization,
              objectId: organization?.domain || domain,
              properties: organization,
            }}
            otherObjects={emailsForThreadCreation.map((email) => ({
              objectType: NativeObjectTypes.Person,
              objectId: email,
              properties: {
                email,
              },
            }))}
            title={`${organization?.name || domain}`}
            onThreadCreated={() => {
              setOpen(false)
            }}
            variant="icon"
            disableElevation={false}
          />
          <Tooltip title={`Add context to ${organization?.name}`}>
            <IconButton
              onClick={() => {
                setSidebarObject({
                  mode: 'create',
                  objectType: NativeObjectTypes.UserContext,
                  objectId: 'CREATE',
                  workspaceId: workspaceId,
                  properties: {
                    parentReferenceKey: `${NativeObjectTypes.Organization} : ${organization.id} : root`,
                    title: organization.name,
                    onUpdate: () => {
                      refetch()
                    },
                  },
                })
              }}
              sx={actionButtonStyle}
              className={'disable-elevation'}
            >
              <IconBubbleText size={14} />
            </IconButton>
          </Tooltip>

          <ActionCreateDialog
            variant="icon"
            disableElevation={true}
            orgs={[domain]}
            people={[]}
            oppIds={[]}
            workspaceId={workspaceId}
            onUpdate={() => {
              refetch()
            }}
            key={'action-create-dialog-organization'}
          />
        </Row>
      </Row>

      {isInternalUser(currentUser) && adminDebug && (
        <Box sx={{ px: 3, mt: 3, ...modernTabStyles }}>
          <Tabs
            value={adminDebug}
            onChange={(_, value) => {
              if (value === 'edit') {
                logger.dev(value)
                window.open(
                  routes.adminOrganizationGlobal({
                    domain,
                  }),
                  '_blank'
                )
              } else {
                setAdminDebug(value)
              }
            }}
          >
            <Tab
              label="JSON"
              value="json"
            />
            <Tab
              label="Logo"
              value="logo"
            />
            <Tab
              label="Edit"
              value="edit"
            />
          </Tabs>
          {adminDebug === 'json' && (
            <ReactJson
              src={organization}
              collapsed={1}
            />
          )}
          {adminDebug === 'logo' && (
            <Box>
              <OrganizationLogoChooser domain={domain} />
            </Box>
          )}
        </Box>
      )}
      <Row sx={{ height: '64px', ...modernTabStyles, px: 3 }}>
        <Tabs
          value={tab}
          onChange={(_, value) => {
            setTab(value)
          }}
          TabIndicatorProps={{
            sx: {
              height: '0px',
            },
          }}
        >
          <Tab
            label="Status"
            value="status"
          />
          <Tab
            label="Timeline"
            value="timeline"
          />
          <Tab
            label="About"
            value="about"
          />
        </Tabs>
      </Row>
      <Box
        sx={{
          px: 3,
          height: 'calc(100vh - 128px - 64px - 40px)',
          overflowY: 'auto',
        }}
      >
        {tab === 'status' && (
          <>
            {organization?.relationship?.oneSentenceSummary ? (
              <Box
                sx={{
                  mt: 0,
                }}
              >
                <OrganizationOneSentence
                  org={organization}
                  workspaceId={workspaceId}
                />
              </Box>
            ) : (
              <Typography
                variant="h3"
                sx={{ mb: 2 }}
              >
                Status
              </Typography>
            )}
            {timeline?.length > 0 && (
              <Box
                sx={{
                  mt: 1,
                }}
              >
                <OrgLastActivity
                  organization={organization}
                  timeline={timeline}
                />
              </Box>
            )}

            <Box sx={{ mt: 5 }}>
              <RelationshipOrgSidebar
                org={organization}
                workspaceId={workspaceId}
              />
            </Box>

            {(organization?.roles?.length > 0 || domainPeople?.length > 0) && (
              <Box sx={{ mt: 3 }}>
                <OrganizationPeople
                  organization={organization}
                  people={domainPeople}
                  workspaceId={workspaceId}
                />
              </Box>
            )}
            <Box
              sx={{
                my: 5,
              }}
            >
              <Row
                sx={{
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                }}
              >
                <Typography
                  variant="h3"
                  sx={{ width: '172px' }}
                >
                  Opportunities
                </Typography>
                <OpportunityDomainChip
                  organization={organization}
                  openSidebar={true}
                />
              </Row>
            </Box>

            <SidebarSlack
              orgId={organization?.domain || domain}
              sx={{ mt: 3 }}
            />
            <SidebarCustomProperties
              workspaceId={workspaceId}
              customProperties={organization?.customProperties}
              objectType={NativeObjectTypes.Organization}
              objectId={organization?.domain || domain}
            />
            <Box sx={{ mt: 3 }}>
              <OrganizationActions orgId={organization?.domain || domain} />
            </Box>
          </>
        )}
        <Box sx={{ mt: 3, display: tab === 'timeline' ? 'block' : 'none' }}>
          <InteractionsTimeline
            showHeader={false}
            timeline={timeline}
            refetch={refetchTimeline}
            loading={loadingTimeline}
          />
        </Box>
        {tab === 'about' && (
          <Box sx={{ '& h3': { mb: 1 } }}>
            {(organization?.about?.aiDescription ||
              organization?.about?.description) && (
              <Box sx={{ mt: 1 }}>
                <Typography variant="h3">About</Typography>
                <Typography sx={sidebarBodyTextStyle}>
                  {organization?.about?.aiDescription ||
                    organization?.about?.description}
                </Typography>
              </Box>
            )}
            {organization?.purpose?.missionAndVision && (
              <Box sx={{ mt: 3 }}>
                <Typography variant="h3">Mission & Vision</Typography>
                <Typography sx={sidebarBodyTextStyle}>
                  {organization?.purpose?.missionAndVision}
                </Typography>
              </Box>
            )}
            {organization?.purpose?.values && (
              <Box sx={{ mt: 3 }}>
                <Typography variant="h3">Values</Typography>
                {organization?.purpose?.values.map((value, index) => (
                  <Row
                    key={`${value}-${index}-${organization?.domain}`}
                    gap={'12px'}
                    sx={{ mb: 2 }}
                  >
                    <IconCircleFilled size={8} />
                    <Typography sx={sidebarBodyTextStyle}>{value}</Typography>
                  </Row>
                ))}
              </Box>
            )}
            {organization?.purpose?.differentiators && (
              <Box sx={{ mt: 3 }}>
                <Typography variant="h3">Differentiators</Typography>
                {organization?.purpose?.differentiators.map(
                  (differentiator, index) => (
                    <Row
                      key={`${differentiator}-${index}-${organization?.domain}`}
                      gap={'12px'}
                      sx={{ mb: 1 }}
                    >
                      <IconCircleFilled size={8} />
                      <Typography sx={sidebarBodyTextStyle}>
                        {differentiator}
                      </Typography>
                    </Row>
                  )
                )}
              </Box>
            )}
            {organization?.purpose?.marketingPromises && (
              <Box sx={{ mt: 3 }}>
                <Typography variant="h3">Marketing Promises</Typography>
                {organization?.purpose?.marketingPromises.map(
                  (promise, index) => (
                    <Row
                      key={`${promise}-${index}-${organization?.domain}`}
                      gap={'12px'}
                      sx={{ mb: 1 }}
                    >
                      <IconCircleFilled size={8} />
                      <Typography sx={sidebarBodyTextStyle}>
                        {promise}
                      </Typography>
                    </Row>
                  )
                )}
              </Box>
            )}
            {organization?.headquarters?.address && (
              <Box sx={{ mt: 3 }}>
                <Typography variant="h3">Headquarters</Typography>
                {['address', 'city', 'state', 'country', 'postalCode'].map(
                  (key) => (
                    <Typography
                      sx={sidebarBodyTextStyle}
                      key={`${key}-${organization?.domain}`}
                    >
                      {organization?.headquarters?.[key]}
                    </Typography>
                  )
                )}
              </Box>
            )}
            {organization?.about?.isHiring && (
              <Box sx={{ mt: 3 }}>
                <Typography variant="h3">Growth</Typography>
                <Typography sx={sidebarBodyTextStyle}>
                  {organization?.about?.isHiring
                    ? `${organization?.name} is hiring! Day.ai Assistant has uncovered evidence that this organization is hiring.`
                    : `Day.ai Assistant has not uncovered evidence that ${organization?.name} is hiring, though it is possible that they are.`}
                </Typography>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </>
  )
}

export default SidebarLayoutOrganization
