import { Chip, lighten, Tooltip } from '@mui/material'
import { IconCircleFilled } from '@tabler/icons-react'

import { dayjs } from 'src/lib/dayjs'

const UpcomingMeetingChip = ({ upcomingEvent, prefix = '' }) => {
  if (!upcomingEvent || !upcomingEvent.startTimestamp) {
    return null
  }

  const startTime = dayjs(upcomingEvent.startTimestamp * 1000)
  const startIsInFuture = startTime.isAfter(dayjs())
  const title = upcomingEvent.title
  const minutesUntil = dayjs().diff(startTime, 'minutes') * -1

  let color, variant
  if (minutesUntil <= 60 * 4) {
    color = 'success'
  } else if (minutesUntil <= 60 * 24) {
    color = 'info'
  } else if (minutesUntil <= 60 * 24 * 7) {
    color = 'warning'
  } else {
    color = 'primary'
  }
  return (
    startTime &&
    startIsInFuture && (
      <Tooltip
        title={`"${title}" ${dayjs(startTime).format('M/D/YY hh:mm A')}`}
        arrow={true}
      >
        <Chip
          size="small"
          icon={<IconCircleFilled />}
          sx={{
            border: 'none',
            fontWeight: 600,
            background: (theme) =>
              variant === 'filled'
                ? lighten(theme.palette[color].main, 0.7)
                : 'transparent',
            '& .MuiChip-icon': {
              mt: '-1px',
              color: (theme) => theme.palette[color].dark,
              height: '8px',
              width: '8px',
              flexShrink: 0,
            },
            justifyContent: 'flex-start',
            width: '100%',
          }}
          variant={'outlined'}
          label={startTime ? `${prefix}${dayjs(startTime).fromNow()}` : null}
        />
      </Tooltip>
    )
  )
}

export default UpcomingMeetingChip
