import { useState } from 'react'

import { Box, Button, Tooltip } from '@mui/material'
import {
  IconBold,
  IconH1,
  IconH2,
  IconH3,
  IconIndentDecrease,
  IconIndentIncrease,
  IconItalic,
  IconLink,
  IconList,
  IconListCheck,
  IconListNumbers,
  IconSeparator,
  IconQuote,
  IconStrikethrough,
} from '@tabler/icons-react'
import { BubbleMenu, isTextSelection } from '@tiptap/react'

import TiptapLinkEditor from 'src/components/Tiptap/TiptapLinkEditor/TiptapLinkEditor'

import { tiptapBubbleSx, tiptapButtonSx } from '../styles'
import TiptapTableActions from '../TiptapTableActions/TiptapTableActions'

const tooltipDirection = 'top'

const TiptapBubbleMenu = ({ editor }) => {
  const [selectedText, setSelectedText] = useState<boolean>(false)
  const [contextMenuTriggered] = useState<boolean>(false)
  const [showLinkChooser, setShowLinkChooser] = useState<boolean>(false)
  const [inTable, setInTable] = useState<boolean>(false)

  const handleChooseLink = () => {
    setShowLinkChooser(true)
  }

  const closeLinkChooser = () => {
    setShowLinkChooser(false)
  }

  return (
    editor && (
      <BubbleMenu
        className="bubble-menu"
        tippyOptions={{ duration: 100, zIndex: 10 }}
        editor={editor}
        shouldShow={({ editor, view, state, from, to }) => {
          if (contextMenuTriggered) return true

          const textSelectedDetector = () => {
            if (!view.hasFocus()) return false
            const { doc, selection } = state
            const isText = isTextSelection(selection)
            if (!isText) return false
            const isEmpty =
              selection.empty ||
              (isText && doc.textBetween(from, to).length === 0)
            if (isEmpty) return false
            if (editor.isActive('day-ai-person')) return false
            return true
          }

          const textSelected = textSelectedDetector()
          setSelectedText(textSelected)
          const isInTable = editor.isActive('day-ai-table-text')
          setInTable(isInTable)
          const shouldShow = textSelected //|| isInTable
          return shouldShow
        }}
      >
        {!showLinkChooser ? (
          <Box sx={{ ...tiptapBubbleSx }}>
            <Box>
              <Tooltip
                title="Write an H1 heading"
                placement={tooltipDirection}
                arrow={true}
              >
                <Button
                  sx={tiptapButtonSx}
                  size="small"
                  onClick={() => {
                    editor.chain().focus().toggleHeading({ level: 1 }).run()
                  }}
                  className={
                    editor.isActive('heading', { level: 1 }) ? 'is-active' : ''
                  }
                >
                  <IconH1 size={16} />
                </Button>
              </Tooltip>

              <Tooltip
                title="Write an H2 heading"
                placement={tooltipDirection}
                arrow={true}
              >
                <Button
                  sx={tiptapButtonSx}
                  size="small"
                  onClick={() =>
                    editor.chain().focus().toggleHeading({ level: 2 }).run()
                  }
                  className={
                    editor.isActive('heading', { level: 2 }) ? 'is-active' : ''
                  }
                >
                  <IconH2 size={16} />
                </Button>
              </Tooltip>

              <Tooltip
                title="Write an H3 heading"
                placement={tooltipDirection}
                arrow={true}
              >
                <Button
                  sx={tiptapButtonSx}
                  size="small"
                  onClick={() =>
                    editor.chain().focus().toggleHeading({ level: 3 }).run()
                  }
                  className={
                    editor.isActive('heading', { level: 3 }) ? 'is-active' : ''
                  }
                >
                  <IconH3 size={16} />
                </Button>
              </Tooltip>
              <Button
                sx={{
                  ...tiptapButtonSx,
                  display: selectedText ? 'inline-flex' : 'none',
                }}
                size="small"
                onClick={() => editor.chain().focus().toggleBold().run()}
                className={editor.isActive('bold') ? 'is-active' : ''}
              >
                <IconBold size={16} />
              </Button>
              <Button
                sx={{
                  ...tiptapButtonSx,
                  display: selectedText ? 'inline-flex' : 'none',
                }}
                size="small"
                onClick={() => editor.chain().focus().toggleItalic().run()}
                className={editor.isActive('italic') ? 'is-active' : ''}
              >
                <IconItalic size={16} />
              </Button>
              <Button
                sx={{
                  ...tiptapButtonSx,
                  display: selectedText ? 'inline-flex' : 'none',
                }}
                size="small"
                onClick={() => editor.chain().focus().toggleStrike().run()}
                className={editor.isActive('strike') ? 'is-active' : ''}
              >
                <IconStrikethrough size={16} />
              </Button>
              <Button
                sx={{
                  ...tiptapButtonSx,
                  display: selectedText ? 'inline-flex' : 'none',
                }}
                size="small"
                onClick={
                  editor.isActive('link')
                    ? () => editor.chain().focus().unsetLink().run()
                    : handleChooseLink
                }
                className={editor.isActive('link') ? 'is-active' : ''}
              >
                <IconLink size={16} />
              </Button>
              <Button
                sx={{
                  ...tiptapButtonSx,
                  display: inTable ? 'none' : 'inline-flex',
                }}
                size="small"
                onClick={() => editor.chain().focus().toggleTaskList().run()}
                className={editor.isActive('taskList') ? 'is-active' : ''}
              >
                <IconListCheck size={16} />
              </Button>
              <Button
                sx={{
                  ...tiptapButtonSx,
                  display: inTable ? 'none' : 'inline-flex',
                }}
                size="small"
                onClick={() => editor.chain().focus().toggleBulletList().run()}
                className={editor.isActive('bulletList') ? 'is-active' : ''}
              >
                <IconList size={16} />
              </Button>
              <Button
                sx={{
                  ...tiptapButtonSx,
                  display: inTable ? 'none' : 'inline-flex',
                }}
                size="small"
                onClick={() => editor.chain().focus().toggleOrderedList().run()}
                className={editor.isActive('orderedList') ? 'is-active' : ''}
              >
                <IconListNumbers size={16} />
              </Button>
              <Button
                sx={{
                  ...tiptapButtonSx,
                  display: inTable ? 'none' : 'inline-flex',
                }}
                size="small"
                onClick={() => editor.chain().focus().toggleBlockquote().run()}
                className={editor.isActive('blockquote') ? 'is-active' : ''}
              >
                <IconQuote size={16} />
              </Button>
              <Button
                sx={{
                  ...tiptapButtonSx,
                  display: inTable ? 'none' : 'inline-flex',
                }}
                size="small"
                onClick={() => editor.chain().focus().setHorizontalRule().run()}
              >
                <IconSeparator size={16} />
              </Button>
              {inTable && <TiptapTableActions editor={editor} />}

              {editor.isActive('orderedList') ||
                (editor.isActive('bulletList') && (
                  <>
                    <Button
                      sx={tiptapButtonSx}
                      size="small"
                      onClick={() =>
                        editor.chain().focus().sinkListItem('listItem').run()
                      }
                      disabled={!editor.can().sinkListItem('listItem')}
                    >
                      <IconIndentIncrease size={16} />
                    </Button>
                    <Button
                      sx={tiptapButtonSx}
                      size="small"
                      onClick={() =>
                        editor.chain().focus().liftListItem('listItem').run()
                      }
                      disabled={!editor.can().liftListItem('listItem')}
                    >
                      <IconIndentDecrease size={16} />
                    </Button>
                  </>
                ))}
            </Box>
          </Box>
        ) : (
          <TiptapLinkEditor
            editor={editor}
            setFocus={(_) => closeLinkChooser()}
          />
        )}
      </BubbleMenu>
    )
  )
}

export default TiptapBubbleMenu
