import { useContext, useEffect, useMemo, useRef, useState } from 'react'

import { Box, Chip, Tooltip, Typography } from '@mui/material'
import { IconCircleFilled } from '@tabler/icons-react'
import type { Organization, OrganizationRole, Person } from 'types/graphql'

import { DayContext } from 'src/lib/dayContext'
import { NativeObjectTypes } from 'src/lib/objects'

import MultiObjectChip from '../Chips/MultiObjectChip/MultiObjectChip'
import ObjectTile from '../ObjectTile/ObjectTile'
import Row from '../Row/Row'
import { actionButtonStyle } from '../Sidebar/styles'

import possibleRoles from './organizationRoles'

const Role = ({
  role,
  workspaceId,
}: {
  role: OrganizationRole
  workspaceId: string
}) => {
  const { selectedWorkspace, workspaces, setSidebarObject } =
    useContext(DayContext)

  const currentWorkspace = workspaces?.find((w) => w.id === selectedWorkspace)

  const benefitLabel = ` from ${currentWorkspace?.name}`

  const chipRoles = Array.from(new Set(role.role))
    .map((r) => possibleRoles.find((pr) => pr.key === r && pr.showChip))
    .filter(Boolean)

  return (
    <Tooltip
      title={role.reasoning}
      arrow={true}
      placement="left"
    >
      <div>
        <Row sx={{ justifyContent: 'space-between', my: 3 }}>
          <Box sx={{ width: '292px' }}>
            <ObjectTile
              objectType={NativeObjectTypes.Person}
              objectId={role.email}
              workspaceId={workspaceId}
              size={32}
              onClick={() => {
                setSidebarObject({
                  objectType: NativeObjectTypes.Person,
                  objectId: role.email,
                  properties: {
                    email: role.email,
                    fullName: role.name,
                  },
                })
              }}
            />
          </Box>
          <Row gap={1}>
            {chipRoles.map((roleToShow) => (
              <Chip
                key={roleToShow.key}
                label={`${roleToShow.label}${roleToShow.key === 'DIRECT_BENEFIT' ? benefitLabel : ''}`}
                variant="outlined"
                size="small"
                icon={<IconCircleFilled />}
                sx={{
                  border: 'none',
                  justifyContent: 'flex-start',
                  '& .MuiChip-icon': {
                    width: 8,
                    height: 8,
                    flexShrink: 0,
                    mr: 0,
                    color: (theme) => theme.palette[roleToShow.color].main,
                  },
                }}
              />
            ))}
          </Row>
        </Row>
      </div>
    </Tooltip>
  )
}

const OrganizationPeople = ({
  organization,
  people,
  showHeader = false,
  workspaceId,
}: {
  organization: Organization
  people: Person[]
  showHeader?: boolean
  workspaceId: string
}) => {
  const { workspaces, setSidebarObject } = useContext(DayContext)
  const [showAllPeople, setShowAllPeople] = useState(false)
  const userHasHiddenPeople = useRef(false)
  const workspaceMemberEmails = workspaces
    ?.map((workspace) =>
      (workspace.members || []).map((member) => member?.email)
    )
    .flat()
    .filter(Boolean)

  const rolesToShow = possibleRoles
    .map((pr) => {
      if (pr.showInList) {
        return pr.key
      }
    })
    .filter(Boolean)

  const roles = organization?.roles?.filter((role) => {
    const roleMatch = role.role.some((r) => {
      return rolesToShow.includes(r)
    })

    const isInternal = (workspaceMemberEmails || []).includes(role.email)
    const isDomain = role.email.endsWith(organization?.domain)

    return roleMatch && !isInternal && isDomain
  })

  const roleEmails = useMemo(() => {
    return roles?.map((role) => role.email)
  }, [roles])

  const morePeopleObjects = useMemo(() => {
    return people
      .map((person) =>
        person.email !== roleEmails?.find((email) => email === person.email)
          ? {
              properties: person,
              objectType: NativeObjectTypes.Person,
              objectId: person.email,
            }
          : null
      )
      .filter(Boolean)
  }, [people, roleEmails])

  useEffect(() => {
    if (
      (!(organization?.roles?.length > 0) ||
        rolesToShow?.length === 0 ||
        morePeopleObjects?.length === 1) &&
      !userHasHiddenPeople.current
    ) {
      setShowAllPeople(true)
    } else {
      userHasHiddenPeople.current = true
    }
  }, [rolesToShow, organization, people, morePeopleObjects])

  return roles?.length > 0 || people?.length > 0 ? (
    <Box>
      {showHeader && <Typography variant="h2">Key People</Typography>}
      {organization.relationship?.origin && (
        <Typography
          variant="body1"
          sx={{ my: 2 }}
        >
          {organization.relationship?.origin}
        </Typography>
      )}
      <Box>
        {roles?.map((role, index) => (
          <Role
            key={`key-person-${role.email}-${index}`}
            role={role}
            workspaceId={workspaceId}
          />
        ))}
      </Box>
      {people?.length > 0 && (
        <Box>
          {showAllPeople ? (
            <>
              <Row
                sx={{ my: 3 }}
                gap={2}
              >
                <Typography variant="h3">All People</Typography>
                <Chip
                  label="Hide"
                  onClick={() => {
                    setShowAllPeople(false)
                    userHasHiddenPeople.current = true
                  }}
                  size="small"
                  variant="outlined"
                  sx={actionButtonStyle}
                />
              </Row>
              {morePeopleObjects?.map((person) => (
                <Box
                  key={person.properties.email}
                  sx={{ my: 2 }}
                >
                  <ObjectTile
                    objectType={NativeObjectTypes.Person}
                    objectId={person.objectId}
                    workspaceId={workspaceId}
                    size={32}
                    onClick={() => {
                      setSidebarObject({
                        objectType: NativeObjectTypes.Person,
                        objectId: person.objectId,
                        properties: person,
                      })
                    }}
                  />
                </Box>
              ))}
            </>
          ) : morePeopleObjects?.length > 1 ? (
            <MultiObjectChip
              crmObjects={morePeopleObjects}
              workspaceId={workspaceId}
              label={`View${
                Array.isArray(people) &&
                Array.isArray(roles) &&
                people.length > roles.length
                  ? ' ' + (people.length - roles?.length)
                  : ''
              } more people`}
              onClick={() => setShowAllPeople(true)}
            />
          ) : null}
        </Box>
      )}
    </Box>
  ) : null
}

export default OrganizationPeople
