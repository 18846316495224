import { useContext, useEffect, useState } from 'react'

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  TextField,
} from '@mui/material'
import toast from 'react-hot-toast'
import type { WorkspaceRole, InviteWorkspaceMember } from 'types/graphql'

import { navigate, routes } from '@redwoodjs/router'
import { useMutation } from '@redwoodjs/web'

import { isValidEmail } from 'src/lib/contactFormatting'
import { DayContext } from 'src/lib/dayContext'

import WorkspaceMemberTile from '../WorkspaceMemberTile'
import WorkspaceRoleDropdown from '../WorkspaceRoleDropdown/WorkspaceRoleDropdown'

const INVITE_MEMBER = gql`
  mutation InviteWorkspaceMember($input: InviteWorkspaceMemberInput!) {
    inviteWorkspaceMember(input: $input) {
      id
      email
      status
      roleId
      isDefaultOwner
    }
  }
`

const InviteMemberDialog = ({
  title,
  prefilledEmail,
  workspaceId,
  allRoles,
  open,
  onClose,
}: {
  title: string
  prefilledEmail?: string
  workspaceId: string
  allRoles: WorkspaceRole[]
  open: boolean
  onClose: () => any
}) => {
  const { refetchWorkspaces } = useContext(DayContext)
  const [inviteMember] = useMutation<InviteWorkspaceMember>(INVITE_MEMBER)
  const [savingInvite, setSavingInvite] = useState(false)

  const [formEmail, setFormEmail] = useState('')
  const [selectedRole, setSelectedRole] = useState(allRoles[0])

  useEffect(() => {
    setSelectedRole(allRoles[0])
  }, [allRoles])

  const email = prefilledEmail || formEmail
  const isEmailValid = isValidEmail(email)

  return (
    <Dialog
      TransitionProps={{
        onExited: () => {
          setFormEmail('')
          setSelectedRole(allRoles[0])
          setSavingInvite(false)
        },
      }}
      open={open}
      onClose={() => {
        onClose()
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={{ width: '500px' }}>
        {prefilledEmail ? (
          <Box sx={{ mb: 2 }}>
            <WorkspaceMemberTile
              workspaceId={workspaceId}
              email={prefilledEmail}
            />
          </Box>
        ) : (
          <TextField
            margin="dense"
            label="Email Address"
            type="email"
            fullWidth
            value={email}
            onChange={(event) => {
              setFormEmail(event.target.value)
            }}
            disabled={savingInvite}
            error={!isEmailValid && email !== ''}
            helperText={
              !isEmailValid && email !== '' ? 'Please enter a valid email.' : ''
            }
          />
        )}
        <FormGroup sx={{ mt: 1 }}>
          <WorkspaceRoleDropdown
            allRoles={allRoles}
            currentRole={selectedRole}
            disabled={savingInvite}
            onSelectRole={(newRole) => {
              setSelectedRole(allRoles.find(({ id }) => id === newRole))
            }}
          />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose()
          }}
          disabled={savingInvite}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          onClick={async () => {
            setSavingInvite(true)

            try {
              await toast.promise(
                inviteMember({
                  variables: {
                    input: {
                      workspaceId,
                      email,
                      roleId: selectedRole.id,
                    },
                  },
                }).then(() => refetchWorkspaces()),
                {
                  loading: 'Inviting team member...',
                  success: 'Invited',
                  error: 'Error inviting team member',
                }
              )
            } catch (error) {
              /* empty */
            }

            navigate(
              routes.workspaceSettings({ id: workspaceId, tab: 'members' })
            )

            onClose()
          }}
          color="primary"
          variant="contained"
          disabled={savingInvite || !isEmailValid}
        >
          Invite
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default InviteMemberDialog
