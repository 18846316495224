import { createContext, type Dispatch } from 'react'

import { IconLoader, IconNote } from '@tabler/icons-react'
import type { CRMObject, Page, Thread } from 'types/graphql'
import type { ThreadMessage } from 'types/graphql'

export const LoadingMessages = {
  CREATING_THREAD: {
    label: 'Creating thread...',
    icon: IconLoader,
  },
  ADDING_MESSAGE: {
    label: 'Adding message...',
    icon: IconLoader,
  },
  CREATING_PAGE: {
    label: 'Creating page...',
    icon: IconNote,
  },
  AI_THINKING: {
    label: 'AI is thinking...',
    icon: IconLoader,
  },
}

export const THREAD_ACTIONS = {
  SET_THREAD: 'SET_THREAD',
  ADD_MESSAGE: 'ADD_MESSAGE',
  SET_LOADING_MESSAGE: 'SET_LOADING_MESSAGE',
  SET_ERROR_MESSAGE: 'SET_ERROR_MESSAGE',
  SET_CONTEXT_STATUS: 'SET_CONTEXT_STATUS',
  SET_CONTEXT_STRING: 'SET_CONTEXT_STRING',
  SET_CONTEXT_OBJECTS: 'SET_CONTEXT_OBJECTS',
  UPDATE_THREAD: 'UPDATE_THREAD',
  DELETE_THREAD: 'DELETE_THREAD',
  SET_CURRENT_THREAD: 'SET_CURRENT_THREAD',
  SET_THREADS: 'SET_THREADS',
  SET_THREAD_ERROR: 'SET_THREAD_ERROR',
  GENERATE_ARTIFACT: 'GENERATE_ARTIFACT',
  SET_PENDING_USER_PROMPT: 'SET_PENDING_USER_PROMPT',
  SET_PENDING_AI_PROMPT: 'SET_PENDING_AI_PROMPT',
  SET_TOKEN: 'SET_TOKEN',
  RESET_THREAD: 'RESET_THREAD',
  SET_THREAD_TITLE: 'SET_THREAD_TITLE',
} as const

export enum ContextStatus {
  LOADING = 'loading',
  ERROR_CAPACITY = 'error_capacity',
  ERROR_UNKNOWN = 'error_unknown',
  SUCCESS = 'loaded',
}

interface ThreadState {
  id: string
  workspaceId: string
  thread: Thread | null
  threads: Thread[]
  messages: ThreadMessage[]
  pendingUserPrompt: string
  pendingAiPrompt: string
  loadingMessage: {
    label: string
    icon: typeof IconLoader
  }
  errorMessage: string
  contextStatus: ContextStatus
  pages: Page[]

  contextString: string
  token: string
  tokenCount: number
  tokenLimit: number
}

type ThreadAction =
  | {
      type: 'SET_THREAD'
      payload: Thread
    }
  | {
      type: 'ADD_MESSAGE'
      payload: ThreadMessage
    }
  | {
      type: 'SET_CONTEXT_OBJECTS'
      payload: {
        contextObjects?: Partial<CRMObject>[]
        dismissedObjects?: Partial<CRMObject>[]
      }
    }
  | {
      type: 'SET_CONTEXT_STATUS'
      payload: ContextStatus
    }
  | {
      type: 'SET_CONTEXT_STRING'
      payload: string
    }
  | {
      type: 'SET_THREAD_ERROR'
      payload: string
    }
  | {
      type: 'SET_THREAD_MESSAGES'
      payload: ThreadMessage[]
    }
  | {
      type: 'SET_THREADS'
      payload: Thread[]
    }
  | {
      type: 'SET_PENDING_USER_PROMPT'
      payload: string
    }
  | {
      type: 'SET_PENDING_AI_PROMPT'
      payload: string
    }
  | {
      type: 'GENERATE_ARTIFACT'
      payload: ThreadMessage
    }
  | {
      type: 'SET_LOADING_MESSAGE'
      payload: {
        label: string
        icon: typeof IconLoader
      }
    }
  | {
      type: 'SET_TOKEN_COUNT'
      payload: number
    }
  | {
      type: 'SET_TOKEN'
      payload: string
    }
  | {
      type: 'RESET_THREAD'
    }
  | {
      type: 'SET_THREAD_TITLE'
      payload: string
    }

export type ThreadContextValue = {
  // State properties
  state: ThreadState

  // Action methods
  dispatch: Dispatch<ThreadAction>

  // Fetch methods
  fetchContext: () => void

  // Update methods
  updateContextObjects: ({
    contextObjects,
    dismissedObjects,
  }: {
    contextObjects: Partial<CRMObject>[]
    dismissedObjects: Partial<CRMObject>[]
  }) => void
  updateTitle: (title: string) => void

  // AI methods
  handleAiResponse: (response: string) => void
}

const ThreadContext = createContext<ThreadContextValue | null>(null)

export default ThreadContext
