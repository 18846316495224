import { PAGE_FIELDS_FRAGMENT } from 'src/components/Pages/fragments'

export const CREATE_ACTION_DRAFT = gql`
  mutation CreateActionDraft($input: CreateActionDraftInput!) {
    createActionDraft(input: $input) {
      ...PageFields
    }
  }
  ${PAGE_FIELDS_FRAGMENT}
`
