import { useEffect, useRef } from 'react'

import { Box, IconButton } from '@mui/material'
import { IconX } from '@tabler/icons-react'

import Row from 'src/components/Row/Row'
import { logger } from 'src/lib/logger'

import History from './History'
import PropertyDetail from './PropertyDetail'
import { SidebarMode } from './SidebarContext'
import SidebarModeConsole from './SidebarModeConsole'
import SidebarModeCreate from './SidebarModeCreate'
import SidebarModeEdit from './SidebarModeEdit'
import SidebarModeView from './SidebarModeView'
import { sidebarSx } from './style'
import useSidebar from './useSidebar'

const ObjectSidebar = () => {
  const { object: _object, mode, closeSidebar } = useSidebar()

  const sidebarRef = useRef<HTMLDivElement>(null)

  // Close sidebar when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!open || !sidebarRef.current) return

      const dialogContainer = document.querySelector('.MuiDialog-container')
      if (dialogContainer) {
        return // No-op when dialog is present
      }

      const sidebarRect = sidebarRef.current.getBoundingClientRect()
      const clickX = event.clientX

      // If click is to the left of the sidebar
      if (clickX < sidebarRect.left) {
        closeSidebar()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [closeSidebar])

  logger.dev({ object: _object, mode })

  return (
    <Box
      ref={sidebarRef}
      sx={sidebarSx}
    >
      <Box className="sidebar-outer">
        <Row className="sidebar-navigation">
          <History />
          <Row gap={1}>
            <IconButton
              sx={{
                height: '32px',
                width: '32px',
                mr: '3px',
                color: 'inherit',
                opacity: 1,
              }}
              onClick={() => closeSidebar()}
            >
              <IconX
                size={24}
                style={{
                  flexShrink: 0,
                }}
              />
            </IconButton>
          </Row>
        </Row>
        <Box className="sidebar-content">
          {mode === SidebarMode.CREATE ? (
            <SidebarModeCreate />
          ) : mode === SidebarMode.EDIT ? (
            <SidebarModeEdit />
          ) : mode === SidebarMode.PROPERTY ? (
            <PropertyDetail />
          ) : mode === SidebarMode.VIEW ? (
            <SidebarModeView />
          ) : mode === SidebarMode.CONSOLE ? (
            <SidebarModeConsole />
          ) : null}
        </Box>
      </Box>
    </Box>
  )
}

export default ObjectSidebar
