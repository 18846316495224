import { Chip } from '@mui/material'
import { RiBardLine } from '@remixicon/react'
import { useConfirm } from 'material-ui-confirm'
import toast from 'react-hot-toast'

import { useMutation } from '@redwoodjs/web'

import { actionButtonStyle } from 'src/components/Sidebar/styles'

const REQUEST_PERSON_ENRICHMENT_FROM_BUTTON = gql`
  mutation RequestPersonEnrichmentFromButton($email: String!) {
    requestPersonEnrichment(email: $email)
  }
`
const PersonEnrichmentButton = ({ email, label = 'Enrich' }) => {
  const confirm = useConfirm()

  const [requestPersonEnrichment] = useMutation(
    REQUEST_PERSON_ENRICHMENT_FROM_BUTTON,
    {
      variables: { email },
    }
  )

  const handleRequestPersonEnrichment = async () => {
    try {
      await confirm({
        title: 'Enrich Person',
        description:
          'Send Day.ai to research and verify this person. The results will appear automatically when complete.',
      })
      toast.promise(requestPersonEnrichment(), {
        loading: 'Requesting person enrichment...',
        success: 'Request underway!',
        error: 'Error requesting person enrichment',
      })
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <Chip
      clickable={true}
      size="small"
      icon={<RiBardLine size={14} />}
      label={label}
      variant="outlined"
      sx={actionButtonStyle}
      onClick={() => {
        handleRequestPersonEnrichment()
      }}
    />
  )
}

export default PersonEnrichmentButton
