import { useContext, useMemo, useState } from 'react'

import {
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  Typography,
  useTheme,
} from '@mui/material'
import {
  IconBell,
  IconBrandGoogle,
  IconFolderShare,
  IconLogout,
  IconTriangleFilled,
  IconUserEdit,
  IconChevronDown,
} from '@tabler/icons-react'
import { ungatedForSlackNotifications } from 'api/src/lib/gates'

import { Link, routes } from '@redwoodjs/router'

import { useAuth } from 'src/auth'
import { DayContext } from 'src/lib/dayContext'

import ContactAvatar from '../../../components/ContactAvatar/ContactAvatar'

const ProfileController = ({ userCoreContact }) => {
  const theme = useTheme()
  const { suggestedExclusions, selectedWorkspace } = useContext(DayContext)
  const [expanded, setExpanded] = useState<boolean>(false)
  const { logOut } = useAuth()

  const profileBoxHeight = '60px'
  const linkStyle = {
    fontSize: '12px',
    fontWeight: 500,
    letterSpacing: '-0.22px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  }

  const iconStyle = {
    marginRight: '8px',
    height: '16px',
    width: '16px',
    color: theme.palette.text.secondary,
  }

  const handleLogout = () => {
    localStorage.clear()
    logOut()
  }

  const containerSx = useMemo(
    () => ({
      position: 'fixed',
      bottom: 0,
      p: 0,
      zIndex: 10,
      background: (theme) =>
        expanded ? theme.palette.background.paper : 'transparent',
      height: expanded ? '248px' : profileBoxHeight,
      transition: 'all 0.25s ease',
      width: expanded ? '264px' : '54px',
      cursor: 'pointer',
      boxShadow: (theme) => (expanded ? theme.shadows[1] : 'none'),
      border: (theme) =>
        expanded ? `1px solid ${theme.palette.divider}` : 'none',

      '& .profile-controller-inner': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
      },

      '& .profile-controller-inner-top': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: profileBoxHeight,
        px: '10px',
        py: 0,
        cursor: 'pointer',
      },
      '& .MuiListItemButton-root': {
        px: '12px',
        '& .MuiListItemIcon-root': {
          minWidth: '0px',
        },
      },
      '& .user-info': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
      },
    }),
    [expanded]
  )

  return (
    <Box sx={containerSx}>
      <Box className="profile-controller-inner">
        <Box
          className="profile-controller-inner-top"
          onClick={() => setExpanded((prev) => !prev)}
        >
          <Box className="user-info">
            <ContactAvatar
              email={userCoreContact.email}
              size={24}
              borderRadius={100}
            />
            {expanded && (
              <Typography
                sx={{
                  fontWeight: 600,
                  ml: 1,
                  fontSize: '13px',
                  transition: 'all 0.25s ease',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '188px',
                  letterSpacing: '-0.3px',
                }}
              >
                {userCoreContact?.firstName || ''}{' '}
                {userCoreContact?.lastName?.length &&
                userCoreContact?.firstName?.length +
                  userCoreContact?.lastName?.length <
                  24
                  ? userCoreContact.lastName
                  : ''}
              </Typography>
            )}
          </Box>

          {expanded && (
            <Box
              sx={{
                width: '20px',
                transition: 'all 0.25s ease',
              }}
            >
              <IconChevronDown size={20} />
            </Box>
          )}
        </Box>
      </Box>

      {expanded && (
        <List sx={{ p: '0px' }}>
          <ListItemButton
            component={Link}
            to={routes.settings({ id: 'profile' })}
            sx={linkStyle}
          >
            <ListItemIcon>
              <IconUserEdit style={iconStyle} />
            </ListItemIcon>
            Your info
          </ListItemButton>
          <ListItemButton
            component={Link}
            to={routes.settings({ id: 'connectedAccounts' })}
            sx={linkStyle}
          >
            <ListItemIcon>
              <IconBrandGoogle style={iconStyle} />
            </ListItemIcon>
            Google Accounts
          </ListItemButton>
          <ListItemButton
            component={Link}
            to={routes.workspaceSharing()}
            sx={linkStyle}
          >
            <ListItemIcon>
              {suggestedExclusions.length > 0 ? (
                <IconTriangleFilled
                  style={{
                    ...iconStyle,
                    color:
                      suggestedExclusions?.length > 0
                        ? theme.palette.error.main
                        : 'auto',
                  }}
                />
              ) : (
                <IconFolderShare style={iconStyle} />
              )}
            </ListItemIcon>
            Email sharing rules
          </ListItemButton>
          {ungatedForSlackNotifications(selectedWorkspace) && (
            <ListItemButton
              component={Link}
              to={routes.settings({ id: 'notifications' })}
              sx={linkStyle}
            >
              <ListItemIcon>
                <IconBell style={iconStyle} />
              </ListItemIcon>
              Notification preferences
            </ListItemButton>
          )}
          <ListItemButton
            onClick={handleLogout}
            sx={linkStyle}
          >
            <ListItemIcon>
              <IconLogout style={iconStyle} />
            </ListItemIcon>
            Log out
          </ListItemButton>
        </List>
      )}
    </Box>
  )
}

export default ProfileController
