import { useCallback, useContext, useMemo } from 'react'

import { useQuery } from '@apollo/client'

import { Metadata } from '@redwoodjs/web'

import BodyContainer from 'src/components/BodyContainer/BodyContainer'
import MetadataChip from 'src/components/Chips/MetadataChip/MetadataChip'
import DayTable from 'src/components/DataTables/DayTable/DayTable'
import { GET_WORKSPACE_TEMPLATES } from 'src/components/Pages/queries'
import Row from 'src/components/Row/Row'
import TemplateIcon from 'src/components/Templates/TemplateIcon/TemplateIcon'
import { DayContext } from 'src/lib/dayContext'
import { NativeObjectTypes, TemplateTypeMetadata } from 'src/lib/objects'

import { fullHeightSx, pageStates } from '../Pages/pageUtils'

const TemplatesPage = () => {
  const { selectedWorkspace, setSidebarObject } = useContext(DayContext)

  const { data: templatesData, loading } = useQuery(GET_WORKSPACE_TEMPLATES, {
    variables: { workspaceId: selectedWorkspace },
    skip: !selectedWorkspace,
  })

  const memoizedHandleRowClick = useCallback(
    (params) => {
      setSidebarObject({
        objectId: params.row.id,
        objectType: NativeObjectTypes.Page,
        workspaceId: selectedWorkspace,
      })
    },
    [setSidebarObject, selectedWorkspace]
  )

  const data = useMemo(() => {
    return {
      columns: [
        { field: 'title', headerName: 'Title', flex: 1 },
        {
          field: 'state',
          headerName: 'Sharing',
          width: 124,
          valueGetter: (value, row) => {
            if (row.madeExternalAt) return 'PUBLIC'
            else if (row.publishedForUserAt) return 'PUBLISHED'
            return 'DRAFT'
          },
          renderCell: ({ value }) =>
            value && (
              <Row sx={fullHeightSx}>
                <MetadataChip
                  state={{
                    label: pageStates[value].label,
                    color: pageStates[value].color,
                    value,
                  }}
                  icon={pageStates[value].icon}
                />
              </Row>
            ),
        },
        {
          field: 'templateType',
          headerName: 'Output Type',
          type: 'singleSelect',
          editable: true,
          valueOptions: ['EMAIL', 'INTERNAL_PAGE', 'KNOWLEDGE', 'SLACK'],
          renderCell: (params) => {
            const value = params.row.templateType
            return (
              <MetadataChip
                icon={
                  <TemplateIcon
                    templateType={value}
                    size={16}
                  />
                }
                state={{
                  label: TemplateTypeMetadata[value]?.label,
                  value: value,
                }}
              />
            )
          },
        },
        { field: 'updatedAt', headerName: 'Updated At' },
      ],
      rows: templatesData?.workspaceTemplates || [],
    }
  }, [templatesData])

  const containerSx = {}

  return (
    <>
      <Metadata title="Templates" />
      <BodyContainer sx={containerSx}>
        <DayTable
          data={data}
          id="templates"
          title="Templates"
          loading={loading}
          onRowClick={memoizedHandleRowClick}
          rowObjectName="templates"
          rowObjectNameSingular="template"
          workspaceId={selectedWorkspace}
        />
      </BodyContainer>
    </>
  )
}

export default TemplatesPage
