import { useContext, useMemo } from 'react'

import {
  CircularProgress,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material'
import {
  IconCalendarEvent,
  IconUsersPlus,
  IconVideo,
} from '@tabler/icons-react'
import dayjs from 'dayjs'

import Row from 'src/components/Row/Row'
import { DayContext } from 'src/lib/dayContext'
import { logger } from 'src/lib/logger'
import { NativeObjectTypes } from 'src/lib/objects'

import SidebarButton from '../SidebarButton/SidebarButton'

const findMeetingLink = (event) => {
  logger.dev({ event })
  // Check for Google Meet link directly
  if (event.hangoutLink) return event.hangoutLink

  // Check Conference Data
  if (event.conferenceData && event.conferenceData.entryPoints) {
    for (const entry of event.conferenceData.entryPoints) {
      if (entry.uri) return entry.uri
    }
  }

  // Regular expression to identify URLs
  const urlRegex = /(https?:\/\/[^\s"]+)/g

  // Check Location & Description
  const fieldsToCheck = [event.location, event.description]
  for (const field of fieldsToCheck) {
    if (field) {
      const matches = field.match(urlRegex)
      if (matches && matches?.[0]) {
        // Trim quotation marks from the beginning and end of the URL
        const cleanedUrl = matches[0]?.replace(/^"|"$/g, '')
        return cleanedUrl // Return the cleaned URL
      }
    }
  }

  return ''
}

const SidebarMeeting = ({ event, workspaceId }) => {
  const { setSidebarObject } = useContext(DayContext)
  const isUpcoming = new Date(event?.start_time) > new Date()
  const recordingId = event?.CalendarEvent?.[0]?.meetingRecordings?.[0]?.id
  const meetingLink = findMeetingLink(event)

  /*
    onClick:
    - if upcoming, navigate to meeting link, show "join" button
    - if recorded, open recording sidebar
    - if not recorded, open event sidebar

  */

  return (
    event && (
      <ListItemButton
        className="sidebar-meeting-history-item"
        onClick={() => {
          setSidebarObject({
            objectId: event.id,
            objectType: NativeObjectTypes.Event,
            workspaceId,
            properties: event,
          })
        }}
      >
        <ListItemIcon>
          <IconCalendarEvent size={24} />
        </ListItemIcon>
        <ListItemText
          className="sidebar-meeting-history-item-title"
          primary={event.title}
          secondary={
            event?.CalendarEvent?.[0]?.meetingRecordings?.[0]?.summary?.output
              ?.Suggested_Title ||
            dayjs(event?.start_time).format('MMM D, YYYY h:mm A')
          }
        />

        {isUpcoming ? (
          meetingLink ? (
            <SidebarButton
              label="Join"
              icon={<IconUsersPlus />}
              onClick={(e) => {
                e.stopPropagation()
                window.open(meetingLink, '_blank')
              }}
            />
          ) : (
            <Typography className="sidebar-meeting-no-link">
              No meeting link found
            </Typography>
          )
        ) : recordingId ? (
          <SidebarButton
            label="View"
            icon={<IconVideo />}
            onClick={() => {
              setSidebarObject({
                objectId: recordingId,
                objectType: NativeObjectTypes.MeetingRecording,
                workspaceId,
              })
            }}
          />
        ) : null}
      </ListItemButton>
    )
  )
}

const SidebarMeetingHistory = ({
  meetings,
  loading,
  workspaceId,
}: {
  meetings: any
  loading: boolean
  workspaceId: string
}) => {
  const containerSx = {
    p: '0px !important',
    m: '0px !important',
    '& .sidebar-meeting-history-item': {
      pl: 0,
      borderRadius: '8px',
      py: '4px',
      '& .MuiListItemIcon-root': {
        minWidth: '36px',
      },
      '& .MuiListItemText-primary': {
        fontSize: '14px',
        fontWeight: 500,
        letterSpacing: '-0.03px',
      },
      '& .MuiListItemText-secondary': {
        fontSize: '12px',
        opacity: 0.5,
        pt: 0,
      },
    },
    '& .sidebar-meeting-history-item-title': {},
    '& .sidebar-meeting-no-link': {
      opacity: 0.5,
      fontSize: '12px',
      mt: -1,
    },
    '& .sidebar-meeting-history-loading': {
      opacity: 0.5,
      fontSize: '12px',
      mt: -1,
    },
  }

  const allEvents = useMemo(() => {
    if (!meetings) return []
    return [...meetings].sort((a, b) => {
      return new Date(b.start_time).getTime() - new Date(a.start_time).getTime()
    })
  }, [meetings])

  logger.dev({
    allEvents,
  })

  return (
    <List
      sx={containerSx}
      disablePadding={true}
    >
      {Array.isArray(allEvents) && allEvents?.length > 0 ? (
        allEvents.map((event) => (
          <SidebarMeeting
            key={event.id}
            event={event}
            workspaceId={workspaceId}
          />
        ))
      ) : loading ? (
        <Row gap={1}>
          <CircularProgress size={16} />
          <Typography className="sidebar-meeting-history-loading">
            Searching workspace for meetings...
          </Typography>
        </Row>
      ) : (
        <Typography variant="body2">No meeting history found</Typography>
      )}
    </List>
  )
}

export default SidebarMeetingHistory
