import { useCallback, useContext, useRef } from 'react'
import { useMemo, useState } from 'react'

import { Chip, IconButton, ListItemText, Menu, MenuItem } from '@mui/material'
import { Box, Typography } from '@mui/material'
import { IconPlus, IconX } from '@tabler/icons-react'
import toast from 'react-hot-toast'
import type { DayObject } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import Row from 'src/components/Row/Row'
import { DayContext } from 'src/lib/dayContext'
import { logger } from 'src/lib/logger'
import type { RelationshipDirection, RelationshipType } from 'src/lib/objects'
import { NativeObjectTypes, parseRelationshipReference } from 'src/lib/objects'
import {
  buildRelationshipReference,
  NativeRelationshipTypes,
  RelationshipStageDisplay,
} from 'src/lib/objects'
import { StandardProperties } from 'src/lib/Properties/properties'

const UPDATE_ORG_RELATIONSHIP_FROM_SIDEBAR = gql`
  mutation UpdateOrganizationRelationshipFromSidebar(
    $input: UpdateOrganizationRelationshipInput!
  ) {
    updateOrganizationRelationship(input: $input)
  }
`

const CREATE_RELATIONSHIP_FROM_SIDEBAR = gql`
  mutation CreateRelationshipEdgeFromSidebar(
    $input: UpdateOrganizationRelationshipInput!
  ) {
    createRelationshipEdge(input: $input) {
      id
      domain
      relationship {
        id
        types {
          id
          label
          userId
          createdAt
          objectId
          source
          key
          value
        }
      }
    }
  }
`

const RelationshipChip = ({
  objectId,
  objectType,
  relationship,
  handleRemoveRelationship,
}: {
  objectId: string
  objectType: string
  relationship: RelationshipType
  handleRemoveRelationship: ({ objectType, objectId, relationship }) => void
}) => {
  const deleted = useRef(false)

  if (!relationship || !relationship.key) {
    logger.warn('Invalid relationship', { relationship, objectType, objectId })
    return null
  }

  const parsedRelationship = parseRelationshipReference(relationship.key)
  if (!parsedRelationship) {
    logger.warn('Invalid relationship', { relationship, objectType, objectId })
    return null
  }

  const { stage, direction, type } = parsedRelationship
  return (
    relationship.value &&
    !deleted.current && (
      <Chip
        label={
          relationship.value ? relationship.label : `Not ${relationship.label}`
        }
        variant="outlined"
        size="small"
        sx={{
          border: 'none',
          mt: '-1px',
          py: '0px',
          minHeight: '0px',
          height: '20px',
        }}
        deleteIcon={<IconX size={16} />}
        onClick={() => {
          logger.dev('Clicked relationship', { relationship })
        }}
        onDelete={(e) => {
          e.stopPropagation()
          deleted.current = true
          handleRemoveRelationship({
            objectType,
            objectId,
            relationship: {
              stage,
              direction,
              type,
            },
          })
        }}
      />
    )
  )
}

const excludedTypes = ['INTERNAL', 'PERSONAL', 'OTHER', 'MENTOR']

const RelationshipChooser = ({
  workspaceId,
  objectType,
  objectId,
  onCreate,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [selectedStage, setSelectedStage] = useState<string | null>(null)

  const options = useMemo(() => {
    if (!selectedStage) return []

    const allOptions = Object.entries(NativeRelationshipTypes).flatMap(
      ([type, directionSet]) =>
        Object.entries(directionSet).flatMap(([direction, stageSet]) => {
          const stageData = stageSet[selectedStage]
          if (
            !stageData ||
            excludedTypes.includes(type) ||
            excludedTypes.includes(direction)
          ) {
            return []
          }
          return [
            {
              key: buildRelationshipReference({
                type: type as RelationshipType,
                direction: direction as RelationshipDirection,
                stage: stageData.key,
              }),
              label: stageData.label,
              type,
              direction,
              stageKey: selectedStage,
            },
          ]
        })
    )

    // Deduplicate by label, keeping the first occurrence
    const uniqueByLabel = Object.values(
      allOptions.reduce(
        (acc, curr) => {
          if (!acc[curr.label]) {
            acc[curr.label] = curr
          }
          return acc
        },
        {} as Record<string, (typeof allOptions)[number]>
      )
    )

    return uniqueByLabel
  }, [selectedStage])

  return (
    <>
      <IconButton
        onClick={(e) => {
          e.stopPropagation()
          setAnchorEl(e.currentTarget)
        }}
        sx={{ flexShrink: 0, p: '4px', borderRadius: '3px' }}
      >
        <IconPlus size={16} />
      </IconButton>
      <Menu
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null)
        }}
        anchorEl={anchorEl}
      >
        {selectedStage ? (
          <MenuItem
            sx={{
              borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
            }}
          >
            <ListItemText
              primaryTypographyProps={{
                fontWeight: 600,
                letterSpacing: '-0.3px',
              }}
              secondaryTypographyProps={{
                sx: {
                  opacity: 0.8,
                  fontSize: '11px',
                  letterSpacing: '-0.3px',
                },
              }}
              primary={RelationshipStageDisplay[selectedStage].primary}
              //secondary={RelationshipStageDisplay[selectedStage].secondary}
            />
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation()
                setSelectedStage(null)
              }}
            >
              <IconX size={16} />
            </IconButton>
          </MenuItem>
        ) : (
          Object.entries(RelationshipStageDisplay).map(([key, value]) => (
            <MenuItem
              key={key}
              onClick={() => setSelectedStage(key)}
            >
              <ListItemText
                primaryTypographyProps={{
                  sx: {
                    fontWeight: 600,
                    letterSpacing: '-0.4px',
                  },
                }}
                secondaryTypographyProps={{
                  sx: {
                    opacity: 0.8,
                    fontSize: '11px',
                    letterSpacing: '-0.3px',
                  },
                }}
                primary={value.primary}
                secondary={value.secondary}
              />
            </MenuItem>
          ))
        )}
        {selectedStage &&
          options.map((option) => (
            <MenuItem
              key={option.key}
              onClick={(e) => {
                e.stopPropagation()
                const input = {
                  workspaceId,
                  objectType,
                  objectId,
                  relationship: {
                    type: option.type,
                    stage: option.stageKey,
                    direction: option.direction,
                    value: true,
                  },
                }
                onCreate(input)
                setAnchorEl(null)
              }}
            >
              {option.label}
            </MenuItem>
          ))}
      </Menu>
    </>
  )
}

const relationshipDescriptionFields = {
  [NativeObjectTypes.Organization]: ['objective/relationshipOrigin'],
}

const RelationshipDescription = ({ object }: { object: DayObject }) => {
  const objectType = object.objectType
  const descriptionField = relationshipDescriptionFields[objectType]
  if (!descriptionField) return null
  const description = object.properties?.standard?.[descriptionField]
  const propertyDefinition =
    StandardProperties?.[objectType]?.[descriptionField]
  if (!description || !propertyDefinition) return null
  return (
    <Typography className="relationship-description">
      {description.value}
    </Typography>
  )
}

const Relationships = ({ object }: { object: DayObject }) => {
  const { refetchWorkspaceOrganizations } = useContext(DayContext)

  const [updateOrganizationRelationshipFromSidebar] = useMutation(
    UPDATE_ORG_RELATIONSHIP_FROM_SIDEBAR
  )

  const workspaceId = object.workspaceId

  const relationships = object.properties?.relationships || []

  const [createRelationshipEdgeFromSidebar] = useMutation(
    CREATE_RELATIONSHIP_FROM_SIDEBAR
  )

  const handleCreateRelationship = useCallback(
    ({ objectId, relationship }) => {
      const input = {
        workspaceId,
        orgId: objectId,
        type: relationship.type,
        stage: relationship.stage,
        direction: relationship.direction,
        value: true,
      }
      toast.promise(
        createRelationshipEdgeFromSidebar({
          variables: { input },
        }),
        {
          loading: 'Creating relationship...',
          success: () => {
            refetchWorkspaceOrganizations()
            return 'Created relationship'
          },
          error: 'Failed to create relationship',
        }
      )
    },
    [
      createRelationshipEdgeFromSidebar,
      refetchWorkspaceOrganizations,
      workspaceId,
    ]
  )

  const handleRemoveRelationship = useCallback(
    ({ objectId, relationship }) => {
      const input = {
        workspaceId,
        orgId: objectId,
        type: relationship.type,
        stage: relationship.stage,
        direction: relationship.direction,
        value: false,
      }

      toast.promise(
        updateOrganizationRelationshipFromSidebar({
          variables: {
            input,
          },
        }),
        {
          loading: 'Removing relationship...',
          success: () => {
            refetchWorkspaceOrganizations()
            return 'Removed relationship'
          },
          error: 'Failed to remove relationship',
        }
      )
    },
    [
      updateOrganizationRelationshipFromSidebar,
      refetchWorkspaceOrganizations,
      workspaceId,
    ]
  )

  return (
    object && (
      <Box>
        <Box className="section-header-row">
          <Typography className="section-title">Relationship</Typography>
        </Box>
        <Box className="properties">
          <Box className="property-row">
            <Box className="property-edit-container">
              <Box className="property-edit-inner">
                <RelationshipChooser
                  objectType={object.objectType}
                  objectId={object.objectId}
                  onCreate={handleCreateRelationship}
                  workspaceId={workspaceId}
                />
              </Box>
            </Box>
            <Row className="property-row-content">
              <Box className="property-name">Relationships</Box>
              <Box className="property-value">
                <Row gap={1}>
                  {(relationships || []).map((relationship) => (
                    <RelationshipChip
                      key={relationship.key}
                      objectId={object.objectId}
                      objectType={object.objectType}
                      relationship={relationship}
                      handleRemoveRelationship={handleRemoveRelationship}
                    />
                  ))}
                </Row>
              </Box>
            </Row>
            <Row className="property-row">
              <Row className="property-row-content">
                <Box className="property-name">Relationship Origin</Box>
                <Box className="property-value">
                  <RelationshipDescription object={object} />
                </Box>
              </Row>
            </Row>
          </Box>
        </Box>
      </Box>
    )
  )
}

export default Relationships
