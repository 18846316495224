import { useMemo, useReducer, useRef, type Dispatch } from 'react'
import { useCallback, useEffect } from 'react'

import { useLazyQuery } from '@apollo/client'
import type { DayObjectSearchEntry } from 'types/graphql'
import { isUUID } from 'validator'

import { useAuth } from 'src/auth'
import { logger as loggerDev } from 'src/lib/logger'
import {
  NativeObjectTypes,
  ObjectTypeMetadata,
  type NativeObjectType,
} from 'src/lib/objects'

import ObjectsContext from './ObjectsContext'
import type { SyncState } from './ObjectsContext'
import {
  GET_WORKSPACE_OBJECTS_BY_IDS,
  GET_WORKSPACE_OBJECTS_IDS,
  GET_WORKSPACE_OBJECTS_UPDATED_SINCE,
} from './queries'
import {
  objectSyncReducer,
  type ObjectSyncReducerAction,
  type ObjectSyncReducerState,
} from './reducer'
import {
  upsertDayObjects,
  getDayObjectStateByType,
  buildFuseIndex,
  clearDayObjects,
  getDayObjectState,
  getSyncQueueEntries,
  addToSyncQueue,
  getNeverSyncedObjects,
  getDayObjects,
  getDayObjectSearchEntry,
} from './searchIndex'

const loggingEnabled = true

const logger = loggingEnabled
  ? loggerDev
  : {
      dev: () => {},
      info: () => {},
      warn: () => {},
      error: () => {},
    }

const ObjectsProvider = ({
  workspaceId,
  children,
}: {
  workspaceId: string
  children: React.ReactNode
}) => {
  const { currentUser } = useAuth()
  const initializedRef = useRef(false)
  const idsCompleteRef = useRef(new Set<NativeObjectType>())
  const verificationOffsetsRef = useRef<Map<NativeObjectType, number>>(
    new Map()
  )

  const [state, dispatch]: [
    ObjectSyncReducerState,
    Dispatch<ObjectSyncReducerAction>,
  ] = useReducer(objectSyncReducer, null)

  const [getWorkspaceObjectsIds, { loading: idsLoading }] = useLazyQuery(
    GET_WORKSPACE_OBJECTS_IDS
  )

  const [getWorkspaceObjectsByIds, { loading: objectsLoading }] = useLazyQuery(
    GET_WORKSPACE_OBJECTS_BY_IDS
  )

  const [getObjectsUpdatedSince, { loading: objectsUpdatesLoading }] =
    useLazyQuery(GET_WORKSPACE_OBJECTS_UPDATED_SINCE)

  const handleResyncObject = useCallback(
    async (objectId: string, objectType: NativeObjectType) => {
      loggerDev.dev('handleResyncObject', { objectId, objectType })
      const result = await getWorkspaceObjectsByIds({
        variables: {
          workspaceId,
          objectType,
          objectIds: [objectId],
        },
      })

      const objects = result?.data?.workspaceObjectsByIds || []

      loggerDev.dev('handleResyncObject: Got results', {
        objectType,
        objectId,
        objects,
      })

      await upsertDayObjects({
        objects,
        objectType,
        workspaceId,
        synced: true,
      })
    },
    [workspaceId, getWorkspaceObjectsByIds]
  )

  const handleGetObjectIds = useCallback(
    async (objectType: NativeObjectType, offset = 0) => {
      logger.dev('handleGetObjectIds: Starting initial sweep phase', {
        objectType,
        offset,
      })

      const limit = 1000
      const variables = {
        workspaceId,
        objectType,
        offset,
        limit,
      }

      let result
      try {
        result = await getWorkspaceObjectsIds({
          variables,
        })
      } catch (error) {
        // Keep error for initial fetch failure, as it prevents any progress
        logger.error('Failed to get workspace object IDs during sweep', {
          error,
          variables,
        })
        return
      }

      const objects = result?.data?.workspaceObjectsIds || []
      const objectIdsFetched = objects.map((obj) => obj.objectId)

      logger.dev('handleGetObjectIds: Sweep received IDs', {
        objectType,
        offset,
        receivedCount: objectIdsFetched.length,
      })

      let batchProcessingError = false

      if (objectIdsFetched.length > 0) {
        let existingEntries = []
        try {
          logger.dev('handleGetObjectIds: Preparing to check local existence', {
            /* ... */
          })
          existingEntries = await getDayObjects({
            workspaceId,
            objectType,
            objectIds: objectIdsFetched,
          })
          logger.dev('handleGetObjectIds: Result from getDayObjects check', {
            /* ... */
          })
        } catch (error) {
          // Use logger.warn for local DB check failures
          logger.warn('Failed to get existing day objects during sweep', {
            error,
            objectType,
            offset,
            objectIdsChecked: objectIdsFetched.length,
          })
          batchProcessingError = true
          existingEntries = []
        }

        const existingIds = new Set(
          existingEntries.map((entry) => entry.objectId)
        )
        logger.dev('handleGetObjectIds: Created existingIds Set', {
          objectType,
          size: existingIds.size,
        })

        const missingIds = objectIdsFetched.filter((id) => !existingIds.has(id))

        if (missingIds.length > 0) {
          logger.dev('handleGetObjectIds: Found missing IDs during sweep', {
            /* ... */
          })
          try {
            await addToSyncQueue({
              objectType,
              objectIds: missingIds,
              workspaceId,
            })
            logger.dev('handleGetObjectIds: Added missing IDs to sync queue', {
              /* ... */
            })
          } catch (error) {
            // Use logger.warn for queueing failures
            logger.warn(
              'Failed to add missing IDs to sync queue during sweep',
              {
                error,
                objectType,
                offset,
                count: missingIds.length,
              }
            )
            batchProcessingError = true
          }
        } else {
          logger.dev('handleGetObjectIds: No missing IDs found in this batch', {
            objectType,
            offset,
          })
        }

        logger.dev(
          'handleGetObjectIds: Updating offset and potentially completion status',
          { objectType, offset, hadError: batchProcessingError }
        )
        verificationOffsetsRef.current.set(
          objectType,
          offset + objectIdsFetched.length
        )

        const isComplete = objectIdsFetched.length < limit
        if (isComplete) {
          idsCompleteRef.current.add(objectType)
          logger.dev('handleGetObjectIds: ID sweep complete for type', {
            objectType,
            hadError: batchProcessingError,
          })
        }

        if (!batchProcessingError) {
          try {
            const finalCurrentState = await getDayObjectStateByType({
              workspaceId,
              objectType,
            })
            dispatch({
              type: 'UPDATE_SYNC_STATUS_BY_TYPE',
              payload: {
                [objectType]: {
                  ...finalCurrentState[objectType],
                  needsMoreIds: !isComplete,
                },
              },
            })
          } catch (stateError) {
            // Use logger.warn for state update failures
            logger.warn(
              'Failed to get final state after processing sweep batch',
              { stateError, objectType }
            )
          }
        } else {
          logger.warn(
            'Skipping UI state update due to batch processing error',
            { objectType, offset }
          )
        }
      } else {
        idsCompleteRef.current.add(objectType)
        logger.dev(
          'handleGetObjectIds: ID sweep complete (empty batch received)',
          { objectType }
        )
        try {
          const finalCurrentState = await getDayObjectStateByType({
            workspaceId,
            objectType,
          })
          dispatch({
            type: 'UPDATE_SYNC_STATUS_BY_TYPE',
            payload: {
              [objectType]: {
                ...finalCurrentState[objectType],
                needsMoreIds: false,
              },
            },
          })
        } catch (stateError) {
          // Use logger.warn for state update failures here too
          logger.warn('Failed to get final state after empty sweep batch', {
            stateError,
            objectType,
          })
        }
      }
    },
    [workspaceId, getWorkspaceObjectsIds]
  )

  const getObjectsLastUpdatedAt = useCallback(async () => {
    // --- Start: Modification inside getObjectsLastUpdatedAt ---
    // Check if the initial ID sweep is fully complete for ALL types before running update logic.
    // This prevents update checks from running prematurely while sweeps are still finding missing IDs.
    const allIdsSwept = Object.values(NativeObjectTypes)
      .filter((type) => ObjectTypeMetadata[type]?.syncEnabled)
      .every((type) => idsCompleteRef.current.has(type as NativeObjectType))

    if (!allIdsSwept) {
      logger.dev(
        'getObjectsLastUpdatedAt: Skipping update check - Initial ID sweep not complete for all types.'
      )
      return 0 // Indicate no updates were found/processed because sweeps are ongoing
    }
    // --- End: Modification inside getObjectsLastUpdatedAt ---

    //return true // Keep your original debug flag if needed
    if (!process.env.HOST.includes('localhost')) {
      // Consider removing this localhost check if updates should run everywhere
      // return;
    }

    const currentState = await getDayObjectState({
      workspaceId,
    })

    let resultCount = 0

    const mostRecentSyncedAtByObjectType = Object.fromEntries(
      Object.entries(currentState).map(([objectType, state]) => [
        objectType,
        state.updatedAt,
      ])
    )

    const neverSyncedObjects = await getNeverSyncedObjects({
      workspaceId,
    })
    const neverSyncedObjectsByType = neverSyncedObjects.reduce(
      (acc, object) => {
        acc[object.objectType] = [...(acc[object.objectType] || []), object]
        return acc
      },
      {} as Record<NativeObjectType, DayObjectSearchEntry[]>
    )
    for (const objectType of Object.keys(neverSyncedObjectsByType)) {
      if (neverSyncedObjectsByType[objectType].length > 0) {
        resultCount += neverSyncedObjectsByType[objectType].length

        logger.dev(
          'getObjectsLastUpdatedAt: NEVER SYNCED Adding to sync queue',
          {
            objectType,
            objectIds: neverSyncedObjectsByType?.[objectType]?.length,
          }
        )

        await addToSyncQueue({
          objectType: objectType as NativeObjectType,
          objectIds: neverSyncedObjectsByType[objectType].map(
            (object) => object.objectId
          ),
          workspaceId,
        })
      }
    }

    for (const objectType of Object.keys(currentState)) {
      if (!objectType || !mostRecentSyncedAtByObjectType[objectType]) continue

      logger.dev('getObjectsLastUpdatedAt: Checking object type', {
        objectType,
        mostRecentSyncedAt: mostRecentSyncedAtByObjectType[objectType],
      })

      const updatedSinceResults = await getObjectsUpdatedSince({
        variables: {
          workspaceId,
          objectType: objectType as NativeObjectType,
          updatedSince: mostRecentSyncedAtByObjectType[objectType],
        },
      })

      const updatedSinceObjects =
        updatedSinceResults?.data?.workspaceObjectsUpdatedSince || []
      if (updatedSinceObjects.length > 0) {
        resultCount += updatedSinceObjects.length
        logger.dev(
          'getObjectsLastUpdatedAt: UPDATED SINCE Adding to sync queue',
          {
            objectType,
            objectIds: updatedSinceObjects.length,
          }
        )
        for (const object of updatedSinceObjects) {
          await addToSyncQueue({
            objectType: object.objectType as NativeObjectType,
            objectIds: [object.objectId],
            workspaceId,
          })
        }
      }
    }
    return resultCount
  }, [workspaceId, getObjectsUpdatedSince])

  const handleGetWorkspaceObjectsByIds = useCallback(
    async (objectType: NativeObjectType, objectIds: string[]) => {
      if (!initializedRef.current || objectsLoading) {
        return
      }

      if (!workspaceId) {
        logger.warn('No workspaceId to get workspace objects by ids')
        return
      }

      if (objectIds.length === 0) {
        return
      }

      const result = await getWorkspaceObjectsByIds({
        variables: {
          workspaceId,
          objectType,
          objectIds,
        },
      })

      // Add type validation before upserting
      const objects = result?.data?.workspaceObjectsByIds || []
      const validObjects = objects.filter(
        (obj) => obj.objectType === objectType
      )

      await upsertDayObjects({
        objects: validObjects, // Only upsert objects with matching type
        objectType,
        workspaceId,
        synced: true,
      })

      const finalCurrentState = await getDayObjectStateByType({
        workspaceId,
        objectType,
      })
      dispatch({
        type: 'UPDATE_SYNC_STATUS_BY_TYPE',
        payload: {
          [objectType]: finalCurrentState[objectType],
        },
      })

      // After successfully upserting objects, rebuild the Fuse index
      try {
        await buildFuseIndex(workspaceId)
      } catch (error) {
        logger.warn('Failed to rebuild Fuse index:', error)
      }
    },
    [workspaceId, getWorkspaceObjectsByIds, initializedRef, objectsLoading]
  )

  const syncState = useMemo(() => {
    if (!state) return null
    const output = {} as SyncState
    for (const objectType of Object.keys(state).filter(Boolean)) {
      if (ObjectTypeMetadata[objectType]?.syncEnabled) {
        output[objectType as NativeObjectType] = {
          objectType: objectType as NativeObjectType,
          objectsNeedingSync: state[objectType].objectsNeedingSync || 0,
          currentCount: state[objectType].currentCount || 0,
          needsMoreIds: !idsCompleteRef.current.has(
            objectType as NativeObjectType
          ),
          updatedAt: state[objectType].updatedAt || null,
        }
      }
    }
    return output
  }, [state])

  const anyLoading = useMemo(() => {
    return idsLoading || objectsLoading || objectsUpdatesLoading
  }, [idsLoading, objectsLoading, objectsUpdatesLoading])

  const sync = useCallback(async () => {
    const currentState = await getDayObjectState({
      workspaceId,
    })
    if (!currentState) return

    let checkForUpdates = true
    const objectTypeNeedingIds = Object.values(NativeObjectTypes).find(
      (type) =>
        type &&
        ObjectTypeMetadata[type]?.syncEnabled &&
        !idsCompleteRef.current.has(type as NativeObjectType)
    )

    if (objectTypeNeedingIds) {
      logger.dev('sync: Found type needing ID sweep', { objectTypeNeedingIds }) // Log sweep needed
      checkForUpdates = false
      const offset =
        verificationOffsetsRef.current.get(objectTypeNeedingIds) || 0
      await handleGetObjectIds(objectTypeNeedingIds as NativeObjectType, offset)
      return // Return after one sweep batch
    } else {
      logger.dev('sync: All ID sweeps complete. Checking sync queue.') // Log sweep complete
    }

    // --- Start Queue Processing Logging ---
    const syncQueueEntries = await getSyncQueueEntries({
      workspaceId,
    })

    if (!syncQueueEntries || syncQueueEntries.length === 0) {
      logger.dev('sync: Sync queue is empty.')
    } else {
      logger.dev('sync: Found entries in sync queue', {
        count: syncQueueEntries.length,
        sample: syncQueueEntries.slice(0, 3),
      })

      const syncQueueEntriesByType = {}
      for (const entry of syncQueueEntries) {
        syncQueueEntriesByType[entry.objectType] = [
          ...(syncQueueEntriesByType[entry.objectType] || []),
          entry.objectId,
        ]
      }
      logger.dev('sync: Grouped queue entries by type', {
        types: Object.keys(syncQueueEntriesByType),
      })

      for (const objectType of Object.keys(syncQueueEntriesByType)) {
        const objectIdsToQuery = syncQueueEntriesByType[objectType]

        if ((objectIdsToQuery || []).length > 0) {
          logger.dev('sync: Processing queue batch', {
            objectType,
            count: objectIdsToQuery.length,
            sample: objectIdsToQuery.slice(0, 5),
          })
          checkForUpdates = false // Don't check for updates if we process queue
          // *** Add log before calling handleGetWorkspaceObjectsByIds ***
          logger.dev(
            'sync: Calling handleGetWorkspaceObjectsByIds for queue batch',
            { objectType, count: objectIdsToQuery.length }
          )
          await handleGetWorkspaceObjectsByIds(
            objectType as NativeObjectType,
            objectIdsToQuery
          )
          // *** Add log after call completes ***
          logger.dev(
            'sync: Finished handleGetWorkspaceObjectsByIds call for queue batch',
            { objectType }
          )
          return // Return after processing one batch from the queue
        }
      }
      // If loop finishes without finding processable entries (shouldn't happen if grouped correctly)
      logger.dev(
        'sync: Finished iterating queue types, none had IDs to process?'
      )
    }
    // --- End Queue Processing Logging ---

    // Only check for updates if sweeps are done AND queue was empty
    if (checkForUpdates) {
      logger.dev(
        'sync: Proceeding to check for updates via getObjectsLastUpdatedAt'
      )
      await getObjectsLastUpdatedAt()
      return
    } else {
      logger.dev(
        'sync: Skipping update check because sweep or queue processing occurred.'
      )
    }
  }, [
    handleGetObjectIds,
    workspaceId,
    handleGetWorkspaceObjectsByIds,
    getObjectsLastUpdatedAt,
  ])

  const syncInterval = useMemo(() => {
    if (!state) return 1000
    const anyNeedsMoreIds = Object.keys(state).some((objectType) => {
      return !idsCompleteRef.current.has(objectType as NativeObjectType)
    })
    if (anyNeedsMoreIds) return 1500

    const anyBackfilling = Object.values(state).some(
      (objectTypeSyncState) => objectTypeSyncState?.objectsNeedingSync > 0
    )
    if (anyBackfilling) return 1000

    return 30000
  }, [state])

  const syncProcessUnderwayRef = useRef(false)

  useEffect(() => {
    if (!workspaceId) return

    let isMounted = true
    let timeoutId: NodeJS.Timeout

    const runSync = async () => {
      if (!isMounted) return

      try {
        if (syncProcessUnderwayRef.current) return
        syncProcessUnderwayRef.current = true
        await sync()
        syncProcessUnderwayRef.current = false
      } catch (error) {
        logger.warn('Failed to sync:', error)
      } finally {
        syncProcessUnderwayRef.current = false
        // Only schedule the next sync if the component is still mounted
        if (isMounted) {
          logger.dev('Scheduling next sync')
          timeoutId = setTimeout(runSync, syncInterval)
          logger.dev({ timeoutId })
        }
      }
    }

    // Start initial sync
    if (initializedRef.current && workspaceId && !anyLoading) {
      runSync()
    }

    // Cleanup
    return () => {
      isMounted = false
      if (timeoutId) {
        logger.dev('Clearing timeout', { timeoutId })
        clearTimeout(timeoutId)
      }
    }
  }, [workspaceId, syncInterval, sync, currentUser, anyLoading])

  // Keep the initialization effect, but fix the state initialization
  useEffect(() => {
    const initHandler = async () => {
      let initialState = null
      await getDayObjectStateByType({
        workspaceId,
        objectType: 'native_contact',
      })
      initialState = await getDayObjectState({
        workspaceId,
      })
      // Initialize the state with all object types

      if (Object.keys(initialState).length > 0) {
        dispatch({
          type: 'UPDATE_SYNC_STATUS',
          payload: initialState,
        })
      }
    }

    if (!state) {
      dispatch({
        type: 'INITIALIZE_STATE',
        payload: null,
      })
      initializedRef.current = true
    }

    if (!initializedRef.current && workspaceId) {
      initHandler().catch((error) => {
        logger.warn('Failed to initialize state:', error)
      })
    }

    return () => {}
  }, [workspaceId, state, currentUser])

  const resetDayObjects = useCallback(async () => {
    try {
      // Clear the state
      dispatch({ type: 'RESET_STATE', payload: null })

      // Reset initialization flag
      initializedRef.current = false

      // Clear all day objects from the database
      await clearDayObjects()
    } catch (error) {
      logger.warn('Failed to reset sync state:', error)
      throw error // Re-throw to handle in UI
    }
  }, [])

  const updateObject = useCallback(
    async ({
      objectId,
      objectType,
      propertyId,
      propertyValue,
    }: {
      objectId: string
      objectType: NativeObjectType
      propertyId: string
      propertyValue: any
    }) => {
      const keyField = isUUID(propertyId) ? 'custom' : 'standard'
      const currentObjectSearchEntry = await getDayObjectSearchEntry({
        workspaceId,
        objectId,
        objectType,
      })

      const currentObject = currentObjectSearchEntry?.object

      const currentProperty =
        currentObject?.properties?.[keyField]?.[propertyId]

      const newProperty = {
        ...currentProperty,
        value: propertyValue,
      }

      const updatedObject = {
        ...currentObject,
        properties: {
          ...currentObject.properties,
          [keyField]: {
            ...(currentObject?.properties?.[keyField] || {}),
            [propertyId]: newProperty,
          },
        },
      }

      logger.dev('updateObject: Upserting updated object', {
        updatedObject,
      })

      await upsertDayObjects({
        objects: [updatedObject],
        objectType,
        workspaceId,
        synced: true,
      })
    },
    [workspaceId]
  )

  const getObjects = useCallback(
    async ({
      objectType,
      objectIds,
    }: {
      objectType: NativeObjectType
      objectIds: string[]
    }) => {
      const searchObjects = await getDayObjects({
        workspaceId,
        objectType,
        objectIds,
      })
      return searchObjects
    },
    [workspaceId]
  )

  const value = useMemo(
    () => ({
      workspaceId,
      syncState,
      resetDayObjects,
      updateObject,
      getObjects,
      resyncObject: handleResyncObject,
    }),
    [
      workspaceId,
      syncState,
      resetDayObjects,
      updateObject,
      getObjects,
      handleResyncObject,
    ]
  )

  return (
    <ObjectsContext.Provider value={value}>{children}</ObjectsContext.Provider>
  )
}

export default ObjectsProvider
