export const actionQueries = [
  'GetActionsForContactSidebar',
  'GetActionsForInbox',
  'GetActionsForMeetingRecording',
  'GetActionsForOpportunitySidebar',
  'GetActionsForOrganizationSidebar',
  'GetActionsForProduct',
  'GetActionsLibrary',
  'GetActionsToday',
]

export const GET_ACTION_SOURCE_INFO = gql`
  query GetActionSourceInfo($workspaceId: String!, $actionId: String!) {
    actionSourceInfo(workspaceId: $workspaceId, actionId: $actionId)
  }
`

export const ActionTypes = {
  SUPPORT: {
    label: 'Support',
    key: 'SUPPORT',
  },
  FOLLOWUP: {
    label: 'Follow Up',
    key: 'FOLLOWUP',
  },
  MEETINGPREP: {
    label: 'Meeting Prep',
    key: 'MEETINGPREP',
  },
  FEATURE_REQUEST: {
    label: 'Feature Request',
    key: 'FEATURE_REQUEST',
  },
  MEETING_RECORDING_FOLLOWUP: {
    label: 'Meeting Follow Up',
    key: 'MEETING_RECORDING_FOLLOWUP',
  },
  EMAIL_RESPONSE: {
    label: 'Email Response',
    key: 'EMAIL_RESPONSE',
  },
  SCHEDULE_MEETING: {
    label: 'Schedule Meeting',
    key: 'SCHEDULE_MEETING',
  },
  NUDGE: {
    label: 'Nudge',
    key: 'NUDGE',
  },
  OTHER: {
    label: 'Other',
    key: 'OTHER',
  },
}
