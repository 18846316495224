export const UPDATE_CONTEXT = gql`
  mutation UpdateContext(
    $id: String!
    $input: UpdateWorkspaceUserContextInput!
  ) {
    updateWorkspaceUserContext(id: $id, input: $input) {
      id
      parentReferenceKey
      plainTextValue
      userId
      workspaceId
      referencedObjectIds
    }
  }
`

export const CREATE_CONTEXT = gql`
  mutation CreateContext($input: CreateWorkspaceUserContextInput!) {
    createWorkspaceUserContext(input: $input) {
      id
      parentReferenceKey
      plainTextValue
      userId
      workspaceId
      referencedObjectIds
    }
  }
`
