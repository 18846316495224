import { useContext, useState } from 'react'

import { Box, Skeleton, Typography, lighten } from '@mui/material'
import { RiDraggable } from '@remixicon/react'
import { Node, mergeAttributes } from '@tiptap/core'
import { ReactNodeViewRenderer, NodeViewWrapper } from '@tiptap/react'

import { useQuery } from '@redwoodjs/web'

import DomainAvatar from 'src/components/DomainAvatar/DomainAvatar'
import OrganizationSocialLinks from 'src/components/Organizations/OrganizationSocialLinks/OrganizationSocialLinks'
import Row from 'src/components/Row/Row'
import TiptapSignupCta from 'src/components/Tiptap/TiptapSignupCta/TiptapSignupCta'
import { DayContext } from 'src/lib/dayContext'
import { NativeObjectTypes } from 'src/lib/objects'

import { nodeDragHandleSx, nodeViewContainerSx } from '../styles'

const GET_CORE_COMPANY_FOR_TIPTAP = gql`
  query GetCoreCompanyForTiptap($domain: String!) {
    coreCompany(domain: $domain) {
      id
      name
      domain
      description
      industry
      employeeCount
      annualRevenue
      funding
      address
      city
      state
      country
      postalCode
      colorVibrant
      colorDarkVibrant
      colorLightVibrant
      colorMuted
      colorDarkMuted
      colorLightMuted
      photoSquare
      photoIcon
      photoBanner
      stockTicker
      socialTwitter
      socialLinkedIn
      socialFacebook
      socialYouTube
      createdAt
      updatedAt
      edgarCik
      crunchbaseEntityId
      promises
    }
  }
`

const TiptapNodeOrganizationRenderer = (props) => {
  const { setSidebarObject } = useContext(DayContext)
  const [ctaOpen, setCtaOpen] = useState(false)

  const handleClose = () => {
    setCtaOpen((prev) => !prev)
  }

  const domain = props.node.attrs.domain.toLowerCase().trim()
  const { data, loading } = useQuery(GET_CORE_COMPANY_FOR_TIPTAP, {
    variables: {
      domain,
    },
    skip: !domain,
  })

  return (
    <NodeViewWrapper
      as="div"
      className="day-ai-organization"
      draggable={props.editor.isEditable}
      {...{ 'data-drag-handle': '' }}
      onClick={() => {
        if (!props.editor.isEditable && !ctaOpen) {
          setCtaOpen((prev) => !prev)
        } else {
          const crmObject = {
            objectType: NativeObjectTypes.Organization,
            objectId: domain,
            properties: data?.coreCompany,
          }
          setSidebarObject(crmObject)
        }
      }}
    >
      <Row
        sx={{
          ...nodeViewContainerSx,
          border: (theme) =>
            `3px solid ${'transparent' || theme.palette.divider}`,
          height: 'auto',
          minHeight: '196px',
          mb: 2,
          '&.selected': {
            background: lighten('#B4D7FF', 0.8),
            color: '#2867B2',
          },
        }}
        className={`day-ai-node ${
          props.editor.isEditable ? 'editable' : 'public'
        } ${props.selected ? 'selected' : ''}`}
      >
        <Box
          className="drag-handle"
          sx={nodeDragHandleSx}
        >
          <RiDraggable />
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            width: '100%',
          }}
        >
          <>
            {loading ? (
              <Row>
                <Box sx={{ width: '48px', flexShrink: 0 }}>
                  <Skeleton
                    animation="wave"
                    variant="circular"
                    width={48}
                    height={48}
                  />
                </Box>
                <Box sx={{ width: '100%', ml: 2 }}>
                  <Skeleton
                    animation="wave"
                    height={24}
                  />
                  <Skeleton
                    animation="wave"
                    height={16}
                  />
                </Box>
              </Row>
            ) : (
              <>
                <Row sx={{ pb: 2 }}>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: '1.3rem !important',
                      mr: 2,
                    }}
                  >
                    {data?.coreCompany?.name}
                  </Typography>
                  <OrganizationSocialLinks
                    coreCompany={data?.coreCompany}
                    iconSize={14}
                  />
                </Row>
                <Row
                  sx={{
                    alignItems: 'flex-start',
                    pb: 2,
                  }}
                >
                  <Box sx={{ maxWidth: '120px', flexShrink: 0, pr: 2 }}>
                    <DomainAvatar
                      domain={domain}
                      photoUrl={data?.coreCompany?.photoSquare}
                      companyColor={data?.coreCompany?.colorVibrant}
                      size={96}
                      borderRadius={0}
                    />
                  </Box>
                  <Box sx={{ flexShrink: 1 }}>
                    <Typography
                      sx={{
                        fontSize: '0.7rem !important',
                        overflow: 'hidden',
                        display: '-webkit-box',
                        WebkitLineClamp: 6,
                        WebkitBoxOrient: 'vertical',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {data?.coreCompany?.aiDescription ||
                        data?.coreCompany?.description}
                    </Typography>
                  </Box>
                </Row>
              </>
            )}
          </>
        </Box>
      </Row>
      <TiptapSignupCta
        open={ctaOpen}
        setClosed={() => {
          handleClose()
        }}
        text={`See more information about ${data?.coreCompany?.name}`}
        redirectTo="/login"
      />
    </NodeViewWrapper>
  )
}

const TiptapNodeOrganization = Node.create({
  name: 'organization',
  group: 'block',
  atom: true,
  draggable: true,

  addAttributes() {
    return {
      domain: {
        default: null,
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'day-ai-organization',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    const organizationAttributes = mergeAttributes(HTMLAttributes, {
      'data-type': 'organization',
    })
    return ['day-ai-organization', organizationAttributes]
  },

  addNodeView() {
    return ReactNodeViewRenderer(TiptapNodeOrganizationRenderer)
  },
})

export default TiptapNodeOrganization
